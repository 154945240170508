<template>
  <div class="w_pack" :class="{ noSearch: true }">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="min-height: 100vh">
      <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad" :immediate-check="false"
        error-text="请求失败，点击重新加载">
        <div v-if="navItem.item.adsType === 2 && iconHomeAds.length" class="top_banner">
          <AdAvatarList :list="iconHomeAds" :wrap="true" key-name="avatar" />
        </div>
        <div class="top_banner" v-else-if="navItem.item.adsType !== 3 && avAD.length">
          <EADSwiper :EADconfig="EADconfig" :imgCDN="imgCDN" :list="avAD" keyName="cover" @clickImg="activityBtn" />
        </div>
        <!-- 通知栏跑马灯 -->
        <van-notice-bar left-icon="volume" color="#000" background="rgb(238, 238, 238, 0.6)"
          @click="toInfo(runningLight)" :text="runningLight?.content" v-if="runningLight" />
        <div class="swiper-ad" v-if="activityAds.length > 1">
          <!-- 334*180 -->
          <EADSwiper class="ad-list2" :centeredSlides="activityAds.length > 1" :list="activityAds" keyName="img"
            :EADconfig="EADconfig" :imgCDN="imgCDN" :activeIndex="2" :slidesPerView="2" :spaceBetween="0"
            @clickImgBetter="activityBtn2">
          </EADSwiper>
          <div class="mask_ad" v-show="maskFlag"></div>
        </div>
        <div class="tuijian-container">

          <!-- 所有主题   -->
          <ul class="topic-list">
            <li v-for="itemTopic in topicList" :key="itemTopic.id">
              <!-- 1:横版(三排两列)50  -->
              <DmHomeVideo5033Box :imgRadius="'0.15rem'" :showType="'row50'" v-if="itemTopic.showType == 4"
                :itemTopic="itemTopic"></DmHomeVideo5033Box>

            </li>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>

    <div class="book" @click.stop="$router.push('/home-comic/bookshelf')">
      <img src="@/assets/imgs/homec/book_icon.gif" alt="" />
      <!-- <span>书架</span> -->
    </div>
  </div>
  <!-- 动漫卡通 -->
</template>
<script>
import { handleURlParams, handleParamsRouteJump } from '@/utils/utils_tools'
import { novelHome } from 'api/novel'
import { advertiseClick } from 'api/home'
export default {
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    VoimcSwiper: () => import('@/components/VoimcSwiper.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue'),
    DmHomeVideo5033Box: () =>
      import('@/components/HomeNovel/topic/box50-33/index.vue'),
    // CardSwiper: () => import('@/components/HomeNovel/topic/cardSwiper.vue'),
    // DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    // HomeSwiper: () => import('@/components/HomeComic/adSwiper'),
    // DmWaterfall: () => import('@/components/HomeNovel/topic/waterfall.vue'),
    // DmBigRow: () => import('@/components/HomeNovel/topic/bigRow.vue'),

    // DmThreeBox: () => import('@/components/HomeComic/topic/threeBox/index.vue'),
    // DmCollection: () => import('@/components/HomeComic/topic/collection/index.vue'),
    // DmMonthHot: () => import('@/components/HomeComic/topic/monthHot.vue'),
  },
  data() {
    return {
      //加载刷新
      pageNum: 1,
      //正式服需要5即可
      pageSize: 20,
      refreshing: false,
      loading: false,
      finished: false,
      //倒计时
      advertise: '',
      //轮播广告
      newAdvertise: [],
      //主题
      topicList: [],
      cartoonAd: [],
      jumpActive: '',
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      maskFlag: true
    }
  },
  props: ['type', 'showType', 'navItem', 'showRemShortVideoType', 'avAD', 'short_videoAD'],
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    activityAds({ $store }) {
      let data = $store.getters['ActivityAds']
      if (data.length) {
        setTimeout(() => {
          this.maskFlag = false
        }, 1000)
      }
      return data
    },
    iconHomeAds({ $store }) {
      return $store.getters['iconHomeAds']
    },
    // 获取首页推荐位广告
    runningLight({ $store }) {
      return $store.getters['runningLight']
    }
  },
  methods: {
    // 活动广告跳转
    async activityBtn(href) {
      const code = handleURlParams(href)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      const res = await advertiseClick({
        id: href.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    // 轮播跳转
    async activityBtn2(item) {
      if (item.url) {
        let code = handleURlParams(item.url)
        // handleParamsRouteJump(this, code);
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      } else {
        if (item.id) {
          advertiseClick({
            id: item.id
          })
          let url = item.href ? item.href : item.url
          let code = handleURlParams(url)
          handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
        }
      }
    },
    async toInfo(ifno) {
      if (ifno.jumpUrl) {
        const code = handleURlParams(ifno.jumpUrl)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
        const res = await advertiseClick({
          id: ifno.id
        })
        if (res !== 200) {
          return this.$toast(res.tip)
        }
      }
    },
    // 首页数据请求
    async getList(type) {
      console.log()
      const res = await novelHome({
        id: this.type,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
      if (res.code === 200 && res.data) {
        this.refreshing = false
        this.loading = false
        //倒计时
        this.advertise = res.data.advertise
        this.newAdvertise = res.data.newAdvertise

        if (
          !res.data ||
          !res.data.topicList ||
          res.data.topicList.length < this.pageSize
        ) {
          this.finished = true
        }
        if (type == 'pull') this.topicList = []
        if (res.data.topicList)
          this.topicList = [...this.topicList, ...res.data.topicList]
      } else {
        this.finished = true
        this.refreshing = false
        this.loading = false
        return this.$toast(res.tip)
      }
    },
    //上拉加载更多
    async onLoad() {
      this.pageNum += 1
      this.getList()
    },
    // 刷新
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.getList('pull')
    },
    // 顶部轮播图点击事件
    clickImg(item) {
      let code = handleURlParams(item.href, this.$router)
    },
    ambientColor(item) {
      this.$emit('ambientColor', item)
    },
  },
  mounted() {
    this.cartoonAd = this.$store.getters['config/videosAd']
    this.onRefresh()
  },
}
</script>

<style lang="scss" scoped>
.w_pack {
  padding-top: 2.7rem;

  :deep() {
    .van-notice-bar {
      height: 0.6rem;
      margin: 0 0.24rem;
      // border-radius: 1rem;
      margin-top: 0.1rem;
      box-shadow: 0px 4px 4px 0px #00000040;
      opacity: 0.6;

      .van-icon {
        color: #000;
      }

      .van-notice-bar__wrap {
        font-size: 0.26rem;
      }
    }
  }

  .tuijian-container {

    //头部+搜索+padding
    // padding-top: calc(0.77rem + 1.39rem );
    // 轮播图

    .topic-list {
      // border-radius: .4rem .4rem 0 0;
      background: #fff;
    }

    // 轮播图
    .vant-swiper {
      margin: 0 auto;
      width: 6.78rem;

      .my-swipe {}
    }



  }

  .book {
    position: fixed;
    right: 0;
    bottom: 6.4rem;
    width: 3rem;
    height: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    // border-radius: 0.2rem;
    // background: #ff669dcc;
    color: #000000;
    // font-size: 0.4rem;
    z-index: 20;

    img {
      width: 100%;
    }
  }
}

.top_banner {

  // height: 4rem;
  .JGbtnList {
    padding: 0 0.3rem;
  }
}

// 运营活动广告
.swiper-ad {
  width: 100%;
  padding: 0 .23rem;
  // height: 1.8rem;
  background: #fff;
  margin: 0.24rem auto;
  position: relative;

  .ad-list1 {
    border-radius: 0.08rem;
    margin: 0 auto;
    overflow: hidden;
  }

  .ad-list2 {
    width: 100%;
    height: 1.5rem;

    :deep {
      .default {
        border-radius: 0.08rem;
        overflow: hidden;
        width: 3.2rem;
        height: 1.5rem;
      }
    }
  }

  .mask_ad {
    width: 7.3rem;
    height: 2rem;
    position: absolute;
    left: -0.1rem;
    top: -0.1rem;
    background-color: #fff;
    z-index: 200;
  }
}
</style>
