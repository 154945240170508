<template>
  <van-swipe class="my-swipe" ref="cardSwiper" :stop-propagation="true" indicator-color="white" :autoplay="autoplay">
    <van-swipe-item
      class="item"
      :class="{ radius: !radius }"
      :style="{ height: height }"
      v-for="item in imgs"
      :key="item.id"
    >
      <DecryptImgNoLazy
        class="resetImg"
        :imgURL="item.cover || item.value || item.avatar || item"
        @clickImg="clickImg(item)"
        ref="refImg"
      />
      <!-- <DecryptImg
          :imgURL="item.cover || item.value || item"
          class="img-box"
          :needPadding="false"
          @clickImg="clickImg(item)"
        ></DecryptImg> -->
    </van-swipe-item>
  </van-swipe>
</template>

<script>
export default {
  components: {
    DecryptImgNoLazy: () => import('@/components/DecryptImgNoLazy/index.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
  },
  props: {
    imgs: {
      type: Array,
      default() {
        return []
      },
    },
    height: {
      type: String,
      default() {
        return '3.8rem'
        // return "3.16rem"
        // return "auto"
      },
    },
    autoplay: {
      type: Number,
      default() {
        return 5000
        // return 100000
      },
    },
    radius: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  computed: {},
  data() {
    return {
      timeOut: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.timeOut = setTimeout(() => {
        this.$refs.cardSwiper ? this.$refs.cardSwiper.resize() : ''
      })
    })
  },
  methods: {
    clickImg(item) {
      this.$emit('clickImg', item)
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
  },
}
</script>

<style lang="scss" scoped>
.my-swipe {
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    .resetImg {
      height: auto;
    }
  }
  .radius {
    ::v-deep {
      .warp {
        border-radius: 0;
        img {
          border-radius: 0;
        }
      }
    }
  }
}
</style>
