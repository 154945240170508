import { render, staticRenderFns } from "./index.vue?vue&type=template&id=60604277&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "../../../static/css/swiper.min.css?vue&type=style&index=0&id=60604277&prod&scoped=true&lang=css&"
import style1 from "./index.vue?vue&type=style&index=1&id=60604277&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60604277",
  null
  
)

export default component.exports