<template>
  <div class="history">
    <EBackTop :title="'浏览记录'" background="#fff" color="#000">
      <div class="right-btn" slot="right" @click="onClickRight">
        {{ btnTxt }}
      </div>
    </EBackTop>
    <ETab class="myHistory-tab" @change="change" :sticky="true" :tabList="cartoonCategory" :v-model="indexActive"
      :animated="false" :lineBg="'#FF669D'" :lineHeight="'0.08rem'" :line-width="'0.3rem'"
      :title-active-color="'#FF669D'" :title-inactive-color="'#333'" :sizeTab="'0.3rem'" :swipe-threshold="4"
      :sizeActive="'.3rem'">
      <template v-slot:default="scope">
        <component ref="childDom" cardType="history" :is="compComponent(scope.data)" :showMask="showMask"
          @checkList="checkList"></component>
      </template>
    </ETab>

    <!-- 弹窗 -->
    <van-popup class="pay-pop" v-model="showType" @close="cancelConfirm" close-icon="cross" position="bottom"
      lazy-render>
      <div @click="confirm">删除</div>
      <div @click="cancelConfirm">取消</div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //文章列表
      historyList: [],
      spliceList: [],
      indexActive: 0,
      showType: false,
      btnTxt: '编辑',
      showMask: false,
      cartoonCategory: [
        { id: 0, showType: 1, name: '长视频' },
        { id: 1, showType: 2, name: '短视频' },
        { id: 2, showType: 5, name: '帖子' },
        { id: 3, showType: 9, name: '约炮' },
        { id: 4, showType: 15, name: '小说' },
        { id: 5, showType: 17, name: '色图' }
      ]
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    LongVideoItem: () => import('@/components/HistoryComponents/LongVideoItem.vue'),
    ShortVideoItem: () => import('@/components/HistoryComponents/ShortVideoItem.vue'),
    dating: () => import('@/components/HistoryComponents/dating.vue'),
    CommunityDefault: () => import('@/components/HistoryComponents/CommunityItem.vue'),
    Photo: () => import('@/components/HistoryComponents/photo.vue'),
    Novel: () => import('@/components/HistoryComponents/novel.vue'),
  },

  created() { },
  computed: {
    // 获取长视频观看记录列表
    avList({ $store }) {
      return $store.getters['avList']
    },
    // 获取动漫视频观看记录列表
    cartoonList({ $store }) {
      return $store.getters['cartoonList']
    },

    postList({ $store }) {
      return $store.getters['postList']
    }
  },
  methods: {
    compComponent(type) {
      switch (type) {
        case 1:
          return 'LongVideoItem'
        case 2:
          return 'ShortVideoItem'
        case 5:
          return 'CommunityDefault'
        case 9:
          return 'dating'
        case 15:
          return 'Novel'
        case 17:
          return 'Photo'
        default:
          return 'LongVideoItem'
      }
    },

    //切换
    change(id) {
      this.indexActive = +id
    },
    // 编辑按钮
    onClickRight() {
      if (this.btnTxt == '编辑') {
        this.showMask = true
        return (this.btnTxt = '删除')
      }
      this.showType = true
    },
    //取消
    cancelConfirm() {
      this.showMask = false
      this.showType = false
      this.btnTxt = '编辑'
    },
    //确定删除
    confirm() {
      if (this.indexActive === 0) {
        this.$store.dispatch('setLongVideoList', { type: 'del', arr: this.spliceList })
      } else if (this.indexActive === 1) {
        this.$store.dispatch('setShortList', { type: 'del', arr: this.spliceList })
      } else if (this.indexActive === 2) {
        this.$store.dispatch('setPostList', { type: 'del', arr: this.spliceList })
      } else if (this.indexActive === 3) {
        this.$store.dispatch('setYpHisList', { type: 'del', arr: this.spliceList })
      } else if (this.indexActive === 4) {
        this.$store.dispatch('setNovelHisList', { type: 'del', arr: this.spliceList })
      }
      else if (this.indexActive === 5) {
        this.$store.dispatch('setphotoHisList', { type: 'del', arr: this.spliceList })
      }
      this.$refs.childDom.refreshData()
      this.showMask = false
      this.showType = false
      this.btnTxt = '编辑'
    },
    checkList(arr) {
      console.log(arr)
      this.spliceList = arr
    }
  }
}
</script>
<style lang="scss" scoped>
.history {}

.myHistory-tab {
  :deep() {
    .van-sticky {
      position: fixed;
      top: 0.9rem;
      left: 0;
      right: 0;
      z-index: 99;
    }

    .van-tabs__nav {
      justify-content: flex-start;
    }

    .van-tab {
      flex: none;
    }
  }
}

.right-btn {
  display: inline-flex;
  padding: 0.04rem 0.16rem;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
  border-radius: 0.24rem;
  border: 1px solid $btnBg;
  color: $txtActive;
  font-size: 0.24rem;
}

.pay-pop {
  background-color: transparent;

  div {
    margin: 0 auto;
    width: 7rem;
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
    color: $txtActive;
    background-color: #fff;
    margin-top: 0.24rem;
    border-radius: 0.1rem;
    font-weight: 600;
    font-size: 0.32rem;
  }
}

.nodata {
  line-height: 60vh;
  font-size: 0.26rem;
  color: #ccc;
  text-align: center;
}
</style>
