<template>
  <div class="exchange-container">
    <EBackTop :title="'兑换码'" />
    <van-pull-refresh   style="min-height: calc(100vh - 1rem);"  v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="redeemcodeList.length?'':''"
        @load="onLoad"
      >
      <div class="exchange-codeWrap">
        <div class="btn" @click="submitRedeemcode">提交</div>
        <input v-model="redeemcode" placeholder="请输入您的兑换码" type="text">
      </div>
      <ul class="exchange-listWrap">
        <li class="item item-title">
          <div class="left top">
            <div>兑换码</div>
          </div>

          <div class="center  top">
            <div>兑换类型</div>
          </div>
          <div class="right  top">
            <div>兑换时间</div>
          </div>
        </li>
        <li class="item item-decial" v-for="(item,index) in redeemcodeList" :key="index">
          <div class="left left-add">
            {{item.code}}
          </div>
          <div class="center center-add">
            {{item.desc}}
          </div>
          <div class="right right-add">
            {{item.activedAt.split('T')[0]}}
          </div>
        </li>
        <div class="nodata" v-if="!redeemcodeList.length">暂时没有数据</div>
      </ul>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { redeemcode_user, redeemcode_list } from 'api/user'
export default {
  data() {
    return {
      redeemcode: '',
      loading: false,
      finished: false,
      // 下拉刷新
      refreshing: false,
      // 兑换列表
      redeemcodeList: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 50
    }
  },

  components: {
  },
  methods: {
    // 获取关注列表
    async getRedeemcodeList(type) {
      const res = await redeemcode_list({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200) {
        this.refreshing = false
        this.loading = false
        if (!res.data.list) {
          res.data.list = []
        }
        if (type === 'pull') this.redeemcodeList = []
        this.redeemcodeList = [...this.redeemcodeList, ...res.data.list]
        if (res.data && res.data.list.length < this.pageSize) {
          this.finished = true
        }
      } else {
        this.$toast(res.tip)
        this.refreshing = false
        this.loading = false
        this.finished = true
      }
    },
    // 下拉加载
    onLoad() {
      this.pageNum++
      this.getRedeemcodeList()
    },
    // 上拉刷新
    onRefresh() {
      this.finished = false
      this.loading = true
      this.pageNum = 1
      this.getRedeemcodeList('pull')
    },
    // 提交兑换码
    async submitRedeemcode() {
      if (!this.redeemcode) return this.$toast('请输入兑换码！！!')
      const res = await redeemcode_user({
        code: this.redeemcode.trim()
      })
      if (res.code === 200) {
        this.redeemcode = ''
        this.$toast('恭喜兑换成功！！！')
        this.onRefresh()
      } else {
        this.redeemcode = ''
        this.$toast(res.tip)
      }
    }
  },
  mounted() {
    this.onRefresh()
  },
  created() {
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
.exchange-container{
  color: #333;
  min-height: 100vh;
  box-sizing: border-box;
  // background: #f1f5fb;
  .exchange-codeWrap{
    position: relative;
    box-sizing: border-box;
    margin: .5rem .3rem;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: .09rem;
    box-sizing: border-box;
    font-size: 0;
    height: .8rem;
    input{
      width: 100%;
      border: 0;
      outline: none;
      background: #fff;
      font-size: .26rem;
      line-height: .6rem;
      border-radius: .05rem;
      text-indent: .15rem;
      height: 100%;
  
    }
    .btn{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -1px;
      padding: 0 .2rem;
      width: 1.2rem;
      height: .8rem;
      line-height: .8rem;
      background: $btnBg;
      font-size: .26rem;
      color: #fff;
      border-radius: .05rem;
    }
  }
  .exchange-listWrap{
    margin: 0 .3rem;
    background: #fff;
    padding: .15rem .15rem .6rem .15rem;
    min-height: 40vh;
    border-radius: .1rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .26rem;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      line-height: .48rem;
      color: #848494;

      
      &.item-title{
        border-bottom: 1px solid #eee;
      }

      .left{
        width: 33.33%;
      }
      .center{
        width: 33.33%;
      }
      .right{
        width: 33.33%;
      }
    }
    .nodata{
      line-height: 40vh;
      font-size: .26rem;
      color: #ccc;
      text-align: center;
    }
  }
}
</style>
