<template>
  <div class="index-label">
    <img src="@/assets/imgs/mine/iconLeft.png" alt="" class="icon-left" @click="onClickLeft" />
    <div class="header-content">
      <img src="@/assets/imgs/mine/myInfoBg.png" alt="" class="labelBg" />
      <div class="labelData">
        <div class="labelAvatar">
          <EDecryptImg :imgCDN="imgCDN" :imgURL="publisher.avatar" class="item-bg"></EDecryptImg>
        </div>
        <div class="user_name">
          <span class="nick_name">{{ publisher.nickname }}</span>
          <span v-if="publisher.cardName" class="card_name">{{ publisher.cardName }}</span>
        </div>
        <div class="labelText">
          <span>粉丝 {{ publisher.fans }}</span>
          <div class="fans_right">
            认证：{{ publisher.nickname }}
            <!-- 2原创作者 3up主 4创作者 -->
            <img
              v-if="publisher.userType === 2 || publisher.userType === 3 || publisher.userType === 4"
              style="width: 0.32rem; height: 0.32rem; margin-left: 0.14rem"
              src="@/assets/imgs/renz.svg"
              alt=""
            />
          </div>
        </div>
        <div :class="publisher.isCare ? 'isFollow' : 'follow'" @click="toFollow">
          {{ publisher.isCare ? '已关注' : '+关注' }}
        </div>
      </div>
    </div>
    <!--切换面板 -->
    <div class="label-pack">
      <ETab
        ref="postTabs"
        class="label-tab"
        @change="change"
        :sticky="true"
        :tabList="category"
        v-model="type"
        :animated="false"
        :lineSwitch="true"
        :lineBg="'#FF669D'"
      :lineHeight="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#FF669D'"
      :title-inactive-color="'#333'"
      :sizeTab="'0.32rem'"
      :sizeActive="'.38rem'"
      >
        <ERefreshLoad
          @refreshData="refreshData"
          @moreData="moreData"
          :finished="finished"
          :loading="loading"
          :refreshing="refreshing"
        >
          <div class="list_data">
            <template v-for="(item, index) in mediaList">
              <CommunityDefault v-if="type == 5" :key="index" :itemData="item" />
            </template>
          </div>
        </ERefreshLoad>
      </ETab>
    </div>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { user_media } from 'api/community'
import { care_add } from 'api/home'
export default {
  mixins: [scrollMixin],
  data() {
    return {
      category: [
        { name: '帖子', id: 5 },
        // { name: '好茶约炮', id: 6 },
        // { name: '嫩模空降', id: 4 }
      ],
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,
      finished: false,
      mediaList: [],
      type: 0,
      publisher: {}
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    CommunityDefault: () => import('@/components/Community/default.vue'),
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  activated() {
    this.type = this.category[0].id

    this.refreshData()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    // 关注
    async toFollow() {
      if (this.userInfo.loginType == 0) {
        return this.$toast('仅注册的用户才能关注')
      }
      // 关注用户
      try {
        const res = await care_add({
          id: +this.$route.query.id,
          add: !this.publisher.isCare
        })
        if (res.code === 200) {
          this.publisher.isCare = !this.publisher.isCare
          if (this.publisher.isCare) {
            return this.$toast('关注成功')
          } else {
            return this.$toast('取消关注')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    async fn_labelTopicList() {
      const res = await user_media({
        userId: +this.$route.query.id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        collectType: this.type
      })
      if (res.code === 200 && res.data) {
        console.log('res.data', res.data)
        this.loading = false
        this.refreshing = false
        this.publisher = res.data
        if (this.type == 5) {
          this.mediaList = [...this.mediaList, ...res.data.postList]
          if (res.data.postList.length < this.pageSize || !res.data.postList) {
            this.finished = true
          }
        } else {
          this.mediaList = [...this.mediaList, ...res.data.modelList]
          if (res.data.modelList.length < this.pageSize || !res.data.modelList) {
            this.finished = true
          }
        }
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip)
      }
    },
    change(type) {
      this.type = type
      this.refreshData()
    },
    compComponent(showType) {
      switch (showType) {
        case 4:
          return 'DiWangTJOneCard'
        case 5:
          return 'CommunityDefault'

      }
    },
    // 上拉加载更多
    moreData(loading) {
      this.loading = true
      this.pageNum += 1
      this.fn_labelTopicList()
    },
    // 下啦刷新
    refreshData() {
      this.refreshing = true
      this.loading = true
      this.finished = false
      this.mediaList = []
      this.pageNum = 1
      this.fn_labelTopicList()
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.index-label {
  .icon-left {
    position: fixed;
    left: 0.49rem;
    top: 1.05rem;
    width: 0.6rem;
    height: 0.6rem;
    flex-shrink: 0;
    z-index: 9;
  }

  .label-pack {
    background: #fff;
    border-radius: 0.42rem 0.42rem 0 0;

    position: relative;
    margin-top: -0.5rem;
    padding-top: 0.32rem;

    .label-tab {
      :deep() {
        .van-sticky {
          z-index: 99;
          padding: 0 0.16rem;

          .van-tab {
            flex: inherit;
          }
        }

        .van-tabs__nav {
          background: transparent !important;
        }
      }
    }
  }

  .header-content {
    position: relative;
    height: 4.8rem;
    width: 100%;
    max-width: $pcMaxWidth;

    .labelBg {
      -webkit-filter: brightness(35%);
      /*考虑浏览器兼容性：兼容 Chrome, Safari, Opera */
      filter: brightness(35%);
    }

    .labelData {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .labelAvatar {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        overflow: hidden;
        margin-top: 0.45rem;
      }

      .labelText {
        @include flexbox();
        margin-top: 0.25rem;

        .fans_right {
          margin-left: 0.24rem;
        }
      }

      .user_name {
        margin-top: 0.32rem;
        @include flexbox($jc: center);

        .nick_name {
          color: #d4d4d4;
          font-size: 0.32rem;
          font-weight: 340;
        }

        .card_name {
          border-radius: 0.04rem;
          background: #ffcf86;
          padding: 0rem 0.12rem;
          color: #333;
          margin-left: 0.32rem;
        }
      }

      .follow {
        border-radius: 1.04rem;
        border: 0.01rem solid $btnBg;

        padding: 0.06rem 0.24rem;
        color: $btnBg;
        margin-top: 0.24rem;
      }
    }

    img {
      width: 100%;
    }
  }
}

.isFollow {
  border-radius: 1.04rem;
  background: #292929;
  padding: 0.06rem 0.24rem;
  color: $btnBg;
  margin-top: 0.24rem;
}
.list_data {
  @include flexbox($fw: wrap, $ai: flex-start);
  padding: 0.48rem 0.36rem;
}
</style>
