<template>
  <div class="aboutAGun">
    <div class="swiper">
      <!-- <div class="banner">
                <AdAvatarList :list="iconSqAds" :wrap="true" key-name="avatar" />
            </div> -->
      <div v-if="getOriginalAdType === 2 && iconSqAds.length" class="banner">
        <AdAvatarList :list="iconSqAds" :wrap="true" key-name="avatar" />
      </div>
      <!-- <ZHSwiper
        v-else-if="getOriginalAdType !== 3"
        @clickImg="clickImg"
        class="top-swiper"
        :height="'2rem'"
        :imgs="myAds"
      ></ZHSwiper> -->
    </div>
    <div class="posts-container">
      <ul class="nav-wrap">
        <li
          :class="{ active: item.name == navItem.name }"
          v-for="(item, index) in tabList"
          :key="index"
          @click="fn_navActiveTxt(item)"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="cityReload_ul">
        <DatingLoufeng :showType="1" ref="cityReload_loufeng" v-if="navItem.name == '最新资讯'" :cityItem="cityItem">
        </DatingLoufeng>
        <!-- <DatingModel :showType="2" ref="cityReload_model" v-else-if="navItem.name == '商家推荐'" :cityItem="cityItem">
        </DatingModel> -->
        <DatingBoss ref="cityReload_boss" v-else-if="navItem.name == '入驻商家'" :cityItem="cityItem"> </DatingBoss>
      </div>
    </div>
    <div class="post-btn" @click="toPublish">
      <span class="add">+</span>
      <span>发布</span>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/plugins/event-bus.js'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
export default {
  name: 'squarePage',
  data() {
    return {
      cityItem: null,
      navItem: { name: '最新资讯', id: 1, type: 'loufeng' },
      tabList: [
        { name: '最新资讯', id: 1, type: 'loufeng' },
        // { name: '商家推荐', id: 2, type: 'model' },
        { name: '入驻商家', id: 3, type: 'boss' }
      ]
    }
  },
  components: {
    // ZHSwiper: () => import('@/components/ZHSwiper.vue'),
    HeaderBar: () => import('@/components/HeaderBar.vue'),
    DatingLoufeng: () => import('@/components/Dating/self-employed/index.vue'),
    // DatingModel: () => import('@/components/Dating/model/index.vue'),
    DatingBoss: () => import('@/components/Dating/boss/index.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },
  methods: {
    clickImg(item) {
      let code = handleURlParams(item.href)
      handleParamsRouteJump(this, code)
    },
    //选中
    fn_navActiveTxt(item) {
      this.navItem = item
    },
    //地区刷新
    // fn_cityReload() {
    //     this.$refs['cityReload_' + this.navItem.type].onRefresh()

    // },
    //发布
    toPublish() {
      if (this.$store.state.user.info.vipType == 11) {
        this.$router.push({
          path: `/dating/publish`,
          query: {
            datingType: this.navItem.id == 1 ? 1 : 2
          }
        })
      } else {
        return this.$toast('亲，只有商家才能发布上门哦！！！')
      }
    }
  },
  mounted() {
    //监控城市更新
    EventBus.$on('reloadCity', item => {
      this.cityItem = item
    })
  },
  computed: {
    iconMineAds({ $store }) {
      return $store.getters['iconMineAds']
    },
    // 社区 列表icon iconSqAds
    iconSqAds({ $store }) {
      return $store.getters['iconSqAds']
    },
    getOriginalAdType({ $store }) {
      return $store.state.config.config.mineAdsType
    }
    // 圈子广告banner
    // myAds({ $store }) {
    //   return $store.getters['config/myAds']
    // }
  }
}
</script>

<style lang="scss" scoped>
.aboutAGun {
  position: relative;
  padding-top: 1rem;
  .swiper {
    margin: 0 0.24rem;

    .top-swiper {
      border-radius: 0.1rem;
      overflow: hidden;
    }
  }
}

.location {
  position: fixed;
  z-index: 9999;
  top: 0.3rem;
  right: 50%;
  margin-right: -3.4rem;
  padding: 0.1rem 0.23rem;
  box-shadow: 0 0.02rem 0.3rem 0 rgb(0 0 0 / 10%);
  background: #fff;
  border-radius: 0.12rem;

  .wrap {
    overflow: hidden;
    white-space: nowrap;
    max-width: 1rem;
    display: flex;
    align-items: center;
    color: #ff8b00;

    img {
      width: 0.17rem;
      margin-right: 0.05rem;
    }
  }
}

.posts-container {
  min-height: calc(100vh - 2rem);

  //   padding-top: $navHeight;
  padding-bottom: 1rem;
  position: relative;
  overflow: hidden;

  .nav-wrap {
    display: flex;
    justify-content: center;
    margin: 0.2rem 0 0.1rem 0;

    li {
      position: relative;
      @include flex-center;
      text-align: center;
      font-size: 0.34rem;
      height: 0.34rem;
      margin: 0.24rem 0;
      padding: 0 0.2rem;
      color: #999;
      border-left: 0.04rem solid #d9d9d9;

      &.active {
        color: #333;
        font-weight: 600;
      }
    }

    li:first-child {
      border-left: none;
    }
  }

  .cityReload_ul {
    background-repeat: no-repeat;
    background-size: contain;
    // margin-top: 1rem;
  }

  .container-tab {
    .van-sticky {
      max-width: $pcMaxWidth;
    }
  }
}

.post-btn {
  right: 0;
  position: fixed;
  bottom: 2.23rem;
  padding: 0.1rem 0.3rem;
  background: #ff669d;
  z-index: 100;
  display: flex;
  color: #fff;
  align-items: center;
  border-radius: 60px 0px 0px 60px;
  .add {
    width: 0.33rem;
    height: 0.33rem;
    border-radius: 50%;
    background-color: #fff;
    color: #ff669d;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.1rem;
    font-size: 0.3rem;
  }
}
</style>
