<template>
  <div class="luck-draw">
    <EBackTop title="幸运大抽奖">
      <template #right>
        <router-link to="/mine/lottery/history" class="md-mine-property-info">
          <p style="color: #F96491;font-size: .28rem;">中奖记录</p>
        </router-link>
      </template>
    </EBackTop>
    <!-- <SwiperShow :imgList="lotteryAd" v-if="lotteryAd && lotteryAd.length" /> -->
    <div class="contentBox">


      <div class="notc">
        <van-notice-bar :left-icon="require('@/assets/imgs/mine/laba.svg')" :scrollable="false">
          <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in lightList" :key="index">恭喜用户 {{ item.name }} 抽中 {{ item.prize
            }}!</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>

      <div class="buttons" v-if="list.length>0">
        <!-- <van-button class="btn1" type="primary" @click="move('free')">每日免费抽奖</van-button> -->
        <!-- <van-button class="btn2" type="primary" @click="move('gold')">10金币抽奖</van-button> -->
      </div>
      <div class="gameBox" v-if="list.length>0">
        <div class="bg1"></div>
        <div class="bg2" v-show="lampShow"></div>
        <div class="start" @click="move('free')">
          <p>剩余次数：{{ number_of_draws }}</p>
        </div>
        <ul>
          <li v-for="(item, i) in list" :key="i" :class="['item' + (i + 1), { 'active': index == i }]">
            <div class="img1">
              <EDecryptImg :imgCDN="imgCDN" :lazy="false" class="md-img" :imgURL="item.cover" />
            </div>
            <p>{{ item.name }}</p>
          </li>
        </ul>
      </div>

      <!-- <div class="monbuyBox">
        使用xxx金币购买抽奖次数
        <div class="btn">点击购买</div>
      </div> -->
      <div class="myPrize">
        <div class="title">我的奖品</div>
        <div class="prize" style="min-height: 2rem;" v-if="historyList && historyList.length
        ">
          <div class="item" v-for="(item,index) in historyList" :key="index">
            <span>获得{{ item.prizeName }}</span>
            <span>         {{ item.createdAt | timeYmd }}</span>
          </div>
        </div>
        <div class="prize" style="min-height: 2rem;text-align: center;line-height: 2rem" v-else>暂无奖品哦～</div>
      </div>

      <!-- <div class="myPrize">
        <div class="title">活动奖品如下</div>
        <div class="prize">
          <div class="text" v-for="(item,index) in list" :key="index">{{ item.name }}  {{ item.desc }}</div>
        </div>
      </div> -->


      <div class="myPrize">
        <div class="title"></div>
        <div class="prize">
          <div class="text" style="white-space: pre-wrap;">{{ prizeDec }}</div>

          <!-- <div class="textt">
            一、各实物奖项
          </div>
          <div class="text">中奖后请联系 <span style="color: #FE6187;">官方TG：@guanfangkefu007</span>
            提供有效凭证（账户id、二维码、绑定号码），
            并提供有效收货地址，兑奖后15个工作日内安
            排发货（兑奖截止2023年5月15日）；</div>
          <div class="textt">
            二、空降外围奖
          </div>
          <div class="text">嫩模空降支持全国所有省市，有效期一年
            中奖用户联系 <span style="color: #FE6187;">官方TG：@guanfangkefu007</span> 官方TG：@guanfangkefu007
            提供有效凭证（账户id、二维码、绑定号码）一
            年内随时下单（截止2024年5月7日）；</div>
          <div class="textt">
            三、8888现金奖
          </div>
          <div class="text">中奖后请联系 <span>官方TG：@guanfangkefu007</span>
            提供有效凭证（账户ID、二维码、绑定号码）
            并登记收款账户姓名、卡号（目前仅支持中国
            银行、招商银行工商银行、建设银行）；亦可
            领取等值USDT（仅支持TRC20），需提供收款
            地址（兑奖截止2023年5月15日）</div>
          <div class="textt">
            四、其余奖项
          </div>
          <div class="text">中奖后系统将自动发放至用户账号，若出现未
            到账的状况，请于app内联系客服，核对中奖信
            息，解决奖品未到账问题。</div> -->
        </div>


      </div>
    </div>
    <van-dialog v-model="popShow" title="恭禧您中奖啦" confirm-button-color="#FF669D" @confirm="confirm">


      <EDecryptImg :imgCDN="imgCDN" :lazy="false" class="md-img2" :imgURL="prize_data.cover" />
    </van-dialog>

    <van-popup v-model="showDialogVip">
      <div class="vip-buy-dialog">
        <div>此抽奖为专属活动，</div>
        <div>
          请前往
          <span>开通活动会员！</span>
        </div>
        <div @click="$router.push('/mine/newRecharge?type=1')">开通VIP</div>
        <div @click="showDialogVip = false">离开</div>
      </div>
    </van-popup>
    <van-popup v-model="showNoMoney">
      <div class="vip-buy-dialog">
        <div>很遗憾,您的金币余额不足</div>
        <div>
          <span></span>
        </div>
        <div @click="$router.push('/mine/newRecharge?type=0')">前往充值</div>
        <div @click="showNoMoney = false">离开</div>
      </div>
    </van-popup>

    <van-popup v-model="showDialogGold">
      <div class="gold-buy-dialog">
        <!-- <img src="/images/spring/draw_result_bg.png" alt="" /> -->
        <div>
          确认花费
          <span> {{ lotteryData.lotteryGoldPrice  | changeMoneyYuan }}</span>
          金币
        </div>
        <div>获得一次抽奖机会吗？</div>
        <div @click="goldDraw">是的，我确定</div>
        <div @click="showDialogGold = false">算了，我怂了</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getLotteryList, checkLottery, lotteryHistory } from 'api/user'
export default {
  name: 'Lottery',
  components: {
    SwiperShow: () => import('@/components/Swiper/index.vue'),
  },
  computed: {
    info({ $store }) {
      return $store.getters['getUserInfo']
    },
    lotteryAd({ $store }) {
      return $store.getters['lotteryAd']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  data() {
    return {
      lotteryData: {}, // 抽奖数据
      popShow:false,
      lightList: [],
      historyList:[],
      prizeDec: '',
      list: [],//奖品列表
      index: 0, // 当前转动到哪个位置，第一次起点位置0,对应页面item1位置
      count: 8, // 总共有多少个位置
      times: 0, // 转动跑格子次数,
      cycle: 60, // 转动基本次数：即至少需要转动多少次再进入抽奖环节
      speed: 120, // 初始转动速度
      lampShow: false,//开始抽奖，灯光闪烁
      timer: 0, // 转动定时器
      lamp: 0, // 灯光定时器
      prize: 0, // 中奖位置，接口返回
      number_of_draws: 10,//限制每天抽奖次数，接口返回
      prize_data: {//中奖信息
        id: Number,//奖品ID
        name: '',//奖品名称
        number: Number,//奖品数量
        image: '',//奖品图片
        type: Number,// 奖品类型
      },
      showDialogVip: false,
      showDialogGold: false,
      money: 0,
      standard: 0,
      showNoMoney: false,
    }
  },
  mounted() {
    this.getList()
    this.getHistory()
    let bal = 0
    if(this.info.balance) {
      bal = Number((this.info.balance/100).toFixed(2))
      this.money = JSON.parse(JSON.stringify(bal))
    }
  },
  methods: {
    goldDraw() {
      //金币抽奖
      // this.drawType = 2;
      this.sureDraw();
    },
    sureDraw() {
      if(this.money) {
        if(this.standard > this.money) {
          // this.$toast("对不起，您的金币不足");
          this.showNoMoney = true
        } else {
          this.move('gold')
        }
      } else {
        // this.$toast("对不起，您的金币不足");
        this.showNoMoney = true
      }
      this.showDialogGold = false

    },
    confirm(){
      this.getList()
     this.getHistory()
    },

    async getHistory() {
      const res = await lotteryHistory({pageNum:1,pageSize:10})
      if (res && res.code === 200) {
        this.historyList = res.data.list?.slice(0,3)

      }
    },

    async getList() {
      const res = await getLotteryList()
      if (res && res.code === 200) {
        this.lotteryData = res.data;
        this.standard = Number((this.lotteryData.lotteryGoldPrice / 100).toFixed(2))
        this.list = res.data.list; // 奖品列表数据
        this.number_of_draws = res.data.lotteryFreeLeftCount;
        this.lightList = res.data.runningLights
        this.prizeDec = res.data.lotteryDescription
      }
    },
    //点击开始抽奖
    async move(type) {
      if (!this.lotteryData.isLottery) {
        this.showDialogVip = true;
      }else if (this.number_of_draws == 0 && type == 'free') {
        // this.$toast('您没有抽奖次数');
        this.showDialogGold = true
      } else if (this.times != 0) {
        this.$toast('正在抽奖中，请勿重复点击')
      } else {
        if (type == 'free') {
          this.number_of_draws--;//抽奖开始，次数-1
        }

        const res = await checkLottery({ type: type == 'free' ? 1 : 2 })

        if (res && res.code === 200) {
          this.lamp = setInterval(() => { //灯光闪烁开启
            this.lampShow = !this.lampShow;
          }, 500)
          this.startRoll();//执行抽奖


          this.speed = 120;//每次抽奖速度初始化为200
          this.prize = this.list.findIndex(item => item.id == res.data.prize.id)
          this.prize_data = res.data.prize
          if(type == 'gold') {
            this.money = this.money - this.standard
          }
        } else {
          this.$toast(res.tip);
        }

        // this.speed = 200;//每次抽奖速度初始化为200
        // this.prize_data = res.data.yes;//已经拿到中奖信息，页面展示，等抽奖结束后，将弹窗弹出
        // this.prize = res.data.yes.id -1;//中奖位置赋值，跑马灯最终停留位置，这里实际位置需要-1



      }
    },
    // 开始转动
    startRoll() {
      this.times += 1; // 转动次数
      this.oneRoll(); // 转动过程调用的每一次转动方法，这里是第一次调用初始化
      this.usePrize();
    },

    // 每一次转动
    oneRoll() {
      let index = this.index; // 当前转动到哪个位置
      const count = 8; // 总共有多少个位置
      index += 1;
      if (index > count - 1) {
        index = 0;
      }
      this.index = index;
    },

    usePrize() {
      // 如果当前转动次数达到要求 && 目前转到的位置是中奖位置
      if (this.times > this.cycle + 10 && this.prize === this.index) {
        clearTimeout(this.timer); // 清除转动定时器
        clearTimeout(this.lamp); // 清除灯光定时器
        this.lampShow = false; // 白色灯隐藏
        this.times = 0; // 转动跑格子次数初始化为0，可以开始下次抽奖

        if (this.prize_data.result) {
          this.popShow = true
          // congsole.log('未中奖，谢谢参与');//未中奖提示弹出，
        } else {
          this.popShow = true
          // congsole.log('中奖啦');//中奖弹出提示
        }
      } else {
        if (this.times < this.cycle - 20) {
          this.speed -= 4; // 加快转动速度
        } else {
          this.speed += 10; // 抽奖即将结束，放慢转动速度
        }
        this.timer = setTimeout(this.startRoll, this.speed);//开始转动
      }
    },
  },


};
</script>
<style  lang="scss" scoped>
.md-img2{
  width: 2.1rem !important;
  height: 2.1rem!important;
  margin: .2rem  auto;
  object-fit: cover;

}
.gameBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.52rem;
  height: 6.52rem;
  margin: 0 auto;
  border-radius: 8px;
  // background: url(../../common/images/home/game1.png)no-repeat left top;
  background: url('../../../assets/imgs/mine/cjbg.png') no-repeat no-repeat center;
  background-size: 100% 100%;
  position: relative;

  .bg1 {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg2 {
    position: absolute;
    left: 4.5px;
    top: 4px;
    width: 317px;
    height: 317px;
    // background: url(../../common/images/home/game2.png)no-repeat center;
    background-size: 317px 317px;
  }

  .start {
    position: relative;
    // padding-top: 70px;
    display: flex;
    flex-direction: column-reverse;
    width: 1.9rem;
    height: 1.9rem;
    background: url('../../../assets/imgs/mine/start.png') no-repeat no-repeat center;
    background-size: 100% 100%;

    // background: url(../../common/images/home/game4.png)no-repeat center;
    p {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }

  ul {
    li {
      position: absolute;
      width: 1.9rem;
      height: 1.9rem;
      background: url('../../../assets/imgs/mine/cjbj2.png') no-repeat no-repeat center;
      background-size: 100% 100%;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img1 {
        // margin: 15px auto 3px;
        margin-top: .35rem;
        width: 1.4rem;
        height: .7rem;

        :deep() {
          img {
            width: 100%;
            object-fit: cover;
          }

        }

        img {
          width: 100%;
          height: auto;
        }
      }

      p {
        // text-align: center;
        font-size: 13px;
        font-weight: 500;
        color: rgba(153, 153, 153, 1);
      }
    }

    .item1 {
      left: .2rem;
      top: .2rem
    }

    .item2 {
      left: 2.3rem;
      top: .2rem;
    }

    .item3 {
      left: 4.4rem;
      top: .2rem;
    }

    .item4 {
      left: 4.4rem;
      top: 2.3rem;
    }

    .item5 {
      left: 4.4rem;
      top: 4.4rem;
    }

    .item6 {
      left: 2.3rem;
      top: 4.4rem;
    }

    .item7 {
      left: .2rem;
      top: 4.4rem;
    }

    .item8 {
      left: .2rem;
      top: 2.3rem;
    }

    .active {
      border: 3px solid #FF669D;
      // background: #FFF2B1;

    }
  }
}









:deep() {
  .ad-warp {
    margin: 0 !important;
  }

  .van-notice-bar {
    border-radius: 200px;
    background-color: #FFE3EA;
    color: #2E2E2E;
  }
}

.myPrize {
  width: 6.5rem;
  // height: 2.58rem;
  margin: .36rem auto;
  border-radius: 0px 0px 10px 10px;
  border: 6px solid #FFC5D3;
  background: #fff;

  .title {
    width: 100%;
    height: .58rem;
    text-align: center;
    color: #fff;
    background-color: #FFC5D3;
    font-size: .26rem;

  }

  .prize {
    width: 100%;
    // height: .58rem;
    font-size: .26rem;
    color: #2E2E2E;
    background-color: #fff;
    padding: .1rem 0;

    .item {
      display: flex;
      justify-content: space-between;
      padding: .1rem .3rem;
    }
  }

  .textt {
    color: #7A7A87;
    font-weight: 500;
    padding: .28rem .3rem;
  }

  .text {
    padding: 0rem .3rem;
    line-height: .5rem;
  }

  // margin: 0 auto;
}

.buttons {
  display: flex;
  justify-content: center;
  padding: .6rem 1rem;

  .btn1 {
    border-radius: 8px;
    width: 2.4rem;
    height: .56rem;
    background-color: #FF669D;
    color: #fff;
    border-color: #FF669D;
  }

  .btn2 {
    border-radius: 8px;
    width: 2.4rem;
    height: .56rem;
    border: 3px solid var(--unnamed, #FF669D);
    background: #FFF;
    color: #FF669D;
  }
}

.notc {
  padding: 0 0.26rem;
}

.notice-swipe {
  height: .58rem;
  line-height: .58rem;
}

.contentBox {
  background: #FFF8FA;
  padding: .36rem 0rem;
  background: url('../../../assets/imgs/mine/cjbg4.png') no-repeat no-repeat center;
  background-size: 100% 100%;
}

.luck-draw {
  background: #FFF8FA;
  margin: 0 atuo;
  // background: #FFE3EA;
}

.luck-draw-box .luck-draw-turntable {
  // height: 590px;
  width: 100%;
  height: 100%;
}

.luck-draw-turntable table {
  width: 100%;
  height: 100%;
}

.luck-draw-turntable table tr .luck-draw-button {
  cursor: pointer;
  font-size: 14px;
}

td.active {
  background: #f9ea5d;
}

.luck-draw-turntable table tr {
  width: 100%;
  // height: 180px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

table tr td {
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 16px;
  background: #fff;
  // line-height: 130px;

  position: relative;
  overflow: hidden;
  text-align: center;
  background: url('../../../assets/imgs/mine/cjbj2.png') no-repeat no-repeat center;
  background-size: 100% 100%;
}

.luck-draw-box {
  height: 6.5rem;
  width: 6.5rem;
  margin-left: .5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: url('../../../assets/imgs/mine/cjbg.png') no-repeat no-repeat center;
  background-size: 100% 100%;
  // background: skyblue;
}

.monbuyBox {
  width: 6.5rem;
  // height: 2.58rem;
  margin: .36rem auto;
  height: .8rem;padding: 0 .25rem 0 .3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffc5d3;
  border-radius: .12rem;
  .btn {
    width: 1.5rem;
    height: .5rem;
    border-radius: .1rem;
    line-height: .5rem;
    text-align: center;
    background-color: #fff;
  }
}

.gold-buy-dialog,
.vip-buy-dialog {
    width: 5.96rem;
    height: 5.46rem;
    // background: url("/images/spring/gold_draw.png") no-repeat transparent;
    background: url("../../../assets/imgs/gold_draw.png") no-repeat transparent;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.52rem;
    > div {
      color: #333;
      font-family: PingFang SC;
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
      &:first-child {
        margin-bottom: 0.24rem;
        > span {
          color: #e21e1e;
        }
      }
      &:nth-child(2) {
        margin-bottom: 0.48rem;
      }

      &:nth-of-type(n + 3) {
        border-radius: 2rem;
        background: #e21e1e;
        width: 3.2rem;
        height: 0.72rem;
        @include flex-center;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 500;
      }
      &:nth-of-type(4) {
        background: #e5d8d8;
        color: #a89090;
        margin-top: 0.32rem;
      }
    }
  }
  .vip-buy-dialog {
    > div {
      &:nth-child(2) {
        > span {
          color: #e21e1e;
        }
      }
    }
  }
  .van-popup {
    background: none;
  }
</style>
