<template>
  <div class="bookshelf-wrap">
    <!-- 右上下拉列表 -->
    <!-- 书籍分组 -->
    <main class="main-list novel">
      <div class="item-wrap">
        <Tab @change="change" :titles="category" :active="activeTab" titleInactiveColor="#666" titleActiveColor="#333">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad"
              :immediate-check="false">
              <ul class="bookshelf-content">
                <template v-if="bookshelfList.length">
                  <DmNovelCard :checked.sync="bookMap[item['id']]" :can-checked="menuObj.showSetting" :show-bg="false"
                    :show-desc="false" v-for="(item, index) in bookshelfList" :item="item" :key="index"
                    :showType="item.isAudio ? 3 : 2">
                    <template #status="scope">
                      <div class="serialStatus">
                        <div v-if="scope.updateStatus === 1" class="finished">
                          已完结·共{{ scope.newChapter }}章
                        </div>
                        <div v-else class="loading">
                          连载中·更新{{ scope.newChapter }}章
                        </div>
                      </div>
                    </template>
                  </DmNovelCard>
                </template>
                <!--                <NoData v-else></NoData>-->
              </ul>
            </van-list>
          </van-pull-refresh>
        </Tab>
      </div>
    </main>
    <!-- 下面设置列表  -->
    <transition name="van-fade">
      <div class="footer-wrap" v-if="menuObj.showSetting">
        <div class="wrap">
          <ul>
            <li @click="toChooseAll" class="footer-item top">
              <div class="text">
                {{ isAllChecked ? '取消全选' : '全选' }}
              </div>
            </li>
            <li>|</li>
            <li class="footer-item">
              <div :class="[{ del: checkedLength }, 'text']" @click="fn_delBook">
                删除{{ checkedLength ? `(${checkedLength})` : '' }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </transition>

    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>
<script>
import { novelTrackList } from 'api/novel'
import { collect_list, collect_del } from 'api/user'
export default {
  props: {
    menuObj: {
      type: Object,
      default() {
        return {
          showSetting: false,
        }
      },
    },
  },
  components: {
    DmPopup: () => import('@/components/Popup/index.vue'),
    HeaderBar: () => import('@/components/HeaderBar.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    NoData: () => import('@/components/noData.vue'),
    DmNovelCard: () => import('@/components/HomeNovel/oneCard'),
    Tab: () => import('@/components/Tab.vue'),
  },
  data() {
    return {
      //下拉
      toggle: '',
      //store书架
      bookshelfList: [],
      //目前选中几本书
      selBookNumber: 0,
      category: [
        { name: '最近更新', id: 0 },
        { name: '最新关注', id: 1 },
        // { name: '最近阅读', id: 2 },
      ],
      pageNum: 1,
      pageSize: 20,
      activeTab: 0,
      bookMap: {},
      loading: false,
      refreshing: false,
      finished: false,
    }
  },
  computed: {
    checkedArr() {
      return Object.keys(this.bookMap).reduce((arr, id) => {
        if (this.bookMap[id]) {
          arr.push(+id)
        }
        return arr
      }, [])
    },
    bookshelfListLength() {
      return this.bookshelfList.length
    },
    isAllChecked: {
      get() {
        return this.bookshelfListLength === this.checkedLength
      },
      set(val) {
        Object.keys(this.bookMap).forEach((id) => {
          this.bookMap[id] = val
        })
      },
    },
    checkedLength() {
      return this.checkedArr.length
    },
  },
  methods: {
    toGoPath(item) {
      this.$router.push(`/home-comic/decial/${item.id}`)
    },
    //移动书籍
    fn_moveBook(item) { },
    //顶置
    toChooseAll() {
      this.isAllChecked = !this.isAllChecked
    },
    //删除收藏书籍
    fn_delBook() {
      this.$refs['popupMsg'].popupMsg = {
        show: true,
        title: '确定删除',
        content: `确定删除选中收藏吗？`,
        type: 'small-text',
        ok: '确定',
        cancel: '',
        position: 'center',
        cb: async (returnData) => {
          this.$refs['popupMsg'].popupMsg.show = false
          const res = await collect_del({
            collectType: 15,
            objectIds: this.checkedArr,
            type: 3,
          })
          if (res.code === 200) {
            this.$toast('取消收藏成功！')
            this.bookshelfList = this.bookshelfList.filter(
              (item) => !this.checkedArr.includes(item.id)
            )
            this.bookMap = this.bookshelfList.reduce((obj, item) => {
              // obj[item.id] = this.bookMap[item.id] !== undefined ? this.bookMap[item.id] :false
              obj[item.id] = false
              return obj
            }, {})
          } else {
            this.$toast(res.data.msg || res.tip)
          }
        },
      }
    },
    //书籍选择
    fn_bookSel(item) { },
    //重置书本选中
    fn_resetSelbook() { },
    change(val) {
      this.activeTab = val
      this.onRefresh()
    },
    getTrackList(type) {
      // const res = await collect_list({
      //     type: 15,
      //     pageNum: this.pageNum,
      //     pageSize: this.pageSize
      //   })
      //   if (res.code === 200) {
      //     this.list = [...this.list, ...res.data.mediaList]
      //   } else {
      //     this.$toast(res.tip)
      //   }
      collect_list({
        collectType: 15,
        type: 1,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        sort: this.activeTab
      }).then((res) => {
        this.refreshing = false
        this.loading = false
        if (type === 'pull') this.bookshelfList = []
        if (
          !res.data ||
          !res.data.list ||
          res.data.list.length < this.pageSize
        ) {
          this.finished = true
        }
        if (res.data.novelList)
          this.bookshelfList = [...this.bookshelfList, ...res.data.novelList]
        this.bookMap = this.bookshelfList.reduce((obj, item) => {
          // obj[item.id] = this.bookMap[item.id] !== undefined ? this.bookMap[item.id] :false
          obj[item.id] = false
          return obj
        }, {})
      })
    },
    onLoad() {
      this.pageNum += 1
      this.getTrackList()
    },
    //上拉刷新
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.getTrackList('pull')
    },
  },

  async mounted() {
    this.getTrackList()
  },
}
</script>

<style lang="scss" scoped>
.bookshelf-wrap {

  //书籍列表
  .main-list {
    .item-wrap {
      position: relative;
      padding: 0 0 0.2rem;

      .share {
        position: fixed;
        top: 1.57rem;
        right: 0.37rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        z-index: 100;

        &-icon {
          width: 0.3rem;
          height: 0.3rem;
          background: url('/newAppMh/home-comic/bookshelf/share.png') center center/100% 100% no-repeat;
        }

        &-text {
          font-size: 0.3rem;
          margin-left: 0.08rem;
        }
      }

      .bookshelf-content {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        gap: 0.1rem;
        padding: 0.28rem 0.37rem 0;

        li {
          display: flex;
          flex-direction: column;
          width: 2.1rem;
          margin-bottom: 0.2rem;

          .md-img {
            height: 2.86rem;
          }

          .decial-wrap {
            width: 100%;
            border-radius: 0.12rem;

            .decial {
              display: flex;
              flex-direction: column;

              div {
                &:first-child {
                  padding-top: 0.12rem;
                  font-size: 0.28rem;
                  color: #6a6a6a;
                }

                &:last-child {
                  font-size: 0.18rem;
                  color: #a0a0a0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  white-space: normal;
                }
              }
            }
          }
        }
      }
    }

    ul {
      //max-height: 7.72rem;
      overflow-y: auto;
      animation: fadeIn 0.5s;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      li {
        display: flex;
        align-items: center;

        .radio {
          padding-left: 0.37rem;
          padding-right: 0.3rem;

          img {
            width: 0.39rem;
            height: 0.39rem;
          }
        }

        .book-img {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 1.12rem;
          margin-top: 0.4rem;
          z-index: 2;

          .md-img-bg1 {
            display: inline-block;
            background: red;
            opacity: 0.15;
            width: 90%;
            height: 0.4rem;
            top: -0.1rem;
            border-top-left-radius: 0.1rem;
            border-top-right-radius: 0.1rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }

          .md-img-bg2 {
            content: '';
            display: inline-block;
            background: red;
            opacity: 0.1;
            width: 80%;
            height: 0.4rem;
            top: -0.2rem;
            border-top-left-radius: 0.1rem;
            border-top-right-radius: 0.1rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }

          .md-img {
            position: relative;
            height: 1.53rem;
          }
        }

        .book-title {
          margin-top: 0.04rem;
          display: inline-block;
          border-radius: 0.04rem;
          padding: 0.04rem 0.06rem;
          font-size: 0.22rem;
          background: rgba(153, 153, 153, 0.08);
          color: #999999;

          &.hasRead {
            background: rgba(255, 139, 0, 0.08);
            color: #FF669D;
          }
        }

        &.selPadding {
          padding-left: 0.37rem;

          .radio {
            display: none;
          }
        }
      }
    }
  }

  //底部导航
  .footer-wrap {
    position: fixed;
    bottom: 1rem;
    width: 100%;
    max-width: $pcMaxWidth;
    z-index: 999;
    background: transparent;
    height: 1.47rem;

    .wrap {
      width: 6.8rem;
      height: 100%;
      margin: 0 auto;
      background: transparent;
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;

      ul {
        width: 100%;
        height: 1.17rem;
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 0.9);
        border: 0.02rem solid #ffc700;
        box-shadow: 0px 5px 19px rgba(113, 113, 113, 0.25);
        border-radius: 0.24rem;

        .footer-item {
          position: relative;
          @include flex-column-center;
          flex: 1;
          font-size: 0.2rem;

          .text {
            margin-top: 0.06rem;
            font-size: 0.34rem;

            &.del {
              @include flex-center;
              width: 1.44rem;
              height: 0.64rem;
              background: #FF669D;
              border-radius: 0.12rem;
              color: #ffffff;
            }
          }
        }

        //图片大小,位置
        li {
          &.top {
            .ico {
              width: 0.6rem;
            }
          }

          &.group {
            position: relative;

            .ico {
              width: 0.42rem;
            }
          }

          &.hc {
            position: relative;

            .ico {
              width: 0.51rem;
            }

            &::before {
              border-radius: 0.1rem;
              position: absolute;
              top: 0.3rem;
              content: '';
              width: 0.48rem;
              height: 0.4rem;
              background: #fc9d9f;
              opacity: 0.5;
            }
          }

          &.share {
            .ico {
              width: 0.43rem;
            }
          }

          &.del {
            .ico {
              width: 0.37rem;
            }
          }
        }
      }
    }
  }

  //操作-组
  .group-wrap {
    z-index: 1002;
    display: flex;
    flex-direction: column;
    color: #6a6a6a;
    background: #fff;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.5rem;
    border-radius: 0.15rem;

    .item {
      box-shadow: 0 0.01rem 0.2rem rgb(0 0 0 / 10%) inset;
      text-align: center;
      border-bottom: 1px solid #eee;
      padding: 0.1rem 0.2rem;

      &:first-child {
        border-top-left-radius: 0.15rem;
        border-top-right-radius: 0.15rem;
      }

      &:last-child {
        border-bottom: 0;
        border-bottom-left-radius: 0.15rem;
        border-bottom-right-radius: 0.15rem;
      }

      &.active {
        color: #FF669D;
      }
    }

    animation: fadeInGroup 0.5s;

    @keyframes fadeInGroup {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  .group-wrap-mask {
    z-index: 1000;
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.2);
  }
}
</style>
