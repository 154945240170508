<template>
  <!-- 更多专题更多列表,一行3个 -->
  <div class="home-comic-more">
    <HeaderBar color="#fff" :background="'transparent'" :placeholder="false"></HeaderBar>
    <div class="md-img">
      <div class="textBack">
        <h2>{{ $route.query.name }}</h2>
        <p>共{{ $route.query.num }}部</p>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad" :immediate-check="false"
        error-text="请求失败，点击重新加载">
        <div class="list-wrap">
          <ul>
            <HomeVideoCard v-for="(item, index) in comicsList" :imgRadius="'0.15rem'" :item="item" :key="index">
            </HomeVideoCard>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { moreList } from 'api/novel'
export default {
  components: {
    DmComicCard: () => import('@/components/HomeNovel/oneCard'),
    // HeaderBar: () => import('@/components/HeaderBar.vue'),
    HeaderBar: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    HomeVideoCard: () => import('@/components/HomeNovel/oneCard'),
  },
  data() {
    return {
      //加载刷新
      pageNum: 1,
      pageSize: 9,
      refreshing: false,
      loading: false,
      finished: false,
      comicsList: []
    }
  },
  methods: {
    async comicsAuthor(type) {
      const res = await moreList({
        id: +this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        //   sort: 0       
      });
      if (res.code === 200 && res.data) {
        this.refreshing = false;
        this.loading = false;
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true;
        }
        if (type == "pull") this.comicsList = [];
        if (res.data.list) this.comicsList = [...this.comicsList, ...res.data.list];
      } else {
        this.finished = true;
        this.refreshing = false;
        this.loading = false;
        return this.$toast(res.tip)
      }
    },
    //上拉加载更多
    async onLoad() {
      this.pageNum += 1
      this.comicsAuthor();
    },
    // 刷新
    onRefresh() {
      this.pageNum = 1;
      this.finished = false;
      this.loading = true;
      this.comicsAuthor('pull');
    },
  },
  mounted() {
    this.onRefresh();
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
.home-comic-more {
  min-height: 100vh;
  padding-bottom: 0.1rem;

  .md-img {
    width: 100%;
    height: 4rem;
    margin-bottom: .2rem;
    background-image: url('/newAppMh/novelBg.png');
    background-size: cover;
    position: relative;

    .textBack {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: #fff;
      // padding-left: .3rem;
      padding-top: .4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.55);

      h2 {
        margin: 0;
        font-size: .4rem;
        line-height: .4rem;
      }

      p {
        margin: 0;
        font-size: .24rem;
        line-height: .24rem;
        margin-top: .6rem;
        border-radius: 32px;
        border: 1px solid #FFF;
        padding: .16rem .32rem;
      }
    }
  }

  .list-wrap {
    padding: 0 0.29rem;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 2.16rem;
      }
    }
  }
}
</style>
