<template>
  <div class="enter-container">
    <div class="top_banner">
      <EADSwiper :imgCDN="imgCDN" :list="lotteryTopAd"  keyName="cover" @clickImg="clickImg" />
    </div>
    <div class="recommemd-list">
      <div class="recommemd-list-title">
        <span></span>
        <span>推荐-热门</span>
      </div>
      <ul>
        <li v-for="item in getRecreationList" :key="item.id">
          <div class="item">
            <!-- <img :src="item.avatar" alt="" /> -->
            <EDecryptImg :imgCDN="imgCDN" class="avatar" :imgURL="item.avatar" />
            <div>
              <p>{{ item.name }}</p>
              <p v-if="item.downTotal">下载次数: {{ Math.floor((item.downTotal / 10000) * 100) / 100 }} W次</p>
              <p v-else>下载次数: {{ Math.floor((item.downloads / 10000) * 100) / 100 }} 次</p>
            </div>
          </div>
          <div @click="toOtherApps(item)">立即进入</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { appsClick, advertiseClick } from 'api/home'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'

export default {
  data() {
    return {
      EADconfig: {
        speed: 300,
        autoplay: false,
        loop: true,
        delay: 4000
      },
      pageParams: {
        pageNum: 1,
        pageSize: 10
      },

    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },

    // 获取轮播广告
    lotteryTopAd({ $store }) {
      return $store.getters['lotteryTopAd']
    },
    // 获取娱乐列表
    getRecreationList({ $store }) {
      console.log( $store.getters['getRecreationList']);
      return $store.getters['getRecreationList'];
    },
  },
  methods: {
    clickImg(item) {
        if (!item.href) return;
      const code = handleURlParams(item.href)
         advertiseClick({
        id: item.id,
      }).then((res) => {
        if (res.code === 200) {
        } else {
          return this.$toast('统计出错！')
        }
      })
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
    },

    toOtherApps(item) {
         if (!item.download_url) return;

      appsClick({
        id: item.id,
      }).then((res) => {
        if (res.code === 200) {
        } else {
          return this.$toast('统计出错！')
        }
      })

      window.open(item.download_url, '_blank')
    }
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>
.enter-container {
  padding-top: 0.7rem;
  padding-bottom: 1rem;
  .top_banner{
    height: 1.8rem;
  }
  .recommemd-list {
    margin-top: 0.42rem;
  }
  .recommemd-list-title {
    display: flex;
    align-items: center;
    margin-bottom: 0.34rem;
    > span {
      color: #333;
      font-family: PingFang SC;
      font-size: 0.34rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:nth-child(1) {
        width: 0.24rem;
        height: 0.24rem;
        background-color: $btnBg;
        border-radius: 50%;
        margin-right: 0.11rem;
      }
    }
  }
  li {
    display: flex;
    justify-content: space-between;
    padding: 0.32rem 0.16rem 0.32rem 0;
    border-bottom: 0.01rem solid #535353;
    &:nth-child(1) {
      padding-top: 0;
    }
    > div {
      &:nth-child(2) {
        height: 0.56rem;
        padding: 0.1rem 0.24rem;
        border-radius: 0.56rem;
        background: $btnBg;
        color: #fff;
      }
    }
    .item {
      display: flex;
      .avatar {
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.2rem;
        border-radius: 0.06rem;
        :deep() {
          img {
            border-radius: 0.06rem;
          }
        }
      }
      p {
        margin-bottom: 0.04rem;
        &:nth-of-type(1) {
          color: #333;
          font-family: PingFang SC;
          font-size: 0.32rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &:nth-of-type(2) {
          color: #999;
          font-family: PingFang SC;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
