<template>
  <div class="home-index" :class="{ isIphonex: isIphonex }" :style="deep ? 'padding-top:2.7rem' : ''">
    <!-- && type === homecategoryV2[0].id -->
    <div v-if="navItem.item.adsType === 2 && iconHomeAds.length" class="top_banner">
      <AdAvatarList :list="iconHomeAds" :wrap="true" key-name="avatar" />
    </div>
    <div class="top_banner" v-else-if="navItem.item.adsType !== 3 && avAD.length">
      <EADSwiper :imgCDN="imgCDN" :list="avAD" keyName="cover" @clickImg="clickImg" />
    </div>
    <!-- 通知栏跑马灯 -->
    <van-notice-bar left-icon="volume" color="#000" background="rgb(238, 238, 238, 0.6)" @click="toInfo(runningLight)"
      :text="runningLight?.content" v-if="runningLight" />
    <!-- <div class="announcement" >
      <div class="title">公告</div>
      <div class="scroll-text">
        <div class="text" ref="text">{{ $store.state.config.config.runningLight.content }}</div>
      </div>
    </div> -->
    <!-- 列表 -->
    <PullUp @refreshData="refreshData" @moreData="moreData" :finished="finished" :loading="loading"
      :refreshing="refreshing">
      <div class="tagsPack">
        <ul>
          <li v-for="(item, index) in newTagList" :key="index" @click="
            $router.push({
              path: `/subpage/labelPage`,
              query: {
                name: item.name,
                tagId: item.id
              }
            })
            ">
            {{ item.name }}
          </li>
          <i></i><i></i>
        </ul>
        <!-- 超出展开 -->
        <div class="LabelPackHead" v-if="showBtn" @click="showAllTgasFn">
          <!-- <van-icon :name="showAllTgas ? 'arrow-down' : 'arrow-up'" />
          {{ showAllTgas ? '展开全部' : '收起标签' }} -->
          <img :src="require(`@/assets/imgs/${showAllTgas ? 'icon-arrow-down' : 'icon-arrow-up'}.png`)" alt="" />
        </div>
      </div>
      <div class="sortPack">
        <ul>
          <li v-for="(item, index) in sortList" :key="index" @click="fn_sort(item)"
            :class="{ active: sortId == item.id }">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="tab-main">
        <!-- 专题类  -->
        <div class="home-index-main">
          <JavFourCard :list="mediaList" :position="[1, 5]" :adPosition="10" :adMode="true"></JavFourCard>
          <!-- 循环卡片 -->
          <div v-for="(item, index) in topicList" :key="item.id">
            <div class="bot_banner"
              v-if="topicList.length >= 2 ? index == 1 : index == 0 && short_videoAD && short_videoAD.length">
              <EADSwiper :imgCDN="imgCDN" :list="short_videoAD" keyName="cover" @clickImg="clickImg" />
            </div>
            <!-- 中间活动倒计时 -->
            <div class="activity-card" v-if="index === 0 && newAdvertise.timeOut && showType === 5"
              @click="activityBtn(newAdvertise.href)">
              <EDecryptImg :imgCDN="imgCDN" class="card-img" :imgURL="newAdvertise.cover" :needPadding="false" />
              <!-- 活动倒计时 -->
              <div class="advertiseTimeOut">
                <span class="txt">倒计时</span>
                <van-count-down :time="time" millisecond>
                  <template #default="timeData">
                    <span class="advertiseTimeOut-block">{{
                      timeData.days >= 10 ? timeData.days : `0${timeData.days}`
                    }}</span>
                    <span class="advertiseTimeOut-txt">:</span>
                    <span class="advertiseTimeOut-block">{{
                      timeData.hours >= 10 ? timeData.hours : `0${timeData.hours}`
                    }}</span>
                    <span class="advertiseTimeOut-txt">:</span>
                    <span class="advertiseTimeOut-block">{{
                      timeData.minutes >= 10 ? timeData.minutes : `0${timeData.minutes}`
                    }}</span>
                    <span class="advertiseTimeOut-txt">:</span>
                    <span class="advertiseTimeOut-block">{{
                      timeData.seconds >= 10 ? timeData.seconds : `0${timeData.seconds}`
                    }}</span>
                  </template>
                </van-count-down>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PullUp>
  </div>
</template>

<script>
import { index_home, advertiseClick } from 'api/home'
import { handleParamsRouteJump, handleURlParams, isIphonex } from '@/utils/utils_tools'
export default {
  data() {
    return {
      isIphonex: isIphonex(),
      pageNum: 1,
      pageSize: 10,
      topicList: [],
      mediaList: [],
      tagList: [],
      newTagList: [],
      sortId: 5,
      loading: false,
      refreshing: false,
      sort: null,
      newAdvertise: {}, //中间活动广告
      finished: false, // 每次请求到的数据长度
      // 推荐
      remDataList: [],
      showBtn: false,
      sortList: [
        {
          name: '热门推荐',
          id: 5,
        },
        {
          name: '综合排序',
          id: 0
        },
        {
          name: '最近更新',
          id: 1
        },
        // {
        //   name: '本周最热',
        //   id: 2
        // },
        {
          name: '最多播放',
          id: 3
        },

        {
          name: '十分钟以上',
          id: 4
        }
      ]
    }
  },
  props: ['type', 'showType', 'navItem', 'showRemShortVideoType', 'avAD', 'short_videoAD', 'deep'],
  components: {
    JavBigList: () => import('@/components/stencil/JavBigList.vue'),
    JavFiveCard: () => import('@/components/stencil/JavFiveCard.vue'),
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'),
    JavShortSix: () => import('@/components/stencil/JavShortSix.vue'),
    JavSmallRow: () => import('@/components/stencil/JavSmallRow.vue'),
    JavShortFour: () => import('@/components/stencil/JavShortFour.vue'),
    JavLongllRow: () => import('@/components/stencil/JavLongllRow.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },

  mounted() {
    this.refreshData()
  },

  computed: {
    showAllTgas() {
      if (this.newTagList && this.newTagList.length > 16) {
        return false
      } else {
        return true
      }
    },
    time() {
      const nowTiem = new Date().getTime()
      const newTime = Date.parse(this.newAdvertise.timeOut)
      const other = newTime - nowTiem
      if (other && other > 0) {
        return other
      } else {
        return false
      }
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取公告内容开关 runningLight
    runningLight({ $store }) {
      return $store.getters['runningLight']
    },
    mediaCategory({ $store }) {
      return $store.getters['mediaCategory']
    },
    iconHomeAds({ $store }) {
      return $store.getters['iconHomeAds']
    },
    discoverAD({ $store }) {
      return $store.getters['discoverAD']
    }
  },

  methods: {
    toInfo(ifno) {
      if (ifno.jumpUrl) {
        const code = handleURlParams(ifno.jumpUrl)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      }
    },
    showAllTgasFn() {
      if (this.showAllTgas) {
        this.newTagList = this.tagList
      } else {
        this.newTagList = this.tagList.slice(0, 16)
      }
    },
    fn_sort(item) {
      this.sortId = item.id
      this.mediaList = []
      this.refreshData()
    },
    // banner跳转
    async clickImg(item) {
      this.activityBtn(item.href)
      const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    // 活动广告跳转
    async activityBtn(href) {
      const code = handleURlParams(href)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      const res = await advertiseClick({
        id: href.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },

    // 专题详情跳转
    lookMore(item) {
      this.$router.push(`/media_detail/${item.id}?title=${item.name}&showType=${item.showType}&price=${item.price}`)
    },
    // 上拉加载更多
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getCartoonPage()
    },
    // 下啦刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = true
      this.finished = false
      this.topicList = []
      this.mediaList = []
      this.pageNum = 1
      this.getCartoonPage()
    },
    // 展示方式 1. 长四宫格 2. 短六宫格 3. 长四宫格加换一换 4. 短六宫格加换一换 5. 长视频横排 6. 大列表  7. 短视频横排 8. 活动横排  9.五宫格

    //JavShortSix 短视频六宫格
    //JavShortFour 短视频四宫格
    //JavFourCard 长视频四宫格
    //JavBigList 大列表
    //JavFiveCard 五宫格
    //JavSmallRow 短小横排
    //JavLongllRow 长横排
    compComponent(showType) {
      switch (showType) {
        case 1: // 长视频四宫格*
          return 'JavFourCard'
        case 2: // 短视频六宫格
          return 'JavShortSix'
        case 3: // 长视频四宫格加换一换
          return 'JavFourCard'
        case 4: // 六宫格加换一换
          return 'JavShortSix'
        case 5: // 长视频横排
          return 'JavLongllRow'
        case 6: // 大列表
          return 'JavBigList'
        case 7: // 短视频横排
          return 'JavSmallRow'
        case 8: // 活动大列表
          return 'JavBigList'
        case 9: // 五宫格
          return 'JavFiveCard'
        default:
          return 'JavShortSix'
      }
    },
    // 获取首页数据
    async getCartoonPage() {
      try {
        const res = await index_home({
          id: this.type,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sort: this.sortId
        })
        if (res.code === 200) {
          this.refreshing = false
          this.loading = false

          // 推荐页特殊处理
          // if(this.navItem.item.showType === 5){
          //   return this.fn_recommend(res);
          // }
          if (this.pageNum === 1) {
            this.tagList = res.data.tagList
            this.newTagList = this.tagList.slice(0, 16)
            if (this.tagList && this.tagList.length > 16) {
              this.showBtn = true
            } else {
              this.showBtn = false
            }
            if (res.data.newAdvertise) {
              this.newAdvertise = res.data.newAdvertise
            } else {
              this.newAdvertise = {}
            }
          } else {
            if (res.data.newAdvertise) this.newAdvertise = res.data.newAdvertise
          }

          // if (res.data.mediaList && res.data.mediaList.length == 9) {
          //   if (this.discoverAD?.length) {
          //     const random = Math.floor(Math.random() * this.discoverAD.length)
          //     res.data.mediaList.push(this.discoverAD[random])
          //   }
          // }

          this.mediaList = [...this.mediaList, ...res.data.mediaList]
          // this.topicList = [...this.topicList, ...res.data.topicList];
          if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
            this.finished = true
          }
        } else {
          this.refreshing = false
          this.loading = false
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        this.refreshing = false
        this.loading = false
        console.log(error)
        this.$toast('请求错误，请稍后再试!')
      }
    }
    // 推荐页面数据整理
    // fn_recommend(res) {
    //   const obj = {
    //     avList: res.data.avList ? res.data.avList : [],
    //     longList: res.data.longList ? res.data.longList : [],
    //     shortList: res.data.shortList ? res.data.shortList : []
    //   }
    //   this.remDataList.push(obj)
    //   if (res.data.avList.length < this.pageSize || !res.data.avList) {
    //     this.finished = true
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.top_banner {

  // height: 4rem;
  .JGbtnList {
    padding: 0 0.3rem;
  }
}

.LabelPackHead {
  text-align: center;
  margin-bottom: 0.2rem;

  img {
    width: 100%;
  }
}

.home-index {
  // height: calc(100vh - 1rem);
  // overflow-x: auto;
  padding-top: 1.8rem;
  padding-bottom: 1rem;

  &.isIphonex {
    // height: calc(100vh - 2.5rem);
  }

  :deep() {
    .van-notice-bar {
      height: 0.6rem;
      margin: 0 0.24rem;
      margin-top: 0.1rem;
      box-shadow: 0px 4px 4px 0px #00000040;
      opacity: 0.6;

      .van-icon {
        color: #000;
      }

      .van-notice-bar__wrap {
        font-size: 0.26rem;
      }
    }
  }
}

//推荐以外的主题
.home-index-main {
  padding: 0 0.25rem;

  .bot_banner {
    height: 1.5rem;
  }
}

.activity-card {
  position: relative;

  .card-img {
    height: 1.5rem;
    width: 100%;
  }

  .advertiseTimeOut {
    position: absolute;
    right: 0.3rem;
    top: 0.45rem;
    display: flex;
    align-items: center;

    .txt {
      margin-right: 0.2rem;
      font-size: 0.3rem;
      color: #fff;
    }

    &-txt {
      color: #fff;
      font-size: 0.3rem;

      font-weight: 600;
      margin: 0 0.02rem;
    }

    &-block {
      width: 0.4rem;
      height: 0.4rem;
      background: #fff;
      border-radius: 0.06rem;
      font-weight: 600;
      color: #000;
      font-size: 0.32rem;
      padding: 0 0.1rem;
    }
  }
}

.tagsPack {
  margin: 0.24rem;

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 1.6rem;
      height: 0.58rem;
      line-height: 0.58rem;
      background: #fff;
      box-shadow: 0px 4px 4px 0px #00000040;
      color: rgba(0, 0, 0, 0.7);
      font-size: 0.28rem;
      border-radius: 0.06rem;
      text-align: center;
      margin-bottom: 0.12rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i {
      width: 1.6rem;
    }
  }
}

.sortPack {
  margin: 0.24rem;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    li {
      width: 1.44rem;
      height: 0.44rem;
      line-height: 0.44rem;
      text-align: center;
      border-radius: 0.06rem;
    }

    .active {
      background: linear-gradient(90deg, #ff669d 0%, #000000 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.announcement {
  font-size: 0.26rem;
  @include flexbox($jc: flex-start);
  margin: 0.36rem 0 0.3rem 0;
  padding: 0 0.25rem;

  .title {
    padding: 0.02rem 0.12rem;
    border-radius: 0.08rem;
    background: #00f6f8;
    white-space: nowrap;
    color: #333;
  }

  .scroll-text {
    overflow: hidden;
    margin-left: 0.12rem;

    .text {
      white-space: nowrap;
      animation: scroll-text 10s linear infinite;
    }
  }

  @keyframes scroll-text {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }
}
</style>
