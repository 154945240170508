<template>
  <div class="release">
    <EBackTop background="#fff" title="选择版块" color="#000"> </EBackTop>
    <ul>
      <li
        @click="checkItem(item)"
        :class="active === item.id ? 'active' : ''"
        v-for="item in postSection"
        :key="item.id"
      >
        <div class="lid" v-if="item.isShow">
          <EDecryptImg :imgCDN="imgCDN" class="resetImg" :imgURL="item.imageUrl"> </EDecryptImg>
          <div class="right">
            <p class="title">{{ item.name }}</p>
            <div class="desc">
              <span>帖子 {{ item.totalPost | numberFilter }}</span>
              <span>浏览 {{ item.totalWatches | numberFilter }}</span>
              <span>关注 {{ item.totalCollects | numberFilter }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      active: 0
    }
  },
  computed: {
    findTagList({ $store }) {
      return $store.getters['getPostSectionByType'](this.$route.query.from)
    },

    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    postSection({ $store }) {
      console.log($store.getters['postSection'])
      return $store.getters['postSection']
    }

    // showTagList() {
    //   const { cid } = this.$route.query
    //   return this.postSection.filter(v => v.postCategoryId == cid)
    // }
  },
  mounted() {},

  methods: {
    checkItem(item) {
      this.active = item.id
      this.$store.commit('setPostSelect', item)
      this.$router.go('-1')
    }
  }
}
</script>

<style lang="scss" scoped>
.release {
  ul {
    padding: 0.36rem;
    .lid {
      border-radius: 0.06rem;
      padding: 0.24rem 0.36rem;
      background: #fff;
      @include flexbox($jc: flex-start);
      margin-bottom: 0.24rem;
      box-shadow: $shadow;
      .resetImg {
        width: 0.88rem;
        height: 0.88rem;
        margin-right: 0.16rem;
        :deep() {
          img {
            border-radius: 0.12rem;
          }
        }
      }
    }
    .active {
      border: 1px solid #00f6f8;
    }
  }
}
.title {
  color: #000;
  font-family: PingFang SC;
  font-size: 0.36rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.desc {
  color: #999;
  font-family: PingFang SC;
  font-size: 0.26rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
