<template>
  <div class="post_transaction">
    <EBackTop :title="'发布约炮'" background="#000" color="#fff"> </EBackTop>
    <div class="post_form">
      <div class="post_title">
        <span class="label">标题</span>
        <van-field
          class="type-input"
          maxlength="10"
          v-model="form.title"
          placeholder="最多输入10个中文汉字"
          :rules="[{ required: true, message: '最多输入10个中文汉字' }]"
        />
      </div>
      <div class="video_box">
        <div class="video_title">
          <p class="label">添加视频</p>
          <p class="default_txt">只能添加一个视频,如果有视频则视频显示为封面</p>
        </div>
        <UploadVideo @result="resultVideo" />
      </div>
      <div class="pohoto_box">
        <div class="video_title">
          <p class="label">添加图片</p>
          <p class="default_txt">最多只能添加6张图片,封面显示为第一张</p>
        </div>
        <UploadImg :maxLength="6" @result="resultImgs" />
      </div>
      <!-- 主体 -->
      <div class="form_main">
        <p class="title">*必填</p>
        <van-form @submit="onSubmit" :show-error-message="false">
          <div class="input_flex">
            <van-field
              class="concat-input input-margin"
              v-model="form.contact"
              name="联系方式"
              label="联系方式"
              :rules="[{ required: true, message: '请填写联系方式' }]"
            />
            <van-field
              v-if="$route.query.indexType == 1"
              aria-disabled="true"
              @click="popupShow = !popupShow"
              class="concat-input"
              v-model="cityName"
              name="城市"
              label="城市"
              :rules="[{ required: true, message: '请选择城市列表' }]"
              readonly
            />
          </div>

          <div class="input_flex">
            <van-field
              class="age-input input-margin"
              v-model.number="form.girlAge"
              name="年龄"
              label="年龄"
              :rules="[{ required: true, message: '请填写年龄' }]"
            />
            <van-field
              class="height-input input-margin"
              v-model.number="form.height"
              name="身高"
              label="身高"
              :rules="[{ required: true, message: '请填写身高' }]"
            />
            <van-field
              class="weight-input"
              v-model.number="form.weight"
              name="体重"
              label="体重"
              :rules="[{ required: true, message: '请填写体重' }]"
            />
          </div>
          <div class="input_flex">
            <div class="chest_flex input-margin">
              <ul class="chest_list">
                <li class="chest_title">
                  <span class="label">胸围</span>
                  <div class="text" @click="showChestList = !showChestList">
                    <span>{{ chestTxt }}</span>
                    <img src="@/assets/imgs/trade/select_icon.png" alt="" />
                  </div>
                </li>
                <li v-if="showChestList">
                  <ul class="sub_list">
                    <li @click="checkChest(item)" v-for="item in chestList">{{ item.text }}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <van-field
              v-if="$route.query.indexType == 1"
              class="price-input"
              v-model="form.price"
              type="服务价格"
              name="服务价格"
              label="服务价格"
              :rules="[{ required: true, message: '请填写服务价格' }]"
            />
          </div>
          <div class="servive_type">
            <span class="label">服务{{ $route.query.indexType == 2 ? '类型' : '项目' }}</span>
            <van-field
              class="type-input"
              show-word-limit
              rows="3"
              maxlength="100"
              v-model="form.desc"
              type="textarea"
              :placeholder="$route.query.indexType == 2 ? '请填写服务类型' : '请填写服务项目'"
              :rules="[{ required: true, message: $route.query.indexType == 2 ? '请填写服务类型' : '请填写服务项目' }]"
            />
            <!-- 帝王调教单独参数 -->
            <div class="tiaoJiao_main" v-if="$route.query.indexType == 2">
              <div>
                <p class="active_tip">*系统已填(不允许修改)</p>
                <div class="hasLianList">
                  <span class="active" v-for="item in requiredListModel" :key="item.id"
                    >{{ item.name }}+{{ item.price | changeGold }}</span
                  >
                </div>
              </div>
              <div>
                <p class="default_tip">*可选</p>
                <div class="hasTypeList">
                  <div class="hasTypeList_item" v-for="item in optionalListModel" :key="item.id">
                    <span @click="checkOption(item)" :class="item.check ? 'active' : 'default'"
                      >{{ item.name }}+{{ item.price | changeGold }}</span
                    >
                    <img v-if="item.check" @click="delOption(item)" src="@/assets/imgs/trade/close.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="submit_btn">
              <van-button round block native-type="submit">发布</van-button>
            </div>
          </div>
        </van-form>
      </div>
    </div>
    <van-popup class="popup-round" position="bottom" v-model="popupShow">
      <van-area title="标题" :columns-num="2" @confirm="fn_close" @cancel="fn_close" :area-list="areaList" />
    </van-popup>

    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>

<script>
import { dating_publish, dating_service } from '@/api/dating'
// import { city_list } from 'api/dating'
import { areaList } from '@vant/area-data'
export default {
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    UploadVideo: () => import('@/components/UploadVideo/index.vue'),
    UploadImg: () => import('@/components/UploadImg/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue')
  },
  inject: ['reload'],
  data() {
    return {
      detailData: {},
      pageNum: 1,
      pageSize: 10,
      reportList: [],
      showBuy: false,
      refreshing: false, // 下拉刷新开关
      loading: false, // 上拉加载
      finished: false, // 上拉加载开关
      chestTxt: '',
      cityName: undefined,
      form: {
        city: undefined,
        contact: undefined,
        height: undefined,
        weight: undefined,
        chest: undefined,
        price: undefined,
        desc: undefined,
        video: undefined,
        imgs: undefined,
        title: undefined,
        girlAge: undefined,
        requiredServiceIDs: [],
        optionalServiceIDs: [],
        taskID: undefined
      },
      showChestList: false,
      chestList: [
        { text: 'B罩杯', value: 1 },
        { text: 'C罩杯', value: 2 },
        { text: 'D罩杯', value: 3 },
        { text: 'E罩杯', value: 4 },
        { text: 'F罩杯', value: 5 },
        { text: 'G罩杯', value: 6 },
        { text: 'H罩杯', value: 7 },
        { text: 'I罩杯', value: 8 },
        { text: 'J罩杯', value: 9 },
        { text: 'K罩杯', value: 10 },
        { text: 'L罩杯', value: 11 },
        { text: 'M罩杯', value: 12 },
        { text: 'N罩杯', value: 13 },
        { text: 'O罩杯', value: 14 },
        { text: 'P罩杯', value: 15 }
      ],
      typeItemActive: 0,
      optionalListModel: [],
      requiredListModel: [],
      areaList, // 城市列表
      popupShow: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 用户信息
    user({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  created() {
    this.getDatingCategory()
    // this.getCityList()
  },
  mounted() {
    // indexType 0 为暗黑游  1为帝王调教
    // console.log(this.$route.query.indexType, this.$route.query.postType);
  },

  methods: {
    //     async getCityList() {
    //   const res = await city_list({})
    //   if (res.code === 200) {
    //     // this.hotList = res.data.hotList
    //     this.areaList = res.data.list
    //     console.log(res.data);
    //   } else {
    //     return this.$toast(res.tip)
    //   }
    // },
    fn_close(arr) {
      this.popupShow = false
      if (arr) {
        this.form.city = arr[1].code
        this.cityName = arr[1].name
      }
    },
    async onSubmit(test) {
      if (this.$route.query.postType == 'video') {
        if (!this.form.video) {
          return this.$toast('请上传视频或图片！')
        }
      }
      if (this.form.imgs) {
        if (this.user.vipType !== 11) {
          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: '注意',
            content: '您还不是商家会员，无法发布内容',
            type: 'up_post',
            ok: '购买VIP',
            img: '',
            cancel: '充值金币',
            position: 'center',
            showBalance: false,
            cb: async returnData => {
              this.$router.push('/mine/newRecharge?type=1')
            },
            close: async returnData => {
              this.$router.push('/mine/newRecharge?type=0')
            }
          }
        } else {
          const res = await dating_publish({
            title: this.form.title,
            contactInfo: this.form.contact,
            images: this.form.imgs,
            price: this.form.price * 100,
            text: this.form.text,
            video: this.form.video,
            cupValue: this.form.chest,
            girlWeight: this.form.weight,
            girlHeight: this.form.height,
            girlAge: this.form.girlAge,
            desc: this.form.desc,
            requiredServiceIDs: this.form.requiredServiceIDs,
            optionalServiceIDs: this.form.optionalServiceIDs,
            city: +this.form.city,
            taskID: this.form.taskID,
            modelType: this.$route.query.indexType == 1 ? 6 : 4
          })
          if (res.code === 200) {
            this.$toast('上传成功')
            setTimeout(() => {
              this.$router.back()
            }, 500)
          } else {
            if (res.code === 6063) {
              this.$toast(`视频重复上传！`)
            } else {
              this.$toast(`上传失败,${res.tip}`)
            }
          }
        }
      } else {
        this.$toast('请上传图片！')
      }
    },
    async getDatingCategory() {
      const res = await dating_service({})
      if (res.code === 200) {
        this.requiredListModel = res.data.requiredList
        this.optionalListModel = res.data.optionalList.map(item => {
          item.check = false
          return item
        })
        this.form.requiredServiceIDs = res.data.requiredList.map(item => {
          return item.id
        })
      } else {
        this.$toast(`上传失败,${res.tip}`)
      }
    },
    // 选中可选
    checkOption(item) {
      item.check = true
      // this.$forceUpdate()
      this.form.optionalServiceIDs.push(item.id)
    },
    // 删除可选
    delOption(item) {
      item.check = false
      let newOptionsArr = this.optionalListModel.filter(item => {
        return item.check
      })
      this.form.optionalServiceIDs = newOptionsArr.map(item => {
        return item.id
      })
    },
    checkChest(item) {
      this.form.chest = item.value
      this.chestTxt = item.text
      this.showChestList = false
    },
    // 视频上传回调
    resultVideo(video) {
      console.log('video', video)
      this.form.taskID = video.taskID
      this.form.video = video.videoUri
    },
    // 图片上传回调
    resultImgs(imgs) {
      this.form.imgs = imgs
    },
    checkTypeItem(item) {
      this.typeItemActive = item
    }
  }
}
</script>

<style lang="scss" scoped>
.post_transaction {
  background: #212223;
  min-height: 100vh;

  :deep() {
    .van-cell {
      border-radius: 0.08rem;
      padding: 0;
      border: 1px solid #535353;
      background: #292929;
      margin-top: 0.4rem;

      .van-field__label {
        width: auto;
        color: #00f6f8;
        padding: 0.04rem 0.08rem;
        text-align: center;
        margin-right: 0;
      }

      .van-field__value {
        color: #fff;
        padding: 0.04rem 0.08rem;
      }

      .van-field__control {
        color: #fff;
      }
    }

    .van-cell::after {
      display: none;
    }
  }
}

.label {
  border-radius: 0.08rem;
  padding: 0.04rem 0.08rem;
  color: #00f6f8;
  margin-right: 0.24rem;
  font-size: 0.26rem;
  white-space: nowrap;
}

.default_txt {
  color: #787979;
  font-size: 0.24rem;
  font-weight: 400;
}

.post_form {
  padding: 0.4rem 0.36rem 1.8rem 0.36rem;
}

.video_title {
  @include flexbox($jc: flex-start);
  margin-bottom: 0.26rem;
}

.post_title {
  @include flexbox($jc: flex-start, $ai: center);
  margin-bottom: 0.26rem;

  :deep() {
    .van-cell {
      margin-top: 0;
    }
  }
}

.video_box {
  margin-bottom: 0.4rem;
}

.form_main {
  margin-top: 0.36rem;

  .title {
    color: #f9c006;
    font-family: PingFang HK;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .input_flex {
    @include flexbox();

    .input-margin {
      margin-right: 0.4rem;
    }
  }

  .servive_type {
    margin-top: 0.44rem;

    .label {
      margin-bottom: 0.28rem;
    }
  }
}

.chest_flex {
  @include flexbox($jc: flex-start);

  margin-top: 0.4rem;
  font-size: 0.26rem;

  .chest_list {
    position: relative;

    .chest_title {
      border-radius: 0rem 0.08rem 0.08rem 0rem;
      border: 1px solid #535353;
      background: #292929;
      padding: 0.04rem 0.18rem 0.04rem 0;
      @include flexbox($jc: flex-start);

      .label {
        margin-right: 0;
        width: 40%;
      }

      .text {
        width: 1.64rem;
        @include flexbox($jc: flex-start);

        span {
          width: 80%;
        }
      }

      img {
        width: 0.26rem;
        height: 0.22rem;
      }
    }

    .sub_list {
      position: absolute;
      width: 2rem;

      max-height: 2rem;
      overflow: auto;
      top: 100%;
      right: 0;
      z-index: 10;

      li {
        padding: 0.04rem 0.18rem;
        background: #292929;

        border-bottom: dotted 0.01rem #535353;
      }
    }
  }
}

.submit_btn {
  position: fixed;
  width: 100%;
  left: 50%;
  max-width: $pcMaxWidth;
  bottom: 0;
  transform: translate(-50%, 0);
  padding: 0.34rem 0;
  background: #212223;
  border-top: solid 0.01rem #535353;

  :deep() {
    .van-button {
      width: 6.5rem;
      height: 0.9rem;
      margin: 0 auto;
      background-color: #00f6f8;
      border: none;
    }

    .van-button--info {
      border-radius: 0.46rem;
      background: #00f6f8;
      border: none;
    }
  }
}

.tiaoJiao_main {
  margin-top: 0.26rem;

  .active_tip {
    color: #f9c006;
    font-size: 0.26rem;
    margin-bottom: 0.26rem;
  }

  .default_tip {
    margin: 0.4rem 0;
  }

  .hasLianList {
    @include flexbox($jc: flex-start, $fw: wrap);

    span {
      margin-right: 0.16rem;
      margin-bottom: 0.36rem;
    }
  }

  .hasTypeList {
    @include flexbox($jc: flex-start, $fw: wrap);

    .hasTypeList_item {
      position: relative;
    }

    span {
      margin-right: 0.16rem;
      margin-bottom: 0.36rem;
      border-radius: 0.12rem;
      border: 2px solid #787979;
      padding: 0.16rem 0.08rem 0.16rem 0.1rem;
      display: inline-block;
    }

    img {
      position: absolute;
      top: -0.1rem;
      right: 0.1rem;
      width: 0.24rem;
      height: 0.24rem;
    }
  }
}

.active {
  border-radius: 0.12rem;
  border: 1px solid #00f6f8;
  padding: 0.16rem 0.18rem;
  color: #00f6f8;
}
</style>
