<template>
  <!-- <client-only> -->
  <div class="photo-detail">
    <!-- <EBackTop :background="'transparent'" :placeholder="false"> </EBackTop> -->
    <HeaderBar :open-fallback="true" :title="''" color="#333" />
    <div class="photo-detail-main">
      <!-- title -->
      <h3>{{ detailData.title }}</h3>
      <!-- userinfo -->
      <div class="user-info">
        <div class="user-info-left" @click="clickUserAvatar(detailData)">
          <DecryptImg class="avatar" :imgURL="detailData.userAvatar" />
          <p>{{ detailData.userName }}</p>
          <!-- <img v-if="detailData.userLvl > 0" :src="require(`@/assets/imgs/mine/vip-${detailData.userLvl}.svg`)" alt="" /> -->
        </div>

        <ul class="user-info-right">
          <li><img src="@/assets/imgs/icon_watch_cont.png" alt="" />{{ detailData.watches | numberFilter }}</li>
          <li><img src="@/assets/imgs/photo/Comment_icon.png" alt="" />{{ detailData.comments | numberFilter }}</li>
          <li>
            <img
              :src="detailData.isLike ? require('@/assets/imgs/photo/thum-active.png') : require('@/assets/imgs/photo/Vector.png')"
              @click.stop="operating(detailData.isLike)">{{ detailData.likes | numberFilter }}
            <!-- <van-icon name="good-job-o" :color="detailData.isLike ? '#ed608b' : '#333'" size="18px"  />{{ detailData.likes | numberFilter }}</li> -->
          </li>
        </ul>
      </div>
      <!-- 文字描述 -->
      <p class="desc">{{ detailData.text }}</p>

      <!-- 写真列表 -->
      <div class="img-list">
        <div class="img-item" v-for="(item, index) in detailData.imgs" :key="index">
          <DecryptImg :needPadding="false" class="img-item-cover" @clickImg="imagePreview(index)" ref="refImg"
            v-if="!((canWatch === 3 || canWatch === 2) && index > 2)" :imgURL="item">
            <!-- 是否可以继续观看遮罩层 -->
            <div class="noBuy-mask" v-if="(canWatch === 3 || canWatch === 2) && index === 2"
              @click.stop="buyPhoto(canWatch)">
              <img class="locking" src="@/assets/imgs/photo/locking.svg" alt="" />
              <div class="hasPrice" v-if="canWatch === 3">
                <img class="gold" src="@/assets/imgs/photo/Untitled.png" alt="" />
                {{ detailData.price | changeGold }}解锁
              </div>
              <div class="noVip" v-if="canWatch === 2">开通会员解锁</div>
            </div>
          </DecryptImg>
        </div>
      </div>
    </div>
    <!-- 写真评论 -->
    <Comment class="photo-comment" :objectId="id" :objectype="6" />
    <!-- <BlComment class="photo-comment" :comments="detailData.comments" :objectId="detailData.id" :objectType="3" ref="photoComment" :sendTxt="commentTxt" /> -->
    <!-- <GoToLoginPopup v-if="showGoToLogin"></GoToLoginPopup> -->
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
  <!-- </client-only> -->
</template>

<script>
// import ComPopupModel from '@/components/PopupModel/index.js'
import { canBuy } from '@/utils/utils_tools';
import { ImagePreview } from 'vant'
import { photoDetail, photoPay } from 'api/photo'
import { collectAdd } from 'api/comics'
export default {

  components: {
    // BlComment: () => import('@/components/BlComment.vue'), //评论
    HeaderBar: () =>
      import('@/components/web_base/component_base/EBackTop/index.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    HeaderBar: () => import("@/components/HeaderBar.vue"),
    Comment: () => import('@/components/Comment/index.vue'), //评论
    DmPopup: () => import('@/components/Popup/index.vue')
    // GoToLoginPopup: () => import('@/components/goToLoginPopup.vue'),
  },
  data() {
    return {
      showGoToLogin: false,
      detailData: {},
      indexImg: 0,
      imgArr: [],
      // commentTxt: undefined, // 评论
      id: 0
    };
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    isVipMember({ $store }) {
      return $store.getters['isMember']
    },
    // 计算当前用户是否可以观看写真
    canWatch() {
      // 先判断是否为金币购买的

      if (this.detailData.price) {
        // 如果自己已经购买了
        if (this.detailData.isBought) {
          return true;
        }
        return 3;
      }
      // 判断是否为vip
      if (this.detailData.isVip) {
        // 如果自己是vip 可以看 不是就返回 2
        if (this.isVipMember) {
          return true;
        } else {
          return 2;
        }
      }
      // 其他的都返回true
      return true;
    },

    // ...mapState({
    //   userInfo: state => state.user.info,
    //   isLight: state => state.user.isLight,
    // }),
    // ...mapGetters({
    //   isMember: 'user/isMember',
    //   isZzVip: 'user/isZzVip',
    // }),
  },
  mounted() {
    this.id = +this.$route.params.id
    this.getPhotoDetail();
  },
  methods: {
    // 头像点击跳转
    clickUserAvatar(item) {
      // this.$router.push(`/uploader/person/${item.userId}`);
    },
    // 图片预览
    imagePreview(indexImg) {
      let imgArr = []
      let domArr = this.$refs['refImg']
      domArr.forEach((item) => {
        if (item.realUrl) {
          imgArr.push(item.realUrl)
        }
      })
      ImagePreview({
        images: imgArr, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: true, //是否开启循环播放
        startPosition: 0, //图片预览起始位置索引
        closeable: true
      })
    },

    // 写真发送评论
    // sendPhotoComment() {
    //   if (!this.commentTxt) return this.$toast('请输入评论内容');
    //   this.$refs.photoComment.addComment();
    //   this.commentTxt = undefined;
    // },
    // 购买写真集
    buyPhoto(canWatch) {
      // canWatch===3 金币写真  canWatch===2 vip写真
      if (canWatch === 3) {
        // 金币数够
        if (canBuy(this.$store, this.detailData.price)) {

          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: `购买写真：${this.detailData.title}`,
            showBalance: true,
            type: 'community-post-enough',
            ok: '确认支付',
            price: this.detailData.price,
            cancel: '狠心离开',
            position: 'center',
            isShowTips: true,
            balance: this.userInfo.balance,
            cb: async returnData => {
              this.photoPay();
            }
          }

        } else {

          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: '余额不足',
            content: '您的余额不足，请充值金币购买',
            type: 'up_post',
            ok: '前往充值',
            img: '',
            cancel: '狠心离开',
            position: 'center',
            showBalance: false,
            cb: async returnData => {
              this.$router.push('/mine/recharge/my-wallet');
            },
          }
        }
      } else {

        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '观看次数已用完',
          content: '您的观看次数已经全部用完开通会员可无限畅看',
          type: 'up_post',
          ok: '开通VIP',
          img: '',
          cancel: '取消',
          position: 'center',
          showBalance: false,
          cb: async returnData => {
            this.$router.push('/mine/recharge');
          },
        }
        // ComPopupModel('video', setting, async datas => {
        //   if (datas.confirm) {
        //     this.$router.push('/mine/recharge');
        //   }
        // });
      }
    },
    // 购买接口
    async photoPay() {
      const res = await photoPay({
        id: +this.$route.params.id,
      });
      if (res.code === 200 && res.data.code === 200) {
        this.detailData.isBought = true;
        this.$toast('购买成功');
      } else {
        this.$toast(res.data.msg || res.tip);
      }
    },
    // 详情接口
    async getPhotoDetail() {
      const res = await photoDetail({
        id: +this.$route.params.id,
      });
      if (res.code === 200) {
        this.detailData = res.data;
      } else {
        this.$toast(res.tip);
      }
    },

    // 写真点赞
    /**  ContentTypeNone        ContentType = 0 // 占位符，无意义
  ContentTypeVideoDsp    ContentType = 1 // 短视频
  ContentTypeVideoLong   ContentType = 2 // 长视频
  ContentTypeViodeGather ContentType = 3 // 合集
  ContentTypePost        ContentType = 4 // 帖子
  ContentTypeCartoon     ContentType = 5 // 动漫(动画)
  ContentTypeComics      ContentType = 6 // 漫画
  ContentTypePhoto       ContentType = 7 // 写真
  */
    async operating(isLike) {
      // const res = await comMediaOperating({
      //   flag: !isLike,
      //   object_id: this.detailData.id,
      //   collect_type: 16,
      //   isCollet: true
      // });
      const res = await collectAdd({
        collectType: 17,
        flag: !isLike,
        object_id: this.detailData.id,
        type: 1,
      })
      if (res.code === 200) {
        this.detailData.isLike = !isLike;
        if (this.detailData.isLike) {
          this.detailData.likes += 1;
        } else {
          this.detailData.likes -= 1;
        }
        return this.$toast(`${isLike ? '取消成功' : '点赞成功'}`);
      } else {
        return this.$toast(res.tip);
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('setphotoHisList', { type: 'add', item: this.detailData })
  }
};
</script>

<style lang="scss" scoped>
.photo-detail {
  min-height: 100vh;
  padding-top: 0.88rem;
  font-size: 0.26rem;

  .photo-detail-main {
    padding: 0.3rem;
    box-sizing: border-box;

    h3 {
      margin: 0 0.3rem 0 0;
      font-size: 0.3rem;
    }
  }

  .desc {
    font-size: 0.24rem;
  }
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.25rem 0;

  &-left {
    display: flex;

    align-items: center;

    .avatar {
      width: 0.56rem;
      margin-right: 0.11rem;
      border-radius: 50%;

      :deep() {
        .warp {
          border-radius: 50%;
        }
      }
    }

    img {
      height: 0.3rem;
      margin-left: 0.1rem;
    }
  }

  &-right {
    display: flex;
    align-items: center;

    li {
      margin-right: 0.5rem;
      display: flex;
      align-items: center;

      img {
        height: 0.25rem;
        margin-right: 0.05rem;
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }
}

.img-list {
  .img-item {
    margin-bottom: 0.2rem;

    .img-item-cover {
      position: relative;

      // 遮罩层
      .noBuy-mask {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), 30%, #000000 70%);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.3rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        .locking {
          width: 0.34rem;
          margin-right: 0.2rem;
        }

        .hasPrice {
          display: flex;
          align-items: center;

          .gold {
            width: 0.4rem;
            margin-right: 0.1rem;
          }
        }
      }
    }
  }
}

.show-all {
  .index-black {
    padding: 0 0.3rem;
    box-sizing: border-box;
    height: 0.84rem;
    width: 100%;
    background: rgba($color: #000, $alpha: 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // 中间遮罩层
  .conter-mask {
    height: calc(100vh - 1rem);
    width: 100vw;
    font-size: 0.3rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

    .locking {
      width: 0.34rem;
      margin-right: 0.2rem;
    }

    .hasPrice {
      display: flex;
      align-items: center;

      .gold {
        width: 0.4rem;
        margin-right: 0.1rem;
      }
    }
  }

  // 底部按钮
  .bot-comments {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 0.84rem;
    width: 100%;
    max-width: $pcMaxWidth;
    padding: 0 0.3rem;
    box-sizing: border-box;
    background: rgba($color: #000, $alpha: 0.2);

    .right-icon {
      transform: rotate(-90deg);
      width: 0.4rem;
    }

    .comment-btn {
      width: 2.6rem;
      height: 0.48rem;
      border-radius: 0.3rem;
      background: rgba($color: #eb658a, $alpha: 0.5);
      text-align: center;
      line-height: 0.48rem;
      margin: 0 0.35rem 0 0.2rem;

      :deep() {
        .van-field {
          height: 0.48rem;
          padding: 0 0.2rem;
          font-size: 0.2rem;
          border-radius: 0.3rem;
        }
      }
    }

    .user-info-right {
      li {
        margin-right: 0.3rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

// 底部隐藏切换按钮
.bot-comments {
  .down-icon {
    transform: rotate(90deg);
    width: 0.4rem;
  }
}

.photo-comment {
  padding-bottom: 1.5rem;
}

// 全部评论弹窗
.photo-comment-pop {
  width: 100%;
  height: 100%;
  background: #212223;
}
</style>
