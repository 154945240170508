<template>
  <div class="vip-pack">
    <!-- 会员卡 横向滑动-->
    <!-- <ul class="card_list">
      <li
        v-for="(sItem, sIndex) in vipList"
        :key="sItem.id"
        :class="activeSwiper == sIndex ? 'active' : ''"
        @click.stop="checkItem(sItem, sIndex)"
      >
        <p class="desc" v-if="sItem.desc">{{ sItem.desc }}</p>
        <p class="name">{{ sItem.title }}</p>
        <p class="money">¥{{ sItem.money | changeGold }}</p>
        <span class="pre_money">¥{{ sItem.preMoney | changeGold }}</span>
      </li>
    </ul> -->
    <!-- 会员卡 3列展示-->
    <ul class="card_list">
      <li
        v-for="(sItem, sIndex) in vipList"
        :key="sItem.id"
        :class="activeSwiper == sIndex ? 'active' : ''"
        @click.stop="checkItem(sItem, sIndex)"
      >
        <p class="desc" v-if="sItem.desc">{{ sItem.desc }}</p>
        <p class="name">{{ sItem.title }}</p>
        <p class="money">¥{{ sItem.money | changeGold }}</p>
        <span class="pre_money">¥{{ sItem.preMoney | changeGold }}</span>
      </li>
      <!-- <div style="width: 2rem; height: 2.4rem;"></div> -->
    </ul>
    <div class="title-tips">
      <span>——————•</span>
      <h3>会员特权</h3>
      <span>•——————</span>
    </div>
    <!-- 当前用户vip等级 -->
    <div v-if="activeData.isNewUserCard">
      <div class="vip-count-down">
        <div>
          <img src="@/assets/imgs/clock.svg" alt="" />
          <img src="@/assets/imgs/discount.png" alt="" />
        </div>
        <van-count-down :time="hourT" millisecond v-if="this.hourT > 0">
          <template #default="timeData">
            <span class="advertiseTimeOut-block">{{
              timeData.hours >= 10 ? timeData.hours : `0${timeData.hours}`
            }}</span>
            <span class="advertiseTimeOut-txt">:</span>
            <span class="advertiseTimeOut-block">{{
              timeData.minutes >= 10 ? timeData.minutes : `0${timeData.minutes}`
            }}</span>
            <span class="advertiseTimeOut-txt">:</span>
            <span class="advertiseTimeOut-block">{{
              timeData.seconds >= 10 ? timeData.seconds : `0${timeData.seconds}`
            }}</span>
          </template>
        </van-count-down>
        <span class="advertiseTimeOut-block-over" v-else>已结束</span>
      </div>
    </div>
    <!-- 活动卡倒计时 -->
    <div v-else-if="hourC > 0">
      <div class="vip-count-down">
        <div>
          <img src="@/assets/imgs/clock.svg" alt="" />
          <img src="@/assets/imgs/discount.png" alt="" />
        </div>
        <van-count-down :time="hourC" millisecond v-if="this.hourC > 0">
          <template #default="timeData">
            <span class="advertiseTimeOut-block">{{
              timeData.hours + timeData.days * 24 >= 10
                ? timeData.hours + timeData.days * 24
                : `0${timeData.hours + timeData.days * 24}`
            }}</span>
            <span class="advertiseTimeOut-txt">:</span>
            <span class="advertiseTimeOut-block">{{
              timeData.minutes >= 10 ? timeData.minutes : `0${timeData.minutes}`
            }}</span>
            <span class="advertiseTimeOut-txt">:</span>
            <span class="advertiseTimeOut-block">{{
              timeData.seconds >= 10 ? timeData.seconds : `0${timeData.seconds}`
            }}</span>
          </template>
        </van-count-down>
        <span class="advertiseTimeOut-block-over" v-else>已结束</span>
      </div>
    </div>
    <div class="vip-label" v-else>
      <img src="@/assets/imgs/vip-bag.png" alt="" />
      <span>您当前VIP等级是</span>
      <span style="color: #ffebbc">- {{ userInfo.cardName ? userInfo.cardName : '游客' }} -</span>
    </div>
    <div class="vip-privilege">
      <ul>
        <li v-for="(item, index) in activeData.rights" :key="index">
          <DecryptImg :imgURL="item.image" class="privilegeImg"></DecryptImg>
          <div class="privilegeName">
            <div class="name">{{ item.name }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="openPack">
      <div class="openVip" @click="vipChuli()">开通会员</div>
    </div>
    <!-- 底部支付弹窗 -->
    <van-popup v-model="showPop" position="bottom" class="submit-pop">
      <div class="popupPack">
        <h2>请选择支付方式</h2>
        <div class="activeItem">已选择 [{{ activeData.title }}] VIP视频任意看 ¥{{ activeData.money / 100 }}</div>
        <ul class="pay-list" v-if="activeData.rchgType && activeData.rchgType.length">
          <li :key="index" v-for="(item, index) in activeData.rchgType" @click="fn_sel(item.type)" class="item">
            <div class="left" v-if="item.type === 'alipay'">
              <img v-if="payMode === item.type" src="@/assets/imgs/mine/zfb2.png" alt="" />
              <img v-else src="@/assets/imgs/mine/zfb1.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'wechat'">
              <img v-if="payMode === item.type" src="@/assets/imgs/mine/weChat.png" alt="" />
              <img v-else src="@/assets/imgs/mine/WeChat3.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'overage'">
              <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'union'">
              <img src="@/assets/imgs/mine/bank.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'daichong'">
              <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
            </div>
            <div class="right">
              <div class="active" v-if="item.type == payMode"></div>
            </div>
          </li>
        </ul>
        <div class="tip-wrap">
          付款提示：<br />
          1、支付前请先绑定手机号，避免重新安装时用户权益遗失！<br />
          2、支付前先选择支付方式，再点“确认支付”跳转后请及时付款，超时支付无法到账，需重新发起支付。<br />
          3、若支付时出现任何风险提示请不要担心，重新支付一次即可，并不会重复付款。<br />
          4、付款如遇到其他问题，可咨询在线客服处理
        </div>
        <div class="payBtn" @click="fn_submit">立即支付</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { vip_list, recharge_sumbit } from 'api/home'
import { userInfo } from 'api/user'
export default {
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {
      vipList: {},
      mySwiper: null,
      showPop: false, //弹窗开关
      activeSwiper: 0, //当前选中图片的index
      activeData: {},
      payMode: '',
      hourT: 0,
      payStatus: false,
    }
  },
  props: {},
  async mounted() {
    await this.getCardList()
    void this.getUserInfo()
    this.hourT = Date.parse(this.userInfo.promotionExpiredAt) - new Date().getTime()
    document.addEventListener('visibilitychange', this.handleVisiable);
  },
  activated() {
    void this.getUserInfo()
  },
  destroyed() {
    // 移除监听
    window.removeEventListener('visibilitychange', this.handleVisiable);
  },
  methods: {
    handleVisiable(e) {
      this.$toast.clear()
      this.intTime()
    },
    // 获取用户信息
    async getUserInfo() {
      const res = await userInfo()
      if (res && res.code === 200) {
        this.$store.dispatch('setUserInfo', res.data)
      }
    },
    //支付金币
    async fn_submit() {
      // this.$toast.loading({
      //   duration: 0,
      //   message: '正在生成订单中，\n请勿重复点击，\n需要等待十秒左右',
      //   className: 'subToast'
      // })
      if (!this.payMode) {
        return this.$toast('请选择支付通道！！！')
      }
      if(this.payStatus) return
      this.$toast.loading({
        duration: 0,
        message: '正在生成订单中，\n请勿重复点击，\n需要等待十秒左右',
        className: 'subToast'
      })
      this.payStatus = true
      // let url = 'http://baidu.yywwk.com/'
      // setTimeout(() => {
      //   this.downloadFileByTagA(url)
      // }, 2000);

      const res = await recharge_sumbit({
        payAmount: this.activeData.money,
        payMode: this.payMode,
        productId: this.activeData.id,
        rchgUse: this.activeData.rchgUse
      })
      if (res.code === 200) {
        this.showPop = false
        if (!res.data) {
          this.$toast.clear()
          this.intTime()
          return this.$toast('购买成功！！！')
        }
        if (res.data && res.data.isOpenNewBrowser) {
          this.downloadFileByTagA(res.data.payUrl)
        }
      } else {
        this.$toast.clear()
        this.$toast(res.tip)
        this.intTime()
      }
    },
    intTime() {
      setTimeout(() => {
        this.payStatus = false
      }, 1000);
    },
    /* 利用a标签跳转safari浏览器机制 */
    downloadFileByTagA(fileUrl) {
      this.$toast.loading({
        duration: 0,
        message: '正在生成订单中，\n请勿重复点击，\n需要等待十秒左右',
        className: 'subToast'
      })
      const TagA = document.createElement('a')
      TagA.href = fileUrl

      TagA.className = 'oInput'
      TagA.style.display = 'none'

      // 兼容ios safari浏览器
      const e = document.createEvent('MouseEvent')
      e.initEvent('click', false, false)
      TagA.dispatchEvent(e)
    },

    //vip选择支付方式
    fn_sel(type) {
      this.payMode = type
    },
    // 选中会员卡
    checkItem(item, index) {
      this.activeSwiper = index
      this.activeData = item
      this.payMode = this.activeData.rchgType?.[0]?.type || ''
    },

    //获取会员信息
    async getCardList() {
      const res = await vip_list({})
      if (res.code === 200) {
        //所有数据
        this.vipList = res.data
        if (this.vipList.length) {
          this.activeSwiper = 0
          this.activeData = this.vipList[this.activeSwiper]
          this.payMode = this.activeData.rchgType?.[0]?.type || ''
        }
      } else {
        this.$toast(res.tip)
      }
    },
    async vipChuli() {
      this.showPop = true
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    hourC() {
      // 目标时区，东8区
      const targetTimezone = -8
      // 当前时区与中时区时差，以min为维度
      const dif = new Date().getTimezoneOffset()
      // 本地时区时间 + 本地时区时差  = 中时区时间
      // 目标时区时间 + 目标时区时差 = 中时区时间
      // 目标时区时间 = 本地时区时间 + 本地时区时差 - 目标时区时差
      // 东8区时间
      const east9time = new Date().getTime() + dif * 60 * 1000 - targetTimezone * 60 * 60 * 1000
      return (
        new Date(this.activeData.activityEndTime).getTime() +
        dif * 60 * 1000 -
        targetTimezone * 60 * 60 * 1000 -
        east9time
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.vip-pack {
  margin-top: 0.5rem;
  .card_list {
    // width: 100%;
    // @include flexbox($jc: flex-start);
    // @include scrollbar-hide();
    // overflow-y: auto;
    // padding-left: 0.64rem;

    width: 100%; display: flex; align-items: flex-start; gap: .36rem;flex-wrap: wrap; padding-left: .38rem;
    li {
      // margin-right: 0.16rem;
      width: 2rem;
      height: 2.4rem;
      position: relative;
      // border-radius: 0.12rem;
      // border: 1px solid #999;
      background: url('../../../assets/imgs/vip-card-bg-no.png') center center no-repeat;
      background-size: contain;
      flex-shrink: 0;
      text-align: center;
      display: flex;
      color: #620a60;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      .desc {
        position: absolute;
        top: 13px;
        left: 29px;
        // border-radius: 0.12rem 0rem;
        padding: 0.04rem 0.08rem;
        color: #620a60;
        font-size: 0.2rem;
        background: #ff7294;
        width: 100%;
        transform: rotate(45deg);
        @include textoverflow();
      }

      .name {
        margin-top: 0.7rem;
        margin-bottom: 0.1rem;
        font-size: 0.24rem;
        // font-weight: 600;
        padding: 0 0.15rem;
        // width: 1.3rem;
        height: 0.4rem;
        border: 1px solid #620a60;
        border-radius: 0.1rem;
        line-height: 0.4rem;
      }

      .money {
        font-size: 0.27rem;
        font-weight: 700;
      }

      .pre_money {
        font-size: 0.2rem;
        font-weight: 400;
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 0.01rem;
          background-color: #fff;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .active {
      // border-radius: 0.12rem;
      background: url('../../../assets/imgs/vip-card-bg.png') center center no-repeat;
      background-size: contain;
      // border: 0.7px solid $btnBg;
      // color: $txtActive;
      .desc {
        color: #fff;
        // background-color: $btnBg;
      }
      .pre_money {
        &::after {
          background-color: $btnBg;
        }
      }
    }
  }

  .vip-label {
    margin: 0.3rem auto;
    font-size: 0.28rem;
    font-weight: 400;
    text-align: center;
    line-height: 0.4rem;

    > img {
      vertical-align: -0.06rem;
      margin-right: 0.08rem;
    }
  }

  .vip-privilege {
    width: 100%;
    height: calc(100vh - 3.92rem);

    z-index: 9;
    margin-top: 0.56rem;
    position: relative;
    overflow: hidden;

    ul {
      margin: 0 0.26rem;
      overflow-y: auto;
      height: calc(100vh - 4.6rem);
      padding-bottom: 1.4rem;
      li {
        border-bottom: 0.01rem solid rgba(153, 153, 153, 0.5);
        padding: 0.24rem 0 0.24rem 0.16rem;
        display: flex;
        align-items: center;
        .privilegeImg {
          width: 0.72rem;
          height: 0.72rem;
          margin-right: 0.16rem;
        }
        .privilegeName {
          .name {
            font-size: 0.28rem;
            line-height: 0.32rem;
            color: #cccccc;
          }

          .desc {
            font-size: 0.2rem;
            color: rgba(153, 153, 153, 0.75);
          }
        }
      }
    }
  }
  .tip-wrap {
    font-size: 0.24rem;
    color: #040404;
    margin-top: 0.5rem;
    padding: 0 0.28rem;
  }
  .openPack {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-radius: 0.16rem 0.16rem 0px 0px;
    display: flex;
    justify-content: center;
    z-index: 999;
    padding: 0 0.2rem;
    .customerService,
    .openVip {
      width: 85%;
      border-radius: 0.45rem;
      height: 0.8rem;
      line-height: 0.8rem;
      font-size: 0.38rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.24rem 0;
    }

    .customerService {
      border: 1px solid #eeeeee;
      margin-right: 0.16rem;

      img {
        width: 0.48rem;
        height: 0.48rem;
        margin-right: 0.08rem;
      }
    }

    .openVip {
      color: #fff;
      background-color: $btnBg;
    }
  }

  .submit-pop {
    border-radius: 0.4rem 0.4rem 0 0;
    overflow: hidden;

    .popupPack {
      background: #fff;

      h2 {
        text-align: center;
      }

      .activeItem {
        padding: 0 0.36rem;
        color: #333333;
        opacity: 0.5;
        font-weight: 600;
      }

      // 支付方式
      .pay-list {
        // min-height: 2rem;
        padding: 0 0.28rem;
        position: relative;

        .item {
          // padding-top: 0.2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 0.96rem;
        }

        .left {
          display: flex;
          align-items: center;
          font-size: 0.28rem;

          img {
            width: 0.47rem;
            //   height: 0.7rem;
            margin-right: 0.12rem;
          }
        }

        // 选中状态
        .right {
          width: 0.28rem;
          height: 0.28rem;
          border-radius: 50%;
          border: 0.02rem solid #333;

          position: relative;

          .active {
            width: 0.18rem;
            height: 0.18rem;
            background: #333;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .hintText {
        padding: 0.3rem;
        margin: 0.3rem;
        background: #eeeeee;
      }

      .payBtn {
        background: linear-gradient(90deg, #ffc47e 0%, #fceeca 100%);
        border-radius: 0.08rem;
        color: #333;
        width: 3.44rem;
        height: 0.8rem;
        line-height: 0.8rem;
        font-size: 0.24rem;
        text-align: center;
        margin: 0.3rem auto;
        margin-top: 0.5rem;
      }
    }
  }
}
.vip-count-down {
  @include flex-center;
  width: 6.78rem;
  border-radius: 0.4rem;
  padding: 0.12rem 0;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.08) 54.69%,
    rgba(255, 255, 255, 0.15) 100%
  );

  img {
    height: 0.38rem;
    vertical-align: middle;

    &:nth-of-type(2) {
      margin: 0 0.2rem;
    }
  }

  .van-count-down {
    display: flex;
    align-items: center;
  }

  .advertiseTimeOut-block {
    @include flex-center;
    border-radius: 0.08rem;
    width: 0.38rem;
    height: 0.38rem;
    border: 0.7px solid #f9c006;
    background: #000;
    color: #fff;
    font-family: PingFang HK;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
  }

  .advertiseTimeOut-txt {
    color: #fff;
    margin: 0 0.12rem;
  }
}
.title-tips {
  @include flexbox($jc: center);
  margin: 0.32rem 0;
  span {
    color: #999;
  }
  h3 {
    margin: 0 0.32rem;
  }
}
</style>

<!-- <style>
body .subToast {
  width: 120px !important;
}
</style> -->
