<template>
  <div class="accout">
    <EBackTop class="header" :title="'找回账号'" />
    <div class="accout-main">
      <div class="top">
        <p>如果您的账号丢失，可以使用以下方法找回</p>
        <p>1.使用您已经保存的账号凭证</p>
        <p>2.输入您已经绑定的手机号，接收验证码找回</p>
        <p>3.联系 <span class="top-kf" @click="toDetail('kf')">在线客服</span></p>
      </div>
      <ul class="bot">
        <li @click="toDetail('accoutCode-camera')">
          <p class="p1">1</p>
          <p class="p2">使用账号凭证找回</p>

        </li>
        <li @click="toDetail('phone')">
          <p class="p1">2</p>
          <p class="p2">使用手机号找回</p>
        </li>
        <li @click="toDetail('kf')">
          <p class="p1">3</p>
          <p class="p2">联系客服找回</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {}
  },
  methods: {

    toDetail(type) {
      this.$router.push(`/mine/setting/${type}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.accout {
  min-height: 100vh;
  background: #fff;
  font-size: 0.28rem;
  .top {
    padding: 0.47rem 0.64rem 0 0.64rem;
    font-size: 0.28rem;
    color: #848494;
    p{
      margin: .11rem 0;
    }
    p:first-child {
      font-weight: 600;
      color: #333;
      font-size: .3rem;
      margin-bottom: .3rem;
    }
  }

  .bot {
    padding: 0.47rem 0.64rem 0 0.64rem;

    li {
      font-size: 0.32rem;
      display: flex;
      align-items: center;
      height: .8rem;
      border: .01rem solid #848494;
      margin-bottom: .3rem ;
      border-radius: .1rem;
      overflow: hidden;
      .p1{
        width: 1.2rem;
        background: #848494;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;

      }
      .p2{
        flex-grow: 1;
        color: #848494;
        font-size: .3rem;
        margin: .19rem 0;
        padding-left: .4rem;
      }
    }
  }
}

.top-kf {
  color: #040404;
}</style>
