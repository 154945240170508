<template>
  <!-- 一行3个 -->
  <div class="threeBox-wrap" v-if="innerList && innerList.length > 0">
    <MoreHeader :itemTopic="itemTopic" :nowTabItem="nowTabItem"></MoreHeader>
    <div class="list-wrap">
      <ul>
        <DmComicCard
          v-for="(item, index) in innerList"
          :item="item"
          :key="index"
          :nowTabItem="nowTabItem"
        ></DmComicCard>
      </ul>

      <div class="btn" v-if="itemTopic.changeStatus">
        <div @click="comicsTopicChange">
          <img src="@/assets/imgs/homec/change.png" alt="" />
          <span>换一换</span>
        </div>
        <div @click="fn_more">
          <img src="@/assets/imgs/homec/mone.png" alt="" />
          <span>查看更多</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { comicsTopicChange } from 'api/comics'
export default {
  components: {
    DmComicCard: () => import('@/components/HomeComic/oneCard'),
    MoreHeader: () => import('@/components/HomeComic/topic/moreHeader.vue')
  },

  data() {
    return {
      comicsList: []
    }
  },
  props: {
    itemTopic: {
      type: Object,
      default() {
        return {}
      }
    },
    nowTabItem: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    innerList() {
      let list = this.comicsList.length ? this.comicsList : this.itemTopic.comicsList
      return list
    }
  },
  methods: {
    fn_more() {
      this.$router.push({
        path: `/home-comic/more`,
        query: {
          id: this.itemTopic.id,
          name: this.itemTopic.name
        }
      })
    },
    async comicsTopicChange() {
      const res = await comicsTopicChange({
        id: this.itemTopic.id
      })
      if (res.code === 200 && res.data) {
        this.comicsList = res.data.list
      } else {
        return this.$toast(res.tip)
      }
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
//<!-- 一行3个 -->
.threeBox-wrap {
  // box-shadow: 0 0.06rem 0.1rem rgb(0 0 0 / 10%);
  padding-bottom: 0.1rem;
  .list-wrap {
    padding: 0 0.37rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .btn {
      display: flex;
      width: 85%;
      margin: 0 auto;
      margin-top: 0.1rem;
      border: 2px solid #ff669d;
      border-radius: 0.34rem;
      overflow: hidden;
      div {
        width: 50%;
        height: 0.62rem;
        font-size: 0.32rem;
        background: #fff;
        color: #fe6fa2;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-child {
          color: #fff;
          background: #fe6fa2;
        }
        img {
          width: 0.35rem;
          height: 0.35rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
}
</style>
