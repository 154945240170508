<template>
  <div>
    <li
      @click="toPicview"
      :class="[{ isNew: item.isNew }, { isFree: item.isFree }, { nowChapter: nowChapter }]"
      v-if="listType == 0"
    >
      {{ item.indexName }}
    </li>
    <li class="listMode" :class="[{ nowChapter: nowChapter }]" @click="toPicview" v-else>
      <DecryptImg class="bgImages" :lazy="false" :imgURL="bgImages"></DecryptImg>
      <div class="listModeR">
        <div>第{{ item.indexName }}话 {{ title }}</div>
        <div class="LabelPack">
          <div class="Label" :class="{'isFree' : item.isFree}," v-if="item.isFree">免费</div>
          <!-- <div class="Label" v-else :class="[{ isVip: shoudBuy == 1 }, { isGold: shoudBuy == 2 }]">
            {{ shoudBuy == 1 ? 'vip' : price / 100 + '金币' }}
          </div> -->
          <!-- 微博漫画直接用 isFree判断是否免费，不免费就用金币购买-->
          <div class="Label isGold" v-else>整本解锁仅需{{ price | changeGold }}金币</div>
        </div>
      </div>
    </li>
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>
<script>
import { picViewRightTo } from '@/utils/utils_tools'

export default {
  components: {
    DmPopup: () => import('@/components/Popup/index.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {
      nowChapter: false
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    listType: {
      type: Number,
      default() {
        return 0
      }
    },
    bgImages: {
      type: String,
      default() {
        return ''
      }
    },
    shoudBuy: {
      type: Number,
      default() {
        return 0 //1vip 2金币
      }
    },
    price: {
      type: Number,
      default() {
        return 0 //金币数量
      }
    },
    title: {
      type: String,
      default() {
        return '' //封面名称
      }
    }
  },
  computed: {},
  methods: {
    async toPicview() {
      //漫画预览页，直接拿id显示当前
      if (this.$route.path.includes('/home-comic/pic-view/')) {
        if (this.nowChapter) return
      }
      picViewRightTo(this, this.item.id, this.price, null, null, null, null, null, this.bgImages, this.title)

      // picViewRightTo(this,this.item.id,this.price,2,this.isCollect,this.shoudBuy);
    },
    //收藏位置
    findIndex() {
      let newItem = {}
      //数据库找出这本书
      this.bookshelfList.forEach(element1 => {
        element1.list.forEach(element2 => {
          if (element2.id == +this.$route.params.id) {
            newItem = element2
          }
        })
      })
      //对比这本书看到的章节和当前章节是否相等
      if (this.item.indexName == newItem.hasViewNum) {
        this.nowChapter = true
      }
    }
  },
  mounted() {
    //漫画预览页，直接拿id显示当前
    if (this.$route.path.includes('/home-comic/pic-view/')) {
      if (+this.$route.params.id == this.item.id) {
        this.nowChapter = true
      }
    }
    //漫画介绍页，拿缓存记录上次已看到的页面
    if (this.$route.path.includes('/home-comic/decial/')) {
      this.findIndex()
    }
  },
  computed: {
    bookshelfList({ $store }) {
      return $store.state.history.bookshelfList
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  margin: 0 0.08rem;
  margin-bottom: 0.16rem;
  border-radius: 0.08rem;
  color: #6a6a6a;
  width: 1.22rem;
  height: 0.63rem;
  background: #f7f6fb;
  border: 1px solid #f7f6fb;
  @include flex-center;
  span {
    transform: scale(0.8);
  }
  &:nth-child(5n) {
    margin-right: 0;
  }
  // &.isNew{
  //   background: url("/newAppMh/home-comic/isNew.png");
  //   background-size:100% 100% ;
  // }
  &.isFree {
    // background: #fff;
    background-image: url('../../../../assets/imgs/homec/isNew.png');
    background-size: 100% 100%;
  }
  &.nowChapter {
    color: #ff7294;
    background-color: #fff;
    border: 1px solid #ff7294 !important;
  }
  &.listMode {
    width: 6.78rem;
    height: 1.12rem;
    margin-bottom: 0.26rem;
    border-radius: 0.14rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    overflow: hidden;
    &.nowChapter {
      border: 1px solid #ff7294;
      background: #ff669d2b;
    }
    .bgImages {
      // min-width: 2.28rem;
      // max-width: 1.28rem;
      width: 0.7rem;
      margin: 0 0.2rem;
      // height: 1.28rem;
      // border-radius: 0.08rem;
    }
    .listModeR {
      height: 100%;
      flex: 1;
      padding-right: 0.28rem;
      padding-top: 0.12rem;
      .LabelPack {
        display: flex;
        justify-content: right;
        align-items: center;
        margin-top: 0.1rem;
        .Label {
          // width: 0.8rem;
          // height: 0.32rem;
          padding: 0.01rem 0.2rem;
          border-radius: 0.04rem;
          color: #fff;
          text-align: center;
          font-size: 0.22rem;
          &.isFree {
            background: #0d9c09;
          }
          &.isVip {
            background: #ff8b00;
          }
          &.isGold {
            background: #ff66c2;
          }
        }
      }
    }
  }
}
</style>
