<template>
  <div class="home-index" :class="{ isIphonex: isIphonex }">
    <!-- 列表 -->
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
    >
      <!-- && type === homecategoryV2[0].id -->
      <div v-if="navItem.item.adsType === 2 && iconHomeAds.length" class="top_banner">
        <AdAvatarList :list="iconHomeAds" :wrap="true" key-name="avatar" />
      </div>
      <div class="top_banner" v-else-if="navItem.item.adsType !== 3 && avAD.length">
        <EADSwiper :EADconfig="EADconfig" :imgCDN="imgCDN" :list="avAD" keyName="cover" @clickImg="activityBtn" />
      </div>
      <!-- 通知栏跑马灯 -->
      <van-notice-bar
        left-icon="volume"
        color="#000"
        background="rgb(238, 238, 238, 0.6)"
        @click="toInfo(runningLight)"
        :text="runningLight?.content"
        v-if="runningLight"
      />
      <div class="swiper-ad" v-if="activityAds.length > 1">
        <!-- 334*180 -->
        <EADSwiper
          class="ad-list2"
          :centeredSlides="activityAds.length > 1"
          :list="activityAds"
          keyName="img"
          :EADconfig="EADconfig"
          :imgCDN="imgCDN"
          :activeIndex="2"
          :slidesPerView="2"
          :spaceBetween="20"
          @clickImgBetter="activityBtn2"
        >
        </EADSwiper>
        <div class="mask_ad" v-show="maskFlag"></div>
      </div>

      <div class="tab-main">
        <!-- 专题类  -->
        <div class="home-index-main">
          <!-- 循环卡片 -->
          <div v-for="(item, index) in topicList" :key="item.id">
            <div class="topPack">
              <div class="topL">{{ item.name }}</div>
              <div
                class="topR"
                @click="
                  $router.push({
                    path: `/subpage/morePage`,
                    query: {
                      id: item.id,
                      name: item.name,
                      type: item.showType
                    }
                  })
                "
              >
                更多<van-icon name="arrow" />
              </div>
            </div>
            <component
              :list="item.mediaList"
              :showType="item.showType"
              :name="item.name"
              :id="item.id"
              :is="compComponent(item.showType)"
            ></component>
          </div>
        </div>
      </div>
    </PullUp>
  </div>
</template>

<script>
import { index_home, advertiseClick } from 'api/home'
import { handleParamsRouteJump, handleURlParams, isIphonex } from '@/utils/utils_tools'
export default {
  data() {
    return {
      isIphonex: isIphonex(),
      pageNum: 1,
      pageSize: 10,
      topicList: [],
      tagList: [],
      loading: false,
      refreshing: false,
      sort: null,
      newAdvertise: {}, //中间活动广告
      finished: false, // 每次请求到的数据长度
      // 推荐
      remDataList: [],
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      maskFlag: true
    }
  },
  props: ['type', 'showType', 'navItem', 'showRemShortVideoType', 'avAD', 'short_videoAD'],
  components: {
    JavBigList: () => import('@/components/stencil/JavBigList.vue'),
    JavFiveCard: () => import('@/components/stencil/JavFiveCard.vue'),
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'),
    JavShortSix: () => import('@/components/stencil/JavShortSix.vue'),
    JavSmallRow: () => import('@/components/stencil/JavSmallRow.vue'),
    JavShortFour: () => import('@/components/stencil/JavShortFour.vue'),
    JavLongllRow: () => import('@/components/stencil/JavLongllRow.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },

  mounted() {
    this.refreshData()
  },
  computed: {
    activityAds({ $store }) {
      let data = $store.getters['ActivityAds']
      if (data.length) {
        setTimeout(() => {
          this.maskFlag = false
        }, 1000)
      }
      return data
    },
    // 获取公告内容开关 runningLight
    runningLight({ $store }) {
      return $store.getters['runningLight']
    },
    time() {
      const nowTiem = new Date().getTime()
      const newTime = Date.parse(this.newAdvertise.timeOut)
      const other = newTime - nowTiem
      if (other && other > 0) {
        return other
      } else {
        return false
      }
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    iconHomeAds({ $store }) {
      return $store.getters['iconHomeAds']
    }
  },

  methods: {
    async toInfo(ifno) {
      if (ifno.jumpUrl) {
        const code = handleURlParams(ifno.jumpUrl)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
        const res = await advertiseClick({
          id: ifno.id
        })
        if (res !== 200) {
          return this.$toast(res.tip)
        }
      }
    },
    // 活动广告跳转
    async activityBtn(item) {
      if (!item.href) return
      const code = handleURlParams(item.href)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    // 轮播跳转
    async activityBtn2(item) {
      if (item.url) {
        let code = handleURlParams(item.url)
        // handleParamsRouteJump(this, code);
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      } else {
        if (item.id) {
          advertiseClick({
            id: item.id
          })
          let url = item.href ? item.href : item.url
          let code = handleURlParams(url)
          handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
        }
      }
    },

    // 专题详情跳转
    lookMore(item) {
      this.$router.push(`/media_detail/${item.id}?title=${item.name}&showType=${item.showType}&price=${item.price}`)
    },
    // 上拉加载更多
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getCartoonPage()
    },
    // 下啦刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = true
      this.finished = false
      this.topicList = []
      this.remDataList = []
      this.pageNum = 1
      this.getCartoonPage()
    },
    // 展示方式 1. 长四宫格 2. 短六宫格 3. 长四宫格加换一换 4. 短六宫格加换一换 5. 长视频横排 6. 大列表  7. 短视频横排 8. 活动横排  9.五宫格

    //JavShortSix 短视频六宫格
    //JavShortFour 短视频四宫格
    //JavFourCard 长视频四宫格
    //JavBigList 大列表
    //JavFiveCard 五宫格
    //JavSmallRow 短小横排
    //JavLongllRow 长横排
    compComponent(showType) {
      switch (showType) {
        case 1: // 长视频四宫格*
          return 'JavFourCard'
        case 2: // 短视频六宫格
          return 'JavShortSix'
        case 3: // 长视频四宫格加换一换
          return 'JavFourCard'
        case 4: // 六宫格加换一换
          return 'JavShortSix'
        case 5: // 长视频横排
          return 'JavLongllRow'
        case 6: // 大列表
          return 'JavBigList'
        case 7: // 短视频横排
          return 'JavSmallRow'
        case 8: // 活动大列表
          return 'JavBigList'
        case 9: // 五宫格
          return 'JavFiveCard'
        default:
          return 'JavShortSix'
      }
    },
    // 获取首页数据
    async getCartoonPage() {
      try {
        const res = await index_home({
          id: this.type,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sort: 1
        })
        if (res.code === 200) {
          this.refreshing = false
          this.loading = false
          // 推荐页特殊处理
          // if(this.navItem.item.showType === 5){
          //   return this.fn_recommend(res);
          // }
          if (this.pageNum === 1) {
            if (res.data && res.data.tagList) {
              this.tagList = res.data.tagList.splice(0, 8)
            } else {
              this.tagList = []
            }
            if (res.data.newAdvertise) {
              this.newAdvertise = res.data.newAdvertise
            } else {
              this.newAdvertise = {}
            }
          } else {
            if (res.data.tagList) this.tagList = res.data.tagList
            if (res.data.newAdvertise) this.newAdvertise = res.data.newAdvertise
          }
          this.topicList = [...this.topicList, ...res.data.topicList]
          if (res.data.topicList.length < this.pageSize || !res.data.topicList) {
            this.finished = true
          }
        } else {
          this.refreshing = false
          this.loading = false
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.finished = true
        this.refreshing = false
        this.loading = false
        console.log(error)
        this.$toast('请求错误，请稍后再试!')
      }
    },
    // 推荐页面数据整理
    fn_recommend(res) {
      const obj = {
        avList: res.data.avList ? res.data.avList : [],
        longList: res.data.longList ? res.data.longList : [],
        shortList: res.data.shortList ? res.data.shortList : []
      }
      this.remDataList.push(obj)
      if (res.data.avList.length < this.pageSize || !res.data.avList) {
        this.finished = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-index {
  // height: calc(100vh - 1rem);
  padding-top: 2.7rem;

  &.isIphonex {
    // height: calc(100vh - 2.5rem);
  }

  :deep() {
    .van-notice-bar {
      opacity: 0.6;
      height: 0.6rem;
      margin: 0 0.24rem;
      // border-radius: 1rem;
      margin-top: 0.1rem;
      box-shadow: 0px 4px 4px 0px #00000040;

      .van-icon {
        color: #000;
      }

      .van-notice-bar__wrap {
        font-size: 0.26rem;
      }
    }
  }
}

//推荐以外的主题
.home-index-main {
  padding: 0 0.25rem 1rem 0.25rem;
  .topPack {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.24rem 0;

    .topL {
      font-size: 0.33rem;
    }

    .topR {
      font-size: 0.26rem;
      color: #bcbcbc;
    }
  }

  .bot_banner {
    height: 1.5rem;
  }
}

.card-list {
  @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: wrap);
  padding-top: 0;
  margin-top: 0.3rem;

  .card-img {
    width: 1.5rem;
    height: 1.9rem;
    border-radius: 0.1rem;
    margin-bottom: 0.2rem;
    position: relative;

    :deep() {
      .warpNoPadding {
        border-radius: 0.1rem;

        img {
          border-radius: 0.1rem;
        }
      }
    }

    .title {
      position: absolute;
      @include transformCenter(-50%, 0);
      left: 50%;
      bottom: 0;
      width: 100%;
      height: 0.4rem;
      text-align: center;
      line-height: 0.4rem;
      background: rgba($color: #000000, $alpha: 0.5);
      font-size: 0.2rem;
      border-bottom-left-radius: 0.1rem;
      border-bottom-right-radius: 0.1rem;
      color: #fff;
    }

    .more-bg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($color: #000000, $alpha: 0.7);
      font-size: 0.2rem;
      border-radius: 0.1rem;

      color: #fff;
    }
  }

  i {
    width: 1.5rem;
  }
}

.activity-card {
  position: relative;

  .card-img {
    height: 1.5rem;
    width: 100%;
  }

  .advertiseTimeOut {
    position: absolute;
    right: 0.3rem;
    top: 0.45rem;
    display: flex;
    align-items: center;

    .txt {
      margin-right: 0.2rem;
      font-size: 0.3rem;
      color: #fff;
    }

    &-txt {
      color: #fff;
      font-size: 0.3rem;

      font-weight: 600;
      margin: 0 0.02rem;
    }

    &-block {
      width: 0.4rem;
      height: 0.4rem;
      background: #fff;
      border-radius: 0.06rem;
      font-weight: 600;
      color: #000;
      font-size: 0.32rem;
      padding: 0 0.1rem;
    }
  }
}

.top_banner {
  // height: 4rem;
  .JGbtnList {
    padding: 0 0.3rem;
  }
}
// 运营活动广告
.swiper-ad {
  width: 7.1rem;
  height: 1.8rem;
  background: #fff;
  margin: 0.24rem auto;
  position: relative;

  .ad-list1 {
    border-radius: 0.08rem;
    margin: 0 auto;
    overflow: hidden;
  }

  .ad-list2 {
    width: 7.1rem;
    height: 1.8rem;
    :deep {
      .default {
        border-radius: 0.08rem;
        overflow: hidden;
      }
    }
  }
  .mask_ad {
    width: 7.3rem;
    height: 2rem;
    position: absolute;
    left: -0.1rem;
    top: -0.1rem;
    background-color: #fff;
    z-index: 200;
  }
}
</style>
