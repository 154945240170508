<template>
  <div class="dating-main">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="cityItem && cityItem.name + '暂无更多数据'"
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="list-wrap">
          <SelfCard :showType="item.datingType" :itemData="item" v-for="(item, index) in modelList" :key="index">
          </SelfCard>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { dating_category } from '@/api/dating'
export default {
  props: {
    showType: {
      type: Number,
      default() {
        return 1
      }
    },
    cityItem: {
      type: Object,
      default() {
        return null
      }
    }
  },
  components: {
    SelfCard: () => import('@/components/Dating/self-employed/selfCard.vue')
  },
  data() {
    return {
      modelList: [],
      pageNum: 1,
      pageSize: 10,
      refreshing: false,
      finished: false,
      loading: false
    }
  },
  computed: {},
  mounted() {
    this.onRefresh()
  },
  watch: {
    cityItem: {
      handler(n, o) {
        this.onRefresh()
      }
    }
  },
  methods: {
    onLoad() {
      this.pageNum++
      this.getDatingGirls()
    },
    //上拉刷新
    onRefresh() {
      this.modelList = []
      this.finished = false
      this.loading = true
      this.pageNum = 1
      this.getDatingGirls()
    },
    // 获取楼凤信息列表
    async getDatingGirls() {
      const res = await dating_category({
        cityId: this.cityItem ? this.cityItem.id : 0,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: this.showType // 1楼凤 2外围
      })
      if (res.code === 200) {
        this.loading = false
        this.refreshing = false
        if (!res.data || !res.data.modelList || res.data.modelList.length < this.pageSize) {
          this.finished = true
        }
        if (res.data.modelList) this.modelList = [...this.modelList, ...res.data.modelList]
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scopde>
.dating-main {
  //   max-height: calc(100vh - 3rem);
  //   overflow-x: hidden;
  //   overflow-y: scroll;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  .list-wrap {
    padding: 0 0.24rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
