<template>
  <van-popup class="activity-img-pop" v-model="isShow" @close="closeActivityImg" v-if="datingAd.length">
    <div class="activity-img" @click.stop="imgJub">
      <EDecryptImg :imgCDN="imgCDN" :imgURL="datingAd[0].cover"> </EDecryptImg>
      <div><van-icon name="close" size="26" color="#fff" @click.stop="handleClose" /></div>
    </div>
  </van-popup>
</template>

<script>
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
import { advertiseClick } from 'api/home'
export default {
  data() {
    return {
      isShow: false,
      datingAd: []
    }
  },
  methods: {
    closeActivityImg() {
      if (this.datingAd.length) {
        this.datingAd.shift()
        this.$nextTick(() => {
          if (this.datingAd.length) {
            this.isShow = true
          }
        })
      }
    },
    handleClose() {
      this.isShow = false
    },
   async imgJub() {
      if (this.datingAd[0].href) {
        const code = handleURlParams(this.datingAd[0].href)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)

        if (this.datingAd[0].href.startsWith('http') == false) {
          this.datingAd = []
          this.isShow = false
        }
            const res = await advertiseClick({
          id: this.datingAd[0]?.id
        })
        if (res !== 200) {
          return this.$toast(res.tip)
        }
      }
      // this.$store.commit('SET_ACTIVITY_POP', false);
      //   this.showActivityImg = this.activityPop;
    }
  },
  computed: {
    // activityImg({ $store }) {
    //   return $store.getters['datingAD'];
    // },

    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  created() {
    this.datingAd = this.$store.getters.popAD
    this.isShow = this.datingAd.length > 0
  }
}
</script>

<style lang="scss" scoped>
.activity-img-pop {
  background-color: transparent !important;
  overflow-y: visible;
  // background: red;
  // z-index: 2003 !important;

  .activity-img {
    text-align: center;
    width: 6.7rem;
    height: 100%;
  }
}
</style>
