<template>
  <div class="other-user-history">
    <EBackTop
      class="back-top"
      :title="' '"
      :fixed="true"
      :isDark="true"
      :background="'transparent'"
      :openFallback="true"
      :color="'#fff'"
    ></EBackTop>
    <!-- 背景 -->
    <div class="bg_img_box">
      <DecryptImg class="user-header" :imgURL="mediaUserObj.avatar" />
      <p>{{ mediaUserObj.name }}</p>
      <div class="focuson_but" @click="toSendMsg">私信</div>
    </div>
    <div class="list-main">
      <PullUp
        v-if="myPostList && myPostList.length"
        @refreshData="refreshData"
        @moreData="moreData"
        :finished="finished"
        :loading="loading"
        :refreshing="refreshing"
      >
        <div class="main_box" v-for="item in myPostList" :key="item.id">
          <div>上架时间:{{ item.base.createdAt | dateFormat08 }}</div>
          <!-- <div>{{ item.base.title }}</div>
          <div class="desc-txt">
            <p class="desc">
              {{ item.node.text }}
            </p>
            <div class="desc_btn" @click.stop="$router.push(`/community/detail/${item.base.id}`)">...阅读全文</div>
          </div> -->
          <template>
            <Stencil :itemData="item" :imgCDN="imgCDN" @checkTag="checkTag"></Stencil>
          </template>

          <div class="meta_box">
            <div class="foot-l">
              <div class="item">
                <img src="@/assets/imgs/picon1.svg" alt="" />
                {{ item.base?.watches }}
              </div>
              <div class="item">
                <img src="@/assets/imgs/picon2.svg" alt="" />
                {{ item.base?.likes }}
              </div>
              <div class="item">
                <img src="@/assets/imgs/picon3.svg" alt="" />
                {{ item.base?.comments }}
              </div>
            </div>
            <div class="meta_name">{{ item.base.postCategory }}</div>
          </div>
        </div>
      </PullUp>
      <NoData class="noData" style="padding: 0.5rem 0" v-else></NoData>
    </div>
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>

<script>
import { videosRouterTo } from '@/utils/utils_tools'
import { user_media } from 'api/community'
import { user_publish, user_post_publish } from '@/api/dating'
export default {
  name: 'historyPage',
  components: {
    CommunityDefault: () => import('@/components/Community/default.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    Stencil: () => import('@/components/Community/stencil.vue'),
    NoData: () => import('@/components/JavNodata.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue')
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      category: [],
      myPostList: [],
      mediaUserObj: {}
    }
  },
  mounted() {
    this.mediaUser()
    this.refreshData()
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    }
  },
  methods: {
    checkTag(item) {
      this.$emit('checkTag', item)
    },
    refreshData(refreshing) {
      this.refreshing = true
      this.finished = false
      this.loading = false
      this.pageNum = 1
      this.myPostList = []
      this.getMyPostPublic()
    },
    //获取列表
    async mediaUser() {
      const res = await user_media({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: +this.$route.params.id
      })
      if (res.code === 200) {
        this.mediaUserObj = res.data.publisher
      } else {
        return this.$toast(res.tip)
      }
    },
    moreData(loading) {
      this.loading = false
      this.pageNum += 1
      this.getMyPostPublic()
    },

    async getMyPostPublic() {
      const res = await user_post_publish({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: +this.$route.params.id
      })
      if (res.code == 200) {
        if (res.data.list && res.data.list.length) {
          this.loading = false
          this.refreshing = false
          this.myPostList = [...this.myPostList, ...res.data.list]
          if (res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
        }
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip || res.data)
      }
    },
    toSendMsg() {
      if (this.isMember) {
        // this.$router.push(`/chat/${+this.$route.params.id}?nickName=${this.publisher.name}`); // 私信页面
        this.$router.push({
          path: `/chatIM/${this.mediaUserObj.id}`,
          query: {
            nickName: this.mediaUserObj.name
          }
        })
      } else {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '注意',
          content: '您暂无私信权限',
          type: 'up_post',
          ok: '开通VIP',
          img: '',
          cancel: '',
          position: 'center',
          showBalance: false,
          cb: async returnData => {
            this.$router.push('/mine/newRecharge?type=1')
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.other-user-history {
  min-height: 100vh;
}

.list-main {
  margin-top: 3.6rem;
  background: #fff;
  border-radius: 0.4rem 0.4rem 0 0;
  position: relative;
}

.bg_img_box {
  width: 7.5rem;
  height: 5.02rem;
  background: url('../../../assets/imgs/bg_user_center.png') no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  @include flexbox($fd: column, $jc: center, $ai: center);
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(0, 0, 0, 0.5);
  }
  p {
    margin-top: 0.14rem;
    color: #fff;
    font-family: PingFang SC;
    font-size: 0.36rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .focuson_but {
    height: 0.62rem;
    line-height: 0.56rem;
    border-radius: 1rem;
    margin-top: 0.12rem;
    width: 2rem;
    text-align: center;
    background-color: #ff669d;
    color: #fff;
    z-index: 2;
  }
}

.user-header {
  width: 1.68rem !important;
  height: 1.68rem !important;
  flex-shrink: 0;
  border-radius: 50%;
  :deep() {
    .warp {
      width: 1.68rem;
      height: 1.68rem;
      border-radius: 50%;
    }
    .img {
      border-radius: 50%;
      width: 1.68rem;
      height: 1.68rem;
    }
  }
}

.main_box {
  margin: 0.2rem;
  padding: 0.2rem;
  box-shadow: 0 0.02rem 0.06rem #ddd;
  border-radius: 0.1rem;
  color: #bbb;
  margin-bottom: 0.3rem;

  > :nth-child(1) {
    border-bottom: 0.01rem solid #333;
    padding-bottom: 0.2rem;
  }

  > :nth-child(2) {
    margin-top: 0.2rem;
    margin-bottom: 0.05rem;
    color: #333;
  }

  .show_img {
    background-color: pink;
    margin: 0.2rem 0;
  }

  .between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .meta_box {
    @extend .between;
    padding-bottom: 0.2rem;

    .foot-l {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 0.64rem;
      }

      img {
        width: 0.28rem;
        height: 0.2rem;
        margin-right: 0.1rem;
      }
    }

    .meta_name {
      background-color: #fdecef;
      padding: 0.05rem 0.1rem;
      color: #000;
      border-radius: 0.05rem;
      font-size: 0.22rem;
      border: 1px solid pink;
    }
  }

  .desc-txt {
    font-size: 0.26rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: normal;
    white-space: pre-wrap;

    .desc {
      margin-top: 0.24rem;
      color: #999;
      @include textoverflow(3);
    }

    .desc_btn {
      color: #ffcf86;
    }
  }

  .img-pack {
    margin: 0.2rem 0;
    .img-item {
      margin-top: 0.2rem;
      border-radius: 0.2rem;
      :deep() {
        img {
          border-radius: 0.2rem;
        }
      }
    }
  }
  .img-list {
    @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
    margin: 0.14rem 0 0.2rem 0;

    .img-item {
      // width: 2.94rem;
      width: fix-content;
      margin-top: 0.1rem;
      border-radius: 0.1rem;
      overflow: hidden;
      width: 1.94rem;
      height: 1.94rem;
      margin-right: 0.16rem;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    i {
      width: 2.26rem;
    }

    .img-item:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
