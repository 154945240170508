<template>
  <div class="city-list">
    <div class="city-main">
      <div class="search-clickbox-box" @keyup.enter="fn_search">
        <van-field class="search-clickbox" v-model="value" @input="change(value)" placeholder="搜索位置" type="text" />
        <!-- <img src="/images/dating/find.png" alt="" /> -->
      </div>
      <div class="main-box">
        <!-- 当前城市 -->
        <div class="now-city">
          <!-- <van-icon name="location-o" /> -->
          <span class="city-type">当前城市</span>
          <span class="city"> {{ $route.query.city ? $route.query.city : '全国' }}</span>
        </div>
        <!-- 热门城市 -->
        <div class="hot-city">
          <div class="top-title">热门城市</div>
          <ul class="hot-city-list">
            <li
              class="hot-city-list-item"
              v-for="(item, index) in hotList"
              :key="item.id"
              @click="checkCity(item)"
              :class="{ lastChild: index === hotList.length - 1 }"
            >
              {{ item.name }}
            </li>
            <li></li>
          </ul>
        </div>
        <!-- 全国 -->
        <p class="all" @click="checkCity({ name: '全国', id: 0 })">全国</p>
      </div>
    </div>

    <!-- 索引 -->
    <van-index-bar
      :sticky-offset-top="stickyOffsetTop"
      :highlight-color="'#FF669D'"
      @index-anchor-line-height="'0.64rem'"
    >
      <template v-for="(citys, key) in newAlphabet">
        <van-index-anchor v-if="!value" :index="key" :key="'anchor' + key" />
        <template v-for="(city, idx) in citys">
          <van-cell @click="checkCity(city)" :title="city.name" />
        </template>
      </template>
    </van-index-bar>
  </div>
</template>

<script>
import { city_list } from 'api/dating'
import { EventBus } from '@/plugins/event-bus.js'
//保留否则报错
import cnchar from 'cnchar'
export default {
  name: 'citySelList',
  components: {
    // ZHHeaderNav: () => import('@/components/ZHHeaderNav.vue'),
  },
  props: {},
  data() {
    return {
      //距离顶部距离
      stickyOffsetTop: 290,
      //显示地址选择
      showCity: false,
      item: {},
      value: '',
      //当前城市
      city: '',
      hotList: [],
      filterArr: [],
      //类型，外围还是楼凤
      type: +this.$route.params.type,
      //I: [],没有i
      alphabet: {
        A: [],
        B: [],
        C: [],
        D: [],
        E: [],
        F: [],
        G: [],
        H: [],
        I: [],
        J: [],
        K: [],
        L: [],
        M: [],
        N: [],
        O: [],
        P: [],
        Q: [],
        R: [],
        S: [],
        T: [],
        U: [],
        V: [],
        W: [],
        X: [],
        Y: [],
        Z: []
      },
      //I: [],没有i
      newAlphabet: {
        A: [],
        B: [],
        C: [],
        D: [],
        E: [],
        F: [],
        G: [],
        H: [],
        I: [],
        J: [],
        K: [],
        L: [],
        M: [],
        N: [],
        O: [],
        P: [],
        Q: [],
        R: [],
        S: [],
        T: [],
        U: [],
        V: [],
        W: [],
        X: [],
        Y: [],
        Z: []
      }
    }
  },
  mounted() {
    this.getCityList()
    this.stickyOffsetTop = (document.querySelector('body').clientWidth * 290) / 375
  },
  methods: {
    //显示城市
    fn_showCity(bool, obj) {
      this.$emit('fn_datingShow', true)
      obj && obj.city ? (this.city = obj.city) : (this.city = '全国')
      this.showCity = bool
    },
    //返回
    toBack() {
      this.fn_showCity(false)
    },
    // 获取城市列表并进行分类
    async getCityList() {
      const res = await city_list({})
      if (res.code === 200) {
        this.hotList = res.data.hotList
        res.data.list.forEach(item => {
          const s = item.name.spell()[0]
          Object.getOwnPropertyNames(this.alphabet).forEach(key => {
            if (key === s) {
              this.alphabet[key].push(item)
              this.newAlphabet[key].push(item)
            }
          })
        })
      } else {
        return this.$toast(res.tip)
      }
    },
    checkCity(item) {
      //发布约炮帖子
      if (this.$route.query.type == 'post') {
        EventBus.$emit('reloadPostCity', item)
        this.$router.replace({
          path: `/dating/publish`,
          query: {
            datingType: +this.$route.query.datingType
          }
        })
        return
      }

      //   this.$router.replace(`/home-comic/home-activity/girls`)
      this.$router.push({
        path: `/post`,
        query: {
          tabName: '约炮',
          cityItem: JSON.stringify(item)
        }
      })
    },
    // 模糊查询
    change(matchStr) {
      // if(!matchStr) return this.newAlphabet=this.alphabet;
      for (const key in this.alphabet) {
        if (this.alphabet.hasOwnProperty.call(this.alphabet, key)) {
          const element = this.alphabet[key]
          let newArr = []
          element.filter((ele, index) => {
            if (ele.name.includes(matchStr)) {
              newArr.push(ele)
            }
          })
          this.newAlphabet[key] = newArr
        }
      }
    }
  },
  computed: {
    // storeForm({ $store }) {
    //   return $store.getters['dating/getForm'+this.type]
    // },
  }
}
</script>

<style lang="scss" scoped>
.city-list {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: $pcMaxWidth;
  z-index: 999;
  min-height: 100vh;
  color: #666666;
  background: #fff;

  :deep() {
    .header-nav {
      z-index: 2;
    }
  }
}

.city-main {
  position: fixed;
  background: #fff;
  width: 100%;
  max-width: $pcMaxWidth;
  z-index: 2;

  // 搜索框
  .search-clickbox-box {
    position: relative;
    width: 100%;
    max-width: $pcMaxWidth;
    z-index: 2;
    background-color: #fff;
    padding: 0.15rem 0;

    .search-clickbox {
      margin: 0 auto;
      width: 92%;
      height: 0.7rem;
      border-radius: 0.56rem;
      background: #f2f5fa;
      color: #999;
      font-size: 0.3rem;
      display: flex;
      align-items: center;

      :deep() {
        .van-field__control {
          text-indent: 0.5rem;
        }
      }
    }

    img {
      position: absolute;
      left: 0.3rem;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-left: 0.3rem;
      margin-right: 0.2rem;
      width: 0.356rem;
      height: 0.356rem;
    }
  }

  // 当前城市
  .now-city {
    display: flex;
    align-items: center;
    font-size: 0.24rem;

    span {
      margin: 0.2rem 0.2rem 0.2rem 0;
    }

    .city-type {
      font-weight: 600;
      font-size: 0.26rem;
      color: #333;
    }

    .city {
      color: #fff;
      background: #ff669d;
      font-weight: 600;
      border-radius: 0.08rem;
      font-size: 0.26rem;
      line-height: 0.26rem;
      padding: 0.1rem 0.3rem;
    }
  }

  // 热门城市
  .hot-city {
    .top-title {
      display: flex;
      align-items: center;
      font-size: 0.26rem;
      font-weight: 600;
      margin-bottom: 0.3rem;
      color: #333;
    }

    .hot-city-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0.3rem 0;

      .hot-city-list-item {
        white-space: nowrap;
        width: 32.5%;
        padding: 0.11rem 0.75rem;
        // margin-right: 0.08rem;
        margin-top: 0.1rem;
        color: #505d71;
        font-size: 0.24rem;
        background: #f1f5fb;
        border-radius: 0.1rem;
      }

      .lastChild {
        margin-left: 0.08rem;
      }
    }

    .hot-city-list::after {
      content: '';
      flex: auto;
      margin-right: 0.1rem;
    }
  }

  .all {
    font-size: 0.3rem;
    margin: 0;
    padding-bottom: 0.1rem;
    font-weight: 600;
  }
}

// 索引
.van-index-bar {
  padding-top: 6rem;
}

.title {
  background: #fff;
  margin: 0;
  padding-left: 0.2rem;
}

:deep() {
  .van-index-bar__sidebar {
    margin-top: 2.2rem;
  }

  .van-index-anchor {
    padding: 0 0.25rem;
    background: #f1f5fb;
    color: #505d71;
  }
}

.van-cell {
  color: #666666;
  background: #fff;
}

.main-box {
  padding: 0 0.24rem;
}
</style>
