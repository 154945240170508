<template>
  <div class="public-noData">
    {{noDataTxt}}
  </div>
</template>

<script>
export default {
  props: {
    noDataTxt: {
      type: String,
      default() {
        return '暂无更多数据';
      },
    },    
  }, 
  data() {
    return{

    }
  },
  methods: {
  },
  mounted() {
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
//无数据样式
.public-noData {
  color: #ccc;
  font-size: 0.24rem; 
  display: flex;
  align-items: center;
  justify-content: center; 
}
</style>


