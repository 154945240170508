<template>
  <div class="md-activity">
    <!-- <HeaderBar :title="'活动列表'" color="#333" /> -->
    <EBackTop :open-fallback="true" title="活动列表" class="back" />
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      class="videos-panel"
    >
      <div class="md-activity-main">
        <div v-for="(item, index) in list" class="activity-item">
          <DecryptImg class="img" :needPadding="false" :imgURL="item.cover" @click.native="jumpActivity(item)">
          </DecryptImg>
          <p class="title" @click="jumpActivity(item)">{{ item.title }}</p>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { activity_list } from 'api/activity'
export default {
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {
      list: [],
      pageNum: 1,
      pageSize: 10,
      loading: false,
      finished: false
    }
  },
  computed: {},
  mounted() {
    this.getActivityList()
  },
  methods: {
    // 跳转到活动详情页
    jumpActivity(item) {
      this.$router.push('/Activity/lottery/detail/' + item.name)
    },
    async getActivityList() {
      const res = await activity_list({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res && res.code === 200) {
        if (this.pageNum == 1) {
          this.list = res.data.list
        } else {
          this.list = [...this.list, ...res.data.list]
        }
        // 加载状态结束
        this.loading = false
        // 数据全部加载完成
        if (res.data.list.length < this.pageSize) {
          this.finished = true
        }
        console.log(res.data)
      }
    },

    onClickLeft() {
      this.$nuxt.$router.go(-1)
    },
    async onLoad() {
      // 异步更新数据
      this.pageNum = this.pageNum + 1
      this.loading = true
      await this.getActivityList()
    }
  }
}
</script>

<style lang="scss" scoped>
.md-activity {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;

  &-main {
    background-color: #fff;
    padding: 0.3rem;
    padding-top: 0.1rem;

    .activity-item {
      display: block;
      width: 100%;
      margin-bottom: 0.4rem;
      .img {
        width: 100%;
        height: 3.3rem;
      }

      .desc {
        margin: 0.2rem 0.3rem;
        font-size: 0.28rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.0028rem;
        text-align: left;
        -webkit-line-clamp: 1; // 用来限制在一个块元素显示的文本的行数
        display: -webkit-box; // 将对象作为弹性伸缩盒模型显示
        -webkit-box-orient: vertical; //设置或检查伸缩盒对象的子元素的排列方式
        text-overflow: ellipsis; // 在多行文本的情况下，用...隐藏超出范围的文本
        word-break: break-all;
        overflow: hidden;
      }
    }
  }
}
.title {
  margin-top: 0.24rem;
  font-size: 0.32rem;
}
</style>
s
