<template>
  <div class="JGbtnList">
    <div class="JGContent" :class="{ wrap }">
      <div v-for="(item, index) in showList" :key="index" @click="JumpFun(item)">
        <div>
          <EDecryptImg :imgCDN="imgCDN" :imgURL="item[keyName]"  class="cover"> </EDecryptImg>
        </div>
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
export default {
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    wrap: {
      type: Boolean,
      default: false
    },
    keyName: {
      type: String,
      default: 'cover'
    },
    maxLength: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  mounted(){
  },
  computed: {
    showList() {
      if (this.maxLength) {
        return this.list.slice(0, this.maxLength)
      }
      return this.list
    },
   imgCDN({ $store }) {
      return $store.getters['cdn'];
    },
  },
  methods: {
    JumpFun(item) {
      let url = item.href || item.download_url
           let code = handleURlParams(url)
           handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store);
    }
  }
}
</script>
<style lang="scss" scoped>
.JGbtnList {
  width: 100%;
  min-height: 1.24rem;
  overflow-x: auto;
  margin-top: 0.18rem;

  > .JGContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.wrap {
      flex-wrap: wrap;

      > div {
        &:nth-child(5n + 5) {
          margin-right: 0;
        }
      }
    }

    > div {
      overflow: hidden;
      width: 1.12rem;
      height: 1.6rem;
      margin-right: 0.32rem;
      flex-shrink: 0;

      > div:first-child {
        border-radius: 0.08rem;
        overflow: hidden;
        .cover {
          width: 1.12rem;
          height: 1.12rem;
        }
      }

      > div:last-child {
        font-size: 0.24rem;
        font-weight: 400;
        text-align: center;
        margin-top: 0.08rem;
        line-height: 0.36rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
}
</style>
