<template>
  <div class="bosses-detail">
    <HeaderBar
      :bgColor="bgColor"
      title=" "
      :textColor="textColor"
      :navText="$route.query.name"
      :background="'transparent'"
      :color="'#fff'"
    />
    <div class="detail-main">
      <!-- 经纪人信息 -->
      <div class="top-warp">
        <DecryptImg :needPadding="false" class="avatar-bg" :imgURL="bossInfo.avatar || bossInfo.background">
          <div class="avatar-child" style="display: none">
            <DecryptImg class="avatar" :imgURL="bossInfo.avatar" />
            <div class="avatar-right">
              <div class="avatar-right-top">
                <p class="name">{{ bossInfo.name }}</p>
                <p class="recomment" v-if="bossInfo.recomment">推荐</p>
              </div>
              <!-- 商家信息 -->
              <div class="boss-info">
                <div>认证妹子 {{ bossInfo.girls | numberFilter }}</div>
                <div>成交 {{ bossInfo.orders | numberFilter }}</div>
                <div>粉丝 {{ bossInfo.fans | numberFilter }}</div>
              </div>
            </div>
          </div>
        </DecryptImg>
        <!-- 描述 -->
        <div class="decial-desc">
          <div class="businessCard">
            <div class="namePack">
              <div class="name">商家名称商家商标</div>
              <!-- <div class="btn_Pack">
                <div class="collect_btn"><img src="/images/mine/line.png" alt="" />收藏</div>
                <div class="report_btn" @click="checkRight">举报</div>
              </div> -->
            </div>
            <div class="fans">
              认证妹子：{{ bossInfo.girls | numberFilter }} 成交：{{ bossInfo.orders | numberFilter }} 粉丝：{{
                bossInfo.fans | numberFilter
              }}
            </div>
          </div>
          <div class="introduce">介绍：<br />{{ bossInfo.desc }}</div>
        </div>
        <!-- 排序查询 -->
        <div class="bot-info">
          <div class="sort-box boss-sort-container">
            妹子列表
            <!-- <van-popover
              :get-container="getContainer"
              class="toggle-wrap"
              v-model="showPopover"
              trigger="click"
              :actions="actions"
              @select="onSelect"
            >
              <template #reference>
                <div class="sortByPrice">{{ actionSel.text }}</div>
              </template>
            </van-popover> -->
          </div>
        </div>
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="暂时没有更多数据！"
          @load="onLoad"
          :immediate-check="false"
        >
          <div class="girls-list">
            <ModelCard :showType="2" :itemData="item" v-for="item in modelList" :key="item.id"></ModelCard>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { bosses_detail } from '@/api/dating'
export default {
  components: {
    HeaderBar: () => import('@/components/HeaderBar.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    ModelCard: () => import('@/components/Dating/model/modelCard.vue')
  },
  data() {
    return {
      //经纪人详情信息
      bossInfo: {},
      //妹子列表
      modelList: [],
      //排序方式
      showPopover: false,
      actionSel: { text: '价格降序', id: 0 },
      actions: [
        { text: '价格降序', id: 0 },
        { text: '价格升序', id: 1 },
        { text: '热度', id: 2 },
        { text: '最新', id: 3 }
      ],
      bgColor: 'transpent',
      textColor: '#fff',
      //分页
      pageNum: 1,
      pageSize: 10,
      refreshing: false,
      finished: false,
      loading: false
    }
  },
  mounted() {
    this.onRefresh()
    window.addEventListener('scroll', this.scrollHandle) //绑定页面滚动事件
  },
  methods: {
    // 举报跳转
    // async checkRight() {
    //   if (this.bossInfo.isBuy) {
    //     // 跳转举报编辑页
    //     this.$router.push({
    //       path: `/mine/Feedback`,
    //       query: {
    //         id: this.bossInfo.id,
    //         type: this.bossInfo.datingType,
    //       },
    //     })
    //   } else {
    //     this.$toast('购买后才能提交举报')
    //   }
    // },
    //获取挂载对象
    getContainer() {
      return document.querySelector('.boss-sort-container')
    },
    //确认选择排序
    onSelect(action, index) {
      this.actionSel = action
      this.onRefresh()
    },
    // 监听滚动高度
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
      if (top >= 200) {
        this.bgColor = '#f7f6fa'
        this.textColor = '#6a6a6a'
      } else {
        this.bgColor = 'transparent'
        this.textColor = '#fff'
      }
    },
    onLoad() {
      this.pageNum++
      this.datingBossDetail()
    },
    //上拉刷新
    onRefresh() {
      this.finished = false
      this.loading = true
      this.pageNum = 1
      this.datingBossDetail('pull')
    },
    // 请求商家详情信息
    async datingBossDetail(type) {
      const res = await bosses_detail({
        id: +this.$route.params.id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        sort: this.actionSel.id
      })
      if (res.code === 200) {
        this.refreshing = false
        this.loading = false
        // 没有背景则选择第一张
        if (res.data.bossInfo && !res.data.bossInfo.background) {
          if (res.data.modelList && res.data.modelList.length && res.data.modelList[0].images) {
            res.data.bossInfo.background = res.data.modelList[0].images[0]
          }
        }
        if (res.data.bossInfo) this.bossInfo = res.data.bossInfo
        if (!res.data || !res.data.modelList || res.data.modelList.length < this.pageSize) {
          this.finished = true
        }
        if (type == 'pull') this.modelList = []
        if (res.data.modelList) this.modelList = [...this.modelList, ...res.data.modelList]
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip)
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandle)
  }
}
</script>

<style lang="scss" scoped>
.bosses-detail {
  background-color: #fff;
  min-height: 100vh;
  :deep() {
    .header-nav {
      color: #fff;
    }
  }
}
// 头部商家信息
.top-warp {
  font-size: 0.28rem;
  .avatar-bg {
    height: 6rem;
    position: relative;
  }
  // 商家名称
  .avatar-child {
    position: absolute;
    bottom: 0rem;
    right: -0.3rem;
    width: 100%;
    box-sizing: content-box;
    padding-bottom: 0.38rem;
    display: flex;
    align-items: center;
    .avatar {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      margin-right: 0.3rem;

      :deep() {
        .warp {
          border-radius: 50%;
        }
      }
    }
    .avatar-right {
      width: 60%;
      .avatar-right-top {
        display: flex;
        align-items: center;
        margin-bottom: 0.09rem;
        font-weight: 600;
        font-size: 0.36rem;

        p {
          margin: 0;
        }
        .name {
          color: #fff;
          margin-right: 0.2rem;
        }
      }
      .boss-info {
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.26rem;
        color: #fff;
      }
    }
  }
  .decial-desc {
    font-size: 0.24rem;
    background: #fff;
    border-radius: 0.4rem 0.4rem 0 0;
    margin-bottom: 0.3rem;
    margin-top: -1.4rem;
    z-index: 999;
    position: relative;
    .businessCard {
      padding: 0.4rem;
      padding-bottom: 0.2rem;
      border-bottom: 0.02rem solid #eee;
      .namePack {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          font-size: 0.3rem;
          color: #333;
        }
        .btn_Pack {
          display: flex;
          align-items: center;
          font-size: 0.22rem;
          .collect_btn {
            height: 0.42rem;
            line-height: 0.42rem;
            padding: 0 0.2rem;
            border: 0.02rem solid #ff669d;
            border-radius: 0.32rem;
            color: #ff669d;
            display: flex;
            align-items: center;
            &.checked {
              color: #c2c4c4;
              border: 0.02rem solid #c2c4c4;
            }
            img {
              height: 0.24rem;
              margin-right: 0.06rem;
            }
          }
          .report_btn {
            height: 0.42rem;
            line-height: 0.42rem;
            padding: 0 0.2rem;
            border: 0.02rem solid #fe7803;
            background: #fe7803;
            border-radius: 0.32rem;
            color: #fff;
            margin-left: 0.12rem;
          }
        }
      }
      .fans {
        color: #999;
        font-size: 0.24rem;
      }
    }
    .introduce {
      color: #999;
      font-size: 0.24rem;
      padding: 0.2rem 0.4rem;
    }
  }
  .recomment {
    background: #ef7f7b;
    color: #fff;
    font-size: 0.22rem;
    border-radius: 999px;
    text-align: center;
    padding: 0 0.2rem;
  }
}
// 经纪人底部描述
.bot-info {
  padding: 0 0.5rem 0 0.3rem;
  color: #333;
  font-size: 0.26rem;
  margin-bottom: 0.2rem;
  .sort-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.32rem;
    font-weight: 500;
    .toggle-wrap {
      .sortByPrice {
        text-align: center;
        width: 1.82rem;
        line-height: 0.48rem;
        border-radius: 999px;
        color: #ff8b00;
        font-size: 0.24rem;
        background: #fff;
      }
    }
    :deep() {
      .van-popover__action {
        width: 1.82rem;
        height: 0.6rem;
        padding: 0;
        font-size: 0.24rem;
        line-height: 0.24rem;
        color: #6a6a6a;
        .van-popover__action-text {
          &::after {
            position: absolute;
            box-sizing: border-box;
            content: ' ';
            pointer-events: none;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            border: 0 solid #ccc;
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
            border-bottom-width: 1px;
          }
        }
      }
    }
  }
}
.girls-list {
  padding: 0 0.24rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::v-deep {
    .item-card {
      .img-card {
        height: 2.98rem;
      }
    }
  }
}
</style>
