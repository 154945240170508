<template>
  <div class="activity-detail">
    <EBackTop :open-fallback="true" title="活动详情" class="back" />
    <div class="ad">
      <DecryptImg
        :imgCDN="cdn"
        :needPadding="false"
        :imgURL="activityData.cover"
        @click.native="clickImg(activityData)"
      ></DecryptImg>
    </div>
    <div class="title van-ellipsis">
      {{ activityData.title }}
    </div>
    <div class="describe">
      {{ activityData.describe }}
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { handleURlParams, handleParamsRouteJump } from '@/utils/utils_tools'
import { activity_detail } from 'api/activity'
export default {
  layout: 'noTab',
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  computed: {
    cdn() {
      return this.$store.getters['config/cdn']
    }
  },
  data() {
    return {
      xsrwList: [],
      activityData: {}
    }
  },
  async mounted() {
    await this.getActivityDetail()
    // if (this.$route.params.name === "xinshourenwu") {
    //   await this.getMissionInfo();
    // }
  },
  methods: {
    trim(string) {
      if (string) {
        let reg = /^\s+|\s+$/g
        return string.replace(reg, '')
      }
    },
    clickImg(item) {
      console.log(item)
      let code = handleURlParams(item.jumpUrl)
      handleParamsRouteJump(this.$router, this, code)
    },
    // 任务详情
    async getActivityDetail() {
      const res = await activity_detail({
        name: this.$route.params.value
      })
      if (res && res.code !== 200) {
        Toast('请求错误')
        return
      }
      this.activityData = res.data
    }

    // 新手任务专用，获取日常新手任务
    // async getMissionInfo() {
    //   const res = await this.$getMissionInfo();

    //   if (res.code !== 200) {
    //     Toast(res.tip);
    //     return;
    //   }
    //   if (res.code === 200 && res.data && res.data.list) {
    //     this.xsrwList = res.data.list;
    //   }
    // },
  }
}
</script>
<style lang="scss" scoped>
.activity-detail {
  // padding: 0.6rem 0 0.5rem 0;
  .title {
    padding: 0.1rem 0.3rem;
    font-size: 0.3rem;
  }
  .describe {
    width: 100%;
    box-sizing: border-box;
    padding: 0.1rem 0.3rem;
    white-space: pre-line;
    font-size: 0.24rem;
    text-align: left;
  }
  .ad {
    margin: 0 auto;
  }
  .xinshourenwu {
    margin: 0 auto;
    width: 6rem;
    background-color: #2b2b3e;
    padding: 0.3rem 0.15rem;
    border-radius: 0.15rem;
    margin-top: 0.5rem;
    box-sizing: border-box;
    position: relative;
    .rw-top {
      position: absolute;
      top: -0.15rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 999;
      text-align: center;
      width: 1.5rem;
      height: 0.4rem;
      line-height: 0.4rem;
      border-radius: 0.1rem;
      background-color: #969799;
    }
    .rw-panel {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      padding: 0.15rem 0;
      .rw-title {
        width: 50%;
      }
      .rw-desc {
        width: 30%;

        text-align: center;
      }
      .rw-status {
        color: #969799;
        flex: 1;
        text-align: center;
        .btn {
          cursor: pointer;
          width: 0.8rem;
          border-radius: 0.15rem;
          border: 1px solid #969799;
        }
      }
    }
  }
}
</style>
