<template>
  <div class="my-code">
    <EBackTop class="header" :title="'账号凭证'" />
    <div class="main">
      <EGenerateImg ref="EGenerateImg" :link="code" :name='`来自${info.nickName}的账号凭证`' :config="config">
       </EGenerateImg>
       <div class="code">
      </div>
      <div class="line-box">
        <div class="line"></div>
        <span>友情提示</span>
        <div class="line"></div>
      </div>
      <div class="waring">
        <p>1.请您保存二维码凭证，以便找回您的账号</p>
        <p>2.请妥善保管此账号凭证，不要随意透漏给任何人</p>
        <p>3.若账号不慎丢失，可通过APP账号安全扫取该二维码凭证登录账号</p>
      </div>
      <div class="footer">
        <div class="text">
          <div class="icon-bg">
            <van-icon color="#fff" name="fail" />
          </div>
          若保存失败，请截屏保存
        </div>
        <div class="btn" @click="clickSaveImg">保存账号凭证</div>
      </div>
    </div>
  </div>
</template>

<script>
import { userQrcode } from 'api/user'
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    EGenerateImg: () => import('@/components/web_base/component_base/EGenerateImg/index.vue'),
    QrcodeVue
  },
  data() {
    return {
      config:{
        qrSize: 200,
     },
      code: '',
      web: '',
    }
  },
  mounted() {
    this.getUserCode()
  },
  computed: {
    // 落地页
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  methods: {

    async getUserCode() {
      const res = await userQrcode()
      if (res.code === 200) {
        this.code = res.data.value
        this.web = res.data.app
      } else {
        return this.$toast('请求失败，请稍后再试')
      }
    },

    clickSaveImg() {
      this.$refs['EGenerateImg'].clickSaveImg();
    },
  }
}
</script>

<style lang="scss" scoped>
 :deep(){
  .qrBg{
    width: 200px !important;
    height: 200px !important;
    left: 50%;
    top: 2.6rem;
    transform: translate(-50%,-50%);
}
}
.my-code {
  min-height: 100vh;
  background: #fff;
  font-size: 0.28rem;
  .imageGeneration{
    position: fixed;
    z-index: -99;
  }
  .main {
    padding: 0.3rem;
    .code-img {
      width: 100%;
      height: 100%;
      :deep() {
        canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
  .code {
    padding: 0.2rem;
    width: 5.1rem;
    height: 5.1rem;
    margin: 0 auto;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  .logo-wrap {
    width: 100%;
    height: auto;
    @include flexbox();
    padding: 0.3rem 0;
    img {
      width: 50%;
    }
  }
  .line-box {
    color: #848494;
    font-size: 0.3rem;
    display: flex;
    align-items: center;
    span {
      margin: 0 0.2rem;
      white-space: nowrap;
    }
    .line {
      height: 0.02rem;
      background: #9493b1;
      width: 100%;
    }
  }
  .waring {
    font-size: 0.24rem;
    color: #9493b1;
  }
}
.footer {
  margin-top: 0.3rem;
  .text {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-bg {
      width: 0.35rem;
      height: 0.35rem;
      background: $btnBg;
      // color: #fff;
      text-align: center;
      line-height: 0.36rem;
      border-radius: 50%;
      margin-right: 0.1rem;
      img{
        width: 0.072rem;
        height: 0.226rem;
      }
    }
  }
  .btn {
    width: 6.5rem;
    height: 0.9rem;
    line-height: 0.9rem;
    font-size: 0.3rem;
    margin: 0 auto;
    margin-top: 0.33rem;
    text-align: center;
    background: $btnBg;
    color: #fff;
    border-radius: 0.46rem;
  }
}
</style>
