<template>
  <div class="message-container">
    <EBackTop title="评论&回复"></EBackTop>
    <PullUp @refreshData="refreshData" @moreData="moreData" :finished="finished" :loading="loading"
      :refreshing="refreshing" v-if="tipList && tipList.length > 0">
      <!-- <ul class="message-listWrap">
        <li class="item" :key="index" v-for="(item, index) in tipList">
          <div class="top">
            <img src="@/assets/imgs/icon_message_symbol.png" alt="" />
            <div>
              <div class="title">{{ item.title }}</div>
              <div>
                {{ item.createdAt.split('T')[0] }}
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="title">{{ item.content }}</div>
          </div>
        </li>
      </ul> -->
      <ul class="message-listWrap">
        <li class="boxWrap" :key="index" v-for="(item, index) in tipList">
          <div class="left">
            <div class="leftImg">
              <EDecryptImg :imgCDN="imgCDN" class="user-header" :imgURL="item.avatar" />
            </div>
            <div class="msg-content">
              <p class="nickName">{{ item.nickName }}</p>
              <p class="content">{{ item.content }}</p>
              <div class="user">
                <span>{{ item.title }}</span>
                <span>{{ item.createdAt | timeYmd }}</span>
              </div>
            </div>
          </div>
          <!-- img -->
          <div class="rightImg">
            <EDecryptImg :imgCDN="imgCDN" class="user-header" :imgURL="item.img" />
          </div>
        </li>
      </ul>
    </PullUp>
    <NoData v-else></NoData>
  </div>
</template>
<script>
import { message_list } from 'api/user'
export default {
  data() {
    return {
      tipList: [],
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 5,
      // 上拉加载
      loading: false,
      // 上拉加载完成
      finished: false,
      // 下拉刷新
      refreshing: false,
      skeleton: false
    }
  },

  components: {
    PullUp: () => import('@/components/PullUp.vue'),
    NoData: () => import('@/components/noData.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
  },
  methods: {
    // 获取消息列表
    async get_tipList() {
      try {
        const res = await message_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type: 4
        })
        if (res.code === 200) {
          this.refreshing = false
          this.loading = false
          this.tipList = [...this.tipList, ...res.data.commentList, ...res.data.dialoglist]
          if (!this.tipList.length || this.tipList.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.refreshing = false
          this.loading = false
          this.finished = true
          this.$toast(res.tip)
        }
        // if (res.code === 200) {
        //   this.refreshing = false
        //   this.loading = false
        //   this.tipList = [...this.tipList, ...res.data.list]
        //   if (!res.data.list || (res.data && res.data.list.length < this.pageSize)) {
        //     this.finished = true
        //   }
        // } else {
        //   this.refreshing = false
        //   this.loading = false
        //   this.finished = true
        //   this.$toast(res.tip)
        // }
      } catch (error) {
        this.refreshing = false
        this.loading = false
        this.finished = true
        console.log(error)
      }
    },

    refreshData(loading) {
      this.loading = loading
      this.pageNum = 1
      this.tipList = []
      this.get_tipList()
    },
    moreData(refreshing) {
      this.refreshing = refreshing
      this.pageNum += 1
      this.get_tipList()
    }
  },

  created() {
    this.refreshData()
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
  }
}
</script>
<style lang="scss" scoped>
.message-container {
  // padding: 1rem 0;

  min-height: 100vh;
  box-sizing: border-box;

  .message-listWrap {
    margin: 0.3rem 0;

    .item {
      display: flex;
      flex-direction: column;
      font-size: 0.24rem;
      // align-items: center;
      padding: 0.25rem;
      margin: 0.3rem 0.3rem;
      box-shadow: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.8);
      padding-bottom: 0.34rem;
      border-radius: 0.06rem;
      background-color: rgba(0, 0, 0, 0.05);

      .top {
        width: 100%;
        // @include flexbox();
        display: flex;
        align-items: center;
        color: #333;
        padding-bottom: 0.3rem;
        border-bottom: 1px solid rgba(151, 151, 151, 0.1);

        img {
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 50%;
          margin-right: 0.2rem;
        }

        div {
          display: flex;
          flex-direction: column;

          .title {
            font-weight: 600;
            color: #333;
            font-size: 0.28rem;
          }

          &:last-child {
            color: #999;
          }
        }
      }

      .bottom {
        .title {
          color: #333;
          // margin-left: -2.2rem;
          font-size: 0.28rem;
        }
      }
    }

    .item-title {
      font-size: 0.32rem;
      padding-left: 0.3rem;
      padding-top: 0.15rem;
    }
  }
}

.boxWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.24rem;
  // align-items: center;
  margin: 0.3rem 0.3rem;

  // box-shadow: rgba(0, 0, 0, 0.05);
  // color: rgba(0, 0, 0, 0.8);
  // padding-bottom: 0.34rem;
  // border-radius: 0.06rem;
  // background-color: rgba(0, 0, 0, 0.05);
  .left {
    display: flex;
    align-items: center;

    .leftImg {
      width: 0.96rem;
      height: 0.96rem;
      border-radius: 100%;
      overflow: hidden;
      margin-right: .2rem;

      .user-header {
        width: 0.96rem;
        border-radius: 100%;
      }
    }

    .msg-content {
      // width: calc(100% - 0.96rem - 1.12rem - 0.32rem - 0.12rem);
      width: 4rem;
      margin: 0 0.32rem 0 0.12rem;

      .nickName {
        font-size: 0.28rem;
        @include textoverflow();
      }
      .content {
        color: #666;
        margin: 0.16rem 0;
        @include textoverflow();
      }
      .user {
        color: #999;
        @include textoverflow();
        span:first-child {
          margin-right: 0.16rem;
        }
      }
    }

  }
  .rightImg {
      width: 1.12rem;
      height: 1.12rem;
      .user-header {
        width: 1.12rem;
        border-radius: 100%;
      }
    }
}
</style>
