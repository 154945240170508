<template>
  <div class="withdraw">
    <EBackTop :title="'申请提现'" />
    <div class="withdraw-main">

      <div class="chek">当前钱包余额</div>
      <div class="checkmoney">{{ withdrawInfo.transferable | changeMoneyYuan  }}</div>

      <!-- 提现方式 -->
      <ul class="witchdraw" v-if="withdrawInfo.list && withdrawInfo.list.length > 0">
        <li
          v-for="(item, index) in withdrawInfo.list"
          :class="{ active: active === item.type }"
          @click="activefn(item.type, index)"
          :key="item.type"
        >
          <img
            v-if="item.type === 'bankcard' && active !== 'bankcard'"
            src="@/assets/imgs/mine/bank-default.svg"
            alt=""
          />
          <img
            v-if="item.type === 'alipay' && active !== 'alipay'"
            src="@/assets/imgs/mine/alipay-default.svg"
            alt=""
          />
          <img v-if="item.type === 'trc20' && active !== 'trc20'" src="@/assets/imgs/mine/bank-default.svg" alt="" />
          <img v-if="item.type === 'jdeCard' && active !== 'jdeCard'" src="@/assets/imgs/mine/jd-default.svg" alt="" />
          <img v-if="item.type === 'bankcard' && active === 'bankcard'" src="@/assets/imgs/mine/bank.svg" alt="" />
          <img v-if="item.type === 'alipay' && active === 'alipay'" src="@/assets/imgs/mine/alipay.svg" alt="" />
          <img v-if="item.type === 'trc20' && active === 'trc20'" src="@/assets/imgs/mine/bank.svg" alt="" />
          <img v-if="item.type === 'jdeCard' && active === 'jdeCard'" src="@/assets/imgs/mine/jd.svg" alt="" />
          {{ item.name }}
        </li>
      </ul>

      <!-- 没有提现方式时 -->
      <div class="no-list" v-else>
        <img src="@/assets/imgs/mine/no-withdraw.png" alt="" />
      </div>
      <!-- 提现表单 -->
      <div class="wihtdraw-form">
        <!-- 提现金额输入框 -->
        <div class="money-input" v-if="active !== 'jdeCard'">
          <div class="input-content">
            <img src="@/assets/imgs/mine/symbol.png" alt="" />
            <van-field v-model="form.money" type="number" placeholder="请输入您要提现的金额" />
            <!-- <div class="all-withdrawal" @click="allMoney">全部提现</div> -->
          </div>
          <div class="input-bot" style="color: #848494;">
            <span></span>
            <!-- <div style="color: #040404;"><span>可提现金额</span>{{ withdrawInfo.transferable | changeMoneyYuan }}</div> -->
            <div>
              提现收取
              <span class="rate-count">{{ itemInfo.rate }}%</span>
              ⼿续费
            </div>
          </div>
        </div>
        <!-- 京东e卡选项 -->
        <ul class="fixedMoney" v-if="active === 'jdeCard'">
          <li
            @click="changeItemMoney(item, index)"
            :class="{ active: activeJd === index }"
            v-for="(item, index) in itemInfo.fixedMoney"
            :key="item"
          >
            ¥{{ item / 100 }}
          </li>
        </ul>
        <!-- 支付宝提现表单 -->
        <div class="alipay-form" v-if="active === 'alipay'">
          <van-field v-model.trim="form.accountName" placeholder="请输入支付宝姓名" />
          <van-field v-model.trim="form.accountNo" placeholder="请输入支付宝账号" />
        </div>
        <!-- 银行卡提现表单 -->
        <div class="alipay-form" v-if="active === 'bankcard'">
          <van-field v-model.trim="form.accountName" placeholder="请输入开户姓名" />
          <van-field v-model.trim="form.bankName" placeholder="请输入银行名称" />
          <van-field v-model.trim="form.accountNo" type="number" placeholder="请输入银行卡号" />
        </div>
        <!-- trc提现表单 -->
        <div class="alipay-form" v-if="active === 'trc20'">
          <van-field v-model.trim="form.accountNo" placeholder="请输入钱包地址" />
        </div>
      </div>
      <!-- 提现提示 -->
      <div class="tip" v-if="active !== 'jdeCard'">
        提现将在1-2⼯作⽇内到账，如未收到，请联系<span class="kf" @click="$router.push('/mine/setting/kf')"
          >在线客服</span> 
          <!-- <div>(请正确填写账号，账号内请不要出现空格，如果账号填写不规范，可能会导致无法提现)</div> -->
        
        <p>(请正确填写账号，账号内请不要出现空格，如果账号填写不规范，可能会导致无法提现)</p>
      </div>
      <div class="tip" v-else>
        京东E卡，只支持固定金额<span class="kf" @click="$router.push('@/assets/imgs/mine/kf.svg')">在线客服</span>
      </div>
      <!-- 提现按钮 -->
      <div class="withdraw-btn" @click="withdrawBtn">立即提现</div>
    </div>
  </div>
</template>

<script>
import { withdrawal_info, withdrawal_submit } from 'api/user'
export default {
  components: {
  },
  data() {
    return {
      form: {
        accountName: undefined, // 账户持有人
        accountNo: undefined, // 交易账户
        bankName: undefined, // 银行名
        money: undefined // 金额
      },
      withdrawInfo: {},
      itemInfo: {}, //当前提现方式信息
      active: '', //默认选中
      activeJd: 0 // 默认选择京东e卡
    }
  },

  async created() {
    await this.mineWithdrawalInfo()
    if(this.withdrawInfo && this.withdrawInfo.list[0] && this.withdrawInfo.list[0].type){
      this.active = this.withdrawInfo.list[0].type

    this.itemInfo = this.withdrawInfo.list[0]
    }

    if (this.active === 'jdeCard' && this.itemInfo.fixedMoney && this.itemInfo.fixedMoney.length !== 0) {
      this.form.money = this.itemInfo.fixedMoney[0]
    }
  },
  computed: {},
  methods: {
    // icon映射
    iconFillter(item) {
      switch (item.type) {
        case 'bankcard':
          return '/images/mine'
          break

        default:
          break
      }
    },
    // 获取提现信息
    async mineWithdrawalInfo() {
      const res = await withdrawal_info()
      if (res.code === 200) {
        this.withdrawInfo = res.data.info
      } else {
        return this.$toast(res.tip)
      }
    },
    // 提现按钮
    withdrawBtn() {
      if (this.withdrawInfo.transferable <= 0) return this.$toast('您暂无可提现额度')
      if (!this.form.money) return this.$toast('请输入提现金额')
      if (this.active !== 'jdeCard') {
        if (this.form.money > this.withdrawInfo.balance / 100) return this.$toast('提现数额大于可提现余额')
      } else {
        if (this.form.money > this.withdrawInfo.balance) return this.$toast('提现数额大于可提现余额')
      }

      if (!this.active) return this.$toast('请选择提现方式')
      if (!this.form.accountName && (this.active === 'bankcard' || this.active === 'alipay'))
        return this.$toast('请输入提现人姓名')
      if (!this.form.bankName && this.active === 'bankcard') return this.$toast('请输入银行卡名称')
      if (!this.form.accountNo && this.active !== 'jdeCard') return this.$toast('请输入提现账户')
      // 提现金额限制
      if (
        (this.form.money > this.itemInfo.maximum / 100 || this.form.money < this.itemInfo.minimum / 100) &&
        this.active !== 'jdeCard'
      )
        return this.$toast(
          `提现金额超出提现额度限制,当前提现方式最提现额度为${this.itemInfo.minimum / 100}~${
            this.itemInfo.maximum / 100
          }`
        )
      this.mineWithdrawalSubmit()
    },
    // 京东e卡切换
    changeItemMoney(item, index) {
      this.form.money = item
      this.activeJd = index
    },
    // 申请提现请求
    async mineWithdrawalSubmit() {
      const res = await withdrawal_submit({
        accountName: this.form.accountName,
        accountNo: this.form.accountNo,
        bankName: this.form.bankName,
        mode: this.active,
        money: this.active === 'jdeCard' ? this.form.money : this.form.money * 100
      })
      if (res.code === 200) {
        return this.$toast(res.tip)
      } else {
        return this.$toast(res.tip)
      }
    },
    // 全部提现事件
    allMoney() {
      this.form.money = this.withdrawInfo.transferable / 100
      this.$forceUpdate()
    },
    // 切换选项
    activefn(type, index) {
      if (this.active === type) return
      this.active = type
      this.itemInfo = this.withdrawInfo.list[index]
      this.form = {
        accountName: undefined, // 账户持有人
        accountNo: undefined, // 交易账户
        bankName: undefined // 银行名
      } // 金额
      if (this.active === 'jdeCard' && this.itemInfo.fixedMoney && this.itemInfo.fixedMoney.length !== 0) {
        this.form.money = this.itemInfo.fixedMoney[0]
      } else {
        this.form.money = undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chek{
  font-size: .24rem;
  color: rgba($color: #000000, $alpha: 0.6);
}
.checkmoney{
  font-size: .68rem;
  font-weight: 600;
  color: #000000;
}
.withdraw {
  min-height: 100vh;
  &-main {
    padding: 0.25rem;
    .withdraw-bg {
      height: 2.74rem;
      background: url('/images/mine/income-bg.png') no-repeat;
      background-size: 100% 100%;
      font-size: 0.32rem;
      position: relative;
      border-radius: 0.18rem;
      text-align: center;
      line-height: 2.74rem;
      font-size: 0.8rem;
      font-weight: 600;
      .can-withdraw {
        position: absolute;
        @include flexbox;
        right: 0;
        top: 0;
        width: 2.15rem;
        height: 0.57rem;
        font-size: 0.3rem;
        background:$btnBg;
        border-top-right-radius: 0.18rem;
        border-bottom-left-radius: 0.18rem;
        img {
          width: 0.32rem;
          height: 0.32rem;
          margin: 0 0.1rem;
        }
      }
    }
  }
  // 提现方式列表
  .witchdraw {
    @include flexbox;
    padding: 0.44rem 0;
    // border-bottom: 0.02rem solid #707070;
    overflow-x: auto;
    li {
      padding: 0 0.1rem;
      box-sizing: content-box;
      min-width: 2.9rem;
      height: 1rem;
      @include flexbox($jc:center, $ai:center, $fd:row, $fw:nowrap);
      color: #848494;
      font-size: 0.36rem;
      border-radius: 0.09rem;
      margin-right: 0.4rem;
      box-shadow: $shadow;
      img {
        width: 0.45rem;
        height: 0.45rem;
        margin-right: 0.2rem;
      }
    }
    .active {
      color: #fff;
  background: #F96491;
    }
    li:last-child {
      margin-right: 0;
    }
  }
}
// 提现表单
.wihtdraw-form {
  font-size: 0.3rem;
  color: #333333;
  margin: 0.3rem 0;
  // border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0.2rem 0.26rem;
  // 提现金额输入框
  .money-input {
    box-shadow: $shadow;
    padding: .3rem;
    .input-content {
      display: flex;
      align-items: center;
     
      // background-color: #f8f8f8;
      border-bottom:  1px solid #848494;;

      // border-radius: 0.09rem;
      img {
        width: 0.23rem;
        height: 0.27rem;
      }
      :deep() {
        .van-cell {
          width: 100%;
          background-color: transparent;
        }
      }
      .all-withdrawal {
        color: #848494;
        font-size: 0.32rem;
        white-space: nowrap;
        font-weight: 600;
      }
    }
    .input-bot {
      @include flexbox();
      margin-top: 0.15rem;
      font-size: 0.22rem;
      div:first-child {
        span {
          margin-right: 0.1rem;
        }
      }
    }
  }
}
// 表单数据
.alipay-form {
  margin-top: 0.5rem;
  :deep() {
    .van-cell {
      // background-color: #f8f8f8;
      // border: 1px solid rgba(0, 0, 0, 0.3);
      box-shadow: $shadow;
      margin-bottom: 0.2rem;
    }
  }
}
// tip
.tip {
  font-size: 0.2rem;
  color: #848494;

  p {
    margin-top: 0.05rem;
  }

  .kf {
    color: #040404;

  }
}
// 提现按钮
.withdraw-btn {
  width: 6rem;
  height: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  background: $btnBg;
  border-radius: 0.06rem;
  margin: 0 auto;
  font-size: 0.3rem;
  margin-top: .2rem;
  color:#fff;
}
// 京东e卡选项
.fixedMoney {
  @include flexbox;

  overflow-x: auto;
  li {
    padding: 0 0.1rem;
    box-sizing: content-box;
    min-width: 1.3rem;
    height: 0.7rem;
    color: #999;
    text-align: center;
    line-height: 0.7rem;
    border: 0.02rem solid #999;
    font-size: 0.26rem;
    border-radius: 0.09rem;
    margin-right: 0.4rem;
  }
  .active {
    color: #0082ff;
    border: 0.02rem solid #0082ff;
  }
}
.no-list {
  text-align: center;
  margin: 0.2rem 0;
  img {
    width: 5.4rem;
    height: 0.8rem;
  }
}
</style>
