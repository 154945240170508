<template>
  <div class="buy-history">
    <EBackTop :title="'购买记录'" background="#fff" color="#000" />
    <ETab class="myHistory-tab" :sticky="true" :tabList="cartoonCategory" :v-model="indexActive" :animated="false"
      @change="change" :lineBg="'#FF669D'" :lineHeight="'0.08rem'" :line-width="'0.3rem'"
      :title-active-color="'#FF669D'" :title-inactive-color="'#333'" :sizeTab="'0.3rem'" :swipe-threshold="4"
      :sizeActive="'.3rem'">
      <template v-slot:default="scope">
        <component :cardType="'order'" :is="compComponent(scope.data)"></component>
      </template>
    </ETab>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //上拉加载
      loading: false,
      //上拉加载完成
      finished: false,
      //下拉刷新
      refreshing: false,
      //文章列表
      payList: [],
      indexActive: 0,
      skeleton: false,
      //当前页
      pageNum: 1,
      // 当前个数
      pageSize: 10,
      cartoonCategory: [
        { showType: 1, name: '长视频' },
        { showType: 2, name: '短视频' },
        { showType: 5, name: '帖子' },
        { showType: 9, name: '约炮' },
        { showType: 15, name: '小说' },
        { showType: 17, name: '色图' },
      ]
    }
  },
  components: {
    PullUp: () => import('@/components/PullUp.vue'),
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    LongVideoItem: () => import('@/components/HistoryComponents/LongVideoItem.vue'),
    ShortVideoItem: () => import('@/components/HistoryComponents/ShortVideoItem.vue'),
    dating: () => import('@/components/HistoryComponents/dating.vue'),
    CommunityDefault: () => import('@/components/HistoryComponents/CommunityItem.vue'),
    Photo: () => import('@/components/HistoryComponents/photo.vue'),
    Novel: () => import('@/components/HistoryComponents/novel.vue'),

  },
  created() { },
  methods: {
    change(id) {
      this.indexActive = id
    },
    compComponent(type) {
      switch (type) {
        case 1:
          return 'LongVideoItem'
        case 2:
          return 'ShortVideoItem'
        case 5:
          return 'CommunityDefault'
        case 9:
          return 'dating'
        case 15:
          return 'Novel'
        case 17:
          return 'Photo'
        default:
          return 'LongVideoItem'
      }
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  }
}
</script>
<style lang="scss" scoped>
.myHistory-tab {
  :deep() {
    .van-sticky {
      position: fixed;
      top: 0.9rem;
      left: 0;
      right: 0;
      z-index: 99;
    }

    .van-tabs__nav {
      justify-content: flex-start;
    }

    .van-tab {
      flex: none;
    }
  }
}
</style>
