<template>
  <div class="release">
    <EBackTop  :title="$route.query.title" > </EBackTop>
    <!-- 标签信息 -->
    <div class="tag_info">
      <EDecryptImg :imgCDN="imgCDN" class="resetImg" :imgURL="tagInfo.detailImageUrl"> </EDecryptImg>
      <div class="right">
        <p class="title">{{tagInfo.title }}</p>
        <div class="desc">
          <span>帖子 {{ tagInfo.posts | numberFilter('w') }} </span>
          <span>浏览 {{ tagInfo.watches | numberFilter('w') }} </span>
        </div>
      </div>
      <!-- <div class="up_info_r">
        <span @click.stop="addCare(tagInfo.isFollow)" :class="{ avatar: tagInfo.isFollow }">
          <img src="@/assets/imgs/jiah.svg" alt="" v-if="!tagInfo.isFollow" />

          {{ tagInfo.isFollow ? '已关注' : '关注' }}
        </span>
      </div> -->
    </div>
    <div class="sortList">
      <div
        class="sortBox"
        :class="{ active: sort == item.sort }"
        @click="sortSwitch(item.sort)"
        v-for="(item, index) in topTextList"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="list_bg" v-if="communityList && communityList.length > 0">
      <ERefreshLoad
        @refreshData="refreshData"
        @moreData="moreData"
        :finished="finished"
        :loading="loading"
        :refreshing="refreshing"
        class="pull-container"
      >
        <CommunityDefault class="default_item" v-for="(item, index) in communityList" :key="index" :itemData="item" />
      </ERefreshLoad>
    </div>
    <Nodata class="noData" style="padding: 0.5rem 0" v-else></Nodata>
  </div>
</template>

<script>
import { section_list } from 'api/community'
import { collect } from 'api/home'
export default {
  components: {
    CommunityDefault: () => import('@/components/Community/default.vue'),
    Nodata: () => import('@/components/JavNodata.vue')
  },
  data() {
    return {
      communityList: [],
      tagInfo: {},
      pageSize: 10,
      pageNum: 1,
      sort: 0,
      loading: false, //上拉加载
      finished: false, //上拉加载完成
      refreshing: false, //下拉刷新
      topTextList: [
        {
          sort: 0,
          name: '推荐'
        },
        {
          sort: 1,
          name: '最新'
        },
        {
          sort: 2,
          name: '最热'
        },
        {
          sort: 3,
          name: '精华'
        },
        {
          sort: 4,
          name: '视频'
        }
      ]
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  mounted() {
    this.getSectionList()
  },

  methods: {
    sortSwitch(sort) {
      //切换排序
      this.sort = sort
      this.getSectionList('pull')
    },
    async getSectionList(type) {
      try {
        const res = await section_list({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          sectionId: +this.$route.query.id,
          sort: this.sort
        })
        if (res.code === 200) {
          this.loading = false
          this.refreshing = false
          if (type == 'pull') {
            this.pageNum = 1
            this.communityList = []
          }
          this.communityList = [...this.communityList, ...res.data.list]
          this.tagInfo = res.data
          console.log('res.data', res.data)
          if (!res.data.list || res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试')
      }
    },
    async addCare(flag) {
      if (this.userInfo.loginType == 0) {
        return this.$toast('仅注册的用户才能关注')
      }
      // 关注用户
      try {
        const res = await collect({
          collect: !this.tagInfo.isFollow,
          object_id: +this.$route.query.id,
          collectType: 12,
          type: 1,
          flag: !flag
        })
        if (res.code === 200) {
          this.tagInfo.isFollow = !this.tagInfo.isFollow
          if (this.tagInfo.isFollow) {
            return this.$toast('关注成功')
          } else {
            return this.$toast('取消关注')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    //上拉刷新
    refreshData() {
      this.finished = false
      this.refreshing = true
      this.pageNum = 1
      this.getSectionList('pull')
    },
    //下拉加载
    async moreData() {
      this.loading = true
      this.pageNum += 1
      await this.getSectionList()
    }
  }
}
</script>

<style lang='scss' scoped>
.list_bg {
  background: #fff;
  min-height: 70vh;
  margin: 0 0.2rem;
  .default_item {

    box-shadow: $shadow;

  }
  .pull-container {
    ::deep() {
      .van-pull-refresh {
        .van-list__finished-text {
          line-height: 70vh !important;
        }
      }
    }
  }
}
.tag_info {
  @include flexbox($jc:flex-start);
  margin: 0.7rem 0.64rem 0.46rem 0.64rem;

  .resetImg {
    width: 1.7rem;
    height: 1.7rem;
    flex-shrink: 0;
    :deep() {
      img {
        border-radius: 0.2rem;
      }
    }
  }
  .right {
    margin: 0 0.24rem 0 0.24rem;
  }
  .title {
    color: #333;
    font-family: PingFang SC;
    font-size: 0.28rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @include textoverflow(2);
  }
  .desc {
    color: #939496;
    font-family: PingFang SC;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.08rem;
  }
}
.sortList {
  width: 100%;

  padding-left: 0.2rem;
  margin-bottom: 0.48rem;
  @include flexbox($jc: center);
  .sortBox {
    display: flex;
    padding: 0.04rem 0.2rem;
    justify-content: center;
    align-items: center;
    color: #333;
    border-radius: 0.08rem;
    font-size: 0.28rem;
    margin-right: 0.36rem;
    &:last-child {
      margin-right: 0;
    }
  }
  .active {
    background: $btnBg;
    color: #fff;
  }
}
.up_info_r {
  span {
    padding: 0.1rem 0.23rem;
    border: 0.02rem solid $btnBg;
    color: $btnBg;
    border-radius: 1rem;
    margin-left: 0.3rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    img {
      height: 0.19rem;
      margin-right: 0.04rem;
    }
  }

  .avatar {
    border: 0.02rem solid #7a7a87;
    color: #7a7a87;
  }
}
</style>
