<!-- home -->
<template>
  <div class="index-container">
    <!-- 输入框 -->
    <div class="top_search">
      <div class="search-input" @click="$router.push('/subpage/search')">
        <van-field shape="round" placeholder="收录10万+影片，5000+女优">
          <template #button>
            <van-button size="small" type="primary">搜索</van-button>
          </template>
        </van-field>
      </div>
      <div class="lottery" @click="$router.push('/Activity/lottery/list')">
        <!-- <img @click="showLeftList" src="@/assets/imgs/home_activity_btn.gif" alt="" /> -->
        <EDecryptImg :imgCDN="imgCDN" :imgURL="homeActivityListImage"/>
      </div>
    </div>
    <!-- 菜单按钮 -->
    <div class="rightIcon">
      <img @click="showLeftList" src="@/assets/imgs/menu-icon.svg" alt="" />
    </div>
    <!--切换面板 -->
    <ETab
      ref="containerTab"
      class="container-tab"
      @change="change"
      :sticky="true"
      :tabList="category"
      :v-model="type ? type : category && category[0] ? category[0].id : 0"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#FF669D'"
      :lineHeight="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#FF669D'"
      :title-inactive-color="'#333'"
      :sizeTab="'0.32rem'"
      :sizeActive="'.38rem'"
      :swipe-threshold="4"
    >
      <template v-slot:default="scope">
        <!-- 遮罩层 popup1Flag-->
        <!-- <div class="popup1" v-if="scope.item.isNeedVip && !userInfoHasType">
          <van-popup class="popup-main" v-model="scope.item.isNeedVip" :overlay="false">
            <div class="popup1-content">
              <div class="bg">
                <span class="popup2-text">{{ scope.item.vipDesc }}</span>
              </div>

              <span class="popup2-btn" @click="$router.push('/mine/newRecharge?type=1')">点击开通 </span>
            </div>
          </van-popup>
        </div> -->
        <div class="popup1" v-if="scope.item.isNeedVip && !userInfoHasType">
          <van-popup class="popup-main" :value="true" :overlay="false">
            <div class="popup1-content">
              <div class="bg">
                <span class="popup2-text">{{ scope.item.vipDesc }}</span>
              </div>

              <span class="popup2-btn" @click="$router.push('/mine/newRecharge?type=1')">点击开通 </span>
            </div>
          </van-popup>
        </div>
        <component
          :navItem="scope"
          :showType="scope.data"
          :avAD="avAD"
          :short_videoAD="short_videoAD"
          :type="scope.item.id"
          :is="compComponent(scope.data)"
        ></component>
      </template>
    </ETab>
    <div
      class="lottery_icon"
      v-if="this.$store.state.config.config.lotteryEnable"
      @click="$router.push('/mine/luckyDraw')"
    >
      <img src="@/assets/imgs/lottery_icon.webp" alt="" />
    </div>
    <!-- <div class="ai-inside-box" :class="{ close: !isShowAi }">
      <div class="show-inside" v-if="isShowAi">
        <img src="@/assets/imgs/aiLab/close_inside_icon.svg" alt="" @click="isShowAi = !isShowAi" />
        <img src="@/assets/imgs/aiLab/ai_inside.png" alt="" @click="$router.push('/ai/generate')" />
        <img src="@/assets/imgs/aiLab/icon_ai_arrow.gif" alt="" />
      </div>
      <div v-else class="inside-close">
        <img src="@/assets/imgs/aiLab/inside_close.png" alt="" @click="isShowAi = !isShowAi" />
      </div>
    </div> -->
    <div class="dy_icon" v-if="locaVAd.length && adFlag">
      <div class="cbx">
        <img @click="adFlag = false" class="clo" src="@/assets/imgs/x_02.png" alt="">
        <EDecryptImg :imgCDN="imgCDN" :imgURL="locaVAd[0].cover" @click.native="toHref(locaVAd[0])"/>
      </div>
      <!-- <img
        src="@/assets/imgs/icon_index_short.webp"
        alt=""
        @click="$router.push(`/subpage/play/shortVideo/${'推荐'}`)"
      /> -->
    </div>
    <!-- 导航列表 -->
    <van-popup v-model="showLeftListData" position="left" class="menuPop">
      <span class="menuPopTitle">导航列表</span>
      <div class="menuPopContent">
        <div
          v-for="(item, index) in category"
          :key="index"
          :class="item.id === type ? 'active' : ''"
          @click="clickMenu(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
export default {
  mixins: [scrollMixin],
  data() {
    return {
      showLeftListData: false,
      type: 0, // 切换的id
      topTabLabelList: [],
      isShowAi: true,
      activeItem: {},
      adFlag: true,
      popup1Flag: false,
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    Index: () => import('@/components/Home/index.vue'),
    Default: () => import('@/components/Home/default.vue')
  },
  computed: {
    // 广告位置
    locaVAd() {
      return this.$store.getters['locaVAd']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 用户是否有分类权限
    userInfoHasType({ $store }) {
      if ($store.getters['getUserInfo'].categoryIds && $store.getters['getUserInfo'].categoryIds.length) {
        let id = $store.getters['getUserInfo'].categoryIds.find(item => {
          return item == this.type
        })
        return id
      } else {
        return false
      }
    },
    // 获取首页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },
    // 获取首页底部轮播广告
    short_videoAD({ $store }) {
      return $store.getters['short_videoAD']
    },
    homeActivityListImage({ $store }) {
      return $store.getters['homeActivityListImage']
    },
    // 获取顶部分类
    category({ $store }) {
      this.activeItem = $store.state.config.config.mediaCategory[0]
      return $store.state.config.config.mediaCategory
    }
  },

  mounted() {
    this.type = this.category[0]?.id
    // this.popup1FlagFun()
  },
  methods: {
    // popup1FlagFun() {
    //   // scope.item.isNeedVip && !userInfoHasType
    //   if(this.activeItem.isNeedVip && !this.userInfoHasType) {
    //     this.popup1Flag = true
    //   } else this.popup1Flag = false
    // },
    toHref(item) {
      if (item.href) {
        let code = handleURlParams(item.href)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      }
    },
    change(type) {
      this.type = type
      this.activeItem = this.category.find(item => {
        return item.id === type
      })
      // this.popup1FlagFun()
    },

    //  Default（2,3切换列表：最新，最热，人气） Index（5+1主题+）
    compComponent(data) {
      switch (data) {
        case 1:
          return 'Default'

        default:
          return 'Index'
      }
    },
    clickMenu(id) {
      this.type = id
      this.showLeftListData = false
      this.$refs['containerTab'].$children[0].scrollTo(id)
      this.activeItem = this.category.find(item => {
        return item.id === id
      })
    },
    showLeftList() {
      this.showLeftListData = true
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.index-container {
  position: relative;

  .lottery_icon {
    position: fixed;
    right: 0;
    bottom: 7.3rem;

    img {
      width: 1.5rem;
    }
  }

  .ai-inside-box {
    height: 2.7rem;
    width: 2.1rem;
    position: fixed;
    right: 0;
    bottom: 4rem;
    transition: all 0.3s;

    &.close {
      height: 0.95rem;
      width: 0.7rem;
    }

    .show-inside {
      position: relative;
      height: 100%;

      img {
        &:nth-child(1) {
          width: 0.32rem;
          height: 0.32rem;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }

        &:nth-child(2) {
          width: 2rem;
          height: 2.5rem;
          position: absolute;
          left: 0;
          top: 0;
        }

        &:nth-child(3) {
          width: 0.47rem;
          height: 0.27rem;
          position: absolute;
          right: 0.3rem;
          bottom: 0.3rem;
        }
      }
    }

    .inside-close {
      img {
        width: 0.7rem;
        height: 0.95rem;
      }
    }
  }

  .top_search {
    position: fixed;
    width: 7.5rem;
    height: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    display: flex;
    padding: 10px 0.3rem;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    :deep() {
      .van-field__body {
        height: 0.65rem;
      }
      .van-cell {
        padding: 0;
      }
      .van-cell__value {
        border: 0.01rem solid #999;
        border-radius: 1rem;
        padding: 0 0.2rem;
      }

      .van-field__button {
        @include flexbox($jc: center);
      }

      .van-button {
        margin: 0;
        padding: 0 0 0 0.3rem;
        border: none;
        outline: none;
        background-color: transparent;
        border-left: 0.01rem solid #999;
        height: 0.3rem;

        &::before {
          display: none;
        }
      }

      .van-button__text {
        color: #333;
        font-size: 0.32rem;
      }
    }

    .search-input {
      width: 5.48rem;
    }
    .lottery {
      width: 1.2rem;
      height: 0.6rem;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }

  .discoverPack {
    position: absolute;
    left: 0.24rem;
    top: 0.2rem;
    z-index: 100;
    display: flex;
    align-items: center;
    font-size: 0.28rem;
    color: #333;
    background: #00f6f8;
    padding: 0.06rem 0.12rem;
    border-radius: 0.08rem;

    img {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.06rem;
    }
  }

  .container-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0.9rem;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        max-width: $pcMaxWidth;
        z-index: 99;

        .van-tab {
          flex: none;
        }

        .van-tabs__wrap {
          margin-right: 1rem;
        }
      }

      // .van-pull-refresh__track {
      //   padding-bottom: 1rem;
      // }
    }
  }
}

.account-img {
  width: 6rem;
  height: 100%;
  // background-image: url('../../assets/imgs/index-activity-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1.5rem 0.4rem 0.4rem 0.4rem;
  text-align: center;

  :deep() {
    .van-icon {
      @include transformCenter(-50%, 0);
      position: absolute;
      right: 0rem;
      top: 0.8rem;
    }
  }

  .title-img {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.26rem;
    margin-bottom: 0.4rem;

    span {
      margin: 0 0.2rem;
    }

    img {
      width: 1.4rem;
    }
  }

  p {
    margin: 0 0 0.2rem 0;
    font-size: 0.63rem;
    color: #eb3876;
  }

  .titlePack {
    min-height: 3.5rem;

    .desc {
      text-align: left;
      font-size: 0.26rem;
      white-space: pre-line;
      color: #666666;
      margin: 0.3rem 0 0.45rem 0;
    }
  }

  .btn {
    background: linear-gradient(to right, #52b5f7, #6ce6f3);
    color: #fff;
    font-size: 0.3rem;
    border-radius: 0.36rem;
    width: 3.12rem;
    height: 0.7rem;
    line-height: 0.7rem;
    margin: 0 auto;
  }
}

.menuPop {
  height: 100%;
  width: 4.8rem;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 0.96rem 0.38rem;

  .menuPopTitle {
    font-size: 0.32rem;
    position: relative;
    text-align: center;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0.24rem;
      height: 0.08rem;
      border-radius: 0.08rem;
      background: $btnBg;

      bottom: -0.2rem;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .menuPopContent {
    @include flexbox($fw: wrap);

    color: #fff;

    > div {
      margin-top: 0.48rem;
      width: 1.84rem;
      height: 0.84rem;
      line-height: 0.84rem;
      text-align: center;
      border-radius: 0.08rem;
      background: linear-gradient(91deg, rgba(207, 232, 255, 0.15) 0.68%, rgba(109, 185, 255, 0.24) 99.65%);
    }

    .active {
      background: $txtActive;
    }
  }
}

.rightIcon {
  position: fixed;
  right: 0rem;
  // margin-right: -3.5rem;
  z-index: 100;
  background-color: #fff;
  top: 0.90rem;
  width: 1.1rem;
  height: 44px;
  text-align: center;
  line-height: 0.72rem;
  img {
    width: 0.48rem;
    height: 0.48rem;
  }
}

.popup1 {
  height: calc(100vh - 3rem);
  top: 1.8rem;
  background: rgba($color: #000000, $alpha: 0.7);
  color: #fff;
  width: 100%;
  transform: translate(-50%, 0);
  @include flexbox($jc: center, $fd: column);

  position: fixed;
  z-index: 100;
  left: 50%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
  }

  .popup-main {
    background: transparent !important;
  }

  .popup1-content {
    @include flexbox($jc: center, $fd: column);

    .bg {
      width: 6.34rem;
      height: 3.89rem;
      background: rgba($color: #fff, $alpha: 0.7);
      text-align: center;
      white-space: pre-line;

      @include flexbox($jc: center, $fd: column);
      flex-shrink: 0;
      flex: none;
    }

    .popup2-text {
      font-size: 0.32rem;
      color: #000;
    }
  }
}

.dy_icon {
  width: 1.5rem;
  height: 1.5rem;
  position: fixed;
  right: .25rem;
  // margin-right: -3.5rem;
  bottom: 4.4rem;
  z-index: 100;
  color: #000;
  .cbx {
      position: relative;
      .clo {
        position: absolute;
        left: .55rem;
        bottom: -.42rem;
        display: block;
        width: .4rem;
        height: .4rem;
        z-index: 100;
      }
      img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<style lang="scss">
html {
  .desktop {
    .discoverPack {
      left: 5rem !important;
    }
  }
}
</style>
