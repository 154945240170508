var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"decial-wrap"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.itemTopic.name))]),(_vm.itemTopic.price)?_c('div',{staticClass:"buy-wrap"},[(!_vm.itemTopic.isBuy)?_c('div',{staticClass:"doNotPay",on:{"click":_vm.fn_buyHj}},[_vm._v(" 共"+_vm._s(_vm.itemTopic.comicsCount)+"部 "),_c('div',{staticClass:"buy-btn"},[_c('div',{staticClass:"price"},[_c('img',{attrs:{"src":require("@/assets/imgs/coin.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm._f("changeMoneyYuan")(_vm.itemTopic.price))+" ")]),_c('div',{staticClass:"btn"},[_vm._v("购买")])])]):_c('div',{staticClass:"hasBuy"},[_vm._v(" 共"+_vm._s(_vm.itemTopic.comicsCount)+"部 "),_c('div',[_vm._v("已购买")])])]):_c('span',{staticClass:"desc"},[_vm._v(_vm._s(_vm.itemTopic.desc))])]),_c('div',{staticClass:"more",on:{"click":function($event){return _vm.$router.push({
        path: `/home-comic/more`,
        query: {
          id: _vm.itemTopic.id,
          name: _vm.itemTopic.name,
          type: _vm.nowTabItem.showType
        }
      })}}},[_vm._v(" 更多 "),_c('van-icon',{attrs:{"name":"arrow"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }