<template>
  <div class="md-mine" ref="mine">
    <EBackTop class="mine-head" title="我的">
      <slot slot="right" name="right">
        <!-- <router-link class="tag" to="/mine/follwe">
          <van-icon :name="require('@/assets/imgs/mine/add.svg')" />
        </router-link> -->
        <router-link class="tag" to="/mine/msg">
          <van-icon :dot="info.newMsg" :name="require('@/assets/imgs/mine/msg.svg')" />
        </router-link>
        <router-link class="tag" to="/mine/setting">
          <van-icon :name="require('@/assets/imgs/mine/setting.svg')"
        /></router-link>
      </slot>
    </EBackTop>
    <div class="md-mine-main" style="margin-left: 0.25rem">
      <div class="main-top">
        <div class="md-mine-info">
          <EDecryptImg @click.native="$router.push('/mine/setting')" :imgCDN="imgCDN" class="md-mine-photo" :imgURL="info.avatarUrl ? info.avatarUrl : ''">
          </EDecryptImg>
          <div class="md-mine-account">
            <div class="name">
              <span>
                {{ info.nickName }}
              </span>
              <span class="id"> ID:{{ info.id }} </span>
            </div>
            <div class="desc" v-if="info.cardName && isMember">会员卡类型：{{ info.cardName }}</div>
            <div class="desc" @click="goRouter('/mine/newRecharge?type=1')" v-if="!isMember">开通会员享受更多权益</div>
            <div class="desc" v-else>会员到期时间:{{ info.vipExpireTime | timeYmd }}</div>
          </div>
          <div class="checkIn" @click="goRouter('/mine/checkin')">
            <div style="width: .4rem; height: .3rem;position: relative;">
              <img class="sign" src="@/assets/imgs/mine_signIn.gif" alt="">

             </div>
            <van-icon :name="require('@/assets/imgs/mine/checkIn.svg')" class="checkIn-icon" />
          </div>
        </div>
        <div class="md-mine-profile">
          <div class="md-mine-profile-item">
            <p>{{ info.balance | changeMoneyYuan }}</p>
            <p>金币</p>
          </div>
          <div class="md-mine-profile-item">
            <p>{{ info.movieTickets }}</p>
            <p>观影券</p>
          </div>
          <div class="md-mine-profile-item">
            <!-- <p v-if="isMember">无限</p>
            <p v-else>{{ info.leftWatchTimes }}/{{ info.totalWatchTimes }}次</p> -->
            <p>{{ info.leftWatchTimes }}次</p>
            <p>免费观看</p>
          </div>
        </div>
      </div>

      <div class="vip-card">
        <router-link to="/mine/newRecharge?type=1">
          <div class="topcard">
            <h3 v-if="!isMember">开通永久VIP 万部好片无限看</h3>
            <img v-else src="@/assets/imgs/vip.png" alt="" />
            <p v-if="!isMember" class="go-vip">开通VIP</p>
            <h3 v-else>{{ info.cardName }}</h3>
          </div>
          <div class="bomcard">
            <p v-if="!!info.vipExpireTime && !!isMember">{{ info.vipExpireTime | timeYmd }}到期</p>
            <p>永久VIP资源 终身免费观看</p>
          </div>
        </router-link>
      </div>
         <!-- ai入口 -->
         <div class="ai_main" @click="$router.push('/ai/generate')">
        <img src="@/assets/imgs/bg_mine_ai.png" alt="" />
      </div>
      <div class="md-mine-property-panel">
        <router-link to="/mine/newRecharge?type=0" class="md-mine-property-info">
          <img src="@/assets/imgs/mine/my-walter.png" alt="" />
          <p>我的钱包</p>
        </router-link>
        <router-link to="/mine/agent" class="md-mine-property-info">
          <img src="@/assets/imgs/mine/share.png" alt="" />
          <p>代理赚钱</p>
        </router-link>
        <!-- <router-link :to="`${info.vipType >=1 && '/chatGroupIM'}`" @click="toChat" class="md-mine-property-info">

        </router-link> -->
        <div class="md-mine-property-info" @click="toChat">
          <img src="@/assets/imgs/icon_group_chat.png" alt="" />
          <p>聊天室</p>
        </div>
        <!-- <router-link to="/mine/group" class="md-mine-property-info">
          <img src="@/assets/imgs/mine/group.png" alt="" />
          <p>交流群</p>
        </router-link> -->
        <router-link to="/mine/msg" class="md-mine-property-info" style="position: relative;">
          <img src="@/assets/imgs/mine/chat_01.png" alt="" />
          <p>私聊</p>
          <!-- v-if="info.newUserMsg"  -->
          <div v-if="info.newUserMsg" class="yNewMessage"></div>
        </router-link>
      </div>
      <!-- 广告轮播 -->
      <!-- 广告 -->
      <div v-if="getOriginalAdType === 3"></div>
      <div v-else-if="getOriginalAdType === 2 && iconMineAds.length" class="banner">
        <AdAvatarList :list="iconMineAds" :wrap="true" key-name="avatar" />
      </div>
      <div class="swiperPack" v-else-if="getOriginalAdType === 1 && mineAD.length">
        <EADSwiper :list="mineAD" keyName="cover" :EADconfig="EADconfig" :imgCDN="imgCDN" @clickImg="clickImg">
        </EADSwiper>
      </div>
      <!-- 跳转列表 -->
      <div class="md-mroe-servic">
        <van-cell title="观看记录" is-link :to="'mine/history'">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/watch-history.svg')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="收藏记录" is-link to="/mine/collect">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/path.svg')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="购买记录" is-link to="/mine/buy-history">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/buy-history.svg')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="活动专区" is-link to="/Activity/lottery/list">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/SpecialZone.svg')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="活动抽奖" is-link to="/mine/lottery">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/lottery.svg')" class="md-mine-icon" />
          </template>
        </van-cell>

        <van-cell title="我的发布" is-link :to="'/trade/myPost'">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/my-public.svg')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="在线客服" is-link to="/mine/setting/kf">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/kf.svg')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="交流群" is-link to="/mine/group">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/group.png')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="兑换码" is-link to="/mine/redemption-code">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/code.svg')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="应用中心" is-link to="/application">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/aplication.png')" class="md-mine-icon" />
          </template>
        </van-cell>
        <van-cell title="意见反馈" is-link to="/mine/feedback">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #icon>
            <van-icon :name="require('@/assets/imgs/mine/feedback.png')" class="md-mine-icon" />
          </template>
        </van-cell>
      </div>
    </div>
    <van-popup v-model="showAccountImgBtn" @close="closeAccountImg">
      <!-- <div class="account-img" v-if="showAccountImgBtn">
          <h2>防止账号丢失</h2>
          <p>请您保存账号凭证</p>
        <div class="qrcode-warp">
          <qrcode-vue class="qrcode" :value="qrCode" level="H" :size="130" />
        </div>
        <div class="desc van-ellipsis">永久域名 {{ ldyCdn }}</div>
      </div>
      <div class="bottom-btn" v-if="showAccountImgBtn">
        <van-button class="btn" @click="clickSaveImg" >立即保存</van-button>
      </div>  -->
      <EGenerateImg ref="EGenerateImg" :link="qrCode" :name="`来自${info.nickName}的个人凭证.png`" :config="config">
        <div class="account-img">
          <div class="head">
            <EDecryptImg :imgCDN="imgCDN" class="md-mine-photo" :imgURL="info.avatarUrl ? info.avatarUrl : ''">
            </EDecryptImg>
            <div>
              <h2>防止账号丢失</h2>
              <p>请您保存账号凭证</p>
            </div>
          </div>

          <div class="qrbg2">
            <div class="desc van-ellipsis">永久域名 {{ ldyCdn }}</div>
          </div>
        </div>
        <div class="bottom-btn">
          <van-button class="btn" @click="clickSaveImg">立即保存</van-button>
        </div>
        <!-- <div class="promote-bg" ref="html2canvas">
        <div class="bgImg"></div>
        <p class="descP1">点击立即分享好基友</p>
        <p class="descP2">与基友一起鸡儿邦邦硬</p>
        <div class="btn-wrap">
          <div @click="onCopy">复制链接</div>
          <div @click="clickSaveImg">保存图片</div>
        </div>
      </div> -->
      </EGenerateImg>
    </van-popup>
  </div>
</template>
<script>
// 处理参数类型
import { handleURlParams, handleParamsRouteJump } from '@/utils/utils_tools';

import { Toast } from 'vant'

import { userQrcode, userInfo } from 'api/user';
import QrcodeVue from 'qrcode.vue';
import QRCode from 'qrcode';
import axios from 'axios';
export default {
  components: {
    QrcodeVue,
    JavSwiper: () => import('@/components/JavSwiper.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue'),
    // SaveCredentials: () => import('@/components/SaveCredentials/index.vue')
  },
  data() {
    return {
      config: {
        qrSize: 200,
      },
      web: '',
      app: '',
      qrCode: '',
      backup: '',
      showAccountImgBtn: false,
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000,
      },
    };
  },
  computed: {
    // 落地页
    ldyCdn({ $store }) {
      return $store.getters['ldyCdn'];
    },
    mineAD({ $store }) {
      return $store.getters['mineAD'];
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMember({ $store }) {
      return $store.getters['isMember'];
    },
    showAccountImg({ $store }) {
      return $store.state.user.showAccountImg;
    },
    // 是否保存过个人凭证
    isSaveImg({ $store }) {
      return $store.state.user.isSaveImg;
    },
    imgCDN({ $store }) {
      return $store.getters['cdn'];
    },
    // 我的icon列表
    iconMineAds({ $store }) {
      return $store.getters['iconMineAds'];
    },
    getOriginalAdType({ $store }) {
      return $store.state.config.config.mineAdsType;
    },
  },
  async mounted() {
    this.showAccountImgBtn = this.showAccountImg && !this.isSaveImg;
    await this.getUerQrcode();
    await this.getUserInfo();
  },
  methods: {
    toChat() {
      if(this.info.vipType >1) {
        this.$router.push('/chatGroupIM')
      }
      else {
        Toast({
          message: '升级vip加入聊天室',
          forbidClick: true,
        });
      }
    },
    clickImg(item) {
      const code = handleURlParams(item.href);
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store);
    },
    clickSaveImg() {
      this.$refs['EGenerateImg'].clickSaveImg();
      this.$store.commit('isSaveImg', true);
      this.closeAccountImg();
    },
    onCopy() {
      this.$refs['EGenerateImg'].doCopy();
    },
    async getUserInfo() {
      const res = await userInfo();
      if (res && res.code === 200) {
        this.$store.dispatch('setUserInfo', res.data);
      }
    },

    goRouter(path = '/') {
      this.$router.push(path);
    },
    // 点击顶部轮播图广告
    clickTopSwiperSlide(item) {
      if (item.href) {
        const type = handleURlParams(item.href);
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store);
      }
    },
    async getUerQrcode() {
      const res = await userQrcode();
      if (res && res.code === 200) {
        this.web = res.data.web;
        this.app = res.data.app;
        this.qrCode = res.data.value;
        this.backup = res.data.backup;
      }
    },
    // 保存图片

    // clickSaveImg() {
    //   QRCode.toDataURL(this.qrCode, async (err, url) => {
    //     console.log(url,'url')
    //     this.download(url, new Date().getTime());
    //   })
    // },
    // download(href, name) {
    //   const eleLink = document.createElement('a')
    //   eleLink.download = name
    //   eleLink.href = href
    //   document.body.appendChild(eleLink)
    //   eleLink.click()
    //   document.body.removeChild(eleLink)
    // },
    // async saveImg() {
    //   axios
    //     .get('/hqcredentials.png', {
    //       params: {
    //         inviteUrl: this.qrCode,
    //         domain: this.ldyCdn
    //       }
    //     })
    //     .then(res => {
    //       this.closeAccountImg()
    //       if (res.status === 200) {
    //         this.base64Url = res.data.base64
    //         // this.download(this.base64Url)
    //         try {
    //           const eleLink = document.createElement('a')
    //           eleLink.href = res.data.base64
    //           eleLink.download = `来自${this.info.nickName}的个人凭证.png`
    //           document.body.appendChild(eleLink)
    //           eleLink.click()
    //           document.body.removeChild(eleLink)
    //           // 记录是否保存过图片，如果保存过了，则不再弹出保存弹窗

    //         } catch (err) {
    //           console.log(err)
    //           console.log('保存失败')
    //         }
    //       } else {
    //         this.$toast(res.tip)
    //       }
    //     })
    // },

    download(href) {
      const eleLink = document.createElement('a');
      eleLink.href = href;
      eleLink.setAttribute('download', `来自${this.info.nickName}的个人凭证.png`);
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
      this.$store.commit('isSaveImg', true);
      this.closeAccountImg();
    },
    closeAccountImg() {
      this.showAccountImgBtn = false;
      this.$store.commit('SET_ACCOUNT_IMG', false);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep() {
  .qrBg {
    width: 200px !important;
    height: 200px !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  .md-mine-photo {
    width: 0.9rem !important;
    height: 0.9rem;
    margin-right: 0.15rem;
  }
  h2 {
    color: #f96491;
    font-size: 0.38rem;
  }
}

.promote-bg {
  font-size: 0.26rem;
}
.checkIn {
  padding-right: 0.4rem;
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  font-size: 0.24rem;
  align-items: center;
  .sign {
    width: 1rem;
    position: absolute;
    left: -.1rem;bottom: 0;
  }
  .checkIn-icon {
    img {
      width: 0.52rem;
      height: 0.52rem;
      vertical-align: middle;
    }
  }
}

.vip-card {
  margin: 0.2rem 0;
  background: #fff;
  border-radius: 0.14rem;

  .topcard {
    height: 1.09rem;
    border-radius: 0.14rem 0.14rem 0 0;
    // background: linear-gradient(90deg, #FBE07C 2.5%, #FFBB10 97.4%);
    background: var(
      --3,
      linear-gradient(123deg, #ffd0de 0%, #ffb4ca 15.7%, #ff9bbf 37.07%, #ff79b1 57.33%, #f56ca6 75.36%, #e76292 100%)
    );
    display: flex;
    justify-content: space-between;
    padding: 0 0.33rem;
    align-items: center;
    img {
      width: 2.03rem;
      height: 0.81rem;
    }
    h3 {
      font-weight: 600;
      font-size: 0.32rem;
      color: rgba(29, 29, 33, 0.8);
    }
    .go-vip {
      width: 1.44rem;
      height: 0.56rem;
      // background: linear-gradient(90deg, #FCECD1 0%, #FCD5A0 100%);
      background: #ffe3ea;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(29, 29, 33, 0.8);
      font-size: 0.24rem;
      border-radius: 0.5rem;
    }
  }
  .bomcard {
    height: 0.54rem;
    background: #ffe3ea;
    border-radius: 0 0 0.14rem 0.14rem;
    opacity: 0.7;
    display: flex;
    justify-content: space-between;
    padding: 0 0.33rem;
    align-items: center;
    p {
      font-weight: 400;
      font-size: 0.24rem;
      color: #cb698c;
    }
  }
}
.md-mine {
  min-height: 100vh;
  position: relative;
  padding: 0 0.25rem 1.2rem 0;

  .mine-head {
    :deep() {
      .van-nav-bar {
        border: none;

        .van-nav-bar__content {
          .van-nav-bar__left {
            display: none;
          }
        }
      }
    }
  }

  .main-top {
    background: #fff;
    border-radius: 0.14rem;
    // box-shadow: $shadow;
    margin-top: 0.2rem;
  }

  &-info {
    padding: 0.27rem 0 0.2rem 0;
    display: flex;
    // margin: 0 0.4rem;
    flex-flow: row nowrap;
    align-items: center;
    // border-bottom: 0.02rem solid #5b5b6f;
  }

  &-photo {
    width: 1.5rem !important;
    border-radius: 50%;
    overflow: hidden;
  }

  &-account {
    margin-left: 0.4rem;
    height: 100%;
    line-height: 1.5;

    .name {
      font-size: 0.32rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      text-align: left;

    }

    .id {
      font-weight: 300;
      font-size: 0.28rem;
      opacity: 0.6;
    }

    .desc {
      font-size: 0.2rem;
      color: $txtActive;
      font-weight: 400;
    }
  }

  &-profile {
    display: flex;
    justify-content: center;
    padding: 0 0.3rem;

    &-item {
      width: 1.5rem;
      height: 1.2rem;
      text-align: center;
      position: relative;
      @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);

      p:first-child {
        font-size: 0.28rem;
        color: #000;
      }

      p {
        font-size: 0.26rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: #848494;
        margin: 0;
      }
    }
  }

  &-activity {
    padding: 0.2rem 0.3rem 0 0.3rem;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &-property-panel {
    padding: 0.38rem 0.35rem;
    margin: 0.2rem 0;
    background: #fff;
    border-radius: 0.14rem;
    box-shadow: $shadow;
    // @include flexbox($jc: space-around, $ai: center, $fd: row, $fw: nowrap);
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: #343434 !important;
    }
  }

  &-property-info {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    img {
      width: 0.6rem;
      height: 0.5rem;
      margin-bottom: 0.25rem;
      object-fit: contain;
    }

    p {
      font-size: 0.22rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;

      text-align: left;
      margin: 0 0;
      padding: 0 0;
    }
  }
  .swiperPack {
    height: 1.6rem;
    width: 100%;
    border-radius: 0.1rem;
    overflow: hidden;
  }

  .md-mroe-servic {
    background: #fff;
    border-radius: 0.14rem;
    box-shadow: $shadow;
    margin-top: 0.3rem;

    .van-cell {
      background-color: transparent;
      font-size: 0.26rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    }

    :deep() {
      .van-cell:nth-child(4) {
        // margin-bottom: 0.1rem;
      }
      .van-cell::after {
        display: none !important;
      }

      .van-cell:nth-child(4)::after {
        // display: block !important;
      }
    }

    .md-mine-icon {
      font-size: 0.28rem;
      padding: 0.05rem 0;

      img {
        width: 0.36rem;
        height: 0.36rem;
        object-fit: contain;
        margin-right: 0.26rem;
      }
    }
  }

  .tag {
    font-size: 0.38rem;
    margin: 0 0.1rem;
    position: relative;
    top: 0.05rem;
  }

  .account-img {
    h2 {
      font-size: 0.38rem;
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 0.24rem;
      color: #7a7a87;
      text-align: left;
    }

    width: 6rem;
    height: 7.2rem;
    // color: #fff;
    // background-image: url('../../assets/imgs/mine/mine-bg.svg');
    // background-image: url('../../assets/imgs/mine/share-bg.svg');
    background-color: #fff;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.3rem;
    .qrbg2 {
      width: 100%;
      height: 5.8rem;
      background-image: url('../../assets/imgs/qrcode.png');
      background-size: 100% 100%;
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 0.4rem;
    }

    .qrcode-warp {
      margin: 0.5rem;
      padding: 0.1rem;
      background-color: #fff;
    }

    .qrcode {
      height: 130px;
    }

    .desc {
      font-size: 0.28rem;
      font-weight: 400;
      color: #fff;
    }
  }

  .bottom-btn {
    width: 100%;
    text-align: center;
    margin-top: 0.3rem;
    .btn {
      width: 4.2rem;
      height: 0.7rem;
      line-height: 0.7rem;
      border-radius: 0.3rem;
      font-size: 0.32rem;
      font-weight: 600;
      color: #fff;
      background-color: #ff669d;
      border-color: #ff669d;
    }
  }

  :deep() {
    .van-popup {
      background-color: transparent;
    }
  }
}
// 广告
.banner {
  margin-top: 0.15rem;
  // padding: 0 0.24rem;
  ::v-deep {
    .van-swipe__track {
      width: 100% !important;
      border-radius: 0.08rem;
      // overflow: hidden;
    }
    // .JGContent {
    //   padding: 0 0.3rem;
    // }
  }
}
.ai_main {
  img {
    height: 2.1rem;
    width: 100%;

  }
}
.yNewMessage {
    width: .2rem; height: .2rem;border-radius: .2rem;background-color: red;position: absolute;right:-.2rem;top:-.1rem;
}
</style>

