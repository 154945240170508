<template>
  <div class="w">
    <EBackTop :background="'transparent'" :color="'#000'" :placeholder="false" />
    <div class="evaluation_pack">
      <div class="theCover">
        <DecryptImg class="md-img" :imgRadius="'0.12rem'" :imgURL="$route.query.coverImg" />
      </div>
      <div class="comics_title">{{ $route.query.title }}</div>
      <div class="score">
        <van-rate v-model="average" allow-half :size="30" color="#ff669d" void-color="#999" />
      </div>
      <div class="text_input">
        <!-- 描述信息 -->
        <van-field
          maxlength="200"
          v-model="text"
          autosize
          required
          type="textarea"
          placeholder="请勿输入广告或网址！请勿连续频繁留言避免被系统屏蔽"
        />
        <div class="input_length">
          还可输入<em> {{ 200 - text.length }} </em>字
        </div>
      </div>

      <div class="submit_btn" @click="addScore">确定</div>
    </div>
  </div>
</template>
<script>
import { addScore } from 'api/comics'
export default {
  components: {
    // HeaderBar: () => import('@/components/HeaderBar.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {
      average: 5,
      text: ''
    }
  },
  computed: {
    isVipMember({ $store }) {
      return $store.getters['isMember']
    }
  },
  methods: {
    async addScore() {
      const res = await addScore({
        contentId: +this.$route.params.id,
        contentType: 8,
        comment: this.text,
        score: this.average * 200
      })
      if (res.code === 200 && res.data) {
        setTimeout(() => {
          this.$router.back()
        }, 500)
        return this.$toast('评分成功')
      } else {
        return this.$toast(res.tip)
      }
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.w {
  .evaluation_pack {
    text-align: center;
    margin-top: 1.6rem;

    .theCover {
      .md-img {
        width: 2.4rem;
        height: 3.3rem;
        border-radius: 0.1rem;
        margin: 0 auto;
      }
    }

    .comics_title {
      width: 5.1rem;
      font-size: 0.32rem;
      color: #000;
      line-height: 0.45rem;
      margin: 0 auto;
      margin-top: 0.28rem;
    }

    .score {
      margin: 0.28rem 0;
    }

    .text_input {
      margin: 0 0.36rem;
      height: 3.8rem;
      width: 6.78rem;
      position: relative;

      .van-cell {
        height: 100%;
        border: 0.01rem solid #999999;
        background: transparent;
        border-radius: 0.12rem;
      }

      .input_length {
        position: absolute;
        bottom: 0.17rem;
        right: 0.31rem;
        color: #999999;

        em {
          color: #f9c006;
        }
      }
    }

    .submit_btn {
      width: 4.3rem;
      height: 0.8rem;
      line-height: 0.72rem;
      border: 0.04rem solid #6a6a6a;
      font-size: 0.32rem;
      color: #6a6a6a;
      border-radius: 1rem;
      margin: 0 auto;
      margin-top: 0.8rem;
    }
  }
}

// 开通会员提示按钮
.noVip-btn {
  color: #fff;
  background-color: #ff669d;
  width: 6rem;
  height: 0.68rem;
  line-height: 0.68rem;
  text-align: center;
  margin: 0.3rem auto;
  border-radius: 0.5rem;
}
</style>
