<template>
    <div id="app">
        <EBackTop  :title="imTitle" background="#121212" color="#fff" height="0.88rem" fontSize="0.32rem" />
        <van-notice-bar class="notice" color="#000" background="rgb(238, 238, 238)" mode="closeable">{{ runningLight?.content }}</van-notice-bar>
        <div class="im-container" ref="scrollContainer">
            <van-pull-refresh v-model="isLoading" :pull-distance="180" @refresh="onRefresh">
                <div class="status" v-if="status">{{ statusText }}</div>
                <div class="im-message" v-for="(message, index) in messages" :key="index"
                    :class="{ 'im-message-right': message.userId === userInfon.id }">
                    <div class="im-avatar">
                        <EDecryptImg :imgCDN="cdn"  :imgURL="message.avatar"></EDecryptImg>
                    </div>
                    <div class="state" v-if="message.state">
                        <img src="@/assets/imgs/state.png" alt="" />
                    </div>
                    <div class="im-bubble">
                        <span class="im-user">{{ message.nickName }}:</span>
                        <div v-if="message.msgType == 1" class="message-text">{{ message.value }}</div>
                        <div class="message-image" @click="clickPhotoItem(message.createdAt)" v-else>
                            <EDecryptImg :imgCDN="cdn"  :imgURL="message.value" ref="refImg" :createdAt="message.createdAt">
                            </EDecryptImg>
                        </div>
                    </div>
                    <div class="sending-time">{{ message.createdAt | timeDiff }}</div>
                </div>
            </van-pull-refresh>
        </div>
        <div class="input-container">
            <van-uploader :after-read="afterRead">
                <van-icon color="#fff" name="photo-o" size="0.54rem" />
            </van-uploader>
            <input @keyup.enter="sendMessage(1, newMessage)" v-model="newMessage" placeholder="请输入内容..."
                class="input-message" />
            <div class="sendBtn" @click="sendMessage(1, newMessage)">發送</div>
        </div>
    </div>
</template>
<script>
import { handleVerAutoImg } from '@/utils/utils_tools';
import { getImList,getImSend } from '@/api/home'
import { ImagePreview } from 'vant';
import _ from 'lodash-es';
export default {
    name: 'chatGroupIM',
    data () {
        return {
            messages: [],
            newMessage: '',
            pageNum: 1,
            pageSize: 10,
            sort: true,
            isLoading: false,
            status: false, // 顶部的加载状态
            statusText: '正在加载...',
            type: true
        };
    },

    computed: {
        userInfon ({ $store }) {
            return $store.getters['getUserInfo'];
        },
        cdn({ $store }) {
          return $store.getters['cdn'];
      },
        imTitle () {
            return '官方群聊'
        },
        runningLight ({ $store }) {
          return $store.getters['runningLight']
        },
    },
    methods: {
        async clickPhotoItem (createdAt) {
            let ingIndex = 0;
            let imgArr = [];
            let domArr = this.$refs['refImg'];
            for (let index = 0; index < domArr.length; index++) {
                const element = domArr[index];
                if (createdAt === element.$attrs.createdAt) {
                    ingIndex = index;
                }
                let url = await handleVerAutoImg(element.url);
                imgArr.push(url);
            }
            ImagePreview({
                images: imgArr, //需要预览的图片 URL 数组
                showIndex: true, //是否显示页码
                loop: true, //是否开启循环播放
                startPosition: ingIndex, //图片预览起始位置索引
                closeable: true, //展示关闭按钮
            });
        },

        async afterRead (files) {
            let param = new FormData();
            const maxSize = 1024 * 1024 * 2; // 最大文件大小限制，这里设为5MB
            if (files instanceof Array) {
                for (let item of files) {
                    // 检查文件大小
                    if (item.file.size > maxSize) {
                        this.$toast(`文件大小不能超过 ${maxSize / (1024 * 1024)} MB`);
                        return;
                    }
                    param.append('upload[]', item.file);
                }
            } else {
                // // 检查文件大小
                if (files.file.size > maxSize) {
                    this.$toast(`文件大小不能超过 ${maxSize / (1024 * 1024)} MB`);
                    return;
                }
                param.append('upload[]', files.file);
            }

            const res = await this.$uploadImages(param);
            if (res.code === 200) {
                this.sendMessage(2, res.data.paths[0]);
            } else {
                this.$toast('图片上传失败！！');
            }
        },

        async getImList (type, start, id) {
            //获取聊天信息  type:history 获取历史消息
            //start 1:第一次请求传当前时间 2:最新消息时间，给当前最下面消息的时间 type:history获取历史消息给最上面一条消息时间
            const res = await getImList({
                id: id,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                sort: type !== 'history', //true请求最新消息 false请求历史消息
                start: start,
            });
            if (res.code === 200 && res.data) {
                let groupMsgList = res.data.groupMsgList.reverse();
                if (type == 'history') {
                    if (groupMsgList.length >= this.pageSize) {
                        this.status = false; //顶部的加载动画
                    } else {
                        this.statusText = '没啦！没有可加载的历史记录了！';
                    }
                    // 历史消息拼接
                    this.messages = [...groupMsgList, ...this.messages];
                } else {
                    // 最新消息拼接
                    this.messages = [...this.messages, ...groupMsgList];
                }
                if (id == '') {
                    this.isLoading = false;
                    this.backBeginning(); //回道底部
                }
            }
        },
        async fetchMessages () {
            if (!this.type) {
                console.log("停止")
                return
            }
            //轮询获取最新数据
            let thas = this;
            setTimeout(() => {
                for (var i = thas.messages.length - 1; i >= 0; i--) {
                    let item = thas.messages[i];
                    if (!item.id && !item.state) {
                        //通过塞入的消息 并且要发送成功的才被删除    删除的目的是为了与接口返回消息不重叠
                        thas.messages.pop();
                    }
                }
                let createdAt = thas.messages.length ? thas.messages[thas.messages.length - 1].createdAt : new Date();
                let id = thas.messages.length ? thas.messages[thas.messages.length - 1].id : '';
                thas.getImList(thas.messages.length ? '' : 'history', createdAt, id);
                this.fetchMessages();
            }, 3000);
        },

        async sendMessage (msgType, value) {
            if (value.trim() !== '') {
                const res = await getImSend({
                    msgType: +msgType,
                    value: value,
                });
                if (res.code !== 200) {
                    if (res.code == 6000) {
                        this.$toast('开通vip后可参与聊天');
                        return;
                    }
                    this.$toast(res.tip || '发送失败!');
                }
                this.messages.push({
                    userId: this.userInfon.id,
                    nickName: this.userInfon.nickName,
                    value: value,
                    state: res.code === 200 ? false : true, //是否发送成功
                    msgType: +msgType, //发送消息类型  1:文本 2:图文 3:表情
                    createdAt: new Date(),
                });
                if (msgType == 1) {
                    this.newMessage = '';
                }
                this.backBeginning(); //回道底部
                console.log('slk', this.messages)
            } else {
                this.$toast('请填写输入内容');
            }
        },
        onRefresh () {
            this.isLoading = true;
            this.messages = [];
            this.getImList('', false, '');
        },
        backBeginning () {
            //聊天面板回道底部
            const { scrollContainer } = this.$refs;
            setTimeout(() => {
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }, 700);
        },
        scrollContainer: _.throttle(function (e) {
            //监听滚动条 加载历史数据
            let scrollY = e.target.scrollTop;
            if (scrollY < 50) {
                this.status = true;
                this.getImList('history', this.messages[0].createdAt, this.messages[0].id);
            }
        }, 500),
    },
    mounted () {
      this.$nextTick(()=>{
        const { scrollContainer } = this.$refs;
        scrollContainer.addEventListener('scroll', this.scrollContainer, true);
        this.fetchMessages();
      })

    },
    activated () {
    },
    beforeDestroy () {
        const { scrollContainer } = this.$refs;
        scrollContainer.removeEventListener('scroll', this.scrollContainer, true);
        this.type = false //停止轮询
        this.fetchMessages();
    },
};
</script>
<style lang="scss" scoped>
#app {
    min-height: 100vh;
    background-color: #444444;
    .notice {
      position: fixed;
      width: 100%;
      left: 0;
      top: .9rem;
      z-index: 999;
    }
    .im-container {
        padding: 1rem 0.2rem 1.1rem 0.24rem;
        .status {
            width: 100%;
            line-height: 0.7rem;
            font-size: 0.26rem;
            color: rgba($color: #fff, $alpha: 0.5);
            text-align: center;
        }

        .im-message {
            margin: 10px 0;
            display: flex;
            align-items: flex-end;

            .im-avatar {
                width: 0.68rem;
                height: 0.68rem;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 0.2rem;
                border-radius: 50%;
                overflow: hidden;
            }

            .im-bubble {
                max-width: 60%;
                padding: 0.2rem;
                border-radius: 0.3rem 0.3rem 0.3rem 0;
                background: #444444;
                position: relative;

                .im-user {
                    font-weight: bold;
                    text-align: left;
                    margin-bottom: 5px;
                    display: block;
                    color: #b872ff;
                }

                .message-text {
                    line-height: 0.4rem;
                    font-size: 0.28rem;
                    text-align: left;
                    color: #fff !important;

                }

                .message-image {
                    width: 2rem;
                }
            }

            .sending-time {
                height: 100%;
                color: #fff;
                font-size: 0.22rem;
                margin: 0 0.2rem;
            }
        }

        .im-message-right {
            flex-direction: row-reverse;

            .im-avatar {
                display: none;
            }

            .state {
                width: 0.4rem;
                height: 0.4rem;
                margin: 0.2rem 0 0.2rem 0.24rem;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .im-bubble {
                background: linear-gradient(#b88a61, #523923);
                border-radius: 0.3rem 0.3rem 0 0.3rem;
                color: white;

                .im-user {
                    // display: none;
                }

                .message-text {
                    text-align: right;
                }
            }
        }
    }

    .input-container {
        height: 1.12rem;
        background: #121212;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        width: 100%;
        position: fixed;
        bottom: 0rem;
        left: 50%;
        transform: translate(-50%,0);
        .preview-cover {
            position: absolute;
            bottom: 0;
            box-sizing: border-box;
            width: 100%;
            padding: 4px;
            color: #fff;
            font-size: 12px;
            text-align: center;
            background: rgba(0, 0, 0, 0.3);
        }

        .input-message {
            width: 4.4rem;
            height: 0.6rem;
            padding: 0 0.3rem;
            border: none;
            border-radius: 0.12rem;
            outline: none;
            color: #333;
        }

        .sendBtn {
            width: 0.96rem;
            height: 0.6rem;
            line-height: 0.6rem;
            border-radius: 0.08rem;
            border: 0.02rem solid #fff;
            color: #fff;
            text-align: center;
        }

        .input-image {
            width: 20%;
        }
    }
}
</style>
