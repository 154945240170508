<template>
  <!-- 更多专题更多列表,一行3个 -->
  <div class="home-comic-decial">
    <!-- <div class="back" @click="$router.go(-1)">
            <img src="/newAppMh/back2.png" alt="" />
        </div> -->
    <HeaderBar color="#fff" :background="'transparent'" :openFallback="true" :placeholder="false"></HeaderBar>
    <template v-if="novelData.id">
      <div class="home-comic-top">
        <div class="novel-info">
          <div class="novel-name">{{ novelData.title }}</div>
          <div class="novel-author" style="display: flex">
            <!-- 作者：{{ novelData.author.length>1?'多作者':novelData.author[0] }} -->

            <div>作者：</div>
            <div style="margin-right: 0.1rem" v-for="(item, index) in novelData.author" :key="index" @click="
              $router.push({
                path: `/home-novel/auther`,
                query: {
                  author: item,
                },
              })
              ">
              {{ item }}
            </div>
          </div>
          <div class="isvip">
            <div v-if="novelData.isVip" class="viptag">VIP</div>

            <div v-else-if="novelData.price" class="viptag">
              <img src="@/assets/imgs/mine/gold.png" alt="" />
              {{ novelData.price | changeMoneyYuan }}金币
            </div>

            <div v-else class="viptag">免费</div>
          </div>

          <div class="top_collect">
            <div class="btn" v-if="novelData.isCollect" @click="setFavour(novelData)">
              已加入书架
            </div>
            <div class="btn" v-else @click="setFavour(novelData)" style="background: #FF669D">
              加入书架
            </div>

            <div class="btn" @click="sharePopup">分享此书</div>
          </div>

          <div class="author">
            <span>标签：</span>
            <div class="tag" @click="
              $router.push({
                path: `/home-novel/tagOne`,
                query: {
                  name: item.name,
                  id: item.id,
                },
              })
              " v-for="(item, index) in novelData.tags" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="novel-cover">
          <DecryptImg :needPadding="false" class="md-img" :imgRadius="'0.12rem'" :imgURL="novelData.coverImg">
            <img v-if="novelData.isAudio" src="@/assets/imgs/novel/is_audio.png" alt="" class="novel-type-img" />
            <img v-else src="@/assets/imgs/novel/not_audio.png" alt="" class="novel-type-img" />
          </DecryptImg>
        </div>
      </div>
      <main class="home-comic-bottom">
        <div class="wrap-pack">
          <div class="lzz">
            <div class="novel-type">
              {{ novelData.updateStatus == 0 ? '待发布' : novelData.updateStatus == 1 ? '连载中' : '已完结' }} 共{{
                novelData.chapterCount
              }}话
              <!-- {{ novelData.isSerial ? '连载中' : '已完结' }} 共{{
                novelData.chapterCount
              }}话 -->
            </div>
            <div style="display: flex; align-items: center">
              <img src="@/assets/imgs/homec/fire8.png" alt="" />
              观看次数：{{ novelData.watchTimes }}
            </div>
          </div>
          <div class="desc" v-if="novelData.desc">
            {{ novelData.desc }}
          </div>
        </div>

        <div class="anthologyBotm">
          <div class="anthologyL" @click="$refs['ComicCatalogue'].initDatas()">
            <img src="@/assets/imgs/novel/anthology_icon.png" alt="" />
            目录
            <i></i>
          </div>
          <div class="anthologyC" ref="anthologyC">
            <CatalogueItem v-for="(item, index) in chapterList" :isEnoughMoney="novelData.isEnoughMoney" :item="item"
              :isAudio="isAudio" :isCollect="isCollect" :key="index" :price="novelData.price" :showType="0">
            </CatalogueItem>
          </div>
          <div class="anthologyR" @click="startReading">开始阅读</div>
        </div>
        <div class="tab-nav">
          <ul>
            <li class="left-wrap">
              <div class="top" :class="{ active: tabActive === 1 }" @click="tabActive = 1">
                <p>更多推荐</p>
              </div>
              <div class="top" :class="{ active: tabActive === 2 }" @click="tabActive = 2">
                <p>
                  评论<span>({{ novelData.comments | numberFilter }})</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
        <template>
          <van-pull-refresh v-show="tabActive == 1" v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad"
              :immediate-check="false" error-text="请求失败，点击重新加载">
              <div class="recommend">
                <DmComicCard v-for="(item, index) in comicsList" :item="item" :key="index" :showType="1"></DmComicCard>
                <i></i>
              </div>
            </van-list>
          </van-pull-refresh>
        </template>
        <template>
          <Comment v-show="tabActive == 2" class="comment-box" :publisher="novelData" :objectId="+$route.params.id"
            :objectype="5" :disabledPull="true" :bottom="'1rem'" @addComments="addComments" />
        </template>
      </main>
      <ComicCatalogue ref="ComicCatalogue" :isEnoughMoney="novelData.isEnoughMoney" :price="novelData.price"
        :isAudio="isAudio" :shoudBuy="shoudBuy" :catalogueObj="catalogueObj" :bgImages="novelData.coverImg"
        :isCollect="isCollect" :showType="1"></ComicCatalogue>
      <DmPopup ref="popupMsg"></DmPopup>
      <SharePopup :setMyCode="setMyCode" :sharePath="$router.currentRoute.path" @closeGuidance="closeGuidance" />
      <!-- <VideoVipPopup v-if="showVipBuyPop" @close="reloadPlay" />
      <videoGoldPopup v-if="showGoldBuyPop" :price="novelData.price" @close="reloadPlay" /> -->
    </template>
  </div>
</template>
<script>
import { novelViewRightTo } from '@/utils/utils_tools'
import { novelDetail } from 'api/novel'
import { collectAdd } from 'api/comics'
export default {
  name: 'novelDetail',
  components: {
    HeaderBar: () =>
      import('@/components/web_base/component_base/EBackTop/index.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    DmComicCard: () => import('@/components/HomeNovel/oneCard'),
    Comment: () => import('@/components/Comment/index.vue'),
    ComicCatalogue: () => import('@/components/HomeNovel/comic/catalogue'),
    CatalogueItem: () => import('@/components/HomeNovel/comic/catalogue/item'),
    SharePopup: () => import('@/components/Popup/sharePopup/index.vue'),
    // VideoVipPopup: () => import('@/components/Popup/videoVipPopup.vue'),
    // videoGoldPopup: () => import('@/components/Popup/videoGoldPopup.vue'),
  },
  data() {
    return {
      showGoldBuyPop: false,
      showVipBuyPop: false,
      tabActive: 1,
      catalogueObj: {
        list: [],
        show: false,
      },
      tab: '漫画详情',
      isAudio: false,
      isCollect: false,
      //漫画详情
      novelData: {},
      chapterList: [],
      //收藏
      isLike: false,
      comicsList: [],
      //加载刷新
      pageNum: 1,
      pageSize: 9,
      refreshing: false,
      loading: false,
      finished: false,
      value: 2.5,
      setMyCode: false,
    }
  },
  watch: {
    $route: {
      async handler(n, o) {
        if (!!o) {
          if (n.path != o.path) {
            this.novelDetail()
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    reloadPlay() {
      this.showGoldBuyPop = false
      this.showVipBuyPop = false
    },
    closeGuidance() {
      // 关闭弹窗
      this.setMyCode = false
    },
    sharePopup() {
      //分享弹窗
      this.setMyCode = true
    },
    //开始阅读
    startReading() {
      const item = this.chapterList.find(
        (v) => v.chapterNum == this.novelData.watchNum
      )
      novelViewRightTo(
        this,
        item ? item.id : this.chapterList[0].id,
        2,
        this.novelData.price,
        this.novelData.isEnoughMoney,
        this.novelData.isCollect,
        this.shoudBuy,
        this.$route.params.id,
        this.novelData.isAudio ? true : false
      )
    },
    //传递评论
    addComments() {
      this.novelData.comments++
    },
    // 设置历史
    setHistory() {
      // let novelData = JSON.parse(JSON.stringify(this.novelData))
      // this.$store.commit('setNovelHisList', {
      //   type: 'add',
      //   item: novelData,
      // })
      this.$store.commit('setNovelHisList', { type: 'add', item: this.novelData })
    },

    //本地收藏
    async setFavour(item) {
      const res = await collectAdd({
        collectType: 15,
        flag: !item.isCollect,
        object_id: item.id,
        type: 1,
        isCollet: false
      })
      if (res.code === 200) {
        if (!item.isCollect) {
          item.isCollect = true
          item.likes += 1
          return this.$toast('已添加书架')
        } else {
          item.isCollect = false
          item.likes -= 1
          return this.$toast('已移除书架')
        }
      } else {
        return this.$toast('操作失败')
      }
    },
    //收藏位置
    findIndex() {
      let status = false
      this.bookshelfList.forEach((element1) => {
        element1.list.forEach((element2) => {
          if (element2.id == +this.$route.params.id) {
            status = true
          }
        })
      })
      if (!status) {
        this.isLike = false
      } else {
        this.isLike = true
      }
    },
    //获取小说详情
    async novelDetail(type) {
      const res = await novelDetail({
        id: +this.$route.params.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
      if (res.code === 200 && res.data) {
        this.chapterList = res.data.chapterList
        this.catalogueObj.list = res.data.chapterList
        this.novelData = res.data.novelData
        this.isAudio = res.data.novelData.isAudio
        this.isCollect = res.data.novelData.isCollect
        this.findIndex()
        this.chapterList.forEach((item, index) => {
          item.indexName = index + 1
        })
        this.refreshing = false
        this.loading = false
        if (
          !res.data ||
          !res.data.novelData ||
          !res.data.novelData.lookNovel ||
          res.data.novelData.lookNovel.length < this.pageSize
        ) {
          this.finished = true
        }
        if (type == 'pull') this.comicsList = []
        if (res.data.novelData && res.data.novelData.lookNovel)
          this.comicsList = [
            ...this.comicsList,
            ...res.data.novelData.lookNovel,
          ]
      } else {
        this.finished = true
        this.refreshing = false
        this.loading = false
        return this.$toast(res.tip)
      }
    },
    //切换
    fn_tab(name) {
      this.tab = name
    },

    //上拉加载更多
    async onLoad() {
      this.pageNum += 1
      this.novelDetail()
    },
    // 刷新
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.novelDetail('pull')
    },
  },
  mounted() {
    this.novelDetail()
  },
  computed: {
    isVipMember({ $store }) {
      return $store.getters['isMember']
    },
    bookshelfList({ $store }) {
      return $store.state.history.bookshelfList
    },
    //判断是否能看
    shoudBuy() {
      if (this.novelData.isBuy) {
        return 3 //已解锁
      } else {
        if (this.novelData.isVip && !this.isVipMember) {
          return 1 //vip
        } else if (this.novelData.price && !this.novelData.isBuy) {
          return 2 //金币
        }
      }
    },
  },
  beforeDestroy() {
    this.setHistory()
  },
}
</script>

<style lang="scss" scoped>
:deep() {
  .submit-box {
    bottom: 0.99rem !important;
  }
}

.novel-type {
  color: #979797;
  border-radius: 0.04rem;
  color: #FF669D;
  ;
  // background: rgba(255, 61, 0, 0.10);
  padding: 0.04rem 0.1rem;
}

.author {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  overflow: hidden;
  gap: 0.2rem;

  .tag {
    border: 1px solid #4fd1c5;
    background: rgba(79, 209, 197, 0.1);
    padding: 0.02rem 0.1rem;
    color: #4fd1c5;
    border-radius: 0.08rem;
  }
}

.isvip {
  display: flex;
}

.viptag {
  border-radius: 0.04rem;
  border: 1px solid #FF669D;
  ;
  display: flex;
  padding: 0.04rem 0.1rem;
  justify-content: center;
  align-items: center;
  color: #FF669D;
  ;
  font-size: 0.24rem;

  img {
    width: 0.22rem;
    height: 0.22rem;
    margin-right: 0.1rem;
  }
}

.home-comic-decial {
  min-height: 100vh;
  padding-bottom: 0.1rem;

  .back {
    height: 0.34rem;
    position: fixed;
    left: 0.3rem;
    top: 0.25rem;

    img {
      height: 0.34rem;
    }
  }

  .home-comic-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 5rem;

    padding: 0 0.6rem;
    padding-top: 0.8rem;
    padding-bottom: 0.2rem;
    background: #655a5a;

    .novel-info {
      padding-right: 0.4rem;
      color: #fff;
      font-size: 0.24rem;

      .novel-name {
        font-size: 0.36rem;
        margin-bottom: 0.2rem;
        line-height: 0.42rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: normal;
      }

      .novel-author {
        margin-bottom: 0.2rem;
      }

      .top_collect {
        margin-top: 0.44rem;
        margin-bottom: 0.5rem;
        display: flex;

        .btn {
          width: 1.6rem;
          height: 0.54rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.08rem;
          background: #FF669D;
          ;
          margin-right: 0.14rem;
        }
      }
    }

    .novel-cover {
      min-width: 1.96rem;
      max-width: 1.96rem;
      height: 2.68rem;

      .md-img {
        width: 100%;
        height: 100%;

        .novel-type-img {
          width: 0.64rem;
          height: 0.64rem;
          position: absolute;
          right: 0.08rem;
          bottom: 0.08rem;
        }
      }
    }
  }

  .home-comic-bottom {
    min-height: calc(100vh - 6.8rem);
    position: relative;
    background: #fff;
    padding-bottom: 1rem;
    @include box-shadow-all;

    .wrap-pack {
      width: 100%;
      padding: 0.3rem;
      padding-bottom: 0.1rem;
      position: relative;

      .lzz {
        img {
          width: 0.28rem;
          margin-right: 0.16rem;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.14rem;
        color: #979797;
      }

      .timesWatched {
        display: flex;
        align-items: center;
        margin-top: 0.16rem;
        font-size: 0.26rem;

        img {
          height: 0.36rem;
          margin-right: 0.16rem;
        }
      }
    }

    .desc {
      padding-bottom: 0.3rem;
      color: #979797;
      line-height: 0.34rem;
    }

    .anthologyBotm {
      height: 1rem;
      position: fixed;
      bottom: -0.01rem;
      left: 50%;
      transform: translate(-50%, 0);
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      z-index: 99;
      max-width: $pcMaxWidth;
      width: 100%;

      .anthologyL {
        width: 1.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #999;
        font-size: 0.22rem;
        box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.1);
        padding: 0.1rem 0;

        img {
          width: 0.42rem;
        }

        i {
          width: 0.36rem;
          height: 0.04rem;
          border-radius: 1rem;
          background: #FF669D;
          ;
          margin-top: 0.05rem;
        }
      }

      .anthologyC {
        width: 4.5rem;
        height: 1rem;
        display: -webkit-box;
        overflow-x: auto;
        padding-left: 0.24rem;

        :deep() {
          li {
            width: 1.18rem;
            height: 0.72rem;
            font-size: 0.26rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 61, 0, 0.10);
            border-radius: 0.08rem;
            margin: 0.14rem 0.24rem 0.14rem 0;
          }
        }
      }

      .anthologyC {
        @include scrollbar-hide;
      }

      .anthologyR {
        width: 1.8rem;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        font-size: 0.24rem;
        color: #fff;
        // background-image: url('/newAppMh/mine/pay/anthology_bg_icon.png');
        background: #FF669D;
        // background-size: auto 100%;
        // background-position: 0;
        // background-repeat: round;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 1.8rem;
          height: 1rem;
          background-image: url('/newAppMh/mine/pay/anthology_bg_icon.png');
          background-size: auto 100%;
          background-position: 0;
          background-repeat: round;
          left: 0;
          top: 0;
          opacity: 0.7;
        }
      }
    }

    .tab-nav {
      @include flex-align-center;
      justify-content: space-between;
      padding: 0.2rem 0.4rem;
      width: 100%;
      border-top: 0.02rem solid rgba(0, 0, 0, 0.2);
      max-width: $pcMaxWidth;

      // margin: .2rem 0;
      ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-wrap {
          display: flex;
          align-items: center;

          .top {
            @include flex-center;
            font-size: 0.32rem;
            font-weight: 500;
            min-width: 1.2rem;
            white-space: nowrap;
            color: #333;
            opacity: 0.7;
            position: relative;
            margin-right: 0.4rem;

            img {
              width: 0.32rem;
              position: absolute;
              bottom: 0.15rem;
            }

            span {
              font-size: 0.3rem;
            }

            &.active {
              position: relative;
              opacity: 1;
            }
          }
        }

        .right-wrap {
          div {
            width: 1.2rem;
            height: 0.46rem;
            border-radius: 0.12rem;
            color: #FF669D;
            ;
            font-size: 0.24rem;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0.5px 0.2rem rgb(0 0 0 / 10%);
          }
        }
      }
    }

    .recommend {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0.3rem;
      justify-content: space-between;

      i {
        width: 2.1rem;
      }
    }

    //评论框
    .comment-box {
      min-height: calc(100vh - 6.9rem);
      background: transparent;
      border-radius: 0;

      &::before {
        display: none;
      }

      :deep() {
        .header {
          display: none;
        }

        .content-main-wrap {
          height: auto;
        }
      }
    }
  }
}
</style>
