<template>
    <div class="w" :style="styleVar">
        <div class="showHide" v-show="clickShow">
            <div class="Top">
                <div class="TopL" @click="$router.go(-1)">
                    <img src="/newAppMh/back2.png" alt="" />
                    返回
                </div>
                <div v-if="data.novelData" class="TopR" @click="setFavour(data.novelData)"
                    :style="{ 'opacity': (data.novelData.isCollect ? 0.7 : 1) }">
                    <!-- <img src="@/assets/imgs/novelshujiaIcon.png" alt=""> -->
                    {{ data.novelData.isCollect ? "已加入" : "加入书架" }}
                </div>
            </div>
            <div class="bottom">
                <div class="SwitchPack" v-if="funType === 0">
                    <div class="chapterSwitch" @click="fn_upPage">上一章</div>
                    <div class="slider">
                        <van-slider v-model="pageValue" bar-height="4px" active-color="#FE9731" :min="1" :max="pageMax"
                            @change="onChange" />
                    </div>
                    <div class="chapterSwitch" @click="fn_downPage">下一章</div>
                </div>
                <!-- 亮度 -->
                <div class="brightPack" v-else-if="funType === 1">
                    <div class="SwitchPack">
                        <div class="chapterSwitch minBright">
                            <!-- <img src="@/assets/imgs/novelminBright.png" alt=""> -->
                        </div>
                        <div class="slider bright">
                            <van-slider v-model="brightnessValue" bar-height="4px" active-color="#FE9731" :min="0"
                                :max="9" @change="onBrightness" />
                        </div>
                        <div class="chapterSwitch maxBright">
                            <!-- <img src="@/assets/imgs/novel/maxBright.png" alt=""> -->
                        </div>
                    </div>
                    <div class="eyeProtection">
                        <div>护眼模式</div>
                        <van-switch v-model="eyeProtection" size="22px" active-color="#FE9731"
                            @change="fu_eyeProtectio" />
                    </div>
                </div>
                <!-- 设置 -->
                <div class="setUpPack" v-else-if="funType === 2">
                    <div class="setUp">
                        字号
                        <div class="setUp_size">
                            <div class="btn_size" @click="fu_size(false)">
                                A -
                            </div>
                            <span>{{ setUp_size }}</span>
                            <div class="btn_size" @click="fu_size(true)">
                                A +
                            </div>
                        </div>
                    </div>
                    <div class="setUp">
                        背景
                        <ul class="setUp_background">
                            <li v-for="(item, index) in backgroundList" :key="index" :style="{ 'background': item }"
                                :class="{ 'active': backgroundActive == item }" @click="fu_background(item)">
                                <!-- <img src="@/assets/imgs/novelactiveIcon.png" alt=""> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <ul>
                    <li @click="fn_chapter">
                        <img src="@/assets/imgs/novel/list2_icon.png" alt="">
                        <p>目录</p>
                    </li>
                    <li @click="funType = 1" :class="{ 'active': funType == 1 }">
                        <img src="@/assets/imgs/novel/brightness_Icon2.png" alt="">
                        <!-- <img src="@/assets/imgs/novelbrightness_Icon.png" alt="" v-else> -->
                        <p>亮度</p>
                    </li>
                    <li @click="funType = 2" :class="{ 'active': funType == 2 }">
                        <img src="@/assets/imgs/novel/setUp_icon2.png" alt="">
                        <!-- <img src="@/assets/imgs/novelsetUp_icon.png" alt="" v-else> -->
                        <p>阅读设置</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="switchChapter" v-show="!clickShow">
            <div class="chapterSwitch" @click="fn_upPage">上一章</div>
            <div class="slider" @click="fn_chapter">目录</div>
            <div class="chapterSwitch" @click="fn_downPage">下一章</div>
        </div>
        <!-- fm_fanye -->
        <div class="Reader" @click="clickShow = !clickShow">
            <article>
                <h2>{{ daraInfo.title || '' }}</h2>
                {{ daraInfo.content || '' }}
                <div class="getLastPage"></div>
            </article>
        </div>
        <div class="BrightnessMask"></div>
        <DmPopup ref="popupMsg"></DmPopup>
        <ChapterList ref="popupChapterList"></ChapterList>
    </div>
</template>
<script>
import { picViewRightTo } from '@/utils/utils_tools'
import { novelInfo, novelDetail } from 'api/novel'
import { collectAdd } from 'api/comics'
export default {
    components: {
        DmPopup: () => import('@/components/Popup/index.vue'),
        ChapterList: () => import('@/components/HomeNovel/chapterList/index.vue'),
    },
    data() {
        return {
            pageValue: 1,
            brightnessValue: 8,
            styleVar: {
                "--brightness_css": "0.05",
                "--fontSize": ".3rem",
                "--lineHeight": ".44rem",
                "--backgroundActive": "#F6E9C7"
            },
            eyeProtection: false, //护眼模式
            pageMax: 0,
            daraInfo: {},
            clickShow: false,
            chapterList: [], //小说章节
            price: undefined,
            data: {},
            funType: 0, //功能类型
            setUp_size: 30,
            lineHeight: 44,
            backgroundList: ["#F6E9C7", "#F0EFEA", "#EEE8EC", "#C5D9C2", "#D9D1CA"],
            backgroundActive: "#F6E9C7",
            iIndex: 0
        }
    },
    methods: {

        //加入书架
        async setFavour(item) {
            const res = await collectAdd({
                collectType: 15,
                flag: !item.isCollect,
                object_id: item.id,
                type: 1,
                isCollet: false
            })
            if (res.code === 200) {
                if (!item.isCollect) {
                    item.isCollect = true
                    return this.$toast('已加入书架')
                } else {
                    item.isCollect = false
                    return this.$toast('移除书架')
                }
            } else {
                return this.$toast('操作失败')
            }
        },
        fn_chapter() {
            this.funType = 0
            this.$refs['popupChapterList'].popupMsg = {
                show: true, //开关
                dataList: this.data, // 章节数据
                currentId: +this.$route.params.id, //当前章节ID
                atLastIndex: JSON.parse(JSON.stringify(this.pageMax)), //最新章节Index
                currentIndex: JSON.parse(JSON.stringify(this.pageValue)), //当前章节Index
                position: 'left',
                muLuList: JSON.parse(JSON.stringify(this.data.chapterList)),
                cb: async (returnData) => {
                    this.fn_toSwitch(returnData)
                }
            }
        },
        fu_size(value) { // 字体大小设置
            let size = value ? ++this.setUp_size : --this.setUp_size
            let Height = value ? ++this.lineHeight : --this.lineHeight
            this.styleVar["--fontSize"] = size / 100 + 'rem'
            this.styleVar["--lineHeight"] = Height / 100 + 'rem'
            //缓存设置
            this.$store.commit('setNovelSetting', {
                setUp_size: size, //字号
                lineHeight: Height, //行高
                backgroundActive: this.novelSetting.backgroundActive //背景色
            });
        },
        fu_background(item) {
            this.backgroundActive = item
            this.styleVar["--backgroundActive"] = item
            //缓存设置
            this.$store.commit('setNovelSetting', {
                setUp_size: this.novelSetting.setUp_size, //字号
                lineHeight: this.novelSetting.lineHeight, //行高
                backgroundActive: item //背景色
            });
        },
        onBrightness(value) { //亮度调节
            let a = (9 - value)
            this.styleVar["--brightness_css"] = a / 20
        },
        fu_eyeProtectio() { //护眼模式开关
            this.brightnessValue = this.eyeProtection ? 4 : 8
            this.styleVar["--brightness_css"] = this.eyeProtection ? .2 : .05
        },
        onChange(value) { //滑动触发事件
            let value2 = JSON.parse(JSON.stringify(value))
            this.fn_toSwitch(value2)
        },
        fn_upPage() { //上一章
            if (this.chapterList.length <= 1) return this.$toast("当前只有一章");
            this.chapterList.forEach(async (item, index) => {
                if (+this.$route.params.id == item.id) {
                    this.fn_toSwitch(index)
                }
            });
        },
        fn_downPage() { //下一章
            this.chapterList.forEach(async (item, index) => {
                if (+this.$route.params.id == item.id) {
                    let value = index + 2
                    this.fn_toSwitch(value)
                }
            });
        },
        fn_toSwitch(value) { //切换章节
            if (value > this.chapterList.length) return this.$toast("当前最后一章")
            if (value < 1) return this.$toast("当前第一章");
            // debugger
            this.$toast("正在加载第" + value + "话");
            // picViewRightTo(this,+this.chapterList[--value].id,this.price,1);
            picViewRightTo(this, +this.chapterList[--value].id, this.daraInfo, 1, 1)
        },
        async getNovelInfo() { //获取当前章节数据
            const res = await novelInfo({
                id: +this.$route.params.id
            })
            if (res.code === 200) {
                this.daraInfo = res.data
            }
        },
        async novelDetail() { //获取该小说的的目录
            const res = await novelDetail({
                id: +this.$route.query.novelId,
                pageNum: 1,
                pageSize: 1,
            })
            if (res.code === 200 && res.data) {
                this.data = res.data
                this.chapterList = res.data.chapterList
                this.pageMax = this.chapterList.length //获取章节长度
                this.price = res.data.novelData.price
                this.chapterList.forEach(async (item, index) => { //获取当前章节
                    if (+this.$route.params.id == item.id) {
                        this.pageValue = ++index
                    }
                })
            } else {
                return this.$toast('章节信息获取失败')
            }
        },
    },
    watch: {
        $route: {
            async handler(n, o) {
                if (!!o) {
                    if (n.path != o.path) {
                        this.getNovelInfo()
                        this.novelDetail()
                    }
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.getNovelInfo()
        this.novelDetail()
        if (!this.novelSetting.backgroundActive || !this.novelSetting.lineHeight || !this.novelSetting.setUp_size) {
            this.$store.commit('setNovelSetting', {
                setUp_size: 30, //字号
                lineHeight: 44, //行高
                backgroundActive: "#F6E9C7" //背景色
            });
        }
        this.setUp_size = this.novelSetting.setUp_size
        this.lineHeight = this.novelSetting.lineHeight
        this.backgroundActive = this.novelSetting.backgroundActive
        this.styleVar["--fontSize"] = this.setUp_size / 100 + "rem",
            this.styleVar["--lineHeight"] = this.lineHeight / 100 + "rem",
            this.styleVar["--backgroundActive"] = this.backgroundActive;
    },
    computed: {
        novelSetting({ $store }) {
            return $store.state.comicsVideo.novelSetting;
        },
    },

}
</script>

<style lang="scss" scoped>
.w {
    .showHide {
        .Top {
            width: 100%;
            background: rgba(0, 0, 0, .6);
            color: #fff;
            height: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: fixed;
            top: 0;
            left: 50%;
            right: 0;
            z-index: 999;
            padding: 0 .3rem;
            max-width: $pcMaxWidth;
            transform: translate(-50%, 0);

            .TopL {
                display: flex;
                align-items: center;
                font-size: .32rem;

                img {
                    height: .3rem;
                    margin-right: .3rem;
                }
            }

            .TopR {
                display: flex;
                align-items: center;

                img {
                    height: .3rem;
                    width: .26rem;
                    margin-right: .08rem;
                }
            }
        }

        .bottom {
            width: 100%;
            padding: .3rem;
            background: rgba(0, 0, 0, .6);
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            color: #fff;

            .SwitchPack {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .chapterSwitch {
                    white-space: nowrap;

                    &.minBright {
                        height: .28rem;

                        img {
                            height: 100%;
                        }

                    }

                    &.maxBright {
                        height: .46rem;

                        img {
                            height: 100%;
                        }
                    }
                }

                .slider {
                    width: 100%;
                    margin: 0 .3rem;

                    &.bright {
                        margin: 0 .4rem;
                    }
                }
            }

            .brightPack {
                margin: 0 .28rem;

                .eyeProtection {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: .34rem;
                }
            }

            .setUpPack {
                .setUp {
                    display: flex;
                    align-items: center;
                    font-size: .28rem;
                    margin-bottom: .3rem;

                    .setUp_size {
                        display: flex;
                        align-items: center;
                        margin-left: .3rem;

                        span {
                            margin: 0 .4rem;
                            font-size: .42rem;
                        }

                        .btn_size {
                            height: .52rem;
                            border: .01rem solid #FFFFFF;
                            border-radius: 1rem;
                            font-size: .42rem;
                            color: #fff;
                            padding: 0 .38rem;
                            display: flex;
                            align-items: center;

                            img {
                                width: .26rem;
                                margin-left: .1rem;
                            }
                        }
                    }

                    .setUp_background {
                        display: flex;
                        padding: 0;
                        margin: 0;
                        margin-left: .3rem;

                        li {
                            width: .62rem;
                            height: .62rem;
                            margin-right: .4rem;
                            border-radius: 1rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: .38rem;
                                height: .34rem;
                                display: none;
                                margin-bottom: 0;
                            }

                            &.active {
                                img {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: .4rem;
                padding: 0 1rem;

                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &.active {
                        color: #FE9731;
                    }

                    img {
                        width: .46rem;
                        height: .46rem;
                        margin-bottom: .22rem;
                    }

                    p {
                        margin: 0;
                        font-size: .28rem;
                    }
                }
            }
        }
    }

    .Reader {
        overflow: hidden;
        // height: 100vh; //左右切换
        min-height: 100vh;
        background: var(--backgroundActive);
        padding: 16px;
        font-size: var(--fontSize);
        line-height: var(--lineHeight);
        white-space: pre-line;

        article {
            columns: calc(100vw - 32px) 1;
            column-gap: 16px;
            height: 100%;
            transition: .4s;
            margin-bottom: .87rem;

            h2 {
                margin: 0;
                font-size: .34rem;
            }
        }
    }

    .switchChapter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        left: 50%;
        right: 0;
        bottom: 0;
        height: .87rem;
        background: rgba(0, 0, 0, .6);
        color: #fff;
        font-size: .26rem;
        padding: 0 .3rem;
        max-width: $pcMaxWidth;
        transform: translate(-50%, 0);
        width: 100%;

        .slider {
            width: 3rem;
            height: 100%;
            text-align: center;
            line-height: .87rem;
        }
    }

    .BrightnessMask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(0, 0, 0);
        opacity: var(--brightness_css) !important;
        pointer-events: none;
        z-index: 9999;
    }
}
</style>
