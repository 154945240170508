<template>
  <div class="cartoon-default" :class="noPadding ? 'no_Padding' : 'has-padding'">
    <SeachIpt :class="!noPadding ? 'search_input' : ''" />
    <ERefreshLoad
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
    >
      <div slot="top" class="findhoPack">
        <div v-if="navItem.item.adsType === 2 && iconSqAds.length" class="iconSqAds-list">
          <AdAvatarList :list="iconSqAds" :wrap="true" key-name="avatar" />
        </div>
        <div class="swiperPack" v-else-if="navItem.adsType !== 3 && commityAD.length > 0">
          <EADSwiper :list="commityAD" keyName="cover" :EADconfig="EADconfig" :imgCDN="imgCDN" @clickImg="clickImg">
          </EADSwiper>
        </div>
        <!-- <div class="announcement">
          <div class="title">公告</div>
          <div class="scroll-text">
            <div class="text" ref="text">{{ $store.state.config.config.postRunningLight?.content }}</div>
          </div>
        </div> -->
      </div>
      <div class="tagList">
        <EDecryptImg
          :imgCDN="imgCDN"
          v-for="(item, index) in findTagList"
          :key="index"
          :imgURL="item.imageUrl"
          class="tag"
          @click.native="$router.push(`/community/tagDteail?id=${item.id}&title=${item.name}`)"
        >
          <div class="abbox">
            <div style="font-size: 0.3rem; font-weight: 500">{{ item.name }}</div>
            <div style="font-size: 0.2rem; font-weight: 400">{{ item.totalPost }}个帖子</div>
          </div>
        </EDecryptImg>
      </div>
      <div class="homePack">
        <div class="sortList">
          <div
            class="sortBox"
            :class="{ active: sort == item.sort }"
            @click="sortSwitch(item.sort)"
            v-for="(item, index) in topTextList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- <div class="homeTop">
            <div class="decorate"></div>
            <template v-if="navItem.item.name === '发现' || navItem.item.name === '约炮'">
                <div class="topText active">
                    最新内容
                </div>
            </template>
            <template v-else>
                <div class="topText" :class="{'active':sort==item.sort}" @click="sortSwitch(item.sort)" v-for="(item,index) in topTextList" :key="index">
                    {{item.name}}
                </div>
            </template>
        </div> -->
        <CommunityDefault v-for="(item, index) in communityList" :key="index" :itemData="item" @checkTag="checkTag" />
        <!-- <div style="height: 2rem;" v-for="(item, index) in communityList" :key="index">{{ index }}</div> -->
      </div>
    </ERefreshLoad>
    <!-- <ComPlayVideo ref="playVideo" /> -->
  </div>
</template>

<script>
import { community_list } from 'api/community'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
import { advertiseClick } from 'api/home'
export default {
  components: {
    CommunityDefault: () => import('@/components/Community/default.vue'),
    ComPlayVideo: () => import('@/components/Community/video.vue'),
    SeachIpt: () => import('@/components/SearchIpt.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },
  props: ['navItem', 'findhotList'],
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      loading: false, //上拉加载
      finished: false, //上拉加载完成
      refreshing: false, //下拉刷新
      communityList: [],
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      topTextList: [
        {
          sort: 0,
          name: '推荐'
        },
        {
          sort: 1,
          name: '最新'
        },
        {
          sort: 2,
          name: '最热'
        },
        {
          sort: 3,
          name: '精华'
        },
        {
          sort: 4,
          name: '视频'
        }
      ],
      sort: 0,
      noPadding: false
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取轮播广告
    commityAD({ $store }) {
      return $store.getters['commityAD']
    },
    findTagList({ $store }) {
      console.log($store.getters['postSection'].filter(item => item.postCategoryId === this.navItem.item.id))
      return $store.getters['postSection'].filter(item => item.postCategoryId === this.navItem.item.id)
    },
    // 获取首页顶部视频分类导航列表
    findCategory({ $store }) {
      return $store.getters['findCategory']
    },
    iconSqAds({ $store }) {
      return $store.getters['iconSqAds']
    }
  },
  watch: {
    $route: {
      handler(n, o) {
        if (n.path === '/post') {
          this.noPadding = false
        } else {
          this.noPadding = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    console.log(this.navItem)
    this.$bus.on('getNewCommunityList', this.getNewPostList)
  },
  methods: {
    getNewPostList() {
      // console.log('@@@@@@@@@@@@@@@@@@@')
      // this.pageNum = 1
      // this.getPostList()
    },
    checkTag(item) {
      this.$emit('checkTag', item)
    },
    link(item) {
      if (item.type === 1) {
        this.$router.push(`/play/${item.objectId}`)
      } else {
        this.$router.push(`/community/detail/${item.objectId}`)
      }
    },

    sortSwitch(sort) {
      //切换排序
      this.pageNum = 1
      this.sort = sort
      this.getPostList('pull')
    },
    async clickImg(item) {
      const code = handleURlParams(item.href)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    // 获取帖子列表community_findhot
    async getPostList(type) {
      try {
        const res = await community_list({
          categoryId: this.navItem.item.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sort: this.sort
        })
        if (res.code === 200) {
          this.loading = false
          this.refreshing = false
          if (type == 'pull') {
            this.communityList = []
          }
          this.communityList = [...this.communityList, ...res.data.list]
          //  图片超出6张只显示6张
          this.communityList.map(item => {
            if (item.imgs?.length > 9) {
              item.imgs = item.imgs.splice(0, 9)
            }
          })
          if (!res.data.list || res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试')
      }
    },

    //上拉刷新
    refreshData() {
      this.finished = false
      this.refreshing = true
      this.pageNum = 1
      this.getPostList('pull')
    },
    //下拉加载
    async moreData() {
      this.loading = true
      this.pageNum += 1
      await this.getPostList()
    }
  },
  mounted() {
    this.getPostList()
  }
}
</script>

<style lang="scss" scoped>
.has-padding {
  .search_input {
    top: 0.9rem !important;
  }
}

.sortList {
  width: 100%;
  display: flex;
  padding-left: 0.2rem;
  margin-top: 0.1rem;
  margin-bottom: 0.36rem;
  .sortBox {
    display: flex;
    padding: 0.06rem 0.24rem;
    justify-content: center;
    align-items: center;
    font-size: 0.28rem;
    border-radius: 0.08rem;
  }
  .active {
    background: $btnBg;
    color: #fff;
  }
}
.tagList {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.2rem;

  .tag {
    width: 2.21rem;
    height: 1.16rem;
    position: relative;
    margin-right: 0.21rem;
    margin-bottom: 0.21rem;
    &::after {
      content: '';
      display: block;
      // backdrop-filter: blur(0.3px);
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
    .abbox {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
      color: #fff;
    }
  }
}
.cartoon-default {
  padding-bottom: 0.8rem;
  &.has-padding {
    padding-top: 2.3rem;
  }
  padding-top: 1.44rem;
  .findhoPack {
    color: #1d1d21;
    font-size: 0.32rem;
    line-height: 0.45rem;
    padding: 0 0.19rem;
    // border-bottom: 0.15rem solid #f2f2f2;
    padding-bottom: 0.3rem;
    .swiperPack {
      // width: 6.78rem;
      height: 3.6rem;
      margin-top: 0.6rem;
    }
    .iconSqAds-list {
      margin-top: 0.6rem;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      li {
        width: 3.19rem;
        height: 0.45rem;
        line-height: 0.45rem;
        margin-top: 0.1rem;
        font-size: 0.32rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          // width: 2.2rem;
          height: 0.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        img {
          // width: 0.28rem;
          height: 0.28rem;
        }
        &.more {
          color: $btnBg;
          justify-content: left;
        }
      }
      &::after {
        content: ' ';
        height: 2.2rem;
        width: 0.02rem;
        background: #d9d9d9;
        opacity: 0.3;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
      }
    }
  }
  .homePack {
    padding: 0 0.36rem;
    .homeTop {
      height: 0.71rem;
      line-height: 0.71rem;
      border-bottom: 0.01rem solid #f2f2f2;
      color: #1d1d21;
      font-size: 0.32rem;
      padding: 0 0.24rem;
      display: flex;
      align-items: center;
      .decorate {
        height: 0.36rem;
        width: 0.06rem;
        background: #fe6187;
        margin-right: 0.24rem;
      }
      .topText {
        font-size: 0.26rem;
        color: #1d1d21;
        opacity: 0.6;
        margin-right: 0.3rem;
        &.active {
          font-size: 0.32rem;
          opacity: 0.8;
        }
      }
    }
  }
  ::v-deep {
    .van-notice-bar {
      height: 0.6rem;
      // border-radius: 1rem;
      margin-top: 0.26rem;
      box-shadow: 0px 4px 4px 0px #00000040;
      opacity: 0.6;
      .van-icon {
        color: #000;
      }
      .van-notice-bar__wrap {
        font-size: 0.26rem;
      }
    }
  }
  ::v-deep {
    .upCarePack {
      border-bottom: 0.15rem solid #f2f2f2;
      padding: 0 0.24rem;
    }
  }
}
.no_Padding {
  padding-top: 1.8rem;
}
.announcement {
  font-size: 0.26rem;
  @include flexbox($jc: flex-start);
  margin: 0.36rem 0 0.3rem 0;

  .title {
    padding: 0.02rem 0.12rem;
    border-radius: 0.08rem;
    background: #00f6f8;
    white-space: nowrap;
    color: #333;
  }

  .scroll-text {
    overflow: hidden;
    margin-left: 0.12rem;
    color: #fff;
    .text {
      white-space: nowrap;
      animation: scroll-text 10s linear infinite;
    }
  }

  @keyframes scroll-text {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }
}
</style>
