<template>
  <div class="jav-tab">
    <van-tabs
      v-model="newActive"
      :swipeable="swipeable"
      lazy-render
      :animated="animated"
      :line-height="lineHeight"
      :title-inactive-color="titleInactiveColor"
      :title-active-color="titleActiveColor"
      :sticky="sticky"
      :background="background"
      @change="change"
      :offset-top="offsetTop"
      :class="addClass"
      :ellipsis="false"
    >
      <van-tab v-for="(item,index) in titles" :key="item.id" :title="item.name" :name="item.id">
        <slot :data="item.showType" :item="item" :index="index"></slot>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  props: {
    addClass: {
      type: String,
      default() {
        return ''
      }
    },
    swipeable: {
      type: Boolean,
      default() {
        return true
      }
    },
    needBack: {
      type: Boolean,
      default() {
        return false
      }
    },
    animated: {
      type: Boolean,
      default() {
        return true
      }
    },
    titles: {
      type: Array,
      default() {
        return []
      }
    },
    background: {
      type: String,
      default() {
        return 'transparent'
      }
    },
    offsetTop: {
      type: String,
      default() {
        return ''
      }
    },
    sticky: {
      type: Boolean,
      default() {
        return false
      }
    },
    active: {
      type: Number,
      default() {
        return 0
      }
    },
    lineHeight: {
      type: Number,
      default() {
        return 3
      }
    },
    hasRoute: {
      type: Boolean,
      default() {
        return true
      }
    },
    titleActiveColor: {
      type: String,
      default() {
        return '#F96491'
      }
    },
    titleInactiveColor: {
      type: String,
      default() {
        return '#939496'
      }
    },
    path: {
      type: String,
      default() {
        return '/'
      }
    }
  },
  data() {
    return {
      newActive: 0
    }
  },
  watch: {
    // 初始化显示控制
    active: {
      handler(n, o) {
        this.newActive = n
      },
      immediate: true
    }
  },

  methods: {
    change(id) {
      if (this.needBack) {
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }
      this.$emit('change', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.jav-tab {
  :deep() {
    .van-tabs {
      width: 100%;
      max-width: $pcMaxWidth;
      .van-tab {
        font-size: .3rem;
        padding: 0;
        margin-left: 0.4rem;
        flex-shrink: 0;
      }
      .van-tab:first-child {
        margin-left: 0;
      }
    }
    .van-tabs__content {
      min-height: 40vh;
    }
    .van-tab--active {
      // font-size:.5rem !important;
      // font-weight: 400;
      .van-tab__text {
        font-weight: 400;
        // -webkit-text-fill-color: transparent;
        // background: $txtActive;
        //   display: inline-block;
        // -webkit-background-clip: text;

      }
      //选中下划线
      // .van-tab__text::after {
      //   content: '';
      //   width: 0.36rem;
      //   display: block;
      //   height: 0.08rem;
      //   margin: 0.03rem auto 0 auto;
      //   background: $txtActive;
      //   border-radius: 0.12rem;
      //   text-align: center;
      // }
    }
    .van-sticky {
      left: 50%;
      width: 100%;
      max-width: $pcMaxWidth;
      z-index: 10;
      padding: 0 0.25rem;
      position: fixed;
      @include transformCenter(-50%, 0);
    }

    .van-tabs__nav {
      background: transparent;
    }
    .van-tabs__line {
      display: none;
    }
    .van-tabs__nav--line.van-tabs__nav--complete {
      padding-left: 0;
    }
    .bgTab {
      .van-tabs__wrap {
        // background: linear-gradient(to right, #e7e3fe, #d5e7fc);
        margin-bottom: 0.2rem;
        // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 0.12rem;
      }

      .van-tab--active {
      .van-tab__text {
        color: #F96491;
        font-weight: 400;
        font-size: .5rem;
      }
      }
      .van-tab__text {
        color: #2E2E2E;
        font-weight: 400;
        font-size: 0.3rem;
      }
    }
    .mine-tab {
      .van-tabs__nav--line {
        padding: 0;
      }
      .van-tabs__content {
        padding-top: 1rem;
      }
      .van-tab {

        height: 0.6rem;
        margin: 0.2rem 0;
      }
      .van-sticky {
        background: #fff;
      }
      .van-tabs__wrap {
        color: #333;
      }
      .van-tab--active {

        border-radius: 0.05rem;
        .van-tab__text {
          font-size: 0.32rem;
          color: $txtActive;
        }
      }

    }
    //漫画合集导航
    .comics-sub-tab{
      .van-tabs__wrap{
        width: 6.9rem;
        margin: 0 auto;
        background: #f6f5f9;
      }
      .van-tab{
        font-size: 0.32rem;
        padding: 0.16rem 0.35rem;
        margin: 0.1rem 0;
      }
      .van-tab--active{
        background: #fff;
        border-radius: 0.24rem;
        box-shadow: 0 0.5px 0.2rem rgba($color: #000000, $alpha: 0.1);
        .van-tab__text{
          &::after{
            display: none;
          }
        }
      }
      .van-tabs__line{
        display: none;
      }
    }
    //漫画列表导航
    .comics-list-tab{
      .van-tabs__wrap{
        height: 0.80rem;
        // box-shadow: 0 0.06rem 0.1rem rgba($color: #000000, $alpha: 0.1);
      }
      .van-tabs__nav--line{
        display: flex;
        justify-content: center;
      }
      .van-tab{
        flex: none;
        position: relative;
        font-size: .28rem;
        padding: 0 0.4rem;
        &:nth-child(3){
          border-right: 0;
        }
        &.van-tab--active{
          border-radius: 0;
          background: transparent;
          box-shadow: none;
          .van-tab__text{
            &::after{
              display: none;
            }
          }
        }
        &::after{
          position: absolute;
          right: 0;
          content: "";
          width: 0.02rem;
          height: 50%;
          // background: #ccc;
        }
        &:nth-child(3){
          &::after{
            display: none;
          }
        }
      }
    }
    //首页AV 选中文字变大
    .home-av-tab{
      .van-tab{
        flex: none;
        span{
          &::after{
            display: none;
          }
        }
        &.van-tab--active{
          font-size: .36rem;
        }
      }
      .van-sticky{
        background: #fff;
      }
    }
    //首页子页面tab
    .home-sub-tab{
      .van-tab{
        flex: none;
      }
      .van-sticky{
        top: 1rem;
        background: #f4f6fd;
      }
    }
  }
  .tab-main {
    background: transparent;
  }
}
</style>

