<template>
  <div class="share">
    <EBackTop :title="'分享邀请'" />
      <EGenerateImg ref="EGenerateImg" :link="userInfo.inviteUrl" :name='`来自${userInfo.nickName}的分享地址.png`' :config="config">
        <div class="share-main">
          <div class="top-num">
            累计分享人数：<span>{{ userInfo.invites }}</span>
          </div>
          <p class="tip-txt">邀请一位下载，即可获得1天VIP 无限领取</p>
          <p>{{ userInfo.inviteUrl }}</p>
        </div>
          </EGenerateImg>


  
    <div class="btn">
      <div @click="clickSaveImg">保存图片分享</div>
      <div v-clipboard:copy="userInfo.inviteUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">
        复制链接分享
      </div>
    </div>
    <div class="tip">
      <p>推广说明</p>
      <span>好友通过您的二维码或者推广链接下载APP,并启动后即算推广成功。</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'MineShare',
  components: {
  },
  data() {
    return {
      config:{
        qrSize: 200,
     },
      web: '',
      app: '',
      qrCode: '',
    }
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  methods: {
    onCopy() {
      this.$toast('复制成功')
    },
    onError() {
      this.$toast('不支持复制')
    },
    clickSaveImg() {
      this.$refs['EGenerateImg'].clickSaveImg();
    },

  }
}
</script>

<style lang="scss" scoped>
:deep(){
  .qrBg{
    width: 200px !important;
    height: 200px !important;
    left: 50%;
    top: 3.6rem;
    transform: translate(-50%,-50%);
  }
}
.share {
  padding: 0.2rem 0rem;
  font-size: 0.24rem;
}
.share-main {
  width: 6rem;
  height: 6.38rem;
  background: url('../../../assets/imgs/mine/share-bg.svg') no-repeat;
  background-size: 100% 100%;
  box-shadow: $shadow;
  font-size: 0.24rem;
  color: #fff;
 margin: auto;
  // @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: .3rem;

  .top-num {
    font-size: 0.3rem;
    font-weight: 600;
    span {
      color: #fff;
    }
  }
  
  .tip-txt {
    margin: -3.4rem 0 0.44rem 0;
    color: #fff;
    font-size: .26rem;
  }
  .code {
    padding: 0.26rem;
    background: #fff;
    box-shadow: $shadow;
  }
  .line {
    height: 0.01rem;
    width: 5rem;
    background: #5b5b6f;
    margin: 0.3rem 0;
  }
  p {
    margin: 0;
    font-size: 0.26rem;
  }
}
.btn {
  @include flexbox($jc: space-around, $ai: center, $fd: row, $fw: nowrap);
  margin: 0.3rem 0 0.34rem 0;
  padding: 0 0.75rem;
  div {
    background: $btnBg;
    border-radius: 0.45rem;
    width: 2.26rem;
    height: 0.68rem;
    text-align: center;
    line-height: 0.68rem;
    box-shadow: $shadow;
    color: #fff;
  }
}
.tip {
  padding: 0 0.75rem;
  p {
    font-size: 0.26rem;
    font-weight: 600;
  }
  span {
    color: #848494;
  }
}
</style>
