<template>
  <!-- <div
    class="session-main"
    :style="{ height }"
  > -->
  <div
    class="session-main"
     style="height: 100vh;"
     ref="scrollContainer"
  >
    <EBackTop
      :open-fallback="true"
      :title="friendInfo.nickName"
      class="back"
    />
    <van-pull-refresh
      v-model="refreshing"
      pulling-text=""
      success-text=""
      loosing-text="加载消息中..."
      pull-distance="40"
      head-height="40"
      @refresh="onRefresh()"
    >
      <div class="conetnt-list message-listWrap" ref="cBox">
        <div
          v-for="(item, index) in msgList"
          :key="index"
          class="msg-inner-item"
          :class="{ right: item.fromId === userInfo.id }"
        >
          <DecryptImg
            :class="{
              avatarLeft: item.fromId !== userInfo.id,
              avatarRight: item.fromId === userInfo.id
            }"
            @clickImg="$router.push(`/uploader/person/${item.fromId}`)"
            :imgURL="item.fromId === userInfo.id ? userInfo.avatarUrl : item.avatar"
          >
          </DecryptImg>

          <div
            class="msg-item msg-align-left"
            :class="{
              reply: item.fromId !== userInfo.id,
              sendMsg: item.fromId === userInfo.id,
              'msg-align-left': item.fromId !== userInfo.id,
              'msg-align-right': item.fromId === userInfo.id
            }"
          >
            <div
              v-if="!item.picture"
              class="msg-item-content"
              :class="{
                'msg-border-ridius-right': item.fromId === userInfo.id,
                'msg-color-right': item.fromId === userInfo.id,
                'msg-border-ridius-left': item.fromId !== userInfo.id,
                'msg-color-left': item.fromId !== userInfo.id
              }"
              style=""
            >
              <p>
                {{ item.text }}
              </p>
            </div>
            <div
              v-else
              class="msg-item-picture"
              :class="{
                'msg-border-ridius-right': item.fromId === userInfo.id,
                'msg-color-right': item.fromId === userInfo.id,
                'msg-border-ridius-left': item.fromId !== userInfo.id,
                'msg-color-left': item.fromId !== userInfo.id
              }"
              style=""
            >
              <!-- <DecryptImg
                ref="refImg"
                @click.native="showImagePreview(item.picture)"
                :imgURL="item.picture"
              > </DecryptImg> -->
              <EDecryptImg
                ref="refImg"
                @click.native="showImagePreview(item.picture)"
                :imgCDN="imgCDN"
                class="user-header"
                :imgURL="item.picture"
              />
            </div>

            <div
              class="msg-item-time"
              :class="{
                'msg-item-time-right': item.fromId === userInfo.id,
                'msg-item-time-left': item.fromId !== userInfo.id
              }"
            >
              {{ item.createdAt | timeDiff }}
            </div>
          </div>
        </div>
        <div id="scrollIntoViewChat"></div>
      </div>
    </van-pull-refresh>
    <div class="comment-text">
      <div class="uploadImg-wrap">
        <van-uploader
          ref="Uploader"
          class="btn"
          :after-read="onReadImg"
          multiple
          :max-count="1"
          accept="image/*"
        > </van-uploader>
      </div>
      <van-field
        v-model="text"
        class="send-input"
        placeholder="请输入你的文字内容"
      />

      <div
        class="send-text"
        @click="sendMsg"
      >发送</div>
    </div>
    <!-- <div style="position: fixed;left: 0;bottom: 2rem;background-color: pink;width:1rem;height: .4rem;" @click="hong"></div> -->
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { canBuy } from '@/utils/utils_tools'
import { getMsInfo, getMsSend } from 'api/user'
import { uploadImgs } from 'api/home'

export default {
  name: 'chatIM',
  data() {
    return {
      id: 0,
      nickName: '',
      msgList: [],
      text: '',
      picture: null,
      pageNum: 1,
      pageSize: 20,
      refreshing: false,
      hasPageNumSuccess: true,
      isFree: true, //是否免费
      upLoadImgs: [],
      // 聊天对象信息
      friendInfo: {
        id: '',
        nickName: '',
        avatar: ''
      },
      height: '100vh',
      times: null,
      lastLengthTime: '',
      noUpdate: false,
      boxHeight: 0,
    }
  },
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      this.computeHeight()
      await this.initUserData()
      // await this.getMessageInfo()
      await this.getMessageInfo()
      this.time()
      if (!this.isFree) {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '提示',
          content: '1金币解锁与该用户24小时免费畅聊',
          type: 'up_post',
          ok: '确认',
          img: '',
          cancel: '',
          position: 'center',
          showBalance: false,
          cb: async returnData => {
            // this.$router.push('/mine/newRecharge?type=1')
          },
        }
      }
      this.fn_scrollView('start')
    })

  },
  destroyed(){
    clearTimeout(this.times)
  },
  methods: {
    time() {
      this.times = setTimeout(() => {
        this.getMessageInfo('update')
        this.time()
      }, 5000);

    },

    computeHeight() {
      let height = window.innerHeight
      const fontSize = +document.documentElement.style.fontSize.replace('px', '')
      // this.height = (height - 60) / fontSize + 'rem'
    },
    async onReadImg(files) {
      let param = new FormData();
      const maxSize = 1024 * 1024 * 5; // 最大文件大小限制，这里设为5MB
      if (files instanceof Array) {
        for (let item of files) {
          // 检查文件大小
          if (item.file.size > maxSize) {
            this.$toast(`文件大小不能超过 ${maxSize / (1024 * 1024)} MB`);
            return;
          }
          param.append('upload[]', item.file);
        }
      } else {
        // // 检查文件大小
        if (files.file.size > maxSize) {
          this.$toast(`文件大小不能超过 ${maxSize / (1024 * 1024)} MB`);
          return;
        }
        param.append('upload[]', files.file);
      }

      const res = await uploadImgs(param);
      if (res.code === 200) {
        this.upLoadImgs = res.data.paths[0];
        // this.upLoadImgs = res.data.paths;
        this.sendPictureMsg()
      } else {
        this.$toast('图片上传失败！！');
      }
    },

    // 选择图片
    // onReadImg(file) {
    //   file = Array.isArray(file) ? file : [file]
    //   if (this.upLoadImgs && file.length + this.upLoadImgs.length > 1) {
    //     return this.$toast('最多传1张')
    //   }
    //   this.upLoadImgs.push(...file)
    //   this.sendPictureMsg()
    // },
    // 初始化聊天对象数据
    initUserData() {
      this.friendInfo.id = +this.$route.params.id;
      this.friendInfo.nickName = this.$route.query.nickName
    },
    dates(t) {
      return new Date(t).getTime()
    },
    // hong() {
    //   console.log('22')
    //   const { scrollContainer } = this.$refs
    //   scrollContainer.scrollTop = this.boxHeight
    // },
    //获取消息列表
    async getMessageInfo(type) {
      // if (!query) {
      //   query = {
      //     pageNum: this.pageNum,
      //     pageSize: this.pageSize,
      //     peerId: this.friendInfo.id
      //   }
      // }
      let query = {
          pageNum: type == 'update' ? 1 : this.pageNum,
          pageSize: this.pageSize,
          peerId: this.friendInfo.id
        }
      const res = await getMsInfo(query)
      if (res.code === 200) {
        this.refreshing = false
        this.isFree = res.data.isFree

        let t2Time = ''
        if(res.data && res.data.messageList && res.data.messageList.length) {
          if(this.noUpdate) {
            let item = res.data.messageList[0]
            this.lastLengthTime = item.createdAt
            t2Time = this.lastLengthTime
            this.msgList[this.msgList.length -1].createdAt = item.createdAt
            this.noUpdate = false
            return
          }
          t2Time = this.dates(res.data.messageList[0].createdAt)
          // if(t2Time === this.lastLengthTime ) {
          //   // let list = res.data.messageList
          // }
          if(t2Time !== this.lastLengthTime) {
            let data = JSON.parse(JSON.stringify(res.data.messageList))
            let list = data.reverse()

            if(type == 'update') {
              this.noUpdate = false
              let arr = []
              this.msgList.length && data.forEach(at1 => {
                let flag = false
                this.msgList.forEach(at2 => {
                  if(this.dates(at1.createdAt) === this.dates(at2.createdAt)) {
                    flag = true
                  }
                })
                if(!flag) arr.push(at1)
              })
              if(arr.length) {
                this.msgList = [...this.msgList, ...arr]
                this.fn_scrollView()
              }
              this.$nextTick(() => {
                const { cBox } = this.$refs
                this.boxHeight = cBox.offsetHeight
              })
            } else {
              this.noUpdate = false
              this.msgList = [...list, ...this.msgList]
              if(this.msgList.length) {
                this.lastLengthTime = this.dates(this.msgList[this.msgList.length -1].createdAt)
              }
              if(type == 'refresh') {
                this.$nextTick(() => {
                  const { cBox, scrollContainer } = this.$refs
                  let height = cBox.offsetHeight
                  let scrollHeight = height - this.boxHeight
                  scrollContainer.scrollTop = scrollHeight
                  this.boxHeight = height
                })
              } else {
                this.fn_scrollView('start')
                this.$nextTick(() => {
                  const { cBox } = this.$refs
                  this.boxHeight = cBox.offsetHeight
                })
              }
            }
          }
        }


      } else {
        this.refreshing = false
        this.$toast(res.tip)
      }
    },
    //上拉刷新
    onRefresh() {
      ++this.pageNum
      this.getMessageInfo('refresh')

    },
    //发送消息
    async sendMsg() {
      if (canBuy(this.$store, 100)) {
        if (!this.text) return this.$toast('请输入消息')
        const res = await getMsSend({
          text: this.text,
          picture: '',
          peerId: this.friendInfo.id
        })

        if (res.code === 200) {
          this.msgList.push({
            createdAt: Date.now(),
            text: this.text,
            picture: '',
            fromId: this.userInfo.id
          })
          this.$toast('发送成功')
          this.noUpdate = true
          this.text = ''
          this.$nextTick(() => {
            this.fn_scrollView()
          })
        } else {
          this.$toast(res.tip)
        }
      } else {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '您的余额不足',
          content: '1金币解锁与该用户24小时免费畅聊',
          type: 'up_post',
          ok: '前往充值',
          img: '',
          cancel: '狠心离开',
          position: 'center',
          showBalance: false,
          cb: async returnData => {
            this.$router.push('/mine/newRecharge?type=0')
          },
        }
        // let setting = {
        //     title: '您的余额不足',
        //     msg: '1金币解锁与该用户24小时免费畅聊',
        //     confirmButtonText: '前往充值',
        //     cancelButtonText: '狠心离开',
        // }
        // ComPopupModel('textBig', setting, async (datas) => {
        //   if (datas.confirm) {
        //     this.$router.push('/mine/my-wallet')
        //   }
        // })
      }
    },
    // 图片预览
    showImagePreview() {
      let imgArr = []
      const domArr = this.$refs['refImg']
      domArr.forEach(item => {
        imgArr.push(item.realUrl)
      })

      ImagePreview({
        images: imgArr, // 需要预览的图片 URL 数组
        showIndex: true, // 是否显示页码
        loop: true, // 是否开启循环播放
        startPosition: 0 // 图片预览起始位置索引
      })
    },
    //发送图片消息
    async sendPictureMsg() {
      const res = await getMsSend({
        text: '',
        picture: this.upLoadImgs,
        peerId: this.friendInfo.id
      })
      if (res.code === 200) {
        this.msgList.push({
          createdAt: Date.now(),
          picture: this.upLoadImgs,
          text: this.text,
          fromId: this.userInfo.id
        })
        this.noUpdate = true
        this.$toast('发送成功')

        this.text = ''
        this.upLoadImgs = []
        this.$nextTick(() => {
          this.fn_scrollView()
        })
      } else {
        this.$toast(res.tip)
      }
    },
    //滚动
    fn_scrollView(type) {
      if (type === 'start') {
        this.$nextTick(() => {
          document.querySelector('.message-listWrap').lastChild.scrollIntoView({})
        })
        return
      }
      this.$nextTick(() => {
        document.querySelector('.message-listWrap').lastChild.scrollIntoView({
          behavior: 'smooth'
        })
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.session-main {
  overflow-y: auto;
  background: #000;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;top:0;
  .back {
    position: fixed;
    top: 0;
    z-index: 2;
  }

  .conetnt-list {
    // padding: 1.4rem 0.3rem 2.2rem;
    padding: 1.8rem 0.3rem 2.2rem;
    min-height: 4rem;
    box-sizing: border-box;
    -webkit-user-select: text;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

// 输入框
.comment-text {
  position: fixed;
  width: 100%;
  display: flex;
  //align-items: center;
  justify-content: space-between;
  padding: 0.28rem;
  font-size: 0.32rem;
  bottom: 0;
  box-sizing: border-box;
  background-color: #292929;
  border: 1px solid #535353;
  box-shadow: 0px -4px 6px 0px #0000001a;
  z-index: 1000;
  .send-input {
    height: 0.6rem;
    line-height: 0.6rem;
    ::v-deep {
      .van-field__body {
        padding-left: 0.2rem;
      }

      .field-placeholder-text-color {
        color: #9493b1;
      }

      .van-field__control {
      }
    }
  }

  ::v-deep {
    .van-cell {
      padding: 0 !important;
      border-radius: 0.15rem;
    }

    .van-cell::after {
      display: none;
    }
  }

  .uploadImg-wrap {
    margin-right: 0.36rem;
    flex-shrink: 0;
    width: 0.57rem;
    height: 0.57rem;
    overflow: hidden;
    background: url('../../assets/imgs/upload-img.png') center center/100% 100% no-repeat;
    .btn {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .send-text {
    flex-shrink: 0;
    margin-left: 0.36rem;
    height: 0.72rem;
    padding: 0rem 0.2rem;
    border-radius: 0.16rem;
    background: linear-gradient(91deg, #652323 6.02%, #320707 100.68%);
    line-height: 0.72rem;
    color: #fff;
  }
}

.msg-inner-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  //align-items: center;
  width: 100%;
  &.right {
    flex-direction: row-reverse;
    .msg-item {
      padding-right: 0.24rem;
    }
  }
}

.avatarLeft {
  width: 0.76rem !important;
  height: 0.76rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc;
  background-size: contain;
  //position: absolute;
  //left: 0;
}

.avatarRight {
  flex-shrink: 0;
  width: 0.76rem !important;
  height: 0.76rem;
  overflow: hidden;
  //position: absolute;
  //right: 0;
  border-radius: 50%;
  background-color: #ccc;
  background-size: contain;
}

.msg-item {
  margin-top: 0.24rem;
  width: 100%;
  padding-left: 0.24rem;
  margin-bottom: 0.25rem;
  box-sizing: border-box;
  display: flex;

  flex-flow: column nowrap;

  .msg-item-content {
    // min-width: 3.5rem;
    max-width: 3.5rem;
    padding: 0.15rem 0.15rem;
    border-radius: 0.08rem;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    word-break: break-all;

    p {
      box-sizing: border-box;
      margin: 0 0;
      padding: 0 0;
      width: 100%;
      font-size: 0.28rem;
    }

    .time {
      display: block;
      margin-bottom: 0.15rem;
      color: rgb(250, 250, 250);
      text-align: right;
      padding: 0.05rem 0.12rem;
      font-size: 0.18rem;
    }

    .msg-item-footer {
      width: 100%;
      text-align: right;
    }

    .msg-item-name {
      font-size: 0.12rem;
      color: #999;
      margin-bottom: 0.05rem;
    }
  }
  .msg-item-time {
    color: #fff;
    margin-top: 0.05rem;
    opacity: 0.7;
  }
  .msg-item-picture {
    max-width: 4.86rem;
    min-height: 2rem;
    max-height: 4.86rem;
    border-radius: 0.16rem;
    overflow: hidden;
  }

  .msg-border-ridius-right {
    border-radius: 0.45rem 0.16rem 0.45rem 0.45rem;
  }

  .msg-border-ridius-left {
    border-radius: 0.08rem 0.4rem 0.4rem 0.4rem;
  }
}

.msg-align-left {
  align-items: flex-start;
}

.msg-align-right {
  align-items: flex-end;
}

.reply {
  justify-content: flex-end;
  overflow: hidden;

  .msg-item-content {
    align-items: flex-end;
  }
}

.sendMsg {
  overflow: hidden;
  justify-content: center;
  min-width: 100%;

  .msg-item-content {
    background-color: rgb(83, 80, 80);
    // width: 100%;

    p {
      width: 100%;
      color: #fff;
    }
  }
}

.msg-color-right {
  background-color: #292929 !important;
}

.msg-color-left {
  color: #ffffff;
  background: linear-gradient(91.4deg, rgba(101, 35, 35, 0.5) 6.02%, rgba(50, 7, 7, 0.5) 100.68%);
}
</style>
