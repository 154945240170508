<template>
  <div class="popup-list">
    <van-popup
      @click-overlay="fn_close(popupMsg.type == 'lotteryType' ? 'yes' : '')"
      class="popup-round"
      :class="popupMsg.type === 'lottery_ok' ? 'lottery_ok_pop' : ''"
      v-model="popupMsg.show"
      get-container="body"
      :position="popupMsg.position"
      round
    >
      <!--small-text 提示-->
      <div class="small-text" v-if="popupMsg.type == 'small-text'">
        <div class="title">{{ popupMsg.title }}</div>
        <div class="text">
          {{ popupMsg.content }}
        </div>
        <div class="btn-wrap">
          <div @click="fn_ok(true)" v-if="popupMsg.ok">{{ popupMsg.ok }}</div>
          <div @click="fn_close('yes')" v-if="popupMsg.cancel">{{ popupMsg.cancel }}</div>
        </div>
      </div>
      <!--up_post 提示-->
      <div class="up_post" v-if="popupMsg.type == 'up_post'">
        <div class="title">
          <span>{{ popupMsg.title }}</span>
          <img @click="fn_close" src="@/assets/imgs/trade/close.png" alt="" />
        </div>
        <div class="text">
          <div class="text-content" v-if="popupMsg.content">{{ popupMsg.content }}</div>
          <div class="text-price" v-if="popupMsg.price">
            <img src="@/assets/imgs/mine/gold.png" alt="" />
            {{ popupMsg.price | changeGold }}金币保证金
          </div>
          <div class="text-balance" v-if="popupMsg.showBalance">
            <span>当前余额</span>
            {{ myBalance }}金币
          </div>
          <p class="text-tip" v-if="popupMsg.tip">{{ popupMsg.tip }}</p>
        </div>
        <div class="pay_btn">
          <div @click="fn_ok(true)" v-if="popupMsg.ok">{{ popupMsg.ok }}</div>
          <div @click="fn_close('yes')" v-if="popupMsg.cancel">{{ popupMsg.cancel }}</div>
        </div>
      </div>
      <!--pay_post 1111111111 提示-->
      <div class="up_post pay_post" v-if="popupMsg.type == 'pay_post'">
        <div class="title">
          <span>{{ popupMsg.title }}</span>
          <img @click="fn_close" src="@/assets/imgs/trade/close.png" alt="" />
        </div>
        <div class="text">
          <div class="text-balance" v-if="popupMsg.showBalance">
            <span>当前余额</span>
            {{ myBalance }}金币
          </div>
          <div class="text-content" v-if="popupMsg.content">{{ popupMsg.content }}</div>
          <EDecryptImg
            v-if="popupMsg.img"
            :imgCDN="imgCDN"
            :imgURL="popupMsg.img ? popupMsg.img : ''"
            class="popupMsg_img"
          >
          </EDecryptImg>
          <div class="text-price" v-if="popupMsg.price">
            <img src="@/assets/imgs/mine/gold.png" alt="" />
            {{ popupMsg.price | changeGold }}金币
          </div>
        </div>
        <div class="pay_btn">
          <div @click="fn_ok(true)" v-if="popupMsg.ok">{{ popupMsg.ok }}</div>
          <div @click="fn_close" v-if="popupMsg.cancel">{{ popupMsg.cancel }}</div>
        </div>
        <div class="disclaimer-tips" v-if="popupMsg.isShowTips">本次交易与平台无关</div>
      </div>
      <!--play_post 提示-->
      <div class="up_post play_post" v-if="popupMsg.type == 'play_post'">
        <div class="text">
          <img src="@/assets/imgs/upHome/warnIcon.png" alt="" class="warnImges" />
          <div class="text-content" v-if="popupMsg.content">{{ popupMsg.content }}</div>
        </div>
        <div class="pay_btn">
          <div @click="fn_ok(true)" v-if="popupMsg.ok">{{ popupMsg.ok }}</div>
          <div @click="fn_close('yes')" v-if="popupMsg.cancel">{{ popupMsg.cancel }}</div>
        </div>
      </div>
      <div class="up_post comic_pay_post" v-if="popupMsg.type == 'comic_pay_post'">
        <div class="text">
          <img
            src="@/assets/imgs/homec/vector.png"
            v-if="myBalance > (popupMsg.price / 100).toFixed(2)"
            alt=""
            class="warnImges"
          />
          <template v-else>
            <img src="@/assets/imgs/homec/pay_warn.png" alt="" class="warnImges" />
            <div class="warn-tilte">对不起，您的余额不足</div>
          </template>

          <div class="warn-tilte">
            购买整本漫画需
            <span>{{ popupMsg.price | changeGold }}金币</span>
            解锁观看
          </div>
          <div class="balance">
            <span>可用余额</span>
            {{ myBalance }}金币
          </div>
        </div>
        <div class="pay_btn">
          <div @click="$router.push('/mine/newRecharge?type=0')" v-if="myBalance < (popupMsg.price / 100).toFixed(2)">
            前往充值
          </div>
          <div @click="fn_ok(true)" v-else>{{ popupMsg.ok }}</div>
        </div>
      </div>
      <!--waring 提示-->
      <div class="waring_post" v-if="popupMsg.type == 'waring_post'">
        <div class="btn-wrap">
          <img @click="fn_close('yes')" src="@/assets/imgs/leave.png" alt="" />
          <img @click="fn_ok(true)" src="@/assets/imgs/agree.png" alt="" />
        </div>
      </div>
      <!--waring 提示 02-->
      <div class="waring_post_02" v-if="popupMsg.type == 'waring_post_02'">
        <img src="@/assets/imgs/waring_02.png" alt="" />
        <p>该板块内容极度,心理承受弱者请自行离开,切记挑战自己好奇害死猫,请您确认还要继续吗?</p>
        <div class="btn-wrap">
          <span @click="fn_close('yes')">离开</span>
          <span @click="fn_ok(true)">确认</span>
        </div>
      </div>
      <div class="vip_waring" v-if="popupMsg.type == 'vip_waring'">
        <img src="@/assets/imgs/vip_waring.png" alt="" />
        <div>您当前还没有开通<span>商家卡</span> ，请前往开通</div>
        <div class="btn-wrap" @click="fn_ok(true)">前往开通</div>
      </div>
      <div class="community-post" v-if="popupMsg.type == 'community-post'">
        <div>
          <img src="@/assets/imgs/community/coin-not-enough.png" alt="" />
        </div>
        <p>对不起,您的余额不足</p>
        <div>
          {{ popupMsg.price | changeGold }}
          金币
        </div>
        <div>
          <span>可用余额</span>
          <span>{{ popupMsg.balance | changeGold }}金币</span>
        </div>
        <div>
          <div @click="$router.push('/mine/newRecharge?type=0')">前往充值</div>
          <div @click="$router.push('/mine/agent')">做任务得VIP</div>
        </div>
        <div class="disclaimer-tips" v-if="popupMsg.isShowTips">本次交易与平台无关</div>
      </div>
      <div class="community-post community-post-enough" v-if="popupMsg.type == 'community-post-enough'">
        <div>
          <img src="@/assets/imgs/community/coin-not-enough.png" alt="" />
        </div>
        <p>请确认支付金币</p>
        <div>
          {{ popupMsg.price | changeGold }}
          金币
        </div>
        <div>
          <span>可用余额</span>
          <span>{{ popupMsg.balance | changeGold }}金币</span>
        </div>
        <div>
          <div class="fn-ok-btn" @click="fn_ok(true)" v-if="popupMsg.ok">{{ popupMsg.ok }}</div>
        </div>
        <div class="disclaimer-tips" v-if="popupMsg.isShowTips">本次交易与平台无关</div>
      </div>
      <div class="change-face-post community-post" v-if="popupMsg.type == 'change-face-post'">
        <div>
          <img src="@/assets/imgs/community/coin-not-enough.png" alt="" />
        </div>
        <p>对不起,您的余额不足</p>
        <div>
          {{ popupMsg.price | changeGold }}
          金币
        </div>
        <div>
          <span>可用余额</span>
          <span>{{ popupMsg.balance | changeGold }}金币</span>
        </div>
        <div class="btn">
          <div @click="$router.push('/mine/newRecharge?type=0')">前往充值</div>
          <div v-if="popupMsg.cancel" class="fn-ok-btn" @click="fn_close('yes')">{{ popupMsg.cancel }}</div>
        </div>
      </div>
      <div v-if="popupMsg.type == 'change-face-enough'" class="change-face-enough-new">
        <div class="top">
          <div class="box">
            <div>扣除</div>
            <div>{{ popupMsg.price | changeGold }}金币</div>
            <div>
              <span>可用余额</span>
              <span>{{ popupMsg.balance | changeGold }}金币</span>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div v-if="popupMsg.ok" class="fn-ok-btn" @click="fn_ok(true)">{{ popupMsg.ok }}</div>
          <div v-if="popupMsg.cancel" class="fn-ok-btn" @click="fn_close('yes')">{{ popupMsg.cancel }}</div>
        </div>
      </div>
      <!-- 抽奖弹窗 -->
      <div class="lottery" v-if="popupMsg.type == 'lotteryType'">
        <img v-if="popupMsg.img" :src="popupMsg.img" alt="" />
        <div class="text-content" v-if="popupMsg.content">{{ popupMsg.content }}</div>
        <div class="ok_btn" @click="fn_ok(true)" v-if="popupMsg.ok">{{ popupMsg.ok }}</div>
        <div class="cancel_btn" @click="fn_close('yes')" v-if="popupMsg.cancel">{{ popupMsg.cancel }}</div>
      </div>
      <!-- 抽奖中奖弹窗 -->
      <div class="lottery_ok" v-if="popupMsg.type == 'lottery_ok'">
        <div class="item_img">
          <EDecryptImg :imgCDN="imgCDN" class="lootery_img" :imgURL="popupMsg.img ? popupMsg.img : ''"> </EDecryptImg>
          <div>
            <p class="content" v-if="popupMsg.content">{{ popupMsg.content }}</p>
            <p class="desc" v-if="popupMsg.desc">{{ popupMsg.desc }}</p>
          </div>
        </div>

        <div class="ok_btn" @click="fn_ok(true, 'lottery')" v-if="popupMsg.ok">{{ popupMsg.ok }}</div>
        <div class="cancel_btn" @click="fn_close()" v-if="popupMsg.cancel">{{ popupMsg.cancel }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    // position: {
    //   type: String,
    //   default() {
    //     return 'bottom'
    //   },
    // }
    img: {
      type: String,
      default() {
        return 'bottom'
      }
    }
  },
  computed: {
    // 获取个人余额
    myBalance({ $store }) {
      return $store.getters['getUserInfo'].balance / 100
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },

  data() {
    return {
      popupMsg: {
        show: false,
        title: '',
        content: '',
        type: 'text',
        cancel: '',
        ok: '',
        position: 'center',
        cb: null,
        price: 0,
        showBalance: false,
        tip: '',
        close: null,
        img: '',
        desc: ''
      }
    }
  },
  mounted() {},
  methods: {
    //取消、关闭
    fn_close(type) {
      this.popupMsg.show = false

      if (type === 'yes' || this.popupMsg.type === 'waring_post' || this.popupMsg.type === 'waring_post_02') {
        this.popupMsg.close(false)
      }
    },
    //确定
    fn_ok(status, type) {
      this.popupMsg.cb(status)
      if (!type) {
        this.popupMsg.show = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.community-post {
  border-radius: 0.24rem;
  // border: 2px solid #fff;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.64rem 0.58rem;

  img {
    width: 0.72rem;
  }

  > p {
    color: #000;
    font-family: PingFang SC;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0.34rem 0 0.16rem;
  }

  > div {
    &:nth-of-type(2),
    &:nth-of-type(3) {
      color: #ffcf86;
      font-family: PingFang HK;
      font-size: 0.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &:nth-of-type(3) {
      margin: 0.16rem 0 0.56rem;
      display: flex;

      > span:nth-child(1) {
        border-radius: 0.04rem;
        background: rgba(255, 207, 134, 0.15);
        font-size: 0.24rem;
        padding: 0.04rem 0.1rem;
        margin-right: 0.1rem;
      }
    }

    &:nth-of-type(4) {
      color: #333;
      font-family: PingFang HK;
      font-size: 0.28rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;

      > div {
        padding: 0.14rem 0.58rem 0.14rem 0.57rem;
        border-radius: 0.56rem;
        background: #ffcf86;
        white-space: nowrap;

        &:nth-child(2) {
          background-color: #ff669d;
          margin-left: 0.32rem;
          color: #fff;
        }
      }
    }
  }

  .disclaimer-tips {
    color: #f77;
    font-family: PingFang HK;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.38rem;
  }
}

.community-post-enough {
  width: 6rem;

  .fn-ok-btn {
    width: 3.92rem;
    text-align: center;
  }
}

.change-face-post {
  width: 6rem;

  .btn {
    > div {
      text-align: center;
    }
  }
}

.change-face-enough-new {
  background-color: #292929;
  width: 5.38rem;
  height: 5.16rem;
  border-radius: 0.12rem;
  position: relative;
  .top {
    padding-top: 0.38rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border-bottom: 1px solid #f0f0f0;
    > .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      > div {
        text-align: center;
        font-family: 'PingFang SC';
        font-size: 0.28rem;
        &:nth-of-type(1) {
          margin: 0.34rem 0 0.16rem;
          color: #fff;
          font-size: 0.32rem;
        }
        &:nth-of-type(2) {
          color: #ffcf86;
          font-size: 0.3rem;
        }
        &:nth-of-type(3) {
          margin-top: 0.2rem;
          @include flex-align-center;
          color: #ffcf86;
          > span:first-child {
            padding: 0.04rem 0.01rem 0.04rem 0.01rem;
            border-radius: 0.04rem;
            background: rgba(255, 207, 134, 0.15);
            margin-right: 0.1rem;
          }
          > span:last-child {
            font-size: 0.28rem;
          }
        }
      }
    }
  }
  .close-icon {
    img {
      position: absolute;
      right: 0.24rem;
      top: 0.24rem;
      width: 0.36rem;
      height: 0.36rem;
      width: 0.36rem;
    }
  }
  .bottom {
    display: flex;
    color: #333;
    font-family: 'PingFang SC';
    font-size: 0.28rem;
    font-weight: 500;
    padding: 0.4rem 0 0 0.32rem;
    > div {
      display: flex;
      padding: 0.16rem 0.48rem;
      justify-content: center;
      align-items: center;
      border-radius: 2rem;
      background: $btnBg;
      &:nth-child(2) {
        margin-left: 0.36rem;
      }
    }
  }
}

.popup-round {
  max-width: $pcMaxWidth;
}

.up_post {
  // border-radius: 0.16rem;
  background: #fff;
  width: 5.6rem;
  // height: 4.82rem;
  flex-shrink: 0;
  font-size: 0.24rem;
  text-align: center;

  .title {
    padding: 0.24rem 0;
    border-bottom: 0.01rem solid #ddd;
    position: relative;
    font-size: 0.34rem;

    img {
      width: 0.32rem;
      height: 0.32rem;
      position: absolute;
      top: 50%;
      right: 0.38rem;
      transform: translate(0, -50%);
    }
  }

  .text-price {
    color: #ffcf86;
    @include flexbox($jc: center);
    margin-top: 0.32rem;

    img {
      width: 0.38rem;
      height: 0.38rem;
      margin-right: 0.16rem;
    }
  }

  .text-balance {
    @include flexbox($jc: center);
    color: #ffd594;
    font-size: 0.34rem;
    font-weight: 500;
    margin: 0.24rem 0;

    span {
      border-radius: 0.08rem;
      background: #fff5e7;
      padding: 0.04rem 0.08rem;
      font-weight: 400;
      font-size: 0.24rem;
      margin-right: 0.16rem;
    }
  }

  .text-tip {
    font-size: 0.26rem;
  }

  .btn-wrap {
    padding: 0.2rem 0;
    @include flexbox($jc: center);

    div {
      border-radius: 0.32rem;

      &:first-child {
        width: 40%;
        height: 0.72rem;
        flex-shrink: 0;
        line-height: 0.72rem;
        background: $btnBg;
        color: #333;
        margin-right: 0.26rem;
      }

      &:last-child {
        width: 40%;
        height: 0.72rem;
        flex-shrink: 0;
        line-height: 0.72rem;
        background: linear-gradient(91deg, #652323 6.02%, #320707 100.68%);
      }
    }
  }
}

.play_post {
  padding: 0.63rem 0.2rem;

  .btn-wrap {
    padding: 0;

    div {
      width: 4.2rem !important;
      margin-right: 0;
    }
  }
}

.warnImges {
  width: 0.72rem;
  height: 0.72rem;
  margin: 0 auto;
}

.text-content {
  font-size: 0.3rem;
  margin: 0.44rem 0;
}

.pay_post {
  .disclaimer-tips {
    color: #f77;
    font-family: PingFang HK;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.38rem;
  }

  .text-price {
    color: #ffcf86;
    font-size: 0.32rem;
    font-weight: 500;
  }

  .popupMsg_img {
    height: 4rem;
    width: 3rem;
    margin: 0.2rem auto;
  }

  .btn-wrap {
    @include flexbox($fd: column);

    div {
      border-radius: 0.32rem;

      &:first-child {
        margin-right: 0;
        border-radius: 0.32rem;
        background: #00f6f8;
      }

      &:last-child {
        margin-top: 0.24rem;
        border-radius: 0.32rem;
        border: 2px solid #fff;
        background: transparent;
      }
    }
  }
}

.waring_post {
  background: url('../../assets/imgs/waring_bg.png') no-repeat;
  background-size: cover;
  width: 5.6rem;
  height: 6rem;
  @include flexbox($jc: center, $ai: flex-end);

  .btn-wrap {
    @include flexbox($jc: space-around);
    margin-bottom: 0.2rem;
  }

  img {
    height: 0.89rem;
    margin-right: 0.2rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.waring_post_02 {
  // background: url('../../assets/imgs/waring_02.png') no-repeat;
  // background-size: cover;
  width: 5.6rem;
  height: 4.2rem;
  // @include flexbox($jc: center,$fd:column  );
  font-size: 0.3rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  p {
    padding: 0.2rem;
    padding-top: 1.3rem;
    text-align: center;
  }

  .btn-wrap {
    @include flexbox($jc: space-around);
    margin-top: 0.3rem;

    span {
      // margin-right: 0.6rem;
      &:first-child {
        border-radius: 0.64rem;
        background: $btnBg;
        color: #333;
        padding: 0.12rem 0.64rem;
      }

      &:last-child {
        border-radius: 0.56rem;
        background: linear-gradient(91deg, #bc1f1f 6.02%, #840101 100.68%);
        padding: 0.12rem 0.64rem;
      }
    }
  }
}

.vip_waring {
  border-radius: 0.24rem;
  background: #292929;
  width: 5.6rem;
  text-align: center;
  color: #fff;
  font-family: PingFang HK;
  font-size: 0.28rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0.63rem;

  span {
    color: #00f6f8;
    font-family: PingFang HK;
    font-size: 0.28rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  img {
    width: 0.72rem;
    height: 0.72001rem;
    margin: 0.63rem 0 0.4rem 0;
  }

  .btn-wrap {
    border-radius: 0.56rem;
    background: $btnBg;
    color: #333;
    width: 4.2rem;
    padding: 0.13rem 1.455rem 0.14rem 1.465rem;
    margin: 0.41rem auto 0 auto;
  }
}

.lottery {
  width: 5.6rem;
  color: #905353;
  font-size: 0.34rem;
  text-align: center;
  padding: 0.65rem 0.6rem;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .text-content {
    margin: 0 0 0.4rem 0;
  }
}

.ok_btn {
  border-radius: 0.36rem;
  background: #ffe49e;
  box-shadow: 0px 4px 10.1px 0px rgba(0, 0, 0, 0.05);
  width: 4.52rem;
  height: 0.8rem;
  line-height: 0.8rem;
  margin-bottom: 0.32rem;
  font-size: 0.28rem;
  text-align: center;
  color: #905353;
}

.cancel_btn {
  border-radius: 0.96rem;
  background: rgba(0, 0, 0, 0.55);
  width: 4.52rem;
  height: 0.8rem;
  color: #fff;
  line-height: 0.8rem;
  font-size: 0.28rem;
  text-align: center;
}

// 中奖弹窗
.lottery_ok_pop {
  background: transparent;
}

.lottery_ok {
  background: url('../../assets/imgs/index/luckyDraw/draw_bg.png') no-repeat;
  background-size: cover;
  width: 5.6rem;
  height: 7.67rem;
  padding: 2.88rem 0.6rem 0 0.6rem;
  .item_img {
    @include flexbox();
    margin-bottom: 0.6rem;
    text-align: center;
    .content {
      font-size: 0.36rem;
    }

    .desc {
      font-size: 0.32rem;
      color: #ffe9a1;
    }
  }

  .lootery_img {
    width: 1.6rem;
    height: 2rem;
    flex-shrink: 0;
  }
}

.pay_btn {
  padding: 0.2rem 0;
  @include flexbox($jc: center);

  div {
    border-radius: 0.32rem;

    &:first-child {
      width: 40%;
      height: 0.72rem;
      flex-shrink: 0;
      line-height: 0.72rem;
      background: $btnBg;
      color: #fff;
      margin-right: 0.26rem;
    }

    &:last-child {
      width: 40%;
      height: 0.72rem;
      flex-shrink: 0;
      line-height: 0.72rem;
      background: #ffcf86;
    }
  }
}

.comic_pay_post {
  border: 1px solid #ff7294;
  border-radius: 16px;
  .text {
    padding-top: 0.4rem;
    .warnImges {
      width: 0.96rem;
      height: 0.96rem;
      margin-bottom: 0.3rem;
    }
    .warn-tilte {
      font-size: 0.32rem;
      margin-bottom: 0.1rem;
      span {
        color: #ff669d;
      }
    }
    .balance {
      color: #ff669d;
      span {
        margin-right: 0.1rem;
      }
    }
  }
  .pay_btn {
    padding: 0.4rem 0;
    div {
      width: 60%;
      height: 0.67rem;
      background-color: #ff7294;
      font-size: 0.28rem;
      margin-right: 0;
    }
  }
}
.small-text {
  text-align: center;
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 5.6rem;
  flex-shrink: 0;
  font-size: 0.24rem;
  .title {
    padding: 0.2rem 0;
  }
  .text {
    // box-shadow: 0 0.06rem 0.1rem rgb(0 0 0 / 10%) inset;
    padding: 0.2rem;
    padding-bottom: 0.2rem;
    opacity: 0.7;
    color: #6a6a6a;
  }
  .btn-wrap {
    padding: 0.2rem 0;
    display: flex;
    justify-content: center;
    box-shadow: 0.06rem 0 0.1rem rgb(0 0 0 / 10%);
    div {
      border-radius: 0.1rem;
      border: 1px solid #fff;
      background: #fff;
      @include box-shadow-all;
      color: #f09035;
      padding: 0.05rem 0.1rem;
      &:first-child {
        width: 50%;
        background: #ff7294;
        border: 1px solid #ff7294;
        color: #fff;
        // margin-right: 0.2rem;
      }
    }
  }
}
</style>
