<template>
  <div class="my_post">
    <EBackTop :title="'我的发布'" background="#fff" color="#000"> </EBackTop>
    <!-- <ETab  class="myPost-tab" :sticky="true" :tabList="category"
      :activeIndex="activeSort" :animated="false" :lineSwitch="true"
      :lineBg="'#FF669D'"
      :lineHeight="'0.08rem'"
      :line-width="'0.3rem'"
      :title-active-color="'#FF669D'"
      :title-inactive-color="'#333'"
      :sizeTab="'0.3rem'"
      :swipe-threshold="4"
      :sizeActive="'.3rem'" :activeId="activeId">
      <template v-slot:default="scope">


      </template>
</ETab> -->
    <!-- <PullUp v-if="myPostList && myPostList.length" @refreshData="refreshData" @moreData="moreData" :finished="finished" :loading="loading"
          :refreshing="refreshing">
          <ul class="ah_list" >
            <li v-for="item in myPostList" :key="item.id" class="diWangTj-order-item">
              <EDecryptImg :imgCDN="imgCDN" :imgURL="item.imageUrls ? item.imageUrls[0] : ''" class="item-bg"></EDecryptImg>
              <div class="order_item_right">
                <p>
                  {{ item.title }}
                </p>
                <p>创建时间:{{ item.createdAt | dateFormat08 }}</p>
                <p class="line"></p>
                <div class="top_pay_type">
                  <div v-if="item.reviewStatus === 0 || item.reviewStatus === 3" @click="toUpdate">
                    <img src="@/assets/imgs/trade/fix_icon.png" alt="" />
                    修改
                  </div>
                  <div class="active_txt" v-if="item.reviewStatus === 4">{{ item.remark }}</div>
                  <span :class="item.reviewStatus === 1 ? 'success' : item.reviewStatus === 4 ? 'error' : 'default'">{{
                    item.reviewStatus === 1 ? '审核通过' : item.reviewStatus === 4 ? '审核拒绝' : '待审核'
                  }}</span>
                </div>
              </div>
            </li>
          </ul>
        </PullUp> -->
    <PullUp v-if="myPostList && myPostList.length" @refreshData="refreshData" @moreData="moreData" :finished="finished"
      :loading="loading" :refreshing="refreshing">
      <div class="main_box" v-for="item in myPostList" :key="item.id">
        <div>上架时间:{{ item.base.createdAt | dateFormat08 }}</div>
        <div>{{ item.base.title }}</div>
        <div>{{ item.node.text }}</div>
        <div class="show_img">
          <EDecryptImg :imgCDN="imgCDN" :imgURL="item.node.imgs[0] || ''" class="item-bg"></EDecryptImg>
        </div>
        <div class="meta_box">
          <div class="foot-l">
            <div class="item">
              <img src="@/assets/imgs/picon1.svg" alt="" />
              {{ item.base?.watches }}
            </div>
            <div class="item">
              <img src="@/assets/imgs/picon2.svg" alt="" />
              {{ item.base?.likes }}
            </div>
            <div class="item">
              <img src="@/assets/imgs/picon3.svg" alt="" />
              {{ item.base?.comments }}
            </div>
          </div>
          <div class="meta_name">{{ item.base.postCategory }}</div>
        </div>

        <div class="examine">
          <div></div>
          <!-- <div>{{ item.base.reviewStatus === 1 ? '审核通过' : item.base.reviewStatus === 4 ? '审核拒绝' : '待审核' }}</div> -->
          <div>{{ fText(item.base?.status) }}</div>

        </div>
      </div>
    </PullUp>
    <NoData class="noData" style="padding:0.5rem 0" v-else></NoData>

  </div>
</template>

<script>
import { user_publish, user_post_publish } from '@/api/dating'
import { income_detail } from '@/api/user'

export default {
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    CommunityDefault: () => import('@/components/Community/default.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    NoData: () => import('@/components/JavNodata.vue'),
  },
  data() {
    return {
      activeSort: 2,
      refreshing: false,
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      category: [


      ],
      status: 1,
      myPostList: [],
      income: 0,
      activeId: 2
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    getUserInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    fText(v) {
      console.log('v',v)
      let str = ''
      if(v == 0) str = '待上架'
      if(v == 1) str = '上架'
      if(v == 2) str = '取消上架'
      if(v == 3) str = '待审核'
      if(v == 4) str = '拒绝'
      return str
    },
    refreshData(refreshing) {
      this.refreshing = true
      this.finished = false
      this.loading = false
      this.pageNum = 1
      this.myPostList = []
      this.getMyPostPublic()

    },
    moreData(loading) {
      this.loading = false
      this.pageNum += 1
      this.getMyPostPublic()

    },

    toUpdate() {
      this.$router.push({
        path: '/trade/postTransaction',
        query: {
          indexType: this.activeSort,
          id: 0,
          postType: 'video'
        }
      })
    },

    async getMyPostPublic() {
      const res = await user_post_publish({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: this.getUserInfo.id
      })
      if (res.code == 200) {
        if (res.data.list && res.data.list.length) {
          this.loading = false
          this.refreshing = false
          this.myPostList = [...this.myPostList, ...res.data.list]
          console.log('myPostList',this.myPostList)
          if (res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
        }
        console.log('myPostList_a数据', this.myPostList)
      }
    },
    toPage(path) {
      this.$router.push(path)
    },
    async getIncome() {
      const res = await income_detail(this.pageParams)
      this.income = res.data.income
    }
  },
  beforeRouteEnter(to, from, next) {
    if (['MineMyShouyiIncome', 'MineMyShouyiWithdraw'].includes(from.name)) {
      next(vm => {
        vm.activeId = 2
        vm.activeSort = 2
      })
    } else {
      next()
    }

    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
  }
}
</script>

<style lang="scss" scoped>
.myPost-tab {
  padding: 0.4rem 0.36rem 2rem 0.36rem;

  :deep() {
    .van-sticky {
      position: fixed;
      top: 1rem;
      left: 0;
      right: 0;
      z-index: 99;
    }


    .van-tab {
      flex: none;
    }

    .van-tabs__content {
      margin-top: 1rem;
    }
  }
}

.cash-gold {
  width: 100%;
  height: 2.4rem;
  padding: 0.49rem 1.71rem 0.44rem 1.71rem;
  border-radius: 0.12rem;
  border: 1px solid #535353;
  text-align: center;
  margin-bottom: 0.36rem;
}

.my_post {
  .bottom-btn {
    display: flex;
    margin-top: 0.51rem;

    :deep() .van-button {
      padding: 0.06rem 0.16rem;
      border-radius: 0.08rem;
      background: $btnBg;
      border: none;
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 0.48rem;
      margin-right: 0.64rem;
      white-space: nowrap;
    }
  }

  .ah_list {
    li {
      border-radius: 0.12rem;
      border: 1px solid #535353;
      background: #292929;
      padding: 0.32rem 0.36rem 0.56rem;

      >div {
        &:nth-child(1) {
          padding-bottom: 0.22rem;
          color: #999;
          font-family: PingFang SC;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-bottom: 0.01rem solid #4e4e4e;
        }

        &:nth-child(2) {
          margin: 0.26rem 0;
          color: #fff;
          font-family: PingFang SC;
          font-size: 0.34rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        &:nth-child(3) {
          display: flex;

          >img {
            flex: 1;

            &:nth-child(1) {
              margin-right: 0.14rem;
            }
          }
        }

        &:nth-child(4) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0.36rem 0;
        }

        &:nth-child(5) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 0.18rem;
          border-top: 0.01rem solid #4e4e4e;

          >div {
            &:nth-child(1) {
              flex: 1;
            }

            &:nth-child(2) {
              padding: 0.04rem 0.16rem;
              border-radius: 0.04rem;
              background: #862424;
              margin-left: 0.45rem;
            }
          }
        }
      }
    }
  }

  .action-group {
    display: flex;

    img {
      height: 0.2rem;
      margin-right: 0.08rem;
      vertical-align: middle;
    }

    color: #fff;
    font-family: PingFang HK;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0;

    >div {
      margin-right: 0.64rem;
    }
  }

  .profile {
    border-radius: 0.04rem;
    border: 1px solid #00f6f8;
    background: rgba(0, 246, 248, 0.15);
    padding: 0.02rem 0.08rem;
  }
}

.diWangTj-order-item {
  border-radius: 0.12rem;
  border: 1px solid #535353;
  background: #292929;
  @include flexbox();
  padding: 0.2rem 0.36rem;
  margin-bottom: 0.32rem;

  .item-bg {
    width: 1.43rem;
    height: 1.92rem;

    :deep() {
      img {
        border-radius: 0.12rem;
      }
    }
  }

  .order_item_right {
    @include flexbox($fd: column, $ai: flex-end);

    .line {
      background-color: rgba(151, 151, 151, 0.3);
      width: 3.75rem;
      height: 0.01rem;
      flex-shrink: 0;
      margin: 0.3rem 0 0.39rem 0;
    }

    .top_pay_type {
      @include flexbox($jc: flex-start);

      div {
        @include flexbox($jc: flex-start);
        color: #f77;
        font-size: 0.22rem;
        margin-right: 0.29rem;

        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.02rem;
        }
      }

      span {
        padding: 0.04rem 0.16rem;
        gap: 0.1rem;
        border-radius: 0.04rem;
        background: rgba(190, 145, 85, 0.3);
      }

      .success {
        border-radius: 0.04rem;
        background: rgba(85, 190, 127, 0.3);
      }

      .error {
        border-radius: 0.04rem;
        background: #00f6f8;
      }
    }
  }
}

.success {
  border-radius: 0.04rem;
  background: rgba(85, 190, 127, 0.3);
}

.error {
  border-radius: 0.04rem;
  background: #00f6f8;
}

.main_box {
  margin: 0.2rem;
  padding: 0.2rem;
  box-shadow: 0 0.02rem 0.06rem #ddd;
  border-radius: 0.1rem;
  color: #bbb;
  margin-bottom: .3rem;

  >:nth-child(1) {
    border-bottom: 0.01rem solid #333;
    padding-bottom: 0.2rem;
  }

  >:nth-child(2) {
    margin-top: .2rem;
    margin-bottom: .05rem;
    color: #333;
  }

  .show_img {
    background-color: pink;
    margin: .2rem 0;
  }

  .between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .meta_box {
    @extend .between;
    border-bottom: 0.01rem solid #999;
    padding-bottom: .2rem;


    .foot-l {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 0.64rem;
      }

      img {
        width: 0.28rem;
        height: 0.2rem;
        margin-right: 0.1rem;
      }
    }

    .meta_name {
      background-color: #fdecef;
      padding: .05rem .1rem;
      color: #000;
      border-radius: .05rem;
      font-size: .22rem;
      border: 1px solid pink;
    }
  }

  .examine {
    @extend .between;
    margin: .2rem 0;

    >:nth-child(2) {
      background-color: #ebdecc;
      font-size: .22rem;
      padding: .05rem .1rem;
      border-radius: .05rem;
      color: #333;

    }
  }
}
</style>
