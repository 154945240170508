<template>
  <div class="contact-container">
    <EBackTop title="交流群"></EBackTop>
    <ul >
      <li @click="openPage(item)" class="item" v-for="(item,index) in contactList" :key="index">
        <EDecryptImg :imgCDN="imgCDN" :imgURL="item.cover" />
        <div style="text-align: center;background-color: #fff;">{{ item.title }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
import {config_contact} from 'api/user'
export default {
  data() {
    return {
      contactList:[]
    }
  },

  components: {
  },
  methods: {
    //获取联系方式
    async getConfigContact(){
      const res = await config_contact({
      })
      if (res.code === 200) {
        this.contactList=res.data;
      } else {
       this.$toast(res.tip);
      }

    },
    //打开页面
    openPage(item){
      if(item.jumpUrl) window.open(item.jumpUrl, '_blank');
    }
  },
  mounted() {
    this.getConfigContact();
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
}
</script>
<style lang="scss" scoped>
.contact-container{
  min-height: 100vh;
  ul{
    display: flex;
    flex-flow: wrap;
    padding-top: .6rem;
    .item{
      width: 2.28rem;
      height: 2.28rem;
      margin: 0 .57rem .8rem .9rem;
      :deep() {
        .warp{
          font-size: 0;

          background-color: #fff;
        }
      }
    }
  }

}
</style>
