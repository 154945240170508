<template>
  <div
    class="warp"
    :class="{ long: coverDir }"
    @click="imgClick"
    :style="[imgRadius ? { 'border-radius': imgRadius } : '']"
  >
    <img
      :style="[imgRadius ? { 'border-radius': imgRadius } : '']"
      v-if="realUrl"
      class="img"
      :src="realUrl"
      @click="imgClick"
      :key="imgCDN + imgURL"
      draggable="false"
      ondragstart="return false;"
    />
    <slot></slot>
  </div>
</template>

<script>
import { handleVerAutoImg } from '@/utils/utils_tools'
import { IMAGE_DB_CONFIG, START_IMAGE_DB_KEY } from '@/config/imgConfig.js'
import { TIME_OUT_NUMBER_KEY } from '@/config/imgConfig.js'

const { max_time_consuming, time_out_max_number } = IMAGE_DB_CONFIG

export default {
  name: 'AutoImage',
  props: ['imgURL', 'imgRadius', 'coverDir'],
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
    if (!this.imgURL) this.status = 0
    this.handler()
  },
  watch: {
    imgURL(n) {
      if (n) {
        this.status = 1
        this.getBlobUri()
      }
    }
  },
  data() {
    return {
      realUrl: false,
      status: 1,
      timeConsuming: 0,
      // 超时次数
      timeOutNumber: 0
    }
  },
  methods: {
    handler() {
      this.imgURL ? this.getBlobUri() : (this.realUrl = false)
    },
    // 解密并获取base64图片地址
    async getBlobUri() {
      if (!this.imgURL) return
      const domain = this.$store.getters['cdn']
      const startTime = new Date().getTime()
      const baseUri = await handleVerAutoImg(`${domain}${this.imgURL}`)
      if (baseUri && baseUri.length && this.status === 1) {
        this.realUrl = baseUri
        this.status = 2
      } else this.status = 0
      // 计算执行耗时事件
      this.timeConsuming = new Date().getTime() - startTime
      // 每一次超时当前+1
      let timeOutN = IMAGE_DB_CONFIG[TIME_OUT_NUMBER_KEY]
      const startDb = IMAGE_DB_CONFIG[START_IMAGE_DB_KEY]

      if (this.timeConsuming >= max_time_consuming) {
        IMAGE_DB_CONFIG[TIME_OUT_NUMBER_KEY] += 1
      }
      // 超过最大次数 关闭 IndexDB
      if (timeOutN > time_out_max_number && startDb) IMAGE_DB_CONFIG[START_IMAGE_DB_KEY] = false
    },
    // 方法传递
    imgClick() {
      this.$emit('clickImg', this.imgURL)
    }
  }
}
</script>

<style scoped lang="scss">
.default {
  width: 100%;
  height: 100%;
}
.warp {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  font-size: 0;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s;
  -webkit-user-drag: none;
}

.load-error {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
