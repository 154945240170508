<!-- home -->
<template>
  <div class="index-container">
    <!-- 输入框 -->
    <div class="top_search">
      <div class="search-input" @click="$router.push('/subpage/search')">
        <van-field shape="round" placeholder="收录10万+影片，5000+女优">
          <template #button>
            <van-button size="small" type="primary">搜索</van-button>
          </template>
        </van-field>
      </div>
      <div class="lottery" @click="$router.push('/Activity/lottery/list')">
        <!-- <img @click="showLeftList" src="@/assets/imgs/home_activity_btn.gif" alt="" /> -->
        <EDecryptImg :imgCDN="imgCDN" :imgURL="homeActivityListImage" />
      </div>
    </div>
    <!--切换面板 -->
    <ETab :sticky="true" :lineSwitch="true" :tabList="tabList" :activeId="active" :lineBg="'#FF669D'"
      :lineHeight="'0.08rem'" :line-width="'0.4rem'" :title-active-color="'#FF669D'" :title-inactive-color="'#333'"
      :sizeTab="'0.32rem'" :sizeActive="'.38rem'" class="container-tab1" v-model="active">
      <template v-slot:default="data">
        <component :navItem="data" :showType="data.data" :avAD="avAD" :type="data.item.id"
          :short_videoAD="short_videoAD" :is="compComponent(data.item.ids)"></component>
      </template>
    </ETab>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { vip_list } from 'api/home'
import { isIphonex } from '@/utils/utils_tools'

export default {
  mixins: [scrollMixin],
  data() {
    return {
      showIsNeedVip: true,
      activeItem: {},
      showLeftListData: false,
      type: 0, // 切换的id
      topTabLabelList: [],
      gerFirstCheckDeep: true,
      show: true,
      vipList: [],
      isIphonex: isIphonex(0),
      active: 0,
      // tabList: [{ name: '漫画', id: 1 }, { name: "视频", id: 2 }, { name: "小说", id: 3 }, { name: "色图", id: 4 }],
      showRight: true,
      category: []
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    Index: () => import('@/components/Home/index.vue'),
    ListIndex: () => import('@/components/Home/listIndex.vue'),
    Actor: () => import('@/components/Home/actor.vue'),
    SwiperShow: () => import('@/components/Swiper/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    Default: () => import('@/components/Home/default.vue'),
    HomeComic: () => import('@/components/HomeComic/default.vue'),
    Photo: () => import('@/components/Photo/index.vue'),
    DeepPage: () => import('@/components/Home/deepPage.vue'),
    NovelPage: () => import('@/components/Home/novelPage.vue'),
  },
  computed: {
    // 用户是否有分类权限
    userInfoHasType({ $store }) {
      if ($store.getters['getUserInfo'].categoryIds && $store.getters['getUserInfo'].categoryIds.length) {
        let id = $store.getters['getUserInfo'].categoryIds.find(item => {
          return item == this.type
        })
        return id
      } else {
        return false
      }
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    homeActivityListImage({ $store }) {
      return $store.getters['homeActivityListImage']
    },
    // 获取首页推荐页轮播广告
    avAD({ $store }) {
      return $store.getters['avAD']
    },
    tabList({ $store }) {
      // let list = [{ name: "视频", id: 2 }, { name: '涩漫', id: 1 }, { name: "小说", id: 3 }, {
      //   name: "色图", id: 4, category: [{
      //     id: 1, name: "色图", showType: 1, isPhoto: true
      //   }]
      // }];
      let list = []
      if ($store.state.config.config.jMediaCategory) {
        // 是否有视频
        list.push({ name: "视频", ids: 2 })
      }
      if ($store.state.config.config.comicsCategory.length > 0) {
        list.push({ ...$store.state.config.config.comicsCategory[0], name: "涩漫", ids: 1, })
      }
      if ($store.state.config.config.novelCategoryList) {
        list.push({ name: "小说", ids: 3 })
      }
      list.push({ name: "色图", ids: 4 })
      console.log('list', list)
      return list;
    },
    // 获取首页底部轮播广告
    short_videoAD({ $store }) {
      return $store.getters['short_videoAD']
    },
    cardList() {
      return this.vipList.filter(v => v.vipType == 8)
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  activated() {

  },
  mounted() {
    // this.type = this.category[0].id
  },
  methods: {

    showLeftList() {
      this.showLeftListData = true
    },

    compComponent(data) {

      switch (data) {
        case 1:
          return 'HomeComic'
        case 2:
          return 'DeepPage'
        case 3:
          return 'NovelPage'
        case 4:
          return 'Photo'
        default:
          return 'Index'
      }
    },

  },
  beforeDestroy() { }
}
</script>
<style lang="scss" scoped>
.van-overlay {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: calc(100vh - 0.2rem - calc(0.2rem + env(safe-area-inset-bottom)) - 0.7rem - 4px - 12px);

  &.is-iphonex-height {
    height: calc(100vh - 0.2rem - calc(0.2rem + env(safe-area-inset-bottom)) - 0.7rem - 4px - 12px - 34px);
  }

  .overlay-center {

    // background-color: red;
    >p {
      color: #fff;
      text-align: center;
      font-family: PingFang SC;
      font-size: 0.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    >div {
      &:nth-of-type(1) {
        background: url('../../assets/imgs/trade/warning.png') top center no-repeat;
        background-size: contain;
        height: 1.8rem;
        padding: 0.5rem 0.72rem 0.28rem;
        margin-top: 0.24rem;
        text-align: center;
        font-size: 0.24rem;
      }

      &:nth-of-type(2) {
        color: #fff;
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0.24rem 0 0.08rem;
      }

      &:nth-of-type(3) {
        color: #00f6f8;
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0.08rem;
      }

      &:nth-of-type(4) {
        display: flex;
        padding: 0.04rem 0.16rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.04rem;
        background: #00f6f8;
        width: fit-content;
        margin: 0 auto;
        color: #333;
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.index-container {

  // min-height: $minHeight;
  .searchPack {
    position: fixed;
    right: 50%;
    right: 0.3rem;
    // margin-right: -3.5rem;
    top: 0.2rem;
    z-index: 10;

    img {
      width: 0.36rem;
      height: 0.36rem;
    }
  }

  .container-tab1 {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0.9rem;
        left: 50%;
        transform: translate(-50%, 0);
        width: 7.5rem;
        // max-width: $pcMaxWidth;
        z-index: 89;
        padding-bottom: 0.15rem;
        background-color: #fff;
        .van-tab {
          flex: none;
        }

        .van-tabs__wrap {
          margin-right: 0;
        }
      }
    }
  }

  .container-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 1.79rem;
        left: 50%;
        transform: translate(-50%, 0);
        width: 7.5rem;
        max-width: $pcMaxWidth;
        z-index: 99;

        .van-tab {
          flex: none;
        }

        .van-tabs__wrap {
          margin-right: 1rem;
        }
      }
    }
  }
}

.top_search {
  position: fixed;
  width: 7.5rem;
  height: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  display: flex;
  padding: 10px 0.3rem;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  :deep() {
    .van-field__body {
      height: 0.65rem;
    }

    .van-cell {
      padding: 0;
    }

    .van-cell__value {
      border: 0.01rem solid #999;
      border-radius: 1rem;
      padding: 0 0.2rem;
    }

    .van-field__button {
      @include flexbox($jc: center);
    }

    .van-button {
      margin: 0;
      padding: 0 0 0 0.3rem;
      border: none;
      outline: none;
      background-color: transparent;
      border-left: 0.01rem solid #999;
      height: 0.3rem;

      &::before {
        display: none;
      }
    }

    .van-button__text {
      color: #333;
      font-size: 0.32rem;
    }
  }

  .search-input {
    width: 5.48rem;
  }

  .lottery {
    width: 1.2rem;
    height: 0.6rem;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }
}

.active_txt {
  color: #fff;
}

.menuPop {
  height: 100%;
  width: 4.8rem;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 0.96rem 0.38rem;

  .menuPopTitle {
    font-size: 0.32rem;
    position: relative;
    text-align: center;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0.24rem;
      height: 0.08rem;
      border-radius: 0.08rem;
      background: $btnBg;
      bottom: -0.2rem;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .menuPopContent {
    @include flexbox($fw: wrap);

    color: #fff;

    >div {
      margin-top: 0.48rem;
      width: 1.84rem;
      height: 0.84rem;
      line-height: 0.84rem;
      text-align: center;
      border-radius: 0.08rem;
      background: linear-gradient(91deg, rgba(207, 232, 255, 0.15) 0.68%, rgba(109, 185, 255, 0.24) 99.65%);
    }

    .active {
      background: $txtActive;
    }
  }
}

.rightIcon {
  position: fixed;
  right: 0rem;
  // margin-right: -3.5rem;
  z-index: 100;
  background-color: #fff;
  top: 1.8rem;
  width: 1.1rem;
  height: 44px;
  text-align: center;
  line-height: 0.72rem;

  img {
    width: 0.48rem;
    height: 0.48rem;
  }
}

.popup1 {
  height: calc(100vh - 3rem);
  top: 1.8rem;
  background: rgba($color: #000000, $alpha: 0.7);
  color: #fff;
  width: 100%;
  transform: translate(-50%, 0);
  @include flexbox($jc: center, $fd: column);

  position: fixed;
  z-index: 100;
  left: 50%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
  }

  .popup-main {
    background: transparent !important;
  }

  .popup1-content {
    @include flexbox($jc: center, $fd: column);

    .bg {
      width: 6.34rem;
      height: 3.89rem;
      background: rgba($color: #fff, $alpha: 0.7);
      text-align: center;
      white-space: pre-line;

      @include flexbox($jc: center, $fd: column);
      flex-shrink: 0;
      flex: none;
    }

    .popup2-text {
      font-size: 0.32rem;
      color: #000;
    }
  }
}

.top_banner {

  // height: 4rem;
  .JGbtnList {
    padding: 0 0.3rem;
  }
}
</style>
