<template>
  <div class="allType-wrap">
    <EBackTop :title="$route.query.name" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂时没有更多数据！"
        @load="onLoad"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
      >
        <div class="list-wrap">
          <ul>
            <DmComicCard v-for="(item, index) in comicsList" :item="item" :key="index"></DmComicCard>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import scrollMixin from '@/utils/scrollMixin'
import { comicsTagListById } from 'api/comics'

export default {
  components: {
    DmComicCard: () => import('@/components/HomeComic/oneCard')
  },
  name: 'TagOne',
  data() {
    return {
      //加载刷新
      pageNum: 1,
      pageSize: 10,
      refreshing: false,
      loading: false,
      finished: false,
      // 列表
      comicsList: []
    }
  },
  mixins: [scrollMixin],
  computed: {},
  methods: {
    //获取漫画列表
    async getComicsTagListById(type) {
      const res = await comicsTagListById({
        id: +this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: 0
      })
      if (res.code === 200 && res.data) {
        this.refreshing = false
        this.loading = false
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true
        }
        if (type == 'pull') this.comicsList = []
        if (res.data.list) this.comicsList = [...this.comicsList, ...res.data.list]
      } else {
        this.finished = true
        this.refreshing = false
        this.loading = false
        return this.$toast(res.tip)
      }
    },
    //上拉加载更多
    async onLoad() {
      this.pageNum += 1
      this.getComicsTagListById()
    },
    // 刷新
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.getComicsTagListById('pull')
    }
  },

  mounted() {
    this.onRefresh()
  }
}
</script>

<style lang="scss" scoped>
.allType-wrap {
  min-height: 100vh;
  padding-bottom: 0.1rem;
  color: #6a6a6a;
  .list-wrap {
    padding: 0 0.37rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
</style>
