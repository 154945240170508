<template>
  <div class="collect">
    <EBackTop :title="'我的收藏'" background="#fff" color="#000">
      <div class="right-btn" slot="right" @click="onClickRight">
        {{ btnTxt }}
      </div>
    </EBackTop>
    <ETab class="myHistory-tab" v-model="indexActive" @change="change" :sticky="true" :tabList="cartoonCategory"
      :animated="false" :lineBg="'#FF669D'" :lineHeight="'0.08rem'" :line-width="'0.3rem'"
      :title-active-color="'#FF669D'" :title-inactive-color="'#333'" :sizeTab="'0.3rem'" :swipe-threshold="4"
      :sizeActive="'.3rem'">
      <template v-slot:default="scope">
        <component cardType="collect" ref="childDom" :is="compComponent(scope.data)" :showMask="showMask"></component>
      </template>
    </ETab>
    <!-- 弹窗 -->
    <van-popup class="pay-pop" v-model="showType" @close="cancelConfirm" close-icon="cross" position="bottom"
      lazy-render>
      <div @click="confirm">删除</div>
      <div @click="cancelConfirm">取消</div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      btnTxt: '编辑',
      //当前页
      pageNum: 1,
      // 当前个数
      pageSize: 40,
      cartoonCategory: [
        { showType: 1, name: '长视频' },
        { showType: 2, name: '短视频' },
        { showType: 5, name: '帖子' },
        { showType: 9, name: '约炮' },
        { showType: 17, name: '色图' },
      ],

      indexActive: 0,
      showType: false,
      showMask: false
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    LongVideoItem: () => import('@/components/HistoryComponents/LongVideoItem.vue'),
    ShortVideoItem: () => import('@/components/HistoryComponents/ShortVideoItem.vue'),
    dating: () => import('@/components/HistoryComponents/dating.vue'),
    CommunityDefault: () => import('@/components/HistoryComponents/CommunityItem.vue'),
    Photo: () => import('@/components/HistoryComponents/photo.vue')
  },

  methods: {
    change(id) {
      this.indexActive = id
    },
    // 编辑按钮
    onClickRight() {
      if (this.btnTxt == '编辑') {
        this.showMask = true
        return (this.btnTxt = '删除')
      }
      this.showType = true
    },
    compComponent(type) {
      switch (type) {
        case 1:
          return 'LongVideoItem'
        case 2:
          return 'ShortVideoItem'
        case 5:
          return 'CommunityDefault'
        case 9:
          return 'dating'
        case 17:
          return 'Photo'
        default:
          return 'LongVideoItem'

      }
    },

    //取消
    cancelConfirm() {
      this.showMask = false
      this.showType = false
      this.btnTxt = '编辑'
    },
    //确定删除
    confirm() {
      // let tyoe = ''
      // if (this.indexActive === 0) {
      //   type = 'longVideo'
      // } else if (this.indexActive === 1) {
      //   type = 'shortVideo'
      // } else if (this.indexActive === 2){
      //   type = 'ahy'
      // }else if(this.indexActive === 3) {
      //   type = 'dwtj'
      // }else if(this.indexActive === 4) {
      //   type = 'ahjy'
      // }
      this.$refs.childDom.delCollect()
      this.showMask = false
      this.showType = false
      this.btnTxt = '编辑'
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  }
}
</script>
<style lang="scss" scoped>
.collect {
  padding-bottom: 1rem;
}

.myHistory-tab {
  :deep() {
    .van-sticky {
      position: fixed;
      top: 0.9rem;
      left: 0;
      right: 0;
      z-index: 99;
    }

    .van-tabs__nav {
      justify-content: flex-start;
    }

    .van-tab {
      flex: none;
    }
  }
}

.pay-pop {
  background-color: transparent;

  div {
    margin: 0 auto;
    width: 7rem;
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
    color: $txtActive;
    background-color: #fff;
    margin-top: 0.24rem;
    border-radius: 0.1rem;
    font-weight: 600;
    font-size: 0.32rem;
  }
}

.right-btn {
  display: inline-flex;
  padding: 0.04rem 0.16rem;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
  border-radius: 0.24rem;
  border: 1px solid $btnBg;
  color: $txtActive;
  font-size: 0.24rem;
}
</style>
