<template>
  <div class="app">
    <ul class="top_list">
      <li
        @click="changeItem(item)"
        :class="{ activeIndex: activeIndex === item.id }"
        v-for="item in appList"
        :key="item.id"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="con_box">
      <ImgType :activeItem="activeItem" :type="isImgType"></ImgType>
      <div v-if="appList.length" class="interval">
        <img src="@/assets/imgs/j1.png"  alt="">
      </div>
      <IsDesc :highQualityDesc="highQualityDesc"></IsDesc>
      <ImgText :highQualityImg="highQualityImg"></ImgText>
    </div>
    <!-- <ul class="bot_list">
      <li v-for="appItem in activeItem" :key="appItem.id">
        <div class="avatar">
          <DecryptImg
            @click.native="clickImg(appItem)"
            :imgCDN="cdn"
            :imgURL="appItem.avatar"
          >
          </DecryptImg>
        </div>

        <p class="font">{{ appItem.name }}</p>
      </li>
      <li></li>
      <li></li>
      <li></li>
    </ul> -->
  </div>
</template>
<script>
import {appPage,appsClick} from 'api/home'
import ImgType from '@/components/imgType/imgType.vue'
import IsDesc from './isDesc.vue'
import ImgText from './imgText.vue'
export default {
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    IsDesc,
    ImgType,
    ImgText,
  },
  data() {
    return {
      appList: [],
      activeItem: [],
      activeIndex: 0,
      isImgType: 0,
      highQualityDesc: [],
      highQualityImg: []
    }
  },
  computed: {
    // 图片cdn
    cdn() {
      return this.$store.getters['config/cdn']
    }
  },

  created() {
    this.getAppLists()
  },
  // activated() {
  //   this.getAppLists()
  // },
  methods: {
    async clickImg(appItem) {
      appsClick({
        id: appItem.id
      }).then((res) => {
        if (res.code === 200) {
          if (!appItem.download_url) return
        } else {
          return this.$toast('统计出错！')
        }
      })
      window.open(appItem.download_url, '_self')
    },
    async getAppLists() {
      const res = await appPage({})

      if (res.code == 200) {
        if (res.data.list.length > 0) {
          this.highQualityDesc = res.data.highQualityDesc
          this.highQualityImg = res.data.highQualityImg
          this.appList = res.data.list
          this.activeIndex = this.appList[0]?.id
          this.isImgType = this.appList[0]?.imgType

          this.activeItem = res.data.list[0]?.applications
        } else {
          return this.$toast('暂无数据！')
        }
      } else {
        return this.$toast('暂无数据！')
      }
    },
    changeItem(item) {
      this.activeItem = item.applications
      this.activeIndex = item.id
      this.isImgType = item.imgType
    }
  }
}
</script>
<style lang="scss" scoped>
.app {
  // background-image: radial-gradient(ellipse farthest-corner at 0% 1000%, #f8dfe8, #f6f6f6);
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
  padding-top: 1rem;
}

.top_list {
  background-color: #fff;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 40px;
  position: fixed;
  top: 0;
  left: 50%;
  padding: 0 0.25rem;
  transform: translate(-50%, 0);
  width: 100%;
  overflow: hidden;
  height: 1rem;

  z-index: 1;
  font-size: 15px;
  li {
    margin-right: 15px;
    position: relative;
  }

  .activeIndex {
    color: $btnBg;
    font-weight: 600;
    &::after{
      content: '';
      display: block;
      width: 0.5rem;
      height: 0.08rem;
      background-color: $btnBg;
      position: absolute;
      bottom: -0.2rem;
      left: 50%;
      border-radius: 0.08rem;
      transform: translate(-50%,0);
    }
  }
}
.con_box {
  background-color: #fff;
  margin: 0 0.2rem 1.5rem 0.2rem;
  border-radius: .15rem;
  padding-top: .2rem;
}

// .bot_list {
//   padding: 0.25rem 0.3rem 2.25rem 0.3rem;
//  @include flexbox($fw:wrap);
//   margin-top: 1rem;
//   li {
//     margin-right: 0.1rem;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 0.2rem;
//     font-size: 0.28rem;
//     width: 1.3rem;
//     &:nth-child(5n) {
//       margin-right: 0;
//     }
//   }
// }

// .avatar {
//   width: 1rem;
//   height: 1rem;
//   border-radius: 0.18rem;
//   ::v-deep {
//     img {
//       border-radius: 0.18rem;
//     }
//   }
// }
// .font {
// font-size: 0.24rem;
// max-width: 1rem;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }
.interval {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: .4rem 0;
  img {
    width: 5.9rem;
  }
}
</style>
