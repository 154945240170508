<template>
  <ul class="dropdown-list" >
    <!-- :style="{'height':bodyHeighy}" -->
    <li 
     :style="{'min-width':minWidth+'rem'}" 
      class="dropdown-item" 
      @click.stop="changeDropdown(item)"  
      :key="index"  v-for="(item, index) in dropdownListObj.list" >
      {{item.name}}
    </li>
  </ul>   
</template>

<script>
export default {
  props: {
    dropdownListObj: {
      // 发布者id
      type: Object,
      default() {
        return {}
      },
    },
    minWidth: {
      // 发布者id
      type: Number,
      default() {
        return 1.76
      },
    },    
    
  },
  data() {
    return {
    }
  },
  methods: {
    changeDropdown(item){
      this.$emit('changeDropdown',item);
      this.dropdownListObj.show=false;
    },
    clickHandle(){
      this.dropdownListObj.show=false;
    }
  },
  mounted(){
    document.body.addEventListener('click', this.clickHandle);
  },
  beforeDestroy(){
    document.body.removeEventListener('click', this.clickHandle);
  }
}
</script>

<style lang="scss" scoped>
.dropdown-list{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  @include box-shadow-all;
  border-radius:0.24rem ;
  background: #fff;
  max-height: 3rem;
  overflow: auto;
  .dropdown-item{
    text-align: center;
    white-space: nowrap;
    padding: 0.1rem 0.5rem;
    font-size: 0.28rem;
    color: #6a6a6a;
    opacity: 0.7;
    border-bottom: 1px solid #d5d5d5;
    animation: fadeIn 1s;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }    
    &:last-child{
      border-bottom: 0;
    }
    &:hover{
      background: #faf9f8;
    }
  }
}
</style>
