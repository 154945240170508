<template>
  <!-- 保存账号凭证弹窗  -->
  <van-popup
    class="code-popup"
    v-model="showMyCode"
    @click-close-icon="closeGuidance"
    @click-overlay="closeGuidance"
    round
    position="center"
    get-container="body"
  >
    <div class="share_pack" ref="bill">
      <img src="@/assets/imgs/homec/shareBg.png" alt="" />
      <div class="share_bot">
        <div class="QRcode_pack">
          <qrcode-vue class="code-img" :value="code" level="H"></qrcode-vue>
        </div>
        <div class="button_pack">
          <div class="copy_url" v-clipboard:copy="code" v-clipboard:success="copyUrl">复制链接</div>
          <div class="copy_img" @click="htmlToCanvas">保存图片</div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import html2canvas from 'html2canvas'
export default {
  props: ['setMyCode', 'sharePath'],
  data() {
    return {
      showMyCode: false,
      code: '',
      canvasImageUrl: ''
    }
  },
  watch: {
    setMyCode: {
      immediate: true,
      handler(value) {
        this.showMyCode = value
      }
    }
  },
  components: {
    QrcodeVue
  },
  computed: {},
  methods: {
    //保存图片
    htmlToCanvas() {
      html2canvas(this.$refs.bill, {}).then(canvas => {
        canvas.toBlob(
          blob => {
            const url = URL.createObjectURL(blob)
            // 可以上传后端或者直接显示
            this.download(url, new Date().getTime())
          },
          'image/webp',
          0
        )
      })
    },
    //复制链接
    copyUrl() {
      this.$toast('成功复制分享链接')
    },
    download(href, name) {
      const eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      document.body.appendChild(eleLink)
      eleLink.click()
      document.body.removeChild(eleLink)
      this.$toast('分享图片已保存成功')
    },
    // 关闭公告getSummary
    closeGuidance() {
      this.showMyCode = false
      this.$emit('closeGuidance', false)
    },
    removeTrailingSlash(str) {
      console.log(str)
      if (str.endsWith('/')) {
        return str.slice(0, -1)
      }
      return str
    }
  },
  created() {
    this.code =
      this.removeTrailingSlash(this.$store.getters['h5Cdn']) +
      '/#' +
      this.sharePath +
      '?pc=' +
      this.$store.state.user.info.inviteCode +
      '&dc=h5share'
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
// 分享弹窗
.code-popup {
  background: transparent;
  border-radius: 8px;
  padding: 0 !important;
  .share_pack {
    width: 4.8rem;
    font-size: 0;
    img {
      width: 100%;
    }
    .share_bot {
      color: #fff;
      background: #181c28;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.36rem 0.4rem;
      border-radius: 0.24rem;
      margin-top: -0.24rem;
      overflow: hidden;
      position: relative;
      .QRcode_pack {
        width: 1.6rem;
        height: 1.6rem;
        background: #fff;
        border-radius: 0.08rem;
        position: relative;
        .code-img {
          position: absolute;
          top: 0.1rem;
          left: 0.1rem;
          width: 1.4rem;
          height: 1.4rem;
          :deep() {
            canvas {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
      }
      .button_pack {
        height: 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          width: 2rem;
          height: 0.68rem;
          line-height: 0.68rem;
          border-radius: 0.16rem;
          font-size: 0.26rem;
          text-align: center;
        }
        .copy_url {
          background: #f4a100;
        }
        .copy_img {
          background: #ff466f;
        }
      }
    }
  }
  :deep() {
    .van-popup {
      padding: 0 !important;
    }
    .van-popup__close-icon {
      color: #fff;
    }
  }
}
</style>
