<template>
  <div class="sign-rule">
    <EBackTop :title="'签到规则'" :placeholder="false" ></EBackTop>
    <div class="rule-wrap">
      <ul v-if="checkinDatas && checkinDatas.length > 0">
        <li :key="index" v-for="(item, index) in checkinDatas">
          <div><span>{{index==0?'每':'连续'}}</span>签到<span class="day">&nbsp;{{ item.dayNum }}&nbsp;</span>天</div>
          <div class="right">{{ index !== 0 ? '额外' : '' }}+{{ item.pointsNum.toString() }}积分</div>
        </li>
      </ul>
          <Nodata v-else />
    </div>
  </div>
</template>

<script>
import { checkin_setup } from '@/api/user'
export default {
  components: {

    Nodata: () => import('@/components/JavNodata.vue')
  },
  data() {
    return {
      checkinDatas: []
    }
  },
  methods: {
    async checkinSetup() {
      const res = await checkin_setup({

      })
      if (res.code === 200) {
        this.checkinDatas = res.data.setups;
      } else {
        this.$toast(res.tip)
      }
    },

  },
  mounted() {
    this.checkinSetup();
  }
}
</script>

<style lang="scss" scoped>
.sign-rule {
  padding-top: 1rem;
  //margin-top: 0.2rem;
  font-size: 0.28rem;
  color: #6a6a6a;

  .rule-wrap {
    padding: 0.61rem 0.64rem 0;
    border-top: .01rem solid rgba(255, 255, 255, 0.30);


    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.2rem;
        @include box-shadow-all;
        padding: 0.32rem 0.36rem;
        border-radius: 0.12rem;
        border: 1px solid var(--red, #ff669d);

        .day {
          color: #ff669d;
        }

        .right {
          padding: .08rem .21rem;
          border-radius: 0.08rem;
        background: $btnBg;
          color: #fff;
        }
      }
    }
  }
}
</style>
