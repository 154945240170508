<template>
  <div class="home-index">
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
    >
      <div class="list-box">
        <CommunityDefault
          class="AWJiaoYiOneCard"
          @clickItem="clickItem"
          :showMask="showMask"
          v-for="(item, index) in listData"
          :key="index"
          :itemData="item"
        >
        </CommunityDefault>
      </div>
    </PullUp>
  </div>
</template>

<script>
import { collect_list, collect_del } from 'api/user'
import { pay_history } from 'api/user'
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,
      listData: [],
      newList: [],
      finished: false // 每次请求到的数据长度
    }
  },
  props: ['cardType', 'showMask'],
  components: {
    PullUp: () => import('@/components/PullUp.vue'),
    CommunityIndex: () => import('@/components/Community/index.vue'),
    CommunityDefault: () => import('@/components/Community/default.vue')
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    postListHistory({ $store }) {
      return $store.getters['postList']
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    // 我的订单列表

    // 收藏列表
    async getList() {
      // OperationTypeCollect OperationType = 1 //收藏
      // OperationTypeLike    OperationType = 2 //点赞
      // OperationTypeStepOn  OperationType = 3 //踩
      if (this.cardType === 'history') {
        this.listData = JSON.parse(JSON.stringify(this.postListHistory))
        this.refreshing = false
        this.loading = false
        this.finished = true
      } else {
        let apiObj = {
          collect: collect_list,
          order: pay_history
        }

        const res = await apiObj[this.cardType]({
          collectType: this.cardType === 'collect' ? 5 : undefined,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type: this.cardType === 'collect' ? 1 : 5 // 收藏
        })
        if (res.code === 200) {
          if (res.data.postList && res.data.postList.length && res.data.postList.length <= this.pageSize) {
            this.listData = [...this.listData, ...res.data.postList]
            this.refreshing = false
            this.loading = false
          } else {
            this.refreshing = false
            this.loading = false
            this.finished = true
          }
        }
      }
      this.listData = this.listData.map(item => {
        item.clickMask = false
        return item
      })
    },
    // 上拉加载更多
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getList()
    },
    // 下啦刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = false
      this.listData = []
      this.pageNum = 1
      this.getList()
    },
    clickItem(list) {
      if (this.cardType === 'history') {
        this.$emit('checkList', list)
      } else if (this.cardType === 'collect') {
        this.newList = list
      }
      this.$forceUpdate()
    },
    // 取消收藏
    /**
     * ContentTypeVideoAv       ContentType = 1  // AV（长视频）
       ContentTypeVideoMedia    ContentType = 2  // 小视频
       ContentTypeViodeModel    ContentType = 4  // 上门 (帝王调教)
       ContentTypePost          ContentType = 5  // 帖子（暗网交易）
       ContentTypeViodeLoufeng  ContentType = 6  // 楼凤 (暗黑游)
     *
     *
     */
    async delCollect() {
      const res = await collect_del({
        collectType: 5,
        objectIds: this.newList,
        type: 1
      })
      if (res.code === 200) {
        this.refreshData()
      } else {
        return this.$toast('操作失败，请联系后台管理员！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-index {
  min-height: 100vh;
  margin-top: 0.7rem;

  padding: 0.36rem;
}

// .awjy-index{

// }
.wallet_tabs {
  margin-top: 1rem;

  :deep() {
    .van-tabs__wrap {
      @include flex-center;
    }

    .van-tabs__nav {
      height: 0.63rem;

      background: transparent !important;
      padding-bottom: 0;
      border-radius: 0.6rem;
      border: 0.01rem solid #00f6f8;

      //border: 1px solid #F9C006;
      .van-tab {
        font-size: 0.24rem !important;
        z-index: 2;
        color: #787979;
      }

      .van-tab--active {
        color: #fff;
      }

      .van-tabs__line {
        width: 1.36rem;
        height: 0.47rem;
        border-radius: 0.24rem;
        background: $btnBg;
        top: 0.06rem;
      }
    }
  }
}
</style>
