<template>
  <div class="long-four" v-if="innerList && innerList.length > 0">
    <template v-for="(item, index) in innerList">
      <RandomAdSwiper v-if="index === 2 && discoverAD.length" :list="discoverAD" class="long-four-item" />
      <LongVideoCards
        :item="item"
        @click.native="toPlay(item)"
        :class="[index === 0 ? 'long-four-item2' : 'long-four-item']"
      />
    </template>
    <i></i><i></i>

    <div class="video-exchange">
      <div @click="fn_videoTopicChange">
        <img src="@/assets/imgs/homec/change.png" alt="" />
        换一批
      </div>
      <div @click="$router.push(`/subpage/morePage?id=${id}&name=${name}&type=${type}&`)">
        <img src="@/assets/imgs/homec/mone.png" alt="" />
        查看更多
      </div>
    </div>
  </div>
</template>

<script>
import { getTopicChange } from 'api/play'
export default {
  name: 'ShortVideo',
  props: ['list', 'id', 'name', 'type'],
  data() {
    return {
      changeList: []
    }
  },
  components: {
    LongVideoCards: () => import('@/components/VideoCard/LongVideoCards.vue'),
    RandomAdSwiper: () => import('@/components/Ad/RandomAdSwiper.vue')
  },

  methods: {
    toPlay(item) {
      this.$router.push(`/subpage/play/longVideo/${item.id}`)
    },
    //换一换
    async fn_videoTopicChange() {
      const res = await getTopicChange({ id: this.id })
      if (res.code === 200 && res.data) {
        this.changeList = res.data.mediaList
      } else {
        return this.$toast(res.tip)
      }
    }
  },
  computed: {
    innerList() {
      let list = this.changeList.length ? this.changeList : this.list.slice(0, 5)
      if (this.discoverAD.length) {
        return list.slice(0, 4)
      } else {
        return list
      }
    },
    discoverAD() {
      return this.$store.getters['topicAd']
    }
  }
}
</script>

<style lang="scss" scoped>
.long-four {
  border-radius: 0.05rem;
  @include flexbox($jc: space-between, $ai: flex-start, $fd: row, $fw: wrap);

  i {
    width: 3.1rem;
  }

  .long-four-item2 {
    width: 100%;

    ::v-deep {
      .item-bg {
        max-height: 8rem;
      }
    }
  }

  .long-four-item {
    width: 3.42rem;

    ::v-deep {
      .item-bg {
        height: 1.9rem;
      }
    }
  }

  ::v-deep {
    .short-six-item {
      margin-bottom: 0.2rem;
    }
  }
}

.video-exchange {
  display: flex;
  width: 85%;
  margin: 0 auto;
  margin-top: 0.1rem;
  border: 2px solid #ff669d;
  border-radius: 0.34rem;
  overflow: hidden;
  div {
    width: 50%;
    height: 0.62rem;
    font-size: 0.32rem;
    background: #fff;
    color: #fe6fa2;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      color: #fff;
      background: #fe6fa2;
    }
    img {
      width: 0.35rem;
      height: 0.35rem;
      margin-right: 0.2rem;
    }
  }
}
</style>
