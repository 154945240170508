<template>
  <van-popup v-model="show" round position="bottom" class="comment-pop" @close="close" get-container="#app">
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
      :skeleton="skeleton"
    >
      <div class="content-main" v-for="item in list" :key="item.id">
        <EDecryptImg
          :imgCDN="imgCDN"
          class="user-header"
          :imgURL="item.userAvatar"
          @click.native="$router.push(`/mine/otherUserInfo/${item.userId}`)"
        />
        <!-- 第一层用户 -->
        <div class="comment-user">
          <div class="first-comment">
            <div class="comment-user-name">
              {{ item.userName }}
              <span v-if="item.userId === publisher" class="zuoze">作者</span>
            </div>
            <div class="text">
              <p class="comment-user-text">
                {{ item.text }}
              </p>
              <!-- 回复按钮，时间 -->
              <div class="comment-user-res">
                <p class="comment-user-time">
                  {{ item.createdAt | timeDiff }}
                </p>
                <!-- 回复 -->
                <div class="resTxt" @click.stop="clickRespondText(item)">回复</div>
              </div>
            </div>
            <!-- 展开 -->
            <div class="comment-user-open" v-if="item.commentCount && item.id !== activeIndex">
              <div class="comment-user-open-right" @click="openSon(item)">
                <span>展开 {{ item.commentCount }} 条回复</span>
              </div>
            </div>
            <!-- 第二层用户评论 -->
            <template v-if="item.id === activeIndex">
              <div class="second-comment" v-for="sItem in secondList" :key="sItem.id">
                <EDecryptImg
                  :imgCDN="imgCDN"
                  class="user-header"
                  :imgURL="sItem.userAvatar"
                  @click.native="$router.push(`/mine/otherUserInfo/${item.userId}`)"
                />
                <div class="second-comment-main">
                  <div class="comment-user-name">
                    {{ sItem.userName }}
                    <span v-if="sItem.userId === publisher" class="zuoze">作者</span>
                    <span class="color">回复</span> {{ item.userName }} <span class="color">的评论</span>
                  </div>
                  <div class="text">
                    <div class="comment-user-text">
                      {{ sItem.text }}
                    </div>
                    <!-- 回复按钮，时间 -->
                    <div class="comment-user-res">
                      <p class="comment-user-time">
                        {{ sItem.createdAt | timeDiff }}
                      </p>
                      <!-- 回复 -->
                      <div class="resTxt" @click.stop="clickRespondText(item)">回复</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </PullUp>
    <!-- 评论 -->
    <div class="submit-box">
      <div class="line-bot"></div>
      <!-- 是否为会员 -->
      <div class="submit-btn" @click="toVip" v-if="!isMember">开通VIP 立即评论</div>
      <div class="submit-input" v-else>
        <van-field v-model="text" :placeholder="placeholder" />
        <span class="submit-txt" @click="addComment">发送</span>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { comment_list, comment_add } from 'api/home'
export default {
  props: {
    objectId: {
      // 评论对象id
      type: Number,
      default() {
        return 0
      }
    },
    objectype: {
      // 评论类型
      type: Number,
      default() {
        return 0
      }
    },
    showComment: {
      // 评论对象id
      type: Boolean,
      default() {
        return false
      }
    },
    publisher: {
      // 发布者id
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      text: '', // 评论内容
      placeholder: '喜欢就给个评论支持一下～',
      pageNum: 1,
      pageSize: 10,
      activeIndex: 0, // 展开的id
      newComments: 0, // 评论数
      parentsId: null, // 父级回复id
      list: [], // 评论列表
      secondList: [], // 二级评论列表
      show: false,
      finished: false,
      loading: false,
      refreshing: false,
      skeleton: false
    }
  },
  mounted() {
    // 一级列表请求参数
    this.skeleton = true
  },
  components: {
    PullUp: () => import('@/components/PullUp.vue')
  },
  computed: {
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  watch: {
    showComment: {
      handler(n) {
        this.show = n
        this.list = []
        this.secondList = []
        if (this.show) {
          const data = {
            objectId: this.objectId,
            objectType: this.objectype,
            pageNum: 1,
            pageSize: this.pageSize,
            parentsId: null
          }
          this.getCommentList(data)
        }
      }
    }
  },
  methods: {
    // 跳转vip开通页面
    toVip() {
      this.$router.push('/vip')
    },
    // 关闭弹窗
    close() {
      this.showType = false
      this.$emit('close', this.showType)
    },
    // 点击回复按钮
    clickRespondText(item) {
      this.placeholder = `回复 ${item.userName}`
      if (!this.isMember) {
        return this.$toast('会员才参与可以评论!')
      }
      this.parentsId = item.id
    },
    // 获取评论列表
    async getCommentList(data) {
      const res = await comment_list(data)
      if (res.code === 200) {
        // 有parentsId 为二级列表请求
        this.loading = false
        this.refreshing = false

        if (data.parentsId) {
          this.secondList = [...this.secondList, ...res.data.list]
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
          this.list = [...this.list, ...res.data.list]
        }
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip || res.data)
      }
    },
    // 发布评论
    async addComment() {
      if (!this.text) {
        return this.$toast('请输入评论内容!')
      }
      const res = await comment_add({
        objectId: this.objectId, // 对象id
        objectType: this.objectype, // 对象类型
        parentsId: this.parentsId, // 上级id
        replyId: this.replyId, // 回复id
        // score: this.score, // 评分 没有为0
        text: this.text
      })
      this.activeIndex = 0
      if (res.code === 200) {
        this.text = null
        this.newComments += 1
        this.list = []
        this.secondList = []
        this.parentsId = null // 每次发布做一次清除操作
        const data = {
          objectId: this.objectId,
          objectType: this.objectype,
          pageNum: 1,
          pageSize: this.pageSize,
          parentsId: this.parentsId
        }
        this.getCommentList(data)
        // 通知父级改变评论数量
        this.$emit('addComment', true)
        return this.$toast('评论成功')
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip || res.data)
      }
    },
    // 打开二级评论列表
    openSon(item) {
      this.activeIndex = item.id
      this.pageNum = 1
      this.finished = false
      this.parentsId = item.id
      const data = {
        objectId: this.objectId,
        objectType: this.objectype,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parentsId: item.id
      }
      this.secondList = []
      this.getCommentList(data)
    },
    // 上拉刷新
    refreshData(refreshing) {
      // 表示处于可以下拉状态
      this.refreshing = refreshing
      this.finished = false
      this.loading = true
      this.list = []
      this.secondList = []
      this.activeIndex = 0
      // 一级列表请求参数
      const data = {
        objectId: this.objectId,
        objectType: this.objectype,
        pageNum: 1,
        pageSize: this.pageSize,
        parentsId: null
      }
      this.getCommentList(data)
    },
    // 下拉加载
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      // 一级列表请求参数
      const data = {
        objectId: this.objectId,
        objectType: this.objectype,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parentsId: this.parentsId
      }
      this.getCommentList(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-pop {
  height: 60vh;
}
//  评论主体内容
.content-main {
  display: flex;
  padding: 0.3rem;
  box-sizing: border-box;
  max-width: 100%;
  border-bottom: 0.02rem solid #eee;
  .user-header {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.2rem;
    :deep() {
      .warp {
        font-size: 0;
        border-radius: 50%;
      }
      .img {
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
  .comment-user {
    max-width: 100%;
    .first-comment,
    .second-comment {
      font-size: 0.22rem;
      color: #fff;
      margin-top: 0.1rem;
      .zuoze {
        display: inline-block;
        width: 0.65rem;
        height: 0.37rem;
        background: #999999;

        font-size: 0.24rem;
        color: #fff;
        text-align: center;
        line-height: 0.37rem;
        border-radius: 0.05rem;
        margin-left: 0.2rem;
      }
      .comment-user-name {
        font-size: 0.26rem;

        color: #999999;
      }
      .comment-user-time {
        font-size: 0.18rem;
        color: #999999;
        margin: 0;
        margin-right: 0.3rem;
      }
      .comment-user-text {
        max-width: 100%;
        font-size: 0.18rem;
        word-break: break-all;
        word-wrap: break-word;
        margin: 0;
        color: #333;
        margin: 0.1rem 0;
        font-size: 0.28rem;
      }
    }
  }
}
// 回复文本样式
.resTxt {
  @include flexbox;
  color: #999999;
  img {
    width: 0.35rem;
    margin-right: 0.1rem;
  }
}
.second-comment {
  display: flex;
  .user-header {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.2rem;
  }
}
.comment-user-res {
  @include flexbox;
}
// 展开回复
.comment-user-open {
  &-right {
    color: #3a84f6;
  }
}
// 提交按钮
.submit-box {
  position: fixed;
  width: 100%;
  max-width: $pcMaxWidth;
  bottom: 0;
  font-size: 0.3rem;
  background-color: #fff;
  .line-bot {
    height: 0.02rem;
    background-color: #e9e9e9;
  }
  // 评论发送按钮
  .submit-input {
    @include flexbox;
    margin: 0.3rem auto;
    font-size: 0.26rem;
    padding: 0 0.3rem;
    .submit-txt {
      width: 1.2rem;
      height: 0.6rem;
      display: inline-block;
      background: $btnBg;
      border-radius: 0.3rem;
      text-align: center;
      line-height: 0.6rem;
    }
    :deep() {
      .van-cell {
        background-color: #f4f4f4;
        border-radius: 0.3rem;
        margin-right: 0.3rem;
        padding: 0.1rem 0.2rem;
      }
    }
  }

  // 开通会员提示按钮
  .submit-btn {
    color: #fff;
    background-color: $btnBg;
    width: 6rem;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    margin: 0.3rem auto;
    border-radius: 0.2rem;
  }
}
.color {
  color: #333;
}
</style>
