<template>
  <!-- 常规播放页 -->
  <ShortVideo :homeSvParams="homeSvParams"></ShortVideo>
</template>

<script>
export default {
  components: {
    ShortVideo: () => import('@/components/ShortVideo/index.vue'),
  },
  data(){
    return{
      homeSvParams:{}
    }
  },
  mounted(){
    if(this.$route.params.id=='推荐'){
      this.homeSvParams = {name:'推荐',nowPosit:'首页短视频'}
    }
  },
};
</script>
