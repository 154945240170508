<template>
  <div class="home-wrap">
    <!--切换面板 -->
    <AppIndex />


  </div>
</template>
<script>

export default {
  name: 'alivePage',
  components: {
    AppIndex: () => import('@/components/AppIndex/index.vue'),


  },
  data() {
    return {

    }
  },
  computed: {


  },
  destroyed() {
  },
  methods: {


  },
  async mounted() {

  }
}
</script>

<style lang="scss" scoped>

</style>
