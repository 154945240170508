<template>
  <div :class="listType == 1 ? 'flexStyle' : ''">
    <span @click="toPicview" :class="[
      { isNew: item.isNew },
      { isFree: item.isFree },
      { nowChapter: nowChapter },
    ]" v-if="listType == 1" class="small-item">
      第{{ item.indexName }}话
    </span>
    <div class="listMode" @click="toPicview" v-else-if="listType == 2">
      <DecryptImg class="bgImages" :lazy="false" :imgURL="bgImages"></DecryptImg>
      <div class="listModeR">
        <div>{{ item.title }}</div>
        <div class="LabelPack">
          <div class="Label" :class="{ 'isFree': item.isFree }," v-if="item.isFree">
            免费
          </div>
          <div class="Label" v-else :class="[{ isVip: shoudBuy == 1 }, { isGold: shoudBuy == 2 }]">
            {{ shoudBuy == 1 ? 'vip' : price / 100 + '金币' }}
          </div>
        </div>
      </div>
    </div>
    <div @click="toPicview" class="default_item" v-if="listType == 0">
      <!-- 第{{ item.indexName }}话 -->
      <div class="item-left">
        <DecryptImg class="bgImages" :lazy="false" :imgURL="bgImages"></DecryptImg>
        <div class="item_info">
          <span>第{{ item.chapterNum }}话</span>
          <span>{{ item.title }}</span>
        </div>
      </div>
      <div class="Label" :class="{ 'isFree': item.isFree }" v-if="item.isFree">
        免费
      </div>
      <div class="Label" v-else :class="[{ isVip: shoudBuy == 1 }, { isGold: shoudBuy == 2 }]">
        {{ shoudBuy == 1 ? 'vip' : '整本解锁仅需' + price / 100 + '金币' }}
      </div>
    </div>
    <DmPopup ref="popupMsg"></DmPopup>
    <!-- <VideoVipPopup v-if="showVipBuyPop" @close="reloadPlay" />
    <videoGoldPopup v-if="showGoldBuyPop" :price="price" @close="reloadPlay" /> -->
  </div>
</template>
<script>
import { novelViewRightTo } from '@/utils/utils_tools'

export default {
  components: {
    DmPopup: () => import('@/components/Popup/index.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    // VideoVipPopup: () => import('@/components/Popup/videoVipPopup.vue'),
    // videoGoldPopup: () => import('@/components/Popup/videoGoldPopup.vue'),
  },
  data() {
    return {
      nowChapter: false,
      showGoldBuyPop: false,
      showVipBuyPop: false
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listType: {
      type: Number,
      default() {
        return 1
      },
    },
    bgImages: {
      type: String,
      default() {
        return ''
      },
    },
    shoudBuy: {
      type: Number,
      default() {
        return 0 //1vip 2金币
      },
    },
    price: {
      type: Number,
      default() {
        return 0 //金币数量
      },
    },
    isAudio: {
      type: Boolean,
      default() {
        return false
      },
    },
    isCollect: {
      type: Boolean,
      default() {
        return false
      },
    },
    isEnoughMoney: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  computed: {},
  methods: {
    reloadPlay() {
      this.showGoldBuyPop = false
      this.showVipBuyPop = false
    },
    async toPicview() {
      console.log(this.item, '000');
      novelViewRightTo(
        this,
        this.item.id,
        2,
        this.price,
        this.isEnoughMoney,
        this.isCollect,
        this.shoudBuy,
        this.$route.params.id,
        this.isAudio ? true : false
      )

    },
    //收藏位置
    findIndex() {
      let newItem = {}
      //数据库找出这本书
      this.bookshelfList.forEach((element1) => {
        element1.list.forEach((element2) => {
          if (element2.id == +this.$route.params.id) {
            newItem = element2
          }
        })
      })
      //对比这本书看到的章节和当前章节是否相等
      if (this.item.indexName == newItem.hasViewNum) {
        this.nowChapter = true
      }
    },
  },
  mounted() {
    //漫画预览页，直接拿id显示当前
    if (this.$route.path.includes('/home-comic/pic-view/')) {
      if (+this.$route.params.id == this.item.id) {
        this.nowChapter = true
      }
    }
    //漫画介绍页，拿缓存记录上次已看到的页面
    if (this.$route.path.includes('/home-comic/decial/')) {
      this.findIndex()
    }
  },
  computed: {
    bookshelfList({ $store }) {
      return $store.state.history.bookshelfList
    },
  },
}
</script>

<style lang="scss" scoped>
.flexStyle {
  text-align: center;
  width: 1.18rem;
  height: 0.72rem;
  line-height: 0.72rem;
  margin-top: 0.14rem;
  margin-right: 0.24rem;

}

.small-item {
  display: inline-block;
  border-radius: 0.08rem;
  background: #F7F6FB;
  width: 100%;
  height: 100%;

  // &.isNew{
  //   background: url("/newAppMh/home-comic/isNew.png");
  //   background-size:100% 100% ;
  // }
  &.isFree {
    background: #F7F6FB;
    background-image: url('/newAppMh/home-comic/isNew.png');
    background-size: 100% 100%;
  }

  &.nowChapter {
    color: #ff7777;
    background-image: url('/newAppMh/home-comic/isNew-red.png') top right no-repeat;
    background-size: 0.22rem 0.22rem;
  }

}

.listMode {
  width: 6.78rem;
  height: 1.28rem;
  margin-bottom: 0.26rem;
  border-radius: 0.14rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .bgImages {
    min-width: 2.28rem;
    max-width: 2.28rem;
    margin-right: 0.2rem;
    height: 1.28rem;
    border-radius: 0.08rem;
  }

  .listModeR {
    height: 100%;
    padding-right: 0.28rem;

    .LabelPack {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 0.24rem;

    }
  }
}

.Label {
  padding: 0 0.04rem;
  height: 0.32rem;
  border-radius: 0.04rem;
  color: #fff;
  text-align: center;
  font-size: 0.22rem;

  &.isFree {
    background: #0d9c09;
  }

  &.isVip {
    background: #FF669D;
  }

  &.isGold {
    background: #FF669D;
  }
}

.default_item {
  border-radius: 0.08rem;
  border: 0.01rem solid #AAA;
  margin-top: 0.16rem;
  @include flexbox($ai: flex-end);
  width: 100%;
  padding: 0.16rem;
  height: 1.12rem;

  .bgImages {
    width: 0.7rem;
    height: 0.8rem;
  }

  .item-left {
    @include flexbox($jc: flex-start, $ai: flex-start);
  }

  .item_info {
    @include flexbox($jc: flex-start);

    span {
      color: #999;

      &:first-child {
        color: #333;
        margin: 0 0.18rem;
        white-space: nowrap;
      }

      &:last-child {
        @include textoverflow();
        width: 2.5rem;
      }
    }
  }
}
</style>
