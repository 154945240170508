<template>
  <div class="header">
    <div  class="decial-wrap">
      <span class="name">{{itemTopic.name}}</span>
      <!-- 有价格 -->
      <div class="buy-wrap" v-if="itemTopic.price">
        <div v-if="!itemTopic.isBuy" @click="fn_buyHj" class="doNotPay" >
          共{{itemTopic.comicsCount}}部  
          <div class="buy-btn">
            <div class="price">
              <img src="/newAppMh/mine/pay/coin.png" alt="">
              {{itemTopic.price | changeMoneyYuan}}
            </div>
            <div  class="btn">
              购买
            </div>
          </div>          
        </div>
        <div v-else class="hasBuy">
          共{{itemTopic.comicsCount}}部  
          <div>已购买</div>
        </div>
      </div>      
      <span v-else class="desc">{{itemTopic.desc}}</span>
    </div>

    <div class="more" @click="toGo">更多 <van-icon name="arrow" /></div>
  </div>    
</template>
<script>
export default {
  props: {
    itemTopic: {
      type: Object,
      default() {
        return {}
      },
    },  
  },
  computed: {

  },
  methods: {
    //合集购买
    async fn_buyHj(){
      const res = await this.$mediaGatherPay({
        id: this.itemTopic.id,
      });
      if (res.code === 200&&res.data.code===200) {
        this.$toast("合集购买成功");
        this.itemTopic.isBuy=true;
      } else {
        this.$toast(res.data.msg||res.tip);
        if(res.data.msg=='余额不足'){
          this.$router.push({path:`/mine/wallet`});          
        }
      }            
    },    
    toGo(){
      if(this.itemTopic.price){
           this.$router.push({
          path: `/home-novel/more`, query: {
            id: this.itemTopic.id,
            name: this.itemTopic.name,
            num: this.itemTopic.novelCount
          }
        });
      }else{
        this.$router.push({
          path: `/home-novel/more/list`, query: {
            id: this.itemTopic.id,
            name: this.itemTopic.name,

          }
        });
     
      }
      
    }
  },
  mounted(){
   
  }
}
</script>

<style lang="scss" scoped>
  .header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 1.05rem;
    padding: 0.3rem 0.37rem ;
    .decial-wrap{
      font-size: 0.32rem;
      color: #6a6a6a;
      display: flex;
      align-items: center;        
      .desc{
        color:#6a6a6a;
        opacity: 0.5;
        font-size: 0.24rem;
        padding-left: 0.1rem;        
      }  
      .desc,.name{
        max-width: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: normal;  
      }   
      .buy-wrap{
        .doNotPay{
          font-size: 0.24rem;
          display: inline-block;
          display: flex;
          align-items: center;
          margin-left: 0.1rem;
          .buy-btn{
            margin-left: 0.22rem;
            @include box-shadow-all;
            background: #fff;
            display: flex;
            align-items: center;
            border-radius: 0.12rem;
            .price{
              display: flex;
              align-items: center;
              padding: 0.12rem 0.19rem;
              img{
                margin-right: 0.05rem;
                width: 0.25rem;
              }
            }
            .btn{
              padding: 0.12rem 0.19rem;
              background: #e15d59;
              color: #fff;
              border-radius: 0.12rem;
            }
          }          
        }
        .hasBuy{
          font-size: 0.24rem;
          display: flex;
          align-items: center;
          margin-left: 0.1rem;
          div{
            margin-left: 0.22rem;
            font-size: 0.24rem;
            padding: 0.12rem 0.19rem;
            background: #e15d59;
            color: #fff;
            border-radius: 0.12rem;            
          }

        }
      }         
    }
    .more{
      background: #fff;
      @include flex-center;
      font-size: 0.24rem;
      width: 1.02rem;
      height: 0.46rem;
      // color: #ff8b00;
      border-radius: 0.12rem;
      // box-shadow: 0.06rem 0.06rem 0.1rem rgb(0 0 0 / 10%);
    }        
  }
</style>
