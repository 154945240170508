<template>
  <div>
    <EBackTop title="中奖记录"> </EBackTop>
    <div class="media-detail">
          <!-- 列表 -->
          <PullUp
            @refreshData="refreshData"
            @moreData="moreData"
            :finished="finished"
            :loading="loading"
            :refreshing="refreshing"
          >
            <div class="item" v-for="(item,index) in mediaList" :key="index">
                {{ item.prizeName }}
            </div>

          </PullUp>
        </div>
      </div>
  </template>

  <script>
import { getLotteryList, checkLottery, lotteryHistory } from 'api/user'
import { gather_buy } from 'api/home'
  export default {
    name: 'LotHistory',
    data() {
      return {
        refreshing: false,
        loading: false,
        finished: false,
        mediaList:[],
        pageNum:1,
        pageSize:10,
        type: 0,
      }
    },
    components: {
      PullUp: () => import('@/components/PullUp.vue'),
    },
    created() {
      this.refreshData()
    },
    methods: {
       // 合集购买
      buyCollection() {
        if (!this.topicInfo.isBuy) {
          this.showBuy = true
        }
      },
        // 购买弹窗关闭事件
      closed(close) {
        this.showBuy = close
      },
      // 购买弹窗购买按钮
      async shoudBuy() {
        try {
          const res = await gather_buy({
            id:this.topicInfo.id
          })
          if (res.code === 200) {
            this.topicInfo.isBuy = true
            this.showBuy = false
          } else {
            return this.$toast(res.tip)
          }
        } catch (error) {
          console.log(error)
          return this.$toast('请求错误，请稍后再试！')
        }
      },
      change(index) {
         this.finished = false
        this.sort = index
        this.refreshData()
      },
      moreData(loading) {
        this.loading = loading
        this.pageNum += 1
        this.getTopicDetails()
      },
      refreshData(refreshing) {
        this.refreshing = refreshing
        this.loading = true
        this.finished = false
        this.pageNum = 1
        this.mediaList = []
        this.getTopicDetails()
      },
      // 获取专题详情
      async getTopicDetails() {
        try {
          let param = {pageNum:this.pageNum,pageSize:this.pageSize, type: this.type}
          const res = await lotteryHistory(param)
          if (res.code === 200) {
            this.refreshing = false
            this.loading = false
            this.mediaList = [...this.mediaList, ...res.data.list]
          if (!res.data.list || res.data.list.length < this.pageSize) {
            this.finished = true
          }
          } else {
            this.refreshing = false
            this.loading = false
            this.finished = true
            this.$toast(res.tip)
          }
        } catch (error) {
          this.refreshing = false
          this.loading = false
          this.finished = true
          console.log(error)
          this.$toast('请求失败，请稍后再试！')
        }
      },
    }
  }
  </script>

  <style lang="scss" scoped>
  .media-detail{
    padding: .3rem;
  }
  .item{
    width: 100%;
    height: .9rem;
    margin-bottom: .3rem;
    background-color: #FFE3EA;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .32rem;
    color:#2E2E2E;
  }

  </style>
