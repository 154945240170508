<template>
  <ul :class="`${type == 0 ? 'bot_list' : type == 1 ? 'type_1' : type == 2 ? 'type_2' : 'bot_list'}`">
    <li v-for="appItem in activeItem" :key="appItem.id">
      <div class="avatar">
        <DecryptImg @click.native="clickImg(appItem)" :imgCDN="cdn" :imgURL="appItem.avatar">
        </DecryptImg>
      </div>

      <p class="font">{{ appItem.name }}</p>
    </li>

    <li v-if="type == 0"></li>
    <li v-if="type == 0"></li>
    <li v-if="type == 0"></li>
  </ul>
</template>
<script>
import { appPage, appsClick } from 'api/home'
export default {
  props: {
    activeItem: {
      type: Array,
      default: [],
    },
    type: {
      type: Number,
      default: 0
    }
  },
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {

    }
  },
  computed: {
    // 图片cdn
    cdn() {
      return this.$store.getters['config/cdn']
    }
  },

  created() {

  },
  methods: {
    async clickImg(appItem) {
      appsClick({
        id: appItem.id
      }).then((res) => {
        if (res.code === 200) {
          if (!appItem.download_url) return
        } else {
          return this.$toast('统计出错！')
        }
      })
      window.open(appItem.download_url, '_self')
    },
  }
}
</script>
<style lang="scss" scoped>
.bot_list {
  // padding: 0.25rem 0.3rem 2.25rem 0.3rem;//
  // padding: 0.25rem 0.3rem 2.25rem 0.3rem;
  @include flexbox($fw: wrap);

  li {
    margin-right: 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.2rem;
    font-size: 0.28rem;
    width: 1.3rem;

    &:nth-child(5n) {
      margin-right: 0;
    }

    .avatar {
      width: 1rem;
      height: 1rem;
      border-radius: 0.18rem;

      ::v-deep {
        img {
          border-radius: 0.18rem;
        }
      }
    }

    .font {
      margin: .12rem 0 .2rem 0;
      font-size: 0.24rem;
      max-width: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.type_1 {
  @include flexbox($fw: wrap);

  li {
    margin-right: 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.2rem;
    font-size: 0.28rem;
    width: 3.5rem;

    &:nth-child(2n) {
      margin-right: 0;
    }

    .avatar {
      width: 3.3rem;
      height: 1.7rem;
      border-radius: 0.18rem;
      overflow: hidden;

      ::v-deep {
        img {
          border-radius: 0.18rem;
        }
      }
    }

    .font {
      font-size: 0.24rem;
      width: 3.3rem;
      line-height: .5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: .07rem 0 .05rem 0;
    }
  }
}

.type_2 {
  @include flexbox($fw: wrap);

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.3rem;
    font-size: 0.28rem;
    width: 100%;
    padding: 0 .1rem;

    .avatar {
      width: 6.9rem;
      height: 3rem;
      border-radius: 0.18rem;
      overflow: hidden;

      ::v-deep {
        img {
          border-radius: 0.18rem;
        }
      }
    }

    .font {
      font-size: 0.24rem;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: .12rem 0;
    }
  }
}
</style>
