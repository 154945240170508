<template>
  <div class="home-index">
    <div class="tab-main">
      <PullUp
        @refreshData="refreshData"
        @moreData="moreData"
        :finished="finished"
        :loading="loading"
        :refreshing="refreshing"
      >
        <div class="list-box">
          <LongVideoOneCard
            class="LongVideoOneCard"
            :cardType="cardType"
            v-for="(item, index) in listData"
            :key="index"
            :item="item"
          >
            <!-- 遮罩层 -->
            <div class="overlay" v-if="showMask" @click.stop="clickItem(item)">
              <img
                :src="
                  item.check
                    ? require('@/assets/imgs/mine/active_check.png')
                    : require('@/assets/imgs/mine/default_check.png')
                "
                alt=""
              />
            </div>
          </LongVideoOneCard>
        </div>
      </PullUp>
    </div>
  </div>
</template>

<script>
import { collect_list, collect_del } from 'api/user'
import { pay_history } from 'api/user'

export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,
      listData: [],
      newList: [],
      finished: false // 每次请求到的数据长度
    }
  },
  props: ['cardType', 'showMask'],
  components: {
    PullUp: () => import('@/components/PullUp.vue'),
    LongVideoOneCard: () => import('./components/LongVideoOneCard.vue')
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    longVideoList({ $store }) {
      return $store.getters['longVideoList']
    }
  },
  async mounted() {
    await this.refreshData()
  },
  methods: {
    // 收藏接口
    async getList() {
      // OperationTypeCollect OperationType = 1 //收藏
      // OperationTypeLike    OperationType = 2 //点赞
      // OperationTypeStepOn  OperationType = 3 //踩
      /**
      * ContentTypeVideoAv       ContentType = 1  // AV（长视频）
        ContentTypeVideoMedia    ContentType = 2  // 小视频
        ContentTypeViodeModel    ContentType = 4  // 上门 (帝王调教)
        ContentTypePost          ContentType = 5  // 帖子（暗网交易）
        ContentTypeViodeLoufeng  ContentType = 6  // 楼凤 (暗黑游)
      *
      *
      */
      if (this.cardType === 'history') {
        this.listData = JSON.parse(JSON.stringify(this.longVideoList))
        this.refreshing = false
        this.loading = false
        this.finished = true
      } else {
        let apiObj = {
          collect: collect_list,
          order: pay_history
        }
        const res = await apiObj[this.cardType]({
          collectType: this.cardType === 'collect' ? 1 : undefined,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type: 1 // 收藏
        })
        if (res.code === 200) {
          if (res.data.mediaList && res.data.mediaList.length && res.data.mediaList.length <= this.pageSize) {
            this.listData = [...this.listData, ...res.data.mediaList]

            this.refreshing = false
            this.loading = false
          } else {
            this.refreshing = false
            this.loading = false
            this.finished = true
          }
        }
      }
      this.listData = this.listData.map(item => {
        item.check = false
        return item
      })
    },
    // 上拉加载更多
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getList()
    },
    // 下啦刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = false
      this.listData = []
      this.pageNum = 1
      this.getList()
    },
    clickItem(item) {
      item.check = !item.check
      if (this.cardType === 'history') {
        this.newList = this.listData.filter(item => {
          return !item.check
        })
        this.$emit('checkList', this.newList)
      } else if (this.cardType === 'collect') {
        this.newList = this.listData.map(item => {
          if (item.check) {
            return item.id
          }
        })
      }

      this.$forceUpdate()
    },
    async delCollect() {
      const res = await collect_del({
        collectType: 1,
        objectIds: this.newList,
        type: 1
      })
      if (res.code === 200) {
        this.refreshData()
      } else {
        return this.$toast('操作失败，请联系后台管理员！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-index {
  min-height: 100vh;
  margin-top: 1rem;
}

.list-box {
  padding: 0.36rem;
  @include flexbox($fw: wrap);

  .LongVideoOneCard {
    position: relative;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  img {
    width: 0.48rem;
    height: 0.48rem;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  }
}
</style>
