<template>
  <div class="allType-wrap">
    <HeaderBar :title="title"></HeaderBar>
    <div class="scopePack">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad"
          :immediate-check="false" error-text="请求失败，点击重新加载">
          <div class="list-wrap">
            <ul>
              <HomeVideoCard v-for="(item, index) in comicsList" :showType="1" :imgRadius="'0.15rem'" :item="item"
                :key="index"></HomeVideoCard>
            </ul>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { noveListByTag } from 'api/novel'
export default {
  components: {
    HomeVideoCard: () => import('@/components/HomeNovel/oneCard'),
    HeaderBar: () => import('@/components/HeaderBar.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {
      //加载刷新
      id: null,
      pageNum: 1,
      pageSize: 10,
      refreshing: false,
      loading: false,
      finished: false,

      // integer
      // 字数；0全部，1）30万以下；2）30-50；3）50-80；4）80-100；5）100-200；6）200以
      // 列表
      comicsList: [],
      title: ''
    }
  },
  computed: {},
  methods: {
    //标签导航
    //获取小说标签列表
    async getComicsTagListById(type) {
      const res = await noveListByTag({
        id: +this.$route.query.id,
        //   payType: this.payType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        //   updateStatus: this.statusId,
        num: 0
      });
      if (res.code === 200 && res.data) {

        this.refreshing = false;
        this.loading = false;
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true;
        }
        if (type == "pull") this.comicsList = [];
        if (res.data.list) this.comicsList = [...this.comicsList, ...res.data.list];
      } else {
        this.finished = true;
        this.refreshing = false;
        this.loading = false;
        return this.$toast(res.tip)
      }
    },
    // 排序: 1:AV 2:小视频 3:动漫
    //上拉加载更多
    async onLoad() {
      this.pageNum += 1
      this.getComicsTagListById();
    },
    // 刷新
    onRefresh() {
      this.pageNum = 1;
      this.finished = false;
      this.loading = true;
      this.getComicsTagListById('pull');
    },
  },

  mounted() {
    //   this.getComicsTagAll();
    this.getComicsTagListById()
    this.title = this.$route.query.name
  },
}
</script>

<style lang="scss" scoped>
.allType-wrap {
  color: #6a6a6a;

  :deep() {
    .col50-wrap {
      width: 32%;

      .md-img {
        height: 2.94rem;
      }
    }
  }

  .scopePack {
    // height: 100vh;
    // padding-top: $navHeight;
    padding-top: 0.9rem;
    overflow: hidden;
    position: relative;

    .topTabPack {
      // height: 20vh;
      padding-bottom: .06rem;

      .TagBack {
        display: flex;
        //   align-items: center;
        font-size: .28rem;
        padding: 0 .3rem;
        padding-bottom: .04rem;

        .topTag {
          color: #333332;
          width: .8rem;
          font-size: .3rem;
          font-weight: 500;
          padding-top: .05rem;
        }

        .allType-tag-all {
          width: 100%;
          overflow-x: auto;
          display: flex;

          @include scrollbar-hide;

          li {
            white-space: nowrap;
            text-align: center;
            height: 0.5rem;
            display: flex;
            align-items: center;
            padding: 0.05rem 0.15rem;
            border-radius: 0.1rem;
            color: #979797;
            font-weight: 400;

            &:nth-child(5n) {
              margin-right: 0;
            }

            &.active {
              font-weight: 500;
              color: #8361F6;
              border-radius: 0 1rem 1rem 0;
            }
          }
        }
      }

      .topTab {
        padding: .14rem .3rem;
        margin-bottom: .1rem;
        border-top: .01rem solid rgba(0, 0, 0, 0.1);
        border-bottom: .01rem solid rgba(0, 0, 0, 0.1);
      }
    }


    .van-pull-refresh {

      // height: calc(80vh - 1.7rem);
      // overflow-y: auto;
      .list-wrap {
        padding: 0 0.3rem;
        margin-top: .3rem;

        ul {
          display: flex;
          flex-wrap: wrap;

          //   justify-content: space-between;
          li {
            width: 33%;
            padding: 0 0.15rem;
          }
        }
      }
    }
  }
}
</style>
