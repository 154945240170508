<template>
  <div class="community-detail">
    <EBackTop :title="'文章详情'" :open-fallback="true" >
      <template #right>
        <div class="collent_btn" @click="clickShare">分享</div>
      </template>
    </EBackTop>
    <ERefreshLoad
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
    >
      <PostItem :isDetail="true" :showType="1" :itemData="detailInfo" @getNewDetail="getDetail" />
      <Comment
        :isShowUpload="true"
        class="comment-box"
        :publisher="detailInfo.base"
        :objectId="+$route.params.id"
        :objectype="2"
        :disabledPull="true"
      />
    </ERefreshLoad>
    <SharePopup
      :sharePopShow="showShare"
      @close="
        e => {
          showShare = e
        }
      "
    />
  </div>
</template>

<script>
import { community_detail } from 'api/community'
import { comment_list, comment_add, collect } from 'api/home'
export default {
  name: 'CommunityDetail',
  data() {
    return {
      detailInfo: {
        nodes: [],
        base: {}
      },
      finished: false,
      loading: false,
      refreshing: false,
      activeIndex: 0, // 展开的id
      commentList: [], // 一级评论列表
      pageNum: 1,
      pageSize: 10,
      text: '', // 评论
      placeholder: '喜欢就给个评论支持一下～',
      parentsId: 0, // 父级id
      secondList: [], // 二级评论列表
      popupShow: false,
      dataItem: {},
      showShare: false
    }
  },
  components: {
    PostItem: () => import('@/components/Community/default.vue'),
    // PopupComment: () => import('@/components/Community/popupComment.vue'),
    Comment: () => import('@/components/Comment/index.vue'),
    SharePopup: () => import('@/components/SharePopup.vue')
  },
  async created() {},
  async mounted() {
    await this.getAndSetDetail()
  },
  computed: {
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    clickShare() {
      this.showShare = true
    },
    async getAndSetDetail() {
      await this.getDetail().then(() => {
        this.$store.dispatch('setPostList', {
          type: 'add',
          item: this.detailInfo
        })
      })
    },
    async addLike(item) {
      // 收藏信息
      try {
        const res = await collect({
          flag: !item.isCollect,
          object_id: item.id,
          collect_type: 10,
          type: 1
        })
        if (res.code === 200) {
          item.isCollect = !item.isCollect
          if (item.isCollect) {
            item.collects += 1
            return this.$toast('收藏成功')
          } else {
            item.collects -= 1
            return this.$toast('取消收藏')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    // 获取详情信息
    async getDetail() {
      try {
        const res = await community_detail({
          id: +this.$route.params.id
        })
        if (res.code === 200) {
          this.detailInfo = res.data
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 触发评论列表刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.finished = false
      this.loading = true
      this.commentList = []
      this.secondList = []
      this.activeIndex = 0
      this.getCommentList({
        objectId: +this.$route.params.id,
        objectType: 2, //1:视频 2旧帖子 3: 4发现页帖子
        pageNum: 1,
        pageSize: 10,
        parentsId: null
      })
    },
    // 触发onload
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getCommentList({
        objectId: +this.$route.params.id,
        objectType: 2, //1:视频 2旧帖子 3: 4发现页帖子
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parentsId: this.parentsId
      })
    },
    // 获取评论列表
    async getCommentList(data) {
      try {
        const res = await comment_list(data)
        if (res.code === 200) {
          // 有parentsId 为二级列表请求
          this.loading = false
          this.refreshing = false
          if (data.parentsId) {
            this.secondList = [...this.secondList, ...res.data.list]
            if (!res.data.list || (res.data && res.data.list.length < this.pageSize)) {
              this.parentsId = null
            }
          } else {
            this.commentList = [...this.commentList, ...res.data.list]
            if (!res.data.list || (res.data && res.data.list.length < this.pageSize)) {
              this.finished = true
            }
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          return this.$toast(res.tip || res.data.msg)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 点击回复按钮
    clickRespondText(item) {
      // if (!this.isMember) {
      //   return this.$toast('会员才参与可以评论!')
      // }
      this.placeholder = `回复 ${item.userName}`
      this.parentsId = item.id
      this.replyId = item.userId
    },
    // 打开二级评论列表
    openSon(item) {
      ;(this.dataItem = {
        objectId: item.objectId,
        parentsId: item.id,
        replyId: item.replyId,
        landlord: this.detailInfo.userId,
        upCommentList: item.upCommentList,
        commentCount: item.commentCount, //回复数
        userName: item.userName
      }),
        (this.popupShow = true)
    },
    closePopup() {
      this.popupShow = false
    },
    // 发布评论
    async addComment() {
      if (!this.text) {
        return this.$toast('请输入评论内容!')
      }
      const res = await comment_add({
        objectId: this.detailInfo.userInfo.id,
        objectType: 4, // 对象类型
        parentsId: this.parentsId, // 上级id
        replyId: this.replyId, // 回复id
        text: this.text
      })
      this.activeIndex = 0
      if (res.code === 200) {
        this.commentList = []
        this.secondList = []
        this.text = ''
        const data = {
          objectId: this.detailInfo.userInfo.id,
          objectType: 2,
          pageNum: 1,
          pageSize: 10,
          parentsId: null
        }
        this.getCommentList(data)
        return this.$toast('评论成功')
      } else {
        this.loading = false
        this.isLoading = false
        this.finished = true
        return this.$toast(res.tip || res.data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.collent_btn {
  display: inline-flex;
  padding: 0.08rem 0.24rem;
  align-items: center;
  gap: 0.04rem;
  border-radius: 0.32rem;
  background: $btnBg;
  color: #fff;
}
.community-detail {
  padding-bottom: 1.1rem;
  border-radius: 0.12rem;
  box-shadow: $shadow;
  min-height: 100vh;
  :deep(){
    .BackTop{
      border-bottom: 0.01rem solid #999;

    }
    .van-nav-bar__content{

      .van-nav-bar__title{
        margin-left: 1.3rem;
      }
    }
  }
}
//  评论主体内容
.content-main {
  display: flex;
  padding: 0.3rem 0.2rem;
  box-sizing: border-box;
  max-width: 100%;
  .user-header {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.2rem;
    :deep() {
      .warp {
        font-size: 0;
        border-radius: 50%;
      }
      .img {
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
  .comment-user {
    width: 6rem;
    .first-comment,
    .second-comment {
      font-size: 0.22rem;
      color: #fff;
      margin-top: 0.1rem;
      .zuoze {
        display: inline-block;
        width: 0.65rem;
        height: 0.37rem;
        background: #fff;
        font-size: 0.24rem;
        color: #fff;
        text-align: center;
        line-height: 0.37rem;
        border-radius: 0.05rem;
        margin-right: 0.1rem;
      }
      .comment-user-name {
        font-size: 0.21rem;
        color: #8b8b8b;
        white-space: nowrap;
      }
      .comment-user-text {
        max-width: 100%;
        font-size: 0.28rem;

        word-break: break-all;
        word-wrap: break-word;
        margin: 0;
        color: #474747;
        margin: 0.1rem 0;
      }
    }

    .second-comment {
      margin-top: 0.2rem;
      background: #f8f8f8;
      border-radius: 0.1rem;
      padding: 0.2rem 0.25rem;
      display: flex;
      .second-comment-main {
        display: flex;
        .comment-user-name {
          color: #6eb3f1;
          font-size: 0.28rem;
          span {
            background: #ff669d;
            color: #fff;
            border-radius: 0.08rem;
            font-size: 0.18rem;
          }
        }
      }
    }
    .comment_bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.2rem;
      color: #aaaaaa;
      border-bottom: 0.02rem solid #eee;
      padding: 0.2rem 0;
      .bottom_l {
      }
      .bottom_r {
        display: flex;
        align-items: center;
        div {
          display: flex;
          align-items: center;
          margin: 0 0.16rem;
          img {
            height: 0.3rem;
            margin-right: 0.1rem;
          }
        }
      }
    }
  }
}
// 回复文本样式
.resTxt {
  @include flexbox;
  color: #fff;
  img {
    width: 0.35rem;
    margin-right: 0.1rem;
  }
}
// 展开回复
.comment-user-open {
  margin-top: 0.2rem;
  background: #f8f8f8;
  border-radius: 0.1rem;
  padding: 0.2rem 0.25rem;
  .second-comment {
    padding: 0 !important;
    margin-top: 0 !important;
    .second-comment-main {
      align-items: center;
      .comment-user-text {
        width: 100%;
        height: 0.3rem;
        line-height: 0.3rem;
        margin: 0 !important;
        @include textoverflow(1);
      }
    }
  }

  &-right {
    color: #6eb3f1;
    font-size: 0.28rem;
  }
}

.color {
  color: #333;
}
</style>
