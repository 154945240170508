<template>
  <van-popup class="catalogue-popup" v-model="catalogueObj.show" round position="bottom">
    <main class="catalogue-list">
      <div class="title">
        <div class="titleL">
          全部章节({{ list.length }})
          <div>连载中·更新到{{ list.length }}话</div>
        </div>
        <!-- <div class="titleR">
          <div v-if="listShowType" @click="listShowTypeChange(0)">
            <img src="@/assets/imgs/homec/list_icon.png" alt="" />
            列表模式
          </div>
          <div v-else @click="listShowTypeChange(2)">
            <img src="@/assets/imgs/homec/list_icon2.png" alt="" />
            缩略模式
          </div>
        </div> -->
        <div class="sort" @click="fn_sortDer">
            <img src="@/assets/imgs/novel/sort_icon_01.png" alt="">
          <div v-if="sortDer">正序</div>
          <div v-else>反序</div>
        </div>
      </div>

      <ul class="catalogue">
        <CatalogueItem v-for="(item, index) in list" :isEnoughMoney="isEnoughMoney" :item="item" :key="index"
          :listType="listShowType" :bgImages="bgImages" :shoudBuy="shoudBuy" :price="price" :isAudio="isAudio"
          :isCollect="isCollect"></CatalogueItem>
      </ul>
    </main>
  </van-popup>
</template>
<script>
export default {
  components: {
    CatalogueItem: () => import('@/components/HomeNovel/comic/catalogue/item'),
  },

  data() {
    return {
      sortDer: true,
      list: [],
      listShowType: 0,
    }
  },
  props: {
    isEnoughMoney: {
      type: Boolean,
      default() {
        return false
      },
    },
    catalogueObj: {
      type: Object,
      default() {
        return {}
      },
    },
    bgImages: {
      type: String,
      default() {
        return ''
      },
    },
    shoudBuy: {
      type: Number,
      default() {
        return 0
      },
    },
    price: {
      type: Number,
      default() {
        return 0
      },
    },
    isAudio: {
      type: Boolean,
      default() {
        return false
      },
    },
    isCollect: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  computed: {},
  methods: {
    listShowTypeChange(num) {
      this.listShowType = num
    },
    //排序
    fn_sortDer() {
      this.sortDer = !this.sortDer
      this.list = this.list.reverse()
    },
    //初始化数据
    initDatas() {
      this.list = JSON.parse(JSON.stringify(this.catalogueObj.list))
      //动画
      setTimeout(() => {
        this.catalogueObj.show = true
      }, 100)
    },
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
.catalogue-popup {
  left: auto;
  max-width: $pcMaxWidth;
  max-height: 60vh;
  overflow-y: scroll;
}

.catalogue-list {
  padding: 0.3rem 0.3rem 0 0.3rem;
  position: relative;
  background: #fff;
  border-top-left-radius: 0.44rem;
  border-top-right-radius: 0.44rem;

  .title {
    position: relative;
    text-align: center;
    font-size: 0.36rem;
    color: #6a6a6a;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titleL {
      font-size: 0.32rem;
      color: #6a6a6a;
      display: flex;
      align-items: center;

      div {
        background: rgba(255, 61, 0, 0.10);
        border-radius: 0.04rem;
        color: #FF669D;
        font-size: 0.22rem;
        padding: 0 0.1rem;
        margin-left: 0.2rem;
      }
    }

    .titleR {
      font-size: 0.24rem;
      color: #6a6a6a;

      div {
        display: flex;
        align-items: center;

        img {
          width: 0.42rem;
          height: 0.42rem;
          margin-right: 0.18rem;
        }
      }
    }

    .sort {
      font-size: 0.24rem;
      position: absolute;
      top: 0.1rem;
      right: 0;
      display: flex;
      align-items: center;

      img {
        width: 0.26rem;
        height: 0.21rem;
        margin-right: 0.1rem;
      }
    }
  }

  .catalogue {
    @include scrollbar-hide;
    max-height: 8.24rem;
    overflow-y: auto;
    margin-top: 0.2rem;
    padding-bottom: 0.3rem;
    padding-top: 0.25rem;
  }
}
</style>
