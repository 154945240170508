<template>
  <ul class="bot_list">

    <li class="cont" v-for="item in highQualityDesc" :key="item.id" @click="clickImg(item)">
      <div>
        {{ item.desc }}
      </div>
    </li>
    <li></li>
  </ul>
</template>
<script>
import { appsClick } from 'api/home'
export default {
  props: {
    highQualityDesc: {
      type: Array,
      default: [],
    },
    type: {
      type: Number,
      default: 0
    }
  },
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {

    }
  },
  computed: {
    // 图片cdn
    cdn() {
      return this.$store.getters['config/cdn']
    }
  },

  mounted() {

  },
  methods: {
    async clickImg(item) {
      appsClick({
        id: item.id
      }).then((res) => {
        if (res.code === 200) {
          if (!item.download_url) return
        } else {
          return this.$toast('统计出错！')
        }
      })
      window.open(item.download_url, '_self')
    },
  }
}
</script>
<style lang="scss" scoped>
.bot_list {
  margin-top: .6rem;
  padding: 0 .1rem;
  @include flexbox($fw: wrap);
  li {
    width: 2.1rem;
  }
  .cont {
    background-color: #ff95bb;
    border-radius: .15rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .3rem;
    height: .7rem;
    padding: 0 .3rem;
    div {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: .22rem;
      line-height: .23rem;
    }
  }
  // @include flexbox($fw: wrap);
  // .cont {
  //   background-color: #ff95bb;
  //   padding: .25rem ;
  //   padding-top: .1rem;
  //   border-radius: .25rem;
  //   color: #fff;
  //   font-size: 0.2rem;
  //   display: -webkit-box;
  //   -webkit-box-orient: vertical;
  //   -webkit-line-clamp: 2;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   max-height: .8rem;
  //   line-height: .32rem;
  // }
  // li {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin-bottom: 0.2rem;
  //   width: 2.1rem;
  // }
}
</style>
