<template>
  <div class="bossInfo-wrap">
    <div class="bossInfo-item">
      <!-- 妹子图片列表 -->
      <div class="girls-img" @touchmove.stop>
        <div class="girls-item" v-for="(item, index) in bossList" :key="index" @click="toDeatail(item)">
          <DecryptImg :needPadding="false" ref="refImg" :lazy="false" :imgRadius="'0.1rem'" :imgURL="item.avatar" />
          <div class="btnPack">
            <div class="btnPackLi">
              <div>认证妹子：{{ item.girls | numberFilter }}</div>
              <div>成交：{{ item.orders | numberFilter }}</div>
            </div>
            <div class="btnPackLi">
              <p class="recomment">人气最高</p>
              <div>粉丝：{{ item.fans | numberFilter }}</div>
            </div>
            <div class="btnPackLi">
              <p class="name">#{{ item.name }}</p>
              <img src="@/assets/imgs/icon_video_more.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bossList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  computed: {},
  mounted() {},
  methods: {
    toDeatail(item) {
      this.$router.push({
        path: `/dating/bosses-detail/${item.id}`,
        query: {
          name: item.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//商家推荐
.bossInfo-wrap {
  margin-bottom: 0.2rem;
  border-radius: 0.2rem;
  .bossInfo-item {
    width: 100%;
    border-radius: 0.2rem;
    overflow: hidden;
    // 妹子图片
    .girls-img {
      //   margin: 0.3rem 0;
      display: flex;
      align-items: center;
      overflow-y: auto;
      //   margin: 0.2rem 0.2rem 0.2rem 0.2rem;
      @include scrollbar-hide;
      .girls-item {
        width: 3.44rem;
        margin-right: 0.23rem;
        flex-shrink: 0;
        border-radius: 0.08rem 0.08rem 0 0;
        overflow: hidden;
        ::v-deep {
          .default {
            width: 100%;
            height: 3.94rem;
          }
        }
        .btnPack {
          width: 100%;
          background: #fff;
          padding: 0.16rem 0.2rem;
          color: #999;
          font-size: 0.22rem;
          .btnPackLi {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .recomment {
              background: #ff669d;
              color: #fff;
              font-size: 0.2rem;
              border-radius: 0.08rem;
              text-align: center;
              width: 1.5rem;
              padding: 0 0.2rem;
              margin: 0.16rem 0;
            }
            .name {
              border-radius: 0.08rem;
              background: rgba(22, 210, 214, 0.1);
              color: #333;
              font-size: 0.28rem;
              height: 0.46rem;
              line-height: 0.46rem;
              padding: 0 0.2rem;
              max-width: 2.62rem;
              overflow: hidden;
            }
            img {
              height: 0.2rem;
            }
          }
        }
      }
    }
    // 经纪人信息
    .boss-info {
      font-size: 0.24rem;
      display: flex;
      align-items: center;
      .line {
        width: 0.04rem;
        height: 0.17rem;
        background: #666666;
        margin: 0 0.3rem;
      }
    }
  }
}
</style>
