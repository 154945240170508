var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list && _vm.list.length > 0)?_c('div',{staticClass:"long-four"},[_vm._l((_vm.videoList.length ? _vm.videoList : _vm.list),function(item,index){return [(_vm.adMode && _vm.adPosition && _vm.discoverAD.length && _vm.position && _vm.position.includes(index % 10))?_c('div',{key:index,staticClass:"short-six-item"},[_c('RandomAdSwiper',{attrs:{"list":_vm.filList(_vm.discoverAD, index)}})],1):_vm._e(),_c('div',{staticClass:"short-six-item",on:{"click":function($event){return _vm.toPlay(item)}}},[_c('LongVideoCards',{attrs:{"item":item}})],1)]}),_c('i'),_c('i'),(_vm.showType == 3 || _vm.showType == 4 || _vm.showType == 2)?_c('div',{staticClass:"btn"},[_c('div',{on:{"click":_vm.fn_videoTopicChange}},[_c('img',{attrs:{"src":require("@/assets/imgs/index/exchangeIcon.png"),"alt":""}}),_vm._v("换一批")]),_c('div',{on:{"click":function($event){return _vm.$router.push({
        path: `/subpage/morePage`,
        query: {
          id: _vm.id,
          name: _vm.name,
          type: _vm.showType
        }
      })}}},[_vm._v(" 查看更多 ")])]):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }