<template>
  <!-- 图片预览注意事项 1.名字refImg  2.图片url叫imgUrl  -->
  <li :class="[{ rowNum3: rowNum == 3 }]">
    <DecryptImg :lazy="false" class="img-item" :imgURL="item" ref="refImg" @click.native.stop="imagePreview(item)">
      <img v-if="showClose" class="colse" @click.stop="deleteImg(item)" src="@/assets/imgs/colse.png" alt="" />
    </DecryptImg>
  </li>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  props: {
    rowNum: {
      type: Number,
      default() {
        return 1
      }
    },
    item: {
      type: String,
      default() {
        return ''
      }
    },
    showClose: {
      type: Boolean,
      default() {
        return false
      }
    },
    upLoadImgs: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  methods: {
    // 图片预览
    imagePreview(imgUrl) {
      let imgArr = []
      let index = 0
      this.$parent.$refs['refImgParent'].forEach((itemBlob, indexBlob) => {
        if (imgUrl == itemBlob.$refs['refImg'].imgURL) {
          index = indexBlob
        }
        imgArr.push(itemBlob.$refs['refImg'].realUrl)
      })
      ImagePreview({
        images: imgArr, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: true, //是否开启循环播放
        startPosition: index //图片预览起始位置索引
      })
    },
    // 取消上传,父组件的传值是数组或者对象时，子组件中不仅能够直接修改，还不会报错
    deleteImg(imgUrl) {
      let index = 0
      this.upLoadImgs.forEach((upLoadItem, upLoadIndex) => {
        if (upLoadItem == imgUrl) index = upLoadIndex
      })
      this.upLoadImgs.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.colse {
  top: 0.1rem;
  right: 0.1rem;
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
}
li {
  width: 100%;
  position: relative;
  margin-bottom: 0.2rem;
  &.rowNum3 {
    width: 33.33%;
    padding: 0 0.1rem;
    .colse {
      top: 0.05rem;
      right: 0.05rem;
      width: 0.3rem;
      height: 0.3rem;
      position: absolute;
    }
  }
}
</style>
