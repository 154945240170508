<template>
  <div class="allType-wrap">
    <EBackTop />
    <ul class="allType-nav-wrap">
      <li
        :class="{ active: navListItem.name == item.name }"
        v-for="(item, index) in navList"
        :key="index"
        @click="fn_navList(item)"
      >
        {{ item.name }}
      </li>
    </ul>
    <ul class="allType-tag-all">
      <li
        :class="{ active: tagAllItem.name == item.name }"
        v-for="(item, index) in comicsTagAllList"
        :key="index"
        @click="fn_comicsTagAll(item)"
      >
        {{ item.name }}
      </li>
    </ul>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂时没有更多数据！"
        @load="onLoad"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
      >
        <div class="list-wrap">
          <ul>
            <DmComicCard v-for="(item, index) in comicsList" :item="item" :key="index"></DmComicCard>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { comicsTagListById, comicsTagAll } from 'api/comics'

export default {
  components: {
    DmComicCard: () => import('@/components/HomeComic/oneCard'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {
      navListItem: {
        name: '最新',
        id: 0
      },
      navList: [
        { name: '最新', id: 0 },
        { name: '推荐', id: 1 },
        { name: '人气', id: 2 }
      ],
      //加载刷新
      pageNum: 1,
      pageSize: 10,
      refreshing: false,
      loading: false,
      finished: false,
      // 标签
      tagAllItem: {},
      comicsTagAllList: [],
      // 列表
      comicsList: []
    }
  },
  computed: {},
  methods: {
    //顶部导航
    fn_navList(item) {
      this.navListItem = item
      this.onRefresh()
    },
    //标签导航
    fn_comicsTagAll(item) {
      this.tagAllItem = item
      this.onRefresh()
    },
    //所有标签
    async getComicsTagAll() {
      const res = await comicsTagAll({})
      if (res.code === 200 && res.data) {
        //无标签默认第一个
        if (!this.$route.query.name) {
          // this.tagAllItem= res.data.list[0];
        } else {
          res.data.list.forEach((element, index) => {
            if (this.$route.query.name == element.name) this.tagAllItem = res.data.list[index]
          })
        }

        this.comicsTagAllList = res.data.list
        this.getComicsTagListById()
      } else {
        return this.$toast(res.tip)
      }
    },
    //获取漫画列表
    async getComicsTagListById(type) {
      const res = await comicsTagListById({
        id: this.tagAllItem.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.navListItem.id
      })
      if (res.code === 200 && res.data) {
        this.refreshing = false
        this.loading = false
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true
        }
        if (type == 'pull') this.comicsList = []
        if (res.data.list) this.comicsList = [...this.comicsList, ...res.data.list]
      } else {
        this.finished = true
        this.refreshing = false
        this.loading = false
        return this.$toast(res.tip)
      }
    },
    //上拉加载更多
    async onLoad() {
      this.pageNum += 1
      this.getComicsTagListById()
    },
    // 刷新
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.getComicsTagListById('pull')
    }
  },

  mounted() {
    this.getComicsTagAll()
  }
}
</script>

<style lang="scss" scoped>
.allType-wrap {
  min-height: 100vh;
  // padding-top: $navHeight;
  padding-bottom: 0.1rem;
  color: #6a6a6a;
  .allType-nav-wrap {
    font-size: 0.32rem;
    position: fixed;
    top: 0.36rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    li {
      white-space: nowrap;
      border-radius: 0.1rem;
      box-shadow: 0 1px 2px 0 #ebeaee inset;
      padding: 0.05rem 0.3rem;
      @include flex-center;
      color: rgba($color: #723c1f, $alpha: 0.7);
      &.active {
        @include box-shadow-all;
        background: #fff;
        color: #ff669d;
      }
    }
  }
  .allType-tag-all {
    box-shadow: 0 0.06rem 0.1rem rgb(0 0 0 / 10%) inset;
    padding: 0.2rem 0.37rem 0.2rem 0.37rem;
    display: flex;
    flex-wrap: wrap;
    li {
      white-space: nowrap;
      margin-right: 0.2rem;
      margin-bottom: 0.15rem;
      text-align: center;
      width: 1.16rem;
      padding: 0.05rem 0.1rem;
      border-radius: 0.1rem;
      box-shadow: 0 1px 2px 0 #ebeaee inset;
      color: rgba($color: #6a6a6a, $alpha: 0.7);
      &:nth-child(5n) {
        margin-right: 0;
      }
      &.active {
        color: #ff7777;
      }
    }
  }
  .list-wrap {
    padding: 0 0.37rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
</style>
