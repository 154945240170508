<template>
  <div class="setting">
    <EBackTop class="mine-head" :fixed="true" :title="'我的'" @click-right="submit">
      <slot slot="right" name="right"> 保存 </slot>
    </EBackTop>
    <div class="setting-main">
      <EDecryptImg :imgCDN="imgCDN" class="avatar" @click.native="$router.push(`/mine/setting/avatar?userAvatarUrl=${userAvatarUrl}`)" :imgURL="userAvatarUrl ? userAvatarUrl : ''"> </EDecryptImg>
      <!-- 基本信息 -->
      <div class="update-info">
        <div class="name">
          <div class="lable">{{`昵\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0称` }}</div>
          <div class="linebox" style="display: flex;align-items: center;">
            <van-field maxlength="10" v-model="nickName" placeholder="请输入昵称" />
            <span class="count">{{ nickName ? nickName.length : 0 }}/8</span>
          </div>

        </div>
        <div class="info">
          <div class="lable">个人简介</div>
          <div class="linebox" style="display: flex;align-items: center;">
          <van-field maxlength="30" v-model="introduction" placeholder="请输入个人简介" />
          <span class="count">{{ introduction ? introduction.length : 0 }}/30</span>
        </div>
        </div>
        <div class="gander">
          <div class="lable" @click="show = true">{{`性\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0别` }}</div>
          <!-- <div class="input" @click="show = true">
            {{ gender ? gender : '请选择性别' }}
          </div> -->
          <van-radio-group v-model="type" direction="horizontal" @channge="changeGander(type)">
            <van-radio name="1" style="margin-right: .77rem;">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </div>
        <!-- 绑定手机号 -->

        <div class="name">
          <div class="lable"> 绑定手机</div>
          <div class="linebox" style="display: flex;align-items: center;" @click="bindPhone">
            <van-field maxlength="10" v-model="mobile" placeholder="请填写个人手机" />
          </div>
        </div>
        <!-- <div class="bind-phone" @click="bindPhone">
          绑定手机
          <van-icon name="arrow" color="#9493b1" size="22" />
        </div> -->
        <!-- 找回账号 -->
        <div class="accout" @click="accout">
          找回账号
          <van-icon name="arrow" color="#9493b1" size="22" />
        </div>
        <!-- 账号凭证 -->
        <div class="accout-code" @click="accoutCode">
          账号凭证
          <van-icon name="arrow" color="#9493b1" size="22" />
        </div>
      </div>
      <!-- 修改性格 -->
      <van-popup v-model="show" position="bottom" :style="{ backgroundColor: 'transparent' }">
        <div class="title">请选择性别</div>
        <div class="content">
          <van-radio-group v-model="type" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>

          <div class="submit" @click="changeGander(type)">确认</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { update_info, userInfo } from 'api/user'
export default {
  name: 'Setting',
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue')
  },
  computed: {
    newUserInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  data() {
    return {
      show: false,
      type: 0,
      gender: '',
      introduction: '',
      nickName: '',
      userAvatarUrl: '',
      mobile:'',
      userImg: '',
    }
  },
  mounted() {
    this.userInfo()
    if (this.newUserInfo.gender === 2) {
      this.gender = '女'
    } else if (this.newUserInfo.gender === 1) {
      this.gender = '男'
    } else {
      this.gender = ''
    }
    this.type = this.newUserInfo.gender.toString()
    this.introduction = this.newUserInfo.introduction
    this.nickName = this.newUserInfo.nickName
    this.userAvatarUrl = this.newUserInfo.avatarUrl
    this.mobile = this.newUserInfo.mobile
  },
  methods: {
    async userInfo() {
      const res = await userInfo();
      if (res && res.code === 200) this.userAvatarUrl = res.data.avatarUrl || this.newUserInfo.avatarUrl
    },
    // 提交
    async submit() {
      if (!this.isMember) {
        return this.$toast('亲，您还不是会员，不能修改噢')
      }
      try {
        const res = await update_info({
          gender: +this.type,
          introduction: this.introduction,
          nickName: this.nickName,
          avatar: this.userAvatarUrl
        })
        if (res.code === 200) {
          this.$toast('修改成功')
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求失败，请稍后再试！')
      }
    },
    // 更改性别
    changeGander(type) {
      this.type = +type
      if (this.type === 1) {
        this.gender = '男'
      } else {
        this.gender = '女'
      }
      this.show = false
    },
    // 绑定手机号
    bindPhone() {
      this.$router.push('/mine/setting/bindPhone')
    },
    // 找回账号
    accout() {
      this.$router.push('/mine/setting/accout')
    },
    // 账号凭证
    accoutCode() {
      this.$router.push('/mine/setting/accoutCode')
    }
  }
}
</script>

<style lang="scss" scoped>
.linebox{
  border: .01rem solid rgba(0, 0, 0, 0.3);
  flex-grow: 1;
  padding: 0 .16rem;
  border-radius: 5px;
  .count{
    color:#848494 ;
  }
}

.setting {
  min-height: 100vh;
}
.setting-main {
  padding: 0.4rem 0.52rem;
  .avatar {
    width: 1.8rem;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    :deep() {
      .warp {
        border-radius: 50%;
      }
    }
  }
}

// 个人信息
.name,
.info,
.gander {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.28rem;
  .lable {
    margin-right: 0.28rem;
    width: 1.12rem;
    box-sizing: border-box;
  }
  .input {
    width: 100%;
    color: #999999;
    font-size: 0.24rem;
    padding-left: 0.32rem;
  }
  .van-cell {
    width: 100%;
    background: #ffffff;
    font-size: 0.24rem;
    font-weight: 400;
    padding: .15rem 0;
    :deep() {
      .field-placeholder-text-color {
        font-weight: 400;
        color: #999999;
      }
      .van-field__control {
        color: #999999;
      }
    }
  }
  :deep() {
    .van-cell::after {
      display: none;
    }
    .van-radio__label{
      margin-left: .2rem;
      color: #848494;
      font-size: .24rem;

    }
    .van-radio__icon .van-icon{
      border: 3px solid #848494;
    }

    .van-radio__icon--checked .van-icon{
      background-color: #848494;
      border-color: #848494;
      color: #848494;
    }
    .van-icon-success:before{
      content: none;
    }
  }
}

.update-info {
  // padding: 0 0.5rem;
}
// 账号凭证等
.bind-phone,
.accout-code,
.accout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  margin-top: 0.5rem;
}
// 性别弹窗

.title {
  background: $btnBg;
  margin-bottom: 0.12rem;
  height: 1.22rem;
  font-size: 0.33rem;

  font-weight: 600;
  text-align: center;
  line-height: 1.22rem;
  border-radius: 0.12rem;
}

.content{
  background-color: #fff;

  height: 2.9rem;
   border-radius: 0.12rem;
  :deep(){
    .van-radio-group{
      display: flex;
      justify-content: center;
      font-size: 0.3rem;
      color: #999999;
      padding-top: 0.5rem;
    }
  }
}
.submit{
  width: 2.4rem;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
    background: $btnBg;
    font-size: 0.36rem;
    border-radius: 0.2rem;
    margin: 0.4rem auto;

}
</style>
