<template>
  <div class="home-comic-more">
    <EBackTop :background="'#fff'" :color="'#000'" :title="$route.query.name" />
    <ETab
      class="more-tab"
      @change="change"
      :sticky="true"
      :tabList="category"
      :v-model="sort ? sort : category && category[0] ? category[0].id : 0"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#ff669d'"
      :lineHeight="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#ff669d'"
      :title-inactive-color="'#333'"
      :sizeTab="'0.3rem'"
      :sizeActive="'.3rem'"
    >
      <template>
        <div class="more-scroll">
          <ERefreshLoad
            @refreshData="refreshData"
            @moreData="moreData"
            :finished="finished"
            :loading="loading"
            :refreshing="refreshing"
          >
            <component
              :list="mediaList"
              :position="[1, 5]"
              :adPosition="10"
              :adMode="true"
              :is="compComponent(type)"
            ></component>
          </ERefreshLoad>
        </div>
      </template>
    </ETab>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { mediaTopicList } from 'api/comics' //api列表
export default {
  mixins: [scrollMixin],
  data() {
    return {
      category: [
        { name: '最新', id: 0 },
        { name: '最热', id: 1 },
      ],
      sort: 0,
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,
      finished: false, // 每次请求到的数据长度
      mediaList: [],
      type: 0
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'),
    JavShortFour: () => import('@/components/stencil/JavShortFour.vue')
  },
  computed: {},

  mounted() {
    this.type = +this.$route.query.type
    this.refreshData()
  },
  methods: {
    async fn_mediaTopicList() {
      const res = await mediaTopicList({
        id: +this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.sort //0:最新 1:最热 2:最受好评
      })
      if (res.code === 200 && res.data) {
        this.loading = false
        this.refreshing = false
        if (this.pageNum == 1) this.mediaList = []
        this.mediaList = [...this.mediaList, ...res.data.mediaList]
        if (res.data.mediaList.length < this.pageSize || !res.data.mediaList) {
          this.finished = true
        }
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip)
      }
    },
    change(type) {
      if (this.type !== type) {
        this.sort = type
        this.refreshData()
      }
    },
    compComponent(showType) {
      switch (showType) {
        case 2: // 短视频四宫格*
        case 7:
          return 'JavShortFour'

        default:
          return 'JavFourCard'
      }
    },
    // 上拉加载更多
    moreData() {
      this.loading = true
      this.pageNum += 1
      this.fn_mediaTopicList()
    },
    // 下啦刷新
    refreshData() {
      this.refreshing = true
      this.loading = true
      this.finished = false
      this.mediaList = []
      this.pageNum = 1
      this.fn_mediaTopicList()
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.home-comic-more {
  :deep() {
    .van-sticky {
      z-index: 99;
      padding: 0 0.16rem;
      .van-tab {
        flex: inherit;
      }
    }
    .van-tabs__nav {
      background: transparent !important;
    }
  }
  .more-tab {
    position: fixed;
    left: 0;
    top: calc(env(safe-area-inset-top) + 1rem);
    top: calc(constant(safe-area-inset-top) + 1rem);
    height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 1rem);
    height: calc(100vh - constant(safe-area-inset-bottom) - constant(safe-area-inset-top) - 1rem);
    overflow: hidden;
    z-index: 2;
    .more-scroll {
      // height: calc(100vh - 2.1rem);
      height: calc(100vh - 2.1rem - env(safe-area-inset-bottom) - env(safe-area-inset-top));
      height: calc(100vh - 2.1rem - constant(safe-area-inset-bottom) - constant(safe-area-inset-top));
      padding: 0 0.26rem;
      overflow-x: auto;
      margin-top: 0.2rem;
      padding-bottom: 1.2rem;
    }
  }
}
</style>
