<!-- home -->
<template>
  <div class="post-container">
    <div v-if="type == 0 || type == 1" class="myPubilc" @click="$router.push('/trade/myPost')">我的发布</div>
    <div
      class="location"
      @click="
        $router.push({
          path: '/dating/city-list',
          query: { city: cityItem && cityItem.name }
        })
      "
      v-else
    >
      <div class="wrap">
        <img src="@/assets/imgs/mine/decial-local.png" alt="" />
        {{ cityItem ? cityItem.name : '全国' }}
      </div>
    </div>
    <ETab
      class="post-tab"
      :class="type == 1 ? 'paddingAll' : ''"
      @change="change"
      :sticky="true"
      :tabList="topTabLabelList"
      :v-model="type"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#FF669D'"
      :lineHeight="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#FF669D'"
      :title-inactive-color="'#333'"
      :sizeTab="'0.32rem'"
      :sizeActive="'.32rem'"
    >
      <template v-slot:default="scope">
        <component
          :navItem="scope"
          :findhotList="findhotList"
          @checkTag="checkTag"
          :is="compComponent(scope.data)"
        ></component>
      </template>
    </ETab>
  </div>
</template>

<script>
import scrollMixin from '@/utils/scrollMixin'
import { EventBus } from '@/plugins/event-bus.js'
export default {
  mixins: [scrollMixin],
  data() {
    return {
      type: 0, // 切换的id
      topTabLabelList: [
        { showType: 0, name: '社区' },
        { showType: 1, name: '娱乐' },
        { showType: 2, name: '约炮' }
      ],
      findhotList: [], //热搜榜列表
      indexActive: 0,
      cityItem: null
    }
  },
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    Community: () => import('@/views/community/index.vue'),
    girls: () => import('@/components/home-activity/girls.vue'),
    Entertainment: () => import('./components/entertainment.vue')
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    postSection({ $store }) {
      return $store.getters['postSection']
    }
  },
  mounted() {
    EventBus.$on('changeTab', item => {
      this.type = item
    })
  },
  activated() {
    if (this.$route.query.cityItem) {
      this.cityItem = JSON.parse(this.$route.query.cityItem)
      EventBus.$emit('reloadCity', this.cityItem)
    }
  },
  methods: {
    checkTag(item) {
      this.indexActive = item
    },

    change(type) {
      this.type = type
    },

    //  0:暗黑游  1:帝王调教 模版 2:暗网交易
    compComponent(data) {
      switch (data) {
        case 0:
          return 'Community'
        case 1:
          return 'Entertainment'
        case 2:
          return 'girls'
        default:
          return 'Community'
      }
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.post-container {
  min-height: $minHeight;

  display: flex;
  flex-direction: column;
  .paddingAll {
    padding: 0.37rem;
  }
  .post-tab {
    :deep() {
      .van-sticky {
        position: fixed;
        top: 0;
        left: 50%;
        right: 0;
        z-index: 99;
        width: 100%;
        transform: translate(-50%, 0);
        max-width: $pcMaxWidth;
        height: 1rem;
      }

      .van-tab {
        flex: none;
      }
    }
  }
}
.myPubilc {
  border-radius: 0.12rem;
  background: $btnBg;
  padding: 0.08rem 0.16rem;
  position: fixed;
  top: 0.2rem;
  right: 0.1rem;
  z-index: 100;
  color: #fff;
}
.location {
  position: fixed;
  right: 50%;
  margin-right: -3.4rem;
  top: 14px;
  // top: 0.3rem;
  z-index: 100;
  background: $btnBg;
  border-radius: 27px;
  padding: 0.04rem 0.2rem;
  color: #fff;

  .wrap {
    display: flex;
    align-items: center;

    img {
      height: 0.24rem;
      margin-right: 0.06rem;
    }
  }
}
</style>
