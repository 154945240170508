<template>
  <div class="audio-play-container" :style="{ backgroundImage: `url(${bgImgUrl})` }">
    <div class="audio-play-container-top">
      <EBackTop :hasSuperiorClick="true" :title="detail.title" :background="'transparent'" :color="'#fff'"
        @goBack="goBack">
        <template #right>
          <img @click="sharePopup" class="right_icon" src="@/assets/imgs/novel/share.png" alt="">
        </template>
      </EBackTop>
      <div class="play-box">
        <div>
          <div ref="rotateBox" class="rotate">
            <img class="play-round" src="@/assets/imgs/novel/play_round.png" alt="" />
            <DecryptImg class="bg-rotate" :imgURL="novelDetailData.coverImg">
            </DecryptImg>
          </div>
          <img class="wire" src="@/assets/imgs/novel/wire.png" alt="" />
        </div>
      </div>
    </div>
    <div class="audio-play-container-bottom">
      <div class="details">
        <div class="left">
          <div>{{ detail.title }}</div>
          <div>{{ detail.desc }}</div>
        </div>
        <div class="right" @click="handleCollect">
          <img v-if="loveStatus" src="@/assets/imgs/novel/loved_icon.png" alt="" />
          <img v-else src="@/assets/imgs/novel/love_icon.png" alt="" />
        </div>
      </div>
      <!-- <van-progress
        :percentage="hasPlayTime"
        color="#fff"
        track-color="#3E3E3E"
        :show-pivot="true"
        @click.native="clickProgress"
      /> -->
      <div class="playtime">{{ detail.playTime | secondToDate }}</div>
      <div class="button-list">
        <ul>
          <li @click="handleChangePlay">
            <img :src="showSrc[srcIndex]" alt="" />
          </li>
          <!-- <li>
            <img src="@/assets/imgs/novel/loop.png" alt="" />
          </li>
          <li>
            <img src="@/assets/imgs/novel/single_circle.png" alt="" />
          </li> -->
        </ul>
        <div>
          <img src="@/assets/imgs/novel/prev.png" alt="" @click="handlePrev" />
          <img v-if="play_status == 'play'" src="@/assets/imgs/novel/pause.png" alt="" @click="clickPlay('pause')" />
          <img v-else src="@/assets/imgs/novel/play.png" alt="" @click="clickPlay('play')" />
          <img src="@/assets/imgs/novel/next.png" alt="" @click="handleNext" />
        </div>
        <span @click="showChapter = !showChapter"> 列表 </span>
      </div>
    </div>

    <div id="audio-dplayer"></div>
    <van-popup v-model="showChapter" position="bottom" class="showchapter-pop">
      <div class="recommend">
        <div class="Indicator">
          <img src="@/assets/imgs/novel/HomeIndicator.png" alt="" />
        </div>
        <ETabBar :list="topTabList" :tabbar-config="tabbarConfig" :active-num="activeId" @change="tabbarChange">
          <!-- <ERefreshLoadV3 :finished="finished" :beforeRefresh="refreshData" :moreData="moreData"> -->
          <div class="recommend-containe">
            <ul>
              <ChapterItem v-for="item in collectChapterList" :key="item.id" :item="item"
                :novelDetailData="novelDetailData" @click.native="clickChapter(item)" />
            </ul>
          </div>
          <!-- </ERefreshLoadV3> -->
        </ETabBar>
      </div>
    </van-popup>
    <SharePopup :setMyCode="setMyCode" :sharePath="$router.currentRoute.path" @closeGuidance="closeGuidance" />
    <div class="bottom-cover-fix"></div>
  </div>
</template>

<script>
const HlsJsPlayer = require('xgplayer-hls.js')
import { handleVerImg } from '@/utils/utils_tools'
import { novelViewRightTo } from '@/utils/utils_tools'
import { novelDetail, novelInfo, novelChapterInfo } from 'api/novel'
import { collectAdd } from 'api/comics'
export default {
  components: {
    ChapterItem: () => import('@/components/HomeNovel/oneCard/chapterItem.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    SharePopup: () => import('@/components/Popup/sharePopup/index.vue'),
  },
  inject: ['reload'],
  data() {
    return {
      player: '',
      url: '',
      detail: {
        playTime: 0,
      },
      loveStatus: false,
      dp: {},
      hasPlayTime: 0,
      timer: null,
      showChapter: false,
      //
      activeId: 0,
      tabbarConfig: {
        barWidth: '7.5rem,',
        barHeight: '0.88rem',
        fontSize: '0.28rem', //默认字体大小
        fontSizeActive: '0.3rem', //选中字体大小
        fontColor: '#999', //默认字体颜色
        fontColorActive: '#333', //选中字体颜色
        bg: 'transparent', //默认背景色
        bgActive: 'transparent', //选中背景色
        lineBott: '0.1rem',
        lineColorActive: '#333',
        lineWidth: '0.16rem', //下划线宽度 String
        sticky: false, //粘性布局
        offsetTop: '0rem', //距顶部距离
        tabFlex: true,
      },
      topTabList: [
        {
          id: 1,
          name: '顺序',
        },
        {
          id: 2,
          name: '我的收藏',
        },
      ],
      finished: false,
      pageNum: 1,
      pageSize: 10,
      novelDetailData: {},
      sortId: 0,
      chapterList: [],
      collectChapterList: [],
      currentChapterIdx: 0,
      bgImgUrl: '',
      play_status: 0,
      isFirst: true,
      showSrc: [
        require('@/assets/imgs/novel/loop.png'),
        require('@/assets/imgs/novel/random.png'),
        require('@/assets/imgs/novel/single_circle.png'),
      ],
      setMyCode: false,
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn'];
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
    shoudBuy() {
      if (this.novelDetailData.isVip && !this.isVipMember) {
        return 1
      } else if (this.novelDetailData.price && !this.novelDetailData.isBuy) {
        return 2
      } else {
        if (this.novelDetailData.isVip) {
          return 7 //VIP观看
        } else if (this.novelDetailData.isBuy) {
          return 8 //已购观看
        } else {
          return 9 //免费
        }
      }
    },
    srcIndex() {
      return this.$store.getters['geAudioPlayMethodIndex']
    },
  },
  watch: {
    $route: {
      async handler(n, o) {
        if (!!o) {
          if (n.path != o.path) {
            await this.getComicsDetail()
            await this.getDetail('first')
          }
        }
      },
      immediate: true
    }
  },
  async mounted() {
    await this.getComicsDetail()
    await this.getDetail('first')
    this.dp = new HlsJsPlayer({
      id: 'audio-dplayer', //容器ID
      lang: 'zh-cn',
      width: '100%',
      height: '100%',
      autoplayMuted: false,
      autoplay: true, // 是否自动播放
      volume: 0.6,
      miniplayer: false, // 迷你播放
      controls: true,
      cssFullscreen: false,
      videoInit: false, // 初始化视频首帧
      lastPlayTime: 0, // 记忆播放，从多少多少秒开始
      lastPlayTimeHideDelay: 5, //提示文字展示时长（单位：秒）
      rotateFullscreen: false, // 使得视频全屏幕时 横屏
      download: false, //设置download控件显示
      enableVideoDbltouch: true,
      airplay: false,
      playsinline: true,
      preloadTime: 10,
      closeInactive: true,
      // url: "https://admin.yejjhsd.com/api/web/media/m3u8/av/cu/61/vb/ku/0b81c5ce442e41f3b7c0aa9ef50ec72a.m3u8",
      url: '/api/app/media/m3u8/' + this.detail.fieldNameFs,
      // 开启弹幕
      execBeforePluginsCall: [
        () => {
          console.log('Execute before plugins call')
        },
      ],

      'x5-video-player-type': 'h5', //微信同层播放：
      'x5-video-player-fullscreen': 'false', //微信全屏播放：
      'x5-video-orientation': 'landscape', //微信横竖屏控制
    })
    this.dp.on('play', () => {
      this.$refs.rotateBox.style.animationPlayState = 'running'
      this.play_status = 'play'
    })
    this.dp.on('pause', () => {
      this.$refs.rotateBox.style.animationPlayState = 'paused'
      this.play_status = 'pause'
    })

    // 如果是预告片播放结束给出购买提示
    this.dp.on('ended', () => {
      console.log('ended')
      this.handleNext()
    })
    // this.play_status = 'play'
    // this.dp.start()
    this.timer = setInterval(() => {
      this.hasPlayTime = (this.dp.currentTime / this.detail.playTime) * 100
      if (this.hasPlayTime >= 100) {
        this.hasPlayTime = 0
      }
    }, 1000)
  },
  beforeDestroy() {
    window.clearInterval(this.timer)
    this.timer = null
    this.dp.destroy
    this.dp = null
  },
  methods: {
    closeGuidance() {
      // 关闭弹窗
      this.setMyCode = false
    },
    sharePopup() {
      //分享弹窗
      this.setMyCode = true
    },
    handleChangePlay() {
      this.$store.commit('history/setAudioPlayMethodIndex')
    },
    async handleCollect() {
      console.log(this.detail);
      const res = await collectAdd({
        flag: !this.detail.isCollect,
        collectType: 15,
        type: 1,
        object_id: this.detail.id,
        isCollet: false
      })
      if (res.code === 200) {
        this.detail.isCollect = !this.detail.isCollect
        if (this.detail.isCollect) {
          this.loveStatus = true
          return this.$toast('收藏成功！')
        } else {
          this.loveStatus = false
          return this.$toast('取消收藏！')
        }
      } else {
        return this.$toast(res.tip)
      }
    },
    async clickChapter(item) {
      const { chapterId, id } = this.$route.query
      if (item.id == chapterId) return
      const res = await novelViewRightTo(
        this,
        item.id,
        2,
        this.novelDetailData.price,
        this.novelDetailData.isCollect,
        this.shoudBuy,
        this.novelDetailData.id,
        this.novelDetailData.isAudio ? true : false
      )
      if (res == 'reload') {
        this.setAudio({ id, cid: item.id })
      }
    },
    goBack() {
      this.dp.destroy()
      this.$router.go(-1)
    },
    async imgTransfer(imgPath) {
      // 图片解密
      const resImg = await handleVerImg(this.imgCDN + imgPath)
      let imgURL = window.URL
        ? window.URL.createObjectURL(new Blob([resImg]))
        : window.webkitURL.createObjectURL(new Blob([resImg]))
      return imgURL
    },
    async handleNext() {
      if (this.srcIndex == 0) {
        if (this.currentChapterIdx + 1 >= this.detail.Count) {
          return this.$toast('没有更多了')
        }
        this.dp.destroy
        this.currentChapterIdx++
        // await this.getDetail()
        this.setAudio({
          id: this.$route.query.id,
          cid: this.chapterList[this.currentChapterIdx].id,
        })
      } else if (this.srcIndex == 1) {
        const random = Math.floor(Math.random() * this.chapterList.length)
        this.setAudio({
          id: this.$route.query.id,
          cid: this.chapterList[random].id,
        })
      } else {
        const { id, chapterId } = this.$route.query
        this.setAudio({ id, cid: chapterId })
      }
    },
    async handlePrev() {
      if (this.srcIndex == 0) {
        if (this.currentChapterIdx <= 0) return this.$toast('已经是第一章了')
        this.dp.destroy
        this.currentChapterIdx--
        this.setAudio({
          id: this.$route.query.id,
          cid: this.chapterList[this.currentChapterIdx].id,
        })
      } else if (this.srcIndex == 1) {
        const random = Math.floor(Math.random() * this.chapterList.length)
        this.setAudio({
          id: this.$route.query.id,
          cid: this.chapterList[random].id,
        })
      } else {
        const { id, chapterId } = this.$route.query
        this.setAudio({ id, cid: chapterId })
      }
    },
    setAudio(item) {
      this.$router.replace({
        path: '/home-novel/audioPlay',
        query: {
          id: item.id,
          chapterId: item.cid,
        },
      })
      this.reload()
    },
    async moreData() {
      this.pageNum += 1
      // await this.getList()
    },
    refreshData() {
      this.finished = false
      // 一级列表请求参数
      // this.playlist = []
    },
    async tabbarChange(sort) {
      this.sort = sort
      this.playlist = []
      this.pageNum = 1
      if (sort == 2) {
        this.collectChapterList = []
        this.getCollectList()
      } else {
        this.collectChapterList = this.chapterList
      }
      // await this.getList()
    },
    async getDetail(action) {
      const id = Number(this.$route.query.chapterId)

      // const res = await novelChapterInfo({ id: id })
      const res = await novelInfo({ id })
      console.log(res)
      this.detail = res.data
      this.loveStatus = res.data.isCollect
      if (action == 'first') {
        const index = this.chapterList.findIndex((v) => v.id == id)
        this.currentChapterIdx = index
      }
      // this.dp.playNext({
      //   url: '/api/app/media/m3u8/' + this.detail.fieldNameFs
      // })
    },
    clickPlay(status) {
      if (status == 'play' && this.dp.paused) {
        this.play_status = status
        if (this.isFirst) {
          this.dp.start()
          this.$refs.rotateBox.classList.add('rotate')
          this.isFirst = false
        } else {
          this.dp.play()
          this.$refs.rotateBox.style.animationPlayState = 'running'
        }
      } else {
        if (!this.dp.paused) {
          this.dp.pause()
          this.play_status = status
          this.$refs.rotateBox.style.animationPlayState = 'paused'
        }
      }
    },
    clickPause() {
      this.dp.pause()
    },
    clickProgress(item) {
      const cWidth = document.documentElement.clientWidth
      const padding = 0.5 / 7.5
      const half = item.clientX / cWidth
      let progress = 0
      if (half >= 0.5) {
        progress = item.clientX / document.documentElement.clientWidth + padding
      } else {
        progress = item.clientX / document.documentElement.clientWidth - padding
      }
      let targetTime = this.detail.playTime * progress
      this.dp.currentTime = targetTime
    },
    async getComicsDetail() {
      const res = await novelDetail({
        id: +this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
      if (res.code === 200) {
        this.novelDetailData = res.data.novelData
        this.chapterList = res.data.chapterList
        this.collectChapterList = res.data.chapterList
        this.bgImgUrl = await this.imgTransfer(this.novelDetailData.coverImg)
      }
    },
    async getCollectList() {
      console.log(this.$store.getters);
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        collectType: 15,
        userId: this.userInfo.id,
        type: 1,
      }
      const res = await collect_list(params)
      this.collectChapterList = res.data.novelList
      console.log('res', res)
    },
  },
}
</script>

<style lang="scss" scoped>
.audio-play-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0.5) -31.51%,
        rgba(0, 0, 0, 0) -31.5%,
        rgba(11, 11, 11, 0.5) 100%);
    z-index: 1;
  }

  height: 100%;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  padding-bottom: 1rem;
  z-index: 2;

  .bottom-cover-fix {
    height: 1.1rem;
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 1000;
  }

  .BackTop {
    box-shadow: none;
    flex: none;

    .right_icon {
      width: 1.1rem;
      height: 0.46rem;
    }

    .van-nav-bar__content {
      border-bottom: none;
    }
  }

  .play-round {
    width: 5.4rem;
    display: block;
    height: 5.4rem;
  }

  .play-box {
    flex: 1;
    position: relative;

    .rotate {
      animation: transfromrotate 8s linear infinite;
    }

    .wire {
      position: absolute;
      right: 1.46rem;
      top: 0;
      width: 1.28rem;
      height: 2.1rem;
    }

    .bg-rotate {
      width: 2.48rem;
      height: 2.48rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;

      :deep() .warp {
        border-radius: 50%;
      }
    }

    >div {
      position: absolute;
      @include flex-center;
      width: 5.4rem;
      height: 5.4rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  /*通过@keyframes规则,能够创建动画 , que 定义动画的名称 可自己定义*/
  @keyframes transfromrotate {

    /*以百分比来规定改变发生的时间 也可以通过"from"和"to",等价于0% 和 100%*/
    0% {
      /*rotate(2D旋转) scale(放大或者缩小) translate(移动) skew(翻转)*/
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes transfromrotateAbsolute {

    /*以百分比来规定改变发生的时间 也可以通过"from"和"to",等价于0% 和 100%*/
    0% {
      /*rotate(2D旋转) scale(放大或者缩小) translate(移动) skew(翻转)*/
      transform: rotate(-0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  &-bottom {
    position: relative;
    width: 100%;
    flex: none;
    padding: 0 0.36rem;
    position: absolute;
    bottom: 1rem;
    z-index: 2;

    >div {

      &:nth-child(1) {
        position: absolute;
        width: calc(100vw - 0.72rem);
        display: flex;
        justify-content: space-between;
        top: -2.5rem;

        img {
          width: 0.5rem;
        }
      }
    }

    .left {
      >div {
        color: rgba(255, 255, 255, 0.5);
        font-family: Monda;
        font-size: 0.3077rem;

        &:nth-child(1) {
          color: #fff;
          font-family: 'PingFang HK';
          font-size: 0.32rem;
          font-weight: 500;
          margin-bottom: 0.62rem;
          max-width: 3.1rem;
          @include textoverflow;
        }
      }
    }

    .right {
      padding-top: 0.31rem;
    }
  }

  &-top {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  :deep() {
    .van-progress__pivot {
      width: 0.21rem;
      height: 0.21rem;
      min-width: 0.21rem;
      color: transparent;
    }
  }

  .playtime {
    display: flex;
    justify-content: flex-end;
    margin: 0.1rem 0 0.28rem;
    color: #fff;
    font-family: Monda;
    font-size: 0.1923rem;
  }

  .button-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.15rem;
    @include flexbox;

    >ul {
      width: 0.4rem;
      height: 0.32rem;

      img {
        width: 0.4rem;
        height: 0.32rem;
      }
    }

    >div {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 0.4rem;

        &:nth-child(2) {
          width: 1.15rem;
          margin: 0 0.64rem;
        }
      }
    }

    >span {
      width: 0.96rem;
      height: 0.56rem;
      border-radius: 0.32rem;
      border: 0.5px solid #FFD177;
      background: rgba(249, 192, 6, 0.12);
      @include flex-center;
      color: #FFD177;
      font-family: 'PingFang HK';
      font-size: 0.28rem;
    }
  }

  :deep() {
    #audio-dplayer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw !important;
      height: 100vh !important;

      // transform: translateX(100vw) !important;
      video {}
    }

    .xgplayer-start,
    .xgplayer-time {
      display: none;
    }

    .xgplayer-skin-default .xgplayer-start {
      top: calc(50% - 0.5rem);
    }

    .xgplayer-controls {
      display: block;
    }

    .xgplayer-skin-default {
      background: none;
    }

    .xgplayer-skin-default {
      .xgplayer-progress {
        top: -3.1rem;
        // transform: translateX(-100vw);
      }
    }

    .xgplayer-volume {
      display: none;
      // position: absolute;
      // // right: 48%;
      // right: 0.3rem;
      // top: -4.1rem;
    }

    .xgplayer-play {
      position: absolute;
      left: 38%;
      top: -1.3rem;
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      z-index: 2;
      // transform: translateX(-100vw);
    }

    .xgplayer-play .xgplayer-icon {
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      background-color: transparent;
    }

    .xgplayer-skin-default .xgplayer-icon {

      .xgplayer-icon-requestfull,
      .xgplayer-icon-play,
      .xgplayer-icon-pause {
        fill: transparent;
      }
    }
  }

  .showchapter-pop {
    border-radius: 0.4rem 0.4rem 0rem 0rem;
    background: var(--f5f5f5, #f5f5f5);
    height: 50vh;
  }

  .recommend {
    background: #f5f5f5;
    width: 100%;
    border-radius: 0.4rem 0.4rem 0rem 0rem;
    padding-top: 0.32rem;
    min-height: calc(100vh - 6.2rem);

    .recommend-containe {
      padding: 0 0.32rem;
    }

    .Indicator {
      width: 0.72rem;
      margin: 0 auto 0.1rem;

      img {
        width: 100%;
      }
    }

    :deep() .van-tabs__nav {

      // justify-content: space-evenly;
      .van-tabs__line {
        width: 3.75rem !important;
        height: 0.02rem !important;
      }

      .van-tab {
        flex: 1;
      }
    }
  }
}
</style>
