<template>
  <!-- 专题二瀑布流 -->
  <div class="waterfall-wrap">
    <MoreHeader :itemTopic="itemTopic"></MoreHeader>
    <div class="list-wrap">
      <ul >
        <li
          @click="clickImg(item)"
          v-for="(item, index) in comicsList"
          :key="index"
          :class="'item'+index"
        >
          <DmComicCardPayType :item="item"></DmComicCardPayType>
          <DecryptImg :imgRadius="'0.12rem'" :lazy="false" class="md-img" :imgURL="item.coverImg" />
          <div class="decial-wrap" :class="'txt'+index">
            <div class="decial" >
              <div>{{item.title}}</div>
              <div>{{item.desc}}</div>
            </div>
          </div>
          <div class="likes">
            <img src="/newAppMh/home-comic/fire.png" alt="">
            {{item.watchTimes | numberFilter}}
          </div>

        </li>
      </ul>
    </div>

  </div>
</template>
<script>
export default {
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    DmComicCardPayType: () => import('@/components/PayType.vue'),
    MoreHeader: () => import('@/components/HomeComic/topic/moreHeader.vue')
  },

  data() {
    return {
      comicsList:[]
    }
  },
  props: {
    itemTopic: {
      type: Object,
      default() {
        return {}
      },
    },
    nowTabItem: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {

  },
  methods: {
    clickImg(item) {
      if(this.nowTabItem.showType == 8){
        this.$router.push(`/play/comicsVideo/${item.id}`)
      }else{
        this.$router.push(`/home-comic/decial/${item.id}`)
      }
    }
  },
  mounted(){
    this.comicsList=this.itemTopic.mediaList
  }
}
</script>

<style lang="scss" scoped>
  //瀑布流
  .waterfall-wrap{
    box-shadow: 0 0.06rem 0.1rem rgb(0 0 0 / 10%);
    padding-bottom: 0.1rem;
    .list-wrap{
      padding: 0 0.37rem;
      ul{
        column-count: 2;
        column-gap: 0.2rem;
        li{
          position: relative;
          width: 3.28rem;
          height: 4.49rem;
          box-shadow: 0.06rem 0.06rem 0.1rem rgb(0 0 0 / 10%);
          margin-bottom: 0.2rem;
          border-radius: 0.12rem;
          .md-img{
            :deep(){
              .warp{
                border-radius: 0.12rem;
              }
            }
          }
          &.item0, &.item5{
            height: 5.53rem;
          }
          .decial-wrap{
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            background: rgb(255 255 255 / .8);
            border-radius: 0.12rem;
            padding:0.13rem ;
            .decial{
              text-align: right;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              div{
                &:first-child{
                  font-size: 0.28rem;
                  color: #6a6a6a;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  white-space: normal;
                }
                &:last-child{
                  max-width: 60%;
                  font-size: 0.18rem;
                  color: #a0a0a0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  white-space: normal;
                  min-height: 0.5rem;
                }
              }
            }
            &.txt5,&.txt0{
              background: #fff;
            }
          }
          .likes{
            color: #a0a0a0;
            position: absolute;
            bottom: 0.2rem;
            left: 0.2rem;
            @include flex-align-center;
            img{
              width: 0.28rem;
              margin-right: 0.05rem;
            }
          }
        }
      }

    }
  }
</style>
