<template>
  <!-- 一行3个 -->
  <div class="threeBox-wrap">
    <div class="list-wrap">
      <ul>
        <DmComicCard v-for="(item, index) in comicsList" :item="item" :key="index"></DmComicCard>
      </ul>
    </div>
    <div class="btn">
      <div @click="fn_more">查看更多</div>
      <div @click="comicsTopicChange">换一换</div>
    </div>
  </div>
</template>
<script>
import { comicsTopicChange } from 'api/comics'
export default {
  components: {
    DmComicCard: () => import('@/components/HomeComic/oneCard')
  },

  data() {
    return {
      comicsList: []
    }
  },
  props: {
    itemTopic: {
      type: Object,
      default() {
        return {}
      }
    },
    activeId: {
      type: Number,
      default() {
        return 0
      }
    },
    tabItemDatas: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {},
  methods: {
    //查看更多
    fn_more() {
      this.$router.push({
        path: `/home-comic/more`,
        query: {
          id: this.tabItemDatas.item.id,
          name: this.tabItemDatas.item.name
        }
      })
    },
    async comicsTopicChange() {
      const res = await comicsTopicChange({
        id: this.tabItemDatas.item.id
      })
      if (res.code === 200 && res.data) {
        this.comicsList = res.data.list
      } else {
        return this.$toast(res.tip)
      }
    }
  },
  mounted() {
    this.comicsList = this.itemTopic.topicInfo[this.tabItemDatas.index].comicsList
  }
}
</script>

<style lang="scss" scoped>
//<!-- 一行3个 -->
.threeBox-wrap {
  position: relative;
  min-height: 8rem;
  padding-bottom: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .list-wrap {
    padding: 0 0.37rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        width: 2.1rem;
        margin-bottom: 0.2rem;
        .md-img {
          height: 2.86rem;
        }
        .decial-wrap {
          width: 100%;
          border-radius: 0.12rem;
          .decial {
            display: flex;
            flex-direction: column;
            div {
              &:first-child {
                padding-top: 0.12rem;
                font-size: 0.28rem;
                color: #6a6a6a;
              }
              &:last-child {
                font-size: 0.18rem;
                color: #a0a0a0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                white-space: normal;
              }
            }
          }
        }
      }
    }
  }
  .btn {
    display: flex;
    width: 5rem;
    margin: 0 auto;
    margin-top: 0.1rem;
    div {
      width: 1.72rem;
      height: 0.6rem;
      line-height: 0.6rem;
      text-align: center;
      border-radius: 0.24rem;
      box-shadow: 0.06rem 0.06rem 0.1rem rgb(0 0 0 / 10%);
      font-size: 0.28rem;
      margin: 0 auto;
      background: #fff;
      color: rgb(255, 139, 0);
      // &:first-child{
      //   color:#ff7777;
      // }
    }
  }
}
</style>
