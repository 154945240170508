<template>
  <div class="item-card" @click="toDeatail(itemData)">
    <!-- 选中 -->
    <!-- <div class="check-img" @click.stop="checItem(itemData)" v-if="isShow">
      <div v-if="!itemData.check" class="check-icon"></div>
      <img v-else src="@/assets/imgs/icon_video_checked.png" alt="" />
    </div> -->
    <DecryptImg class="img-card" :needPadding="false" :imgURL="itemData.images && itemData.images[0]">
      <!-- 楼风最新 -->
      <div class="itemData-msg">
        <div class="title van-ellipsis">{{ itemData.title }}</div>
        <div class="decial van-ellipsis">
          <span class="girlAge">年龄:{{ itemData.age || itemData.girlAge }}</span>
          <span class="area">地区:{{ itemData.cityName || itemData.serviceCity[0] }}</span>
        </div>
        <div class="price-wrap van-ellipsis">
          <div class="price">价格: {{ itemData.price }}</div>
        </div>
      </div>
    </DecryptImg>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    showType: {
      type: Number,
      default() {
        return 1
      }
    },
    itemData: {
      type: Object,
      default() {
        return {}
      }
    },
    isShow: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {}
  },
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  computed: {},
  mounted() {},
  methods: {
    checItem(item) {
      this.itemData.check = !item.check
      this.$store.commit('setCannonballEdit', { id: item.id, check: item.check })
      this.$forceUpdate()
    },
    toDeatail() {
      this.$router.push({
        path: `/dating/model-decial/${this.itemData.id}`,
        query: {
          type: this.showType,
          name: this.showType == 1 ? '楼凤' : '外围'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item-card {
  max-width: 3.44rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f7f6fb;
  color: #333333;
  margin-top: 0.14rem;
  border-radius: 0.08rem;
  overflow: hidden;
  .check-img {
    position: absolute;
    top: 0.15rem;
    right: 0.1rem;
    z-index: 99;
    width: 0.32rem;
    border-radius: 50%;
    height: 0.32rem;
    border: 0.01rem solid #fff;
    margin-right: 0.12rem;
    font-size: 0;
    .check-icon {
      width: 0.32rem;
      border-radius: 50%;
      height: 0.32rem;
    }
    img {
      width: 0.32rem;
      border-radius: 50%;
      height: 0.32rem;
    }
  }
  //卡片
  .img-card {
    width: 3.44rem;
    height: 4rem;
    position: relative;
    :deep() {
      .warp {
        border-top-left-radius: 0.24rem;
        border-top-right-radius: 0.24rem;
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
    //详细信息
    .itemData-msg {
      width: 3.45rem;
      padding: 0.12rem 0.2rem;
      padding-left: 0;
      background: rgba($color: #ebf5f5, $alpha: 0.8);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      font-size: 0.24rem;
      .title {
        padding-left: 0.2rem;
        font-size: 0.26rem;
      }
      .decial {
        padding-left: 0.2rem;
        color: rgba($color: #6a6a6a, $alpha: 0.7);
        .area {
          padding-left: 0.23rem;
        }
      }
      .price-wrap {
        position: relative;
        color: rgba($color: #6a6a6a, $alpha: 0.7);
        display: flex;
        padding-left: 0.16rem;
        .price {
          margin-top: 0.1rem;
          color: #fff;
          height: 0.42rem;
          line-height: 0.42rem;
          background: #ff669d;
          padding: 0 0.24rem;
          border-radius: 0.08rem;
          font-size: 0.24rem;
        }
      }
    }
  }
}
</style>
