<template>
  <div class="home-index">
    <div class="tab-main">
      <PullUp @refreshData="refreshData" @moreData="moreData" :finished="finished" :loading="loading"
        :refreshing="refreshing">
        <div class="list-box">
          <template v-for="(item, index) in listData">
            <HomeVideoCard :item="item">
              <div class="overlay" v-if="showMask" @click.stop="clickItem(item)">
                <img :src="item.check
                  ? require('@/assets/imgs/mine/active_check.png')
                  : require('@/assets/imgs/mine/default_check.png')
                  " alt="" />
              </div>
            </HomeVideoCard>
          </template>

          <i></i>
          <i></i>
          <i></i>
        </div>
      </PullUp>
    </div>
  </div>
</template>

<script>
import { collect_list, collect_del } from 'api/user'
import { pay_history } from 'api/user'

export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      loading: false,
      refreshing: false,

      listData: [],
      newList: [],
      finished: false
    }
  },
  props: ['cardType', 'showMask'],
  components: {
    PullUp: () => import('@/components/PullUp.vue'),
    PhotoItem: () => import('@/components/Photo/PhotoItem.vue'),
    HomeVideoCard: () => import('@/components/HomeNovel/oneCard'),
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取短视频观看记录列表
    novelHisList({ $store }) {
      return $store.getters['novelHisList']
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    // 收藏接口
    async getList() {

      if (this.cardType === 'history') {
        this.listData = JSON.parse(JSON.stringify(this.novelHisList))
        this.refreshing = false
        this.loading = false
        this.finished = true
      } else {
        const res = await pay_history({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type: 15 // 收藏
        })
        if (res.code === 200) {

          if (res.data.novelList && res.data.novelList.length && res.data.novelList.length <= this.pageSize) {
            this.listData = [...this.listData, ...res.data.novelList]
            this.refreshing = false
            this.loading = false
          } else {
            this.refreshing = false
            this.loading = false
            this.finished = true
          }
        }
      }
      this.listData = this.listData.map(item => {
        item.check = false
        return item
      })
    },
    // 上拉加载更多
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
      this.getList()
    },
    // 下啦刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = true
      this.finished = false
      this.listData = []
      this.pageNum = 1
      this.getList()
    },
    clickItem(item) {
      item.check = !item.check
      if (this.cardType === 'history') {
        this.newList = this.listData.filter(item => {
          return !item.check
        })
        this.$emit('checkList', this.newList)
      } else if (this.cardType === 'collect') {
        this.newList = this.listData.map(item => {
          if (item.check) {
            return item.id
          }
        })
      }
      this.$forceUpdate()
    },
    async delCollect() {
      const res = await collect_del({
        collectType: 15,
        objectIds: this.newList,
        type: 1
      })
      if (res.code === 200) {
        this.refreshData()
      } else {
        return this.$toast('操作失败，请联系后台管理员！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-box {
  padding: 00.2rem;
  @include flexbox($fw: wrap);

  i {
    width: 32%;
  }
}

.home-index {
  min-height: 100vh;
  margin-top: 1rem;

  .ShortVideoOneCard {
    position: relative;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  img {
    width: 0.48rem;
    height: 0.48rem;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  }
}
</style>
