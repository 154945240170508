<template>
  <!-- 合集一行3个 -->
  <div class="collection-wrap">
    <div class="header">
      <div>{{ itemTopic.name }}</div>
      <div class="desc">{{ itemTopic.desc }}</div>
    </div>
    <!--切换面板 -->
    <Tab
      :addClass="'home-tab2'"
      @change="change"
      :swipeable="true"
      :sticky="false"
      :titles="itemTopic.topicInfo"
      :animated="true"
      :background="'#fff'"
      :titleActiveColor="'#FF669D'"
      :titleInactiveColor="'#723c1f'"
      :ifScrollTop="false"
    >
      <template v-slot:default="scope">
        <div class="tab-main">
          <component :itemTopic="itemTopic" :tabItemDatas="scope" :is="'CollectionList'"></component>
        </div>
      </template>
    </Tab>
  </div>
</template>
<script>
export default {
  components: {
    CollectionList: () => import('@/components/HomeComic/topic/collection/list.vue'),
    Tab: () => import('@/components/Tab.vue')
  },
  data() {
    return {
      activeId: 0
    }
  },
  props: {
    itemTopic: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {},
  methods: {
    change(id) {
      this.activeId = id
    }
  },
  mounted() {
    if (this.itemTopic.topicInfo.length) this.activeId = this.itemTopic.topicInfo[0].id
  }
}
</script>

<style lang="scss" scoped>
//<!-- 一行3个 -->
.collection-wrap {
  box-shadow: 0 0.06rem 0.1rem rgb(0 0 0 / 10%);
  .header {
    display: flex;
    align-items: center;
    height: 1.05rem;
    padding: 0.3rem 0.37rem;
    div {
      &:first-child {
        font-size: 0.32rem;
        color: #6a6a6a;
      }
    }
    div {
      &:last-child {
        color: #6a6a6a;
        opacity: 0.5;
        font-size: 0.24rem;
        padding-left: 0.1rem;
      }
    }
  }
  .list-wrap {
    padding: 0 0.37rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        width: 2.1rem;
        margin-bottom: 0.2rem;
        .md-img {
          height: 2.86rem;
        }
        .decial-wrap {
          width: 100%;
          border-radius: 0.12rem;
          .decial {
            display: flex;
            flex-direction: column;
            div {
              &:first-child {
                padding-top: 0.12rem;
                font-size: 0.28rem;
                color: #6a6a6a;
              }
              &:last-child {
                font-size: 0.18rem;
                color: #a0a0a0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                white-space: normal;
              }
            }
          }
        }
      }
    }
  }
}
</style>
