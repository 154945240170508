<template>
  <div class="myWallet-container">
    <EBackTop :title="'关注'" />
    <JavTab
      @change="changeActive"
      :sticky="true"
      :titles="cartoonCategory"
      :active="indexActive"
      :animated="false"
      :addClass="'mine-tab'"
    >
      <PullUp
        @refreshData="refreshData"
        @moreData="moreData"
        :finished="finished"
        :loading="loading"
        :refreshing="refreshing"
        :skeleton="skeleton"
        v-if="careList && careList.length > 0"
      >
        <ul class="care-listWrap">
          <li class="item" v-for="(item, index) in careList" :key="index">
            <div class="left" @click="$router.push(`/up/index/${item.id}`)">
              <div class="avatar">
                <EDecryptImg :imgCDN="imgCDN" :imgURL="item.avatar" />
              </div>
              <div class="name">
                <div>{{ item.name }}</div>
                <div>{{ item.introduction }}</div>
              </div>
            </div>
            <div @click="removeCare(item, index)" class="right">
              <div>取消关注</div>
            </div>
          </li>
        </ul>
      </PullUp>
      <Nodata imgType="3" :text="indexActive===1 ? '您还没有关注别人哦～' : '您还没有粉丝哦~'" v-else />
    </JavTab>
  </div>
</template>
<script>
import { care_cancel } from 'api/home'
import { care_list, care_fans } from 'api/user'

export default {
  data() {
    return {
      // 上拉加载（loading:false代表可继续上拉加载）
      loading: false,
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 下拉刷新
      refreshing: false,
      // 关注列表
      careList: [],
      cartoonCategory: [
        { id: 1, name: '我的关注' },
        { id: 2, name: '我的粉丝' }
      ],
      skeleton: false,
      indexActive: 1,
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 5
    }
  },

  components: {
    PullUp: () => import('@/components/PullUp.vue'),
    JavTab: () => import('@/components/JavTab.vue'),
    Nodata: () => import('@/components/JavNodata.vue'),
  },
  methods: {
    // 获取关注列表
    async getCareList() {
      try {
        const res = await care_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.refreshing = false
          this.loading = false
          this.careList = [...this.careList, ...res.data.list]
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.$toast(res.tip)
          this.refreshing = false
          this.loading = false
          this.finished = true
        }
      } catch (error) {
        this.refreshing = false
        this.loading = false
        this.finished = true
        console.log(error)
        this.$toast('请求出错，请稍后再试！')
      }
    },
    // 获取粉丝列表
    async getCareFans() {
      try {
        const res = await care_fans({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.refreshing = false
          this.loading = false
          this.careList = [...this.careList, ...res.data.list]
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.$toast(res.tip)
          this.refreshing = false
          this.loading = false
          this.finished = true
        }
      } catch (error) {
        this.refreshing = false
        this.loading = false
        this.finished = true
        console.log(error)
        this.$toast('请求出错，请稍后再试！')
      }
    },
    // 下拉加载
    moreData(loading) {
      this.loading = loading
      this.pageNum++
      if (this.indexActive === 1) {
        this.getCareList()
      } else {
        this.getCareFans()
      }
    },
    // 上拉刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.finished = false
      this.loading = true
      this.careList = []
      this.pageNum = 1
      this.skeleton = true
      if (this.indexActive === 1) {
        this.getCareList()
      } else {
        this.getCareFans()
      }
    },
    // 取消关注
    async removeCare(item, index) {
      const res = await care_cancel({
        Ids: [item.id]
      })
      if (res.code === 200) {
        this.careList.splice(index, 1)
        this.$toast('取消关注')
      } else {
        this.$toast(res.tip)
      }
    },
    changeActive(type) {
      if (this.indexActive !== type) {
        this.indexActive = type
        this.refreshData()
      } else {
        return
      }
    }
  },
  mounted() {
    this.refreshData()
  },
  created() {},
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  }
}
</script>
<style lang="scss" scoped>
.myWallet-container {
  min-height: 100vh;
  box-sizing: border-box;
  .top-nav {
    @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
    font-size: 0.28rem;
    color: #848494;
    margin-top: 0.3rem;
    li {
      width: 3.1rem;
      height: 0.6rem;
      text-align: center;
      line-height: 0.6rem;
    }
    .active {
      background: $btnBg;
      color: #040404;
      font-weight: 600;
    }
  }
  .care-listWrap {
    padding-top: 0.15rem;
    .item {
      padding: 0.13rem 0.25rem;
      @include flexbox;
      // box-shadow: $shadow;
      border: 0.01rem solid rgba(0, 0, 0, 0.3);
      margin-bottom: 0.15rem;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      border-radius: 0.08rem;
      .left {
        @include flexbox;

        .avatar {
          width: 0.95rem;
          flex-shrink: 0;
          border-radius: 1rem;
          overflow: hidden;
          :deep() {
            .warp {
              border-radius: 50%;
              img {
                border-radius: 50%;
              }
              .imgTag {
                height: 100%;
              }
            }
          }
        }
        .name {
          padding-left: 0.15rem;
          font-size: 0.28rem;
          width: 3.8rem;
          div {
            &:last-child {
              padding-top: 0.1rem;
              font-size: 0.24rem;
              color: #a7a7a7;
            }
          }
        }
      }
      .right {
        @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
        margin-left: .1rem;
        div {
          @include flexbox($jc: center, $ai: center, $fd: row, $fw: nowrap);
          width: 1.4rem;
          height: 0.5rem;
          font-size: 0.26rem;
          background: $btnBg;
          color: #fff;
          border-radius: 0.25rem;
          color: #fff;
        }
      }
    }
  }
}
</style>
