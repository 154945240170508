<template>
  <div class="release">
    <HeaderBar :title="'嫩模发布页'"></HeaderBar>
    <div class="release-main">
      <!-- 标题    -->
      <div class="item">
        <van-field
          type="text"
          :label-width="'1.4rem'"
          label="标题"
          class="captcha-input"
          v-model="params.title"
          placeholder="请输入标题*"
        />
      </div>
      <!-- 设定价格 -->
      <div class="item">
        <van-field
          type="number"
          :label-width="'1.4rem'"
          label="设定价格"
          class="captcha-input"
          v-model="params.price"
          placeholder="请输入设定价格*"
        />
      </div>
      <!-- 联系方式 -->
      <div class="item">
        <van-field
          type="number"
          :label-width="'1.4rem'"
          label="联系方式"
          class="captcha-input"
          v-model="params.contactInfo"
          placeholder="电话号码*"
        />
      </div>
      <!-- 身高 -->
      <div class="item">
        <van-field
          type="number"
          :label-width="'1.4rem'"
          label="身高"
          class="captcha-input"
          v-model="params.girlHeight"
          placeholder="请输入身高"
        />
      </div>
      <!-- 体重 -->
      <!-- <div class="item">
        <van-field
          type="number"
          :label-width="'1.4rem'"
          label="体重"
          class="captcha-input"
          v-model="params.girlWeight"
          placeholder="请输入体重"
        />
      </div> -->
      <!-- 年龄 -->
      <div class="item">
        <van-field
          type="number"
          :label-width="'1.4rem'"
          label="年龄"
          class="captcha-input"
          v-model="params.girlAge"
          placeholder="请输入年龄"
        />
      </div>
      <!-- <div class="item" v-if="datingType == 1">
        <van-field
          type="text"
          :label-width="'1.4rem'"
          label="微信号"
          class="captcha-input"
          v-model="params.weChat"
          placeholder="请输入微信号"
        />
      </div>
      <div class="item" v-if="datingType == 1">
        <van-field
          type="number"
          :label-width="'1.4rem'"
          label="QQ"
          class="captcha-input"
          v-model="params.qq"
          placeholder="请输入QQ号"
        />
      </div>
      <div class="item" v-if="datingType == 1">
        <van-field
          type="text"
          :label-width="'1.4rem'"
          label="地址"
          class="captcha-input"
          v-model="params.address"
          placeholder="请输入详细地址"
        />
      </div> -->
      <!-- 请选择罩杯 params.cupValue  dropdownListObj-->
      <div class="item select-cb-wrap" @click.stop="dropdownListObj.show = true">
        <div class="title">请选择罩杯</div>
        <!-- <div class="triangle"></div> -->
        <div class="cb-box">
          <van-field v-model="params.cupValue" readonly class="cb" placeholder="请选择罩杯" />

          <DmDropdown
            @changeDropdown="changeCb"
            :dropdownListObj="dropdownListObj"
            v-if="dropdownListObj.show"
            :minWidth="2.5"
          >
          </DmDropdown>
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- 服务项目 serviceItemString-->
      <div class="item">
        <div class="title">请选择服务项目</div>
        <div class="service-item">
          <div
            v-for="(item, index) in serviceItemlist"
            :key="index"
            @click="selItem(item, index)"
            :class="{ active: item.checked }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 请选择地区 cityItem.name-->
      <div class="item choose" @click="selCity">
        <div class="title">请选择地区</div>
        <div class="cb-box">
          <van-field v-model="cityItem.name" readonly class="cb" placeholder="请选择地区" />
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- 详细描述   -->
      <div class="item">
        <div class="title">具体描述</div>
        <van-field
          class="textarea"
          v-model="params.desc"
          maxlength="25"
          show-word-limit
          autosize
          type="textarea"
          placeholder="请输入详细描述"
        />
      </div>
      <div class="title">上传图片</div>
      <ul class="img-show">
        <DmImagePreview
          ref="refImgParent"
          v-for="(item, index) in params.images"
          :key="index"
          :upLoadImgs="params.images"
          :rowNum="3"
          :item="item"
          :showClose="true"
        ></DmImagePreview>
        <div class="btn-wrap">
          <UploadImgs :maxLength="9" :upLoadImgs="params.images" @onReadImg="onReadImg">
            <div class="btn1">
              <img src="@/assets/imgs/upload.png" alt="" />
            </div>
          </UploadImgs>
        </div>
      </ul>
      <div class="submit">
        <div class="btn2" @click="submit">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
import { dating_publish } from '@/api/dating'
export default {
  name: 'publishGirl',
  components: {
    HeaderBar: () => import('@/components/HeaderBar.vue'),
    UploadImgs: () => import('@/components/UploadImgs/index.vue'),
    DmImagePreview: () => import('@/components/ImagePreview/index.vue'),
    DmDropdown: () => import('@/components/Dropdown/index.vue')
  },
  data() {
    return {
      //城市
      cityItem: {
        name: '',
        id: ''
      },
      //服务项目(数组)
      serviceItemString: '',
      serviceTypeString: '',
      params: {
        contactInfo: '',
        cupValue: '',
        desc: '',
        girlAge: '',
        girlHeight: '',
        images: [],
        price: '',
        serviceCity: [],
        serviceItems: [],
        serviceType: [],
        taskID: '',
        title: '',
        video: ''
        // girlWeight: '',
        // weChat: '',
        // qq: '',
        // address: ''
      },
      // 罩杯
      dropdownListObj: {
        show: false,
        list: [
          { name: 'A罩杯', id: 0 },
          { name: 'B罩杯', id: 1 },
          { name: 'C罩杯', id: 2 },
          { name: 'D罩杯', id: 3 },
          { name: 'E罩杯', id: 4 },
          { name: 'F罩杯', id: 5 },
          { name: 'G罩杯', id: 6 },
          { name: 'H罩杯', id: 7 },
          { name: 'I罩杯', id: 8 }
        ]
      },
      // 服务方式
      dropdownServerObj: {
        show: false,
        list: [{ name: '上门' }, { name: '到店' }, { name: '空降' }]
      },
      datingType: 1,
      serviceItemlist: [
        { name: '口交', checked: false },
        { name: '水床', checked: false },
        { name: '毒龙', checked: false },
        { name: 'SM', checked: false },
        { name: '舌吻', checked: false },
        { name: '69', checked: false }
      ]
    }
  },
  watch: {
    $route: {
      async handler(n, o) {
        if (!!o) {
          if (n.path != o.path) {
            if (this.$route.query.datingType) {
              // this.datingType = +this.$route.query.datingType
            }
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.datingType = +this.$route.query.datingType
    EventBus.$on('reloadPostCity', item => {
      let newItem = JSON.parse(JSON.stringify(item))
      this.$nextTick(() => {
        this.cityItem.name = newItem.name
        this.cityItem.id = newItem.id
      })
    })
  },
  activated() {},
  methods: {
    //选择城市
    selCity() {
      this.$router.push({
        path: '/dating/city-list',
        query: {
          city: this.cityItem.name,
          datingType: this.datingType,
          type: 'post'
        }
      })
    },
    //选中
    changeSer(item) {
      this.serviceTypeString = item.name
    },
    //选中
    changeCb(item) {
      this.params.cupValue = item.name
    },
    //获取id
    getCbId(cupValue) {
      this.dropdownListObj.list.forEach(item => {
        if (cupValue == item.name) {
          return item.id
        }
      })
    },
    selItem(item, index) {
      this.serviceItemlist[index].checked = !this.serviceItemlist[index].checked
    },
    //读取img
    onReadImg(list) {
      if (!list.length) return
      this.params.images = [...this.params.images, ...list]
    },
    async submit() {
      this.serviceItemString = this.serviceItemlist.filter(obj => obj.checked).map(obj => obj.name)
      let setting = {
        contactInfo: this.params.contactInfo,
        cupValue: this.getCbId(this.params.cupValue),
        desc: this.params.desc,
        girlAge: +this.params.girlAge,
        girlHeight: +this.params.girlHeight,
        images: this.params.images,
        price: +this.params.price * 100,
        serviceCity: [this.cityItem.name],
        serviceItems: this.serviceItemString,
        serviceType: [this.serviceTypeString],
        taskID: '',
        title: this.params.title,
        video: ''
        // cityId: this.cityItem.id,
        // datingType: this.datingType
      }
      // if (this.datingType == 1) {
      //   setting.qq = this.params.qq
      //   setting.weChat = this.params.weChat
      //   setting.address = this.params.address
      //   if (this.cityItem.id == 0 || !this.cityItem.id) {
      //     return this.$toast('请选择城市')
      //   }
      // }
      const res = await dating_publish(setting)
      if (res.code === 200) {
        this.$toast('发布成功，等待审核通过！！！')
        this.$router.go(-1)
      } else {
        return this.$toast(res.tip)
      }
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.state.user.info
    }
  }
}
</script>

<style lang="scss" scoped>
.release {
  min-height: 100vh;
  padding-top: 1rem;
  .release-main {
    padding: 0 0.3rem;
    padding-bottom: 0.6rem;
    .item {
      ::v-deep {
        .van-field__label {
          color: #333;
          font-weight: 500;
          font-size: 0.28rem;
        }
      }
    }
    .service-item {
      display: flex;
      div {
        color: #333;
        padding: 0.1rem;
        background-color: #f5f5f5;
        border-radius: 0.1rem;
        margin-right: 0.2rem;
      }
      .active {
        color: #fff;
        background-color: #ff669d;
      }
    }
    .select-cb-wrap {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cb-box {
        position: relative;
        display: flex;
        align-items: center;
        :deep() {
          .van-field__control {
            text-align: right;
          }
        }
      }
    }
    .choose {
      height: 0.96rem;
      line-height: 0.96rem;
      border-bottom: 0.02rem solid #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        padding: 0;
      }
      .cb-box {
        display: flex;
        align-items: center;
        :deep() {
          .van-field__control {
            text-align: right;
          }
        }
      }
    }
    .title {
      font-size: 0.28rem;
      color: #333;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      font-weight: 500;
    }
    .img-show {
      display: flex;
      flex-wrap: wrap;
      :deep() {
        li {
          &.rowNum3 {
            height: 2.12rem;
          }
        }
      }
      .btn-wrap {
        width: 33.33%;
        padding: 0 0.05rem;
        .btn1 {
          @include flex-center;
          width: 2.12rem;
          height: 2.12rem;
          color: #ff7777;
          font-size: 0.32rem;
          background: #d9d7da;
          img {
            margin-right: 0.1rem;
            width: 0.62rem;
            height: 0.62rem;
          }
        }
      }
    }
    .captcha-input {
      width: 100%;
      padding: 0;
      height: 0.96rem;
      display: flex;
      align-items: center;
      border-bottom: 0.02rem solid #e5e5e5;
    }
    .sel-3 {
      display: flex;
      li {
        width: 33.33%;
        .girlHeight {
          border-top-left-radius: 0.24rem;
          border-bottom-left-radius: 0.24rem;
        }
        //服务城市和服务方式
        &.per50 {
          width: 50%;
        }
        //罩杯
        &.select-cb-wrap {
          position: relative;
          .triangle {
            position: absolute;
            top: 50%;
            right: 0.2rem;
            transform: translateY(-25%);
            z-index: 3;
            width: 0;
            height: 0;
            border-width: 0.15rem;
            border-style: solid;
            border-color: #979797 transparent transparent transparent;
          }
          .cb {
            border-top-right-radius: 0.24rem;
            border-bottom-right-radius: 0.24rem;
          }
        }
        //城市
        &.city {
          position: relative;
          .triangle {
            position: absolute;
            top: 50%;
            right: 0.2rem;
            transform: translateY(-25%);
            z-index: 3;
            width: 0;
            height: 0;
            border-width: 0.15rem;
            border-style: solid;
            border-color: #979797 transparent transparent transparent;
          }
        }
      }
    }
    .item {
      position: relative;
      .textarea {
        border-radius: 0.08rem;
        height: 1.76rem;
        background: #f5f5f5;
      }
      :deep() {
        @include placeholder-color;
      }
    }
    .submit {
      display: flex;
      padding-top: 0.4rem;
      .btn2 {
        margin: 0 auto;
        width: 4.64rem;
        font-size: 0.36rem;
        line-height: 0.36rem;
        padding: 0.22rem 0;
        background: #ff669d;
        @include flex-center;
        border-radius: 0.5rem;
        color: #fff;
        // background: linear-gradient(109.99deg, #00dbd4 -0.6%, #31e5b0 46.87%, #31e5b0 100.66%);
      }
    }
  }
}
</style>
