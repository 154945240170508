<template>
    <!-- 更多专题更多列表,一行3个 -->
    <div class="home-comic-more">
        <HeaderBar :title="$route.query.name"></HeaderBar>
        <!-- <EBackTop :title="$route.query.name" /> -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad"
                :immediate-check="false" error-text="请求失败，点击重新加载">
                <Tab @change="change" :swipeable="true" :active="activeId" :sticky="true" :titles="category"
                    :animated="true" :background="'#fff'" :titleActiveColor="'#fff'" :titleInactiveColor="'#6f6f6f'"
                    class="more-tab-deep">
                    <div class="list-wrap">
                        <ul>
                            <HomeVideoCard v-for="(item, index) in comicsList" :imgRadius="'0.15rem'" :item="item"
                                :key="index"></HomeVideoCard>
                        </ul>

                    </div>

                </Tab>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
<script>
import scrollMixin from '@/utils/scrollMixin'
import { moreList } from 'api/novel'
export default {
    name: 'VideoMore',
    mixins: [scrollMixin],
    components: {
        HomeVideoCard: () => import('@/components/HomeNovel/oneCard'),
        HeaderBar: () => import('@/components/HeaderBar.vue'),
        Tab: () => import('@/components/Tab.vue'),
    },
    data() {
        return {
            //加载刷新
            pageNum: 1,
            pageSize: 9,
            refreshing: false,
            loading: false,
            finished: false,
            comicsList: [],
            activeId: 0,
            category: [
                { name: '最新', id: 0 },
                { name: '最热', id: 1 },
                // { name: '最多收藏', id: 2 }
            ],
        }
    },
    methods: {
        async comicsAuthor(type) {
            const res = await moreList({
                id: +this.$route.query.id,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                sort: this.activeId
            });
            if (res.code === 200 && res.data) {
                this.refreshing = false;
                this.loading = false;
                if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
                    this.finished = true;
                }
                if (type == "pull") this.comicsList = [];
                if (res.data.list) this.comicsList = [...this.comicsList, ...res.data.list];
            } else {
                this.finished = true;
                this.refreshing = false;
                this.loading = false;
                return this.$toast(res.tip)
            }
        },
        change(id) {
            this.activeId = id
            this.onRefresh()
        },
        //上拉加载更多
        async onLoad() {
            this.pageNum += 1
            this.comicsAuthor();
        },
        // 刷新
        onRefresh() {
            this.pageNum = 1;
            this.finished = false;
            this.loading = true;
            this.comicsAuthor('pull');
        },
    },
    mounted() {
        this.onRefresh();
    },
    computed: {
    },
}
</script>

<style lang="scss" scoped>
.home-comic-more {
    min-height: 100vh;
    padding-top: .9rem;
    padding-bottom: 0.1rem;

    :deep() {
        .more-tab-deep {
            .van-sticky {
              background-color: #fff;
                display: flex;
                justify-content: center;
                padding-top: .2rem;
                height: .9rem;
                .van-tabs__wrap {
                    margin-bottom: 0.2rem;
                    justify-content: center;
                    display: flex;
                    // align-items: center;
                    height: .8rem;
                }
            }
        }
    }
}

.list-wrap {
    padding: 0 0.29rem;
    padding-top: .9rem;


    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
            width: 2.16rem;
        }
    }
}

.more-tab-deep {
    :deep() {
        .van-tabs__nav {
            justify-content: center;
            width: 2rem;
            height: .48rem;
            background-image: linear-gradient(to right, #fff, #f1f1f1, #fff) !important;
            align-items: center;
        }

        .van-tabs__line {
            display: none;
        }

        .van-tab--active {
            box-shadow: none !important;
            border-radius: .45rem;
            background-color: #ff509d !important;
          //   position: relative;
          // ::before {
          //   content: '';
          //   width: 100%;
          //   height: 100%;
          //   border-radius: .45rem;
          //   position: absolute;
          //   left: 0;
          //   top: 0;
          //   background-color: #ff509d;
          // }
        }
        .van-tab {
          background-color: #f1f1f1;
          span {
            font-size: .26rem;
          }
        }
        .van-tab:nth-child(1) {
          border-top-left-radius: .45rem;
          border-bottom-left-radius: .45rem;
          width: 50%;
          height: .45rem;
          padding: 0;
          margin: 0;
          padding-bottom: .02rem;
        }
        .van-tab:nth-child(2) {
          border-top-right-radius: .45rem;
          border-bottom-right-radius: .45rem;
          width: 50%;
          height: .45rem;
          padding: 0;
          margin: 0;
          padding-bottom: .02rem;

        }
    }
}
// .tab-list[data-v-bc09d9d6] .van-tab--active
</style>
