

<template>
  <div class="income">
    <EBackTop :title="'分享赚钱'" />
    <!-- 账户余额 -->
    <div class="content">
      <div class="user-money">
        <div class="lv">
          <span>当前等级：{{ incomeInfo.level }}</span>
        </div>
        <div class="user-gold">
          <div class="money-left">
            <div>金币余额</div>
            {{ incomeInfo.balance | changeMoneyYuan }}
          </div>
          <div class="line"></div>
          <div class="moeny-sy">
            <div>总收益</div>
            {{ incomeInfo.totalIncome | changeMoneyYuan }}
          </div>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="btn">
        <div @click="$router.push('/mine/withdraw')">金币提现</div>
        <div @click="$router.push('/mine/share')">推广分享</div>
      </div>
      <!-- 收益 -->
      <ul class="sy-data">
        <li>
          <p>当月推广收益</p>
          <p class="price">{{ incomeInfo.currentMonthIncome | changeMoneyYuan }}</p>
        </li>
        <li class="line"></li>
        <li>
          <p>当月推广人数</p>
          <p class="price">{{ incomeInfo.currentMonthProxy }}</p>
        </li>
        <li class="line"></li>
        <li>
          <p>累计推广人数</p>
          <p class="price">{{ userInfo.invites }}</p>
        </li>
      </ul>

      <!-- 活动规则 -->
      <div class="tip">
        <h2>活动规则</h2>
        <p>1.在APP中，我的-分享推广中，分享推广链接或复制推广码，分享给好友</p>
        <p>2.用户下载安装APP后，自动绑定推荐关系，用户开通会员，最高比例可返55%</p>
        <p>备注:推广等级总共分为4级,达到指定业绩自动升级,即可享有该等级的返佣比例.</p>
        <div class="footer-text">
          <img src="@/assets/imgs/mine/share-tip.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { user_income } from 'api/user'
export default {
  components: {
  },
  data() {
    return {
      incomeInfo: {
        runningLights: []
      }
    }
  },
  created() {
    this.getMyInviteInfo()
  },
  computed: {
    userInfo({$store}){
      return $store.getters['getUserInfo']
    }
  },
  methods: {
    // 获取代理收益
    async getMyInviteInfo() {
      const res = await user_income({})
      if (res.code === 200) {
        this.incomeInfo = res.data
      } else {
        return this.$toast(res.tip)
      }
    }
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
.income {
  min-height: 100vh;
  .top-tip {
    height: 0.6rem;
    font-size: 0.3rem;
    overflow: hidden;
    text-align: center;
    line-height: 0.6rem;
    span:first-child {
      font-weight: 600;
    }
  }
  .content {
    padding: 0rem 0.3rem;
  }
  .user-money {
    background: rgba(29, 29, 33, 0.8);
    font-size: 0.24rem;
    color: #44495e;
    border-radius: 0.1rem;
    .lv {
      display: flex;
      justify-content: center;
      padding: .22rem;
      span {
        min-width: 1.9rem;
        height: 0.44rem;
        // background: $btnBg;
        color: #fff;
        text-align: center;
        line-height: 0.44rem;
      }
    }
    .user-gold {
      @include flexbox($jc: space-around, $ai: center, $fd: row, $fw: nowrap);
      font-size: 0.42rem;
      color: #fff;
      text-align: center;
      .line {
        width: 0.02rem;
        height: 1.29rem;
        margin-bottom: 0.3rem;
        background: #6f707f;
      }
      .money-left,
      .moeny-sy {
        @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);

        div {
          // background: $btnBg;
          background-color: #FFE3EA;
          text-align: center;
          height: 0.48rem;
          width: 1.3rem;
          color: #303145;
          line-height: 0.48rem;
          font-size: 0.24rem;
          border-radius: 15px;

        }
      }
    }
  }
  .btn {
    @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
    margin: 0.3rem 0;
    div {
      background: $btnBg;
      font-size: 0.36rem;
      width: 3.2rem;
      height: 0.74rem;
      line-height: 0.74rem;
      text-align: center;
      box-shadow: $shadow;
      color: #fff;
      border-radius: 0.1rem;
    }
  }
  .sy-data {
    @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
    height: 2rem;
    margin: 0.3rem 0;
    background-color: #ffffff;
    // box-shadow: $shadow;
    border: 1px solid rgba(0, 0, 0, 0.3);
    font-size: 0.26rem;
    border-radius: 0.09rem;
    justify-content: space-around;
    p {
      text-align: center;
      font-size: 0.24rem;
    }
    .price {
      font-size: 0.48rem;
      color: #848494;
      margin: 0;
    }
    .line {
      width: 0.02rem;
      height: 1.29rem;
      background: #848494;
    }
  }

  .tip {
    font-size: 0.24rem;
    color: #848494;

    p {
      margin: 0;
      margin-bottom: 0.1rem;
    }
    .rolor {
      color: #0082ff;
    }
  }
}

.footer-text {
  text-align: center;
  img {
    width: 100%;
  }
}
</style>
