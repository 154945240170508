<template>
  <!-- 一行1个,本月热门 -->
  <div class="monthHot-wrap">
    <div class="header">
      <div>{{ itemTopic.name }}</div>
    </div>          
    <div class="list-wrap">
      <ul>
        <li v-for="(item, index) in itemTopic.comicsList" :key="index" @click="clickImg(item)">
          <DecryptImg  class="md-img" :imgRadius="'0.15rem'" :imgURL="item.coverImg">
            <DmComicCardPayType :item="item"></DmComicCardPayType>
          </DecryptImg>
          <div class="text">
            <div>{{item.title}}</div>
            <div>{{item.desc}}</div>
          </div>
          <div class="rank">
            {{item.rank}}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    DmComicCardPayType: () => import('@/components/PayType.vue'),
  },

  data() {
    return {
    }
  },
  props: {
    itemTopic: {
      type: Object,
      default() {
        return {}
      },
    },
    nowTabItem: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {},
  methods: {
    clickImg(item) {
      if(this.nowTabItem.showType == 8){
        this.$router.push(`/play/comicsVideo/${item.id}`)
      }else{
        this.$router.push(`/home-comic/decial/${item.id}`)
      }
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
//<!-- 一行1个,本月热门-->
.monthHot-wrap {
  padding-bottom: 0.3rem;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 1.05rem;
    padding: 0.3rem 0.37rem;
    div {
      &:first-child {
        font-size: 0.32rem;
        color: #6a6a6a;
      }
    }
  }    
  .list-wrap {
    ul {
      display: flex;
      flex: 1;
      flex-direction: column;
      li {
        padding: 0 0.6rem;
        position: relative;
        display: flex;
        align-items: center;
        width: 6.83rem;
        height: 2.18rem;
        border-radius: 0.41rem;
        background: #fff;
        margin: 0 auto;
        margin-bottom: 0.2rem;
        .md-img{
          width: 1.38rem;
          height: 1.84rem;
          margin-right: 0.2rem;
          box-shadow: 0 0 0.1rem 0 rgb(0 0 0 / 30%);
        }
        .text{
          div{
            &:first-child{
              padding-top:0.2rem ;
              width: 3.92rem;
              font-size: 0.3rem;
              color: #000000;
              padding-bottom:0.05rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              white-space: normal;                 
            }
            &:last-child{
              width: 3.92rem;
              font-size: 0.22rem;
              color: #b0a799;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              white-space: normal;              
            }            
          }
        }
        .rank{
          line-height: 0.96rem;
          text-align: center;
          width: 0.88rem;
          height: 0.96rem;
          background: #ce9f66;
          position: absolute;
          right: 0.3rem;
          top: -0.1rem;
          color: #fff;
          font-size: 0.22rem;
          padding-bottom: 0.9rem;
          border-bottom-left-radius:0.24rem ;
          border-bottom-right-radius: 0.24rem;
          background: url("/newAppMh/home-comic/hot.png");
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
