<template>
  <div class="bookshelf-wrap">
    <EBackTop :title="'书架'">
      <slot slot="right">
        <!-- 右上下拉列表 -->
        <div class="toggle-nav-wrap">
          <div class="btn" :class="{ active: menuObj.showSetting }"
            @click.stop="menuObj.showSetting = !menuObj.showSetting">
            {{ menuObj.showSetting ? '取消' : '编辑' }}
          </div>
        </div>
        <!-- <div class="share" @click="share_fn">
          <div class="share-icon"></div>
          <div class="share-text">分享</div>
        </div> -->
      </slot>
    </EBackTop>

    <Tab :sticky="true" :titles="category" :active="activeTab" :addClass="'bookshelf-tab'" titleInactiveColor="#666"
      titleActiveColor="#333">
      <template v-slot:default="scope">
        <component :is="compComponent(scope.item.id)" :menuObj="menuObj"></component>
        <!-- 发布按钮 -->
      </template>
    </Tab>
  </div>
</template>
<script>
export default {
  components: {
    Tab: () => import('@/components/Tab.vue'),
    comicBook: () => import('@/components/HomeComic/book.vue'),
    novelBook: () => import('@/components/HomeNovel/novel.vue'),
  },
  data() {
    return {
      //右上下拉列表
      menuObj: {
        showSetting: false
      },
      activeTab: 0,
      category: [
        {
          id: 0,
          name: '漫画'
        },
        {
          id: 1,
          name: '小说'
        }
      ]
      //下拉
    }
  },
  methods: {
    compComponent(data) {
      switch (data) {
        case 1:
          return 'novelBook'
        default:
          return 'comicBook'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bookshelf-wrap {
  min-height: 100vh;
  padding-top: 1rem;
  padding-bottom: 1.66rem;
  color: #6a6a6a;
  position: relative;

  :deep() {
    .van-nav-bar__placeholder {
      height: 0 !important;
    }
  }

  .toggle-nav-wrap {
    position: absolute;
    bottom: -38px;
    z-index: 999;
    right: 0.27rem;

    .btn {
      @include flex-center;
      font-size: 0.28rem;
      width: 1.04rem;
      height: 0.55rem;
      color: #ffffff;
      background: #ff669d;

      &.active {
        background: #999999;
      }
    }
  }
}
</style>
