<template>
  <div class="recharge">
    <EBackTop :title="''" />
      <!--切换面板 -->
      <ETab ref="containerTab" class="container-tab" @change="change" :sticky="true" :tabList="category"
      :v-model="showtype " :animated="false" :lineSwitch="true"
      :lineBg="'#FF669D'" :lineHeight="'0.08rem'" :line-width="'0.4rem'" :title-active-color="'#FF669D'"
      :title-inactive-color="'#333'" :sizeTab="'0.32rem'" :sizeActive="'.38rem'" :swipe-threshold="4">
          <template v-slot:default="scope">
              <GoldCoinRecharge  v-if="showtype === 0"></GoldCoinRecharge>
              <VipRecharge @changeType="change" :showType="scope.item.id" v-if="showtype === 1 ">
              </VipRecharge>
          </template>
      </ETab>

  </div>
</template>
<script>
export default {
  components: {
      VipRecharge: () => import('@/views/mine/vip/index.vue'),
      GoldCoinRecharge: () => import('@/views/mine/my-wallet/index.vue'),
  },
  data() {
      return {
          category: [
              { id: 0, name: '金币' },
              { id: 1, name: 'VIP' },
              // { id: 2, name: 'SVIP' },
          ],
          showtype: 0,
      }
  },
  async mounted() {
      this.showtype = +this.$route.query.type
      this.$nextTick(()=>{
        setTimeout(()=>{
          this.$refs['containerTab'].$children[0].scrollTo(this.showtype)
        },500)

      })

  },
  computed: {
      userInfo({ $store }) {
          return $store.getters['user/getUserInfo']
      },
  },
  methods: {

      change(type) {
          this.showtype  =  type

      },

  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-popup--bottom {
      max-width: 7.5rem;
      left: auto;
  }
}

.recharge {
  min-height: 100vh;
  overflow: hidden;
  .left-text {
      position: fixed;
      top: 0;
      z-index: 99;
      width: 0.9rem;
      height: 0.88rem;
      color: #fff;
      font-size: 0.28rem;
      line-height: 1.1rem;
      text-align: center;
  }
}
</style>
