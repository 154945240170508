<template>
  <div class="postDetails">
    <!-- 帖子详情 -->
    <template v-if="showType">
      <div class="details-txt details-title">
        <!-- <span class="categoryType">#{{ newData.categoryId | nickNamef(findCategory) }}</span> -->
        <span>{{ newData.base.title }}</span>
        <!-- <span v-else-if="newData.postMediaInfo">{{ newData.postMediaInfo.desc }}</span> -->
      </div>
      <div v-for="(item, index) in newData.nodes" :key="index">
        <div class="details-txt">
          <span>{{ item.text }}</span>
        </div>
        <p class="desc">{{ newData.base?.videoText }}</p>

        <div class="img-pack" @click.stop="clickImg(item.imgs)">
          <EDecryptImg
            v-for="(imgs, index) in item.imgs"
            :key="index"
            :imgCDN="imgCDN"
            class="img-item"
            :needPadding="false"
            :imgURL="imgs"
            ref="refImg"
          />
        </div>
      </div>
      <EDecryptImg
        :imgCDN="imgCDN"
        v-if="newData.base.videoUrl"
        class="img-item img-video"
        :imgURL="newData.base.videoCover"
      >
        <div class="play" @click.stop="fn_playVideo(newData)">
          <div class="play_text" v-if="newData.base.price && !newData.base.isBought" style="">
            <img src="@/assets/imgs/noto_coin.svg" alt="" />
            {{ newData.base.price / 100 }}金币解锁观看
          </div>
          <div class="play_text" v-else-if="isMemberAndNotShop" style="">会员解锁观看</div>
          <img src="@/assets/imgs/index/card-play.svg" alt="" />
        </div>

      </EDecryptImg>
    </template>

    <!-- 发现帖子列表 -->
    <div class="desc-txt" v-if="showType == 0">
      <div class="title_box">
        <span class="at" v-if="newData.base?.recommend">精华</span>
        <span class="title">{{ newData.base?.title }}</span>
      </div>

      <div>
        <p class="desc">
          {{ newData.node?.text }}
        </p>
        <div class="desc_btn" @click.stop="toDetail(newData)">...阅读全文</div>
      </div>
    </div>
    <!-- 视频展示 同时也是详情里的视频展示-->
    <div class="video-cover" v-if="newData.postMediaInfo && showType" @click.stop="fn_playVideo(newData)">
      <div class="video-pack">
        <div class="video-l">
          <VideoImage :media="newData.postMediaInfo"></VideoImage>
        </div>
        <div class="video-r">
          {{ newData.postMediaInfo?.title }}
        </div>
      </div>
    </div>
    <!-- 图片+video展示最多显示2排 -->
    <template v-else-if="showType == 0">
      <lazy-component>
        <div class="img-list" v-if="newData.base?.postCategory !== '情色文学'">
          <EDecryptImg
            :imgCDN="imgCDN"
            v-if="newData.base?.videoUrl"
            class="img-item img-video"
            :imgURL="newData.base?.videoCover"
          >
            <div class="play">
              <img src="@/assets/imgs/index/card-play.svg" alt="" />
            </div>
          </EDecryptImg>
          <!-- 历史记录item与其他记录item字段不一致 -->
          <template v-if="newData.node && newData.node.imgs">
            <EDecryptImg
              :imgCDN="imgCDN"
              class="img-item"
              v-for="(img, index) in newData.node?.imgs"
              :needPadding="false"
              :key="index"
              :imgURL="img"
            />
          </template>
          <template v-if="newData.nodes && newData.nodes?.imgs">
            <EDecryptImg
              :imgCDN="imgCDN"
              class="img-item"
              v-for="(img, index) in newData.nodes?.imgs"
              :needPadding="false"
              :key="index"
              :imgURL="img"
            />
          </template>

          <i></i>
        </div>
        <div v-else class="node_text">
          {{ newData.node?.text }}
        </div>
      </lazy-component>
    </template>
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>
<script>
import { ImagePreview } from 'vant'
export default {
  data() {
    return {
      newData: {}
    }
  },
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isDetail: {
      typeof: Boolean,
      default() {
        return false
      }
    },
    showType: {
      type: Number,
      default: () => {
        return 0 //0:帖子列表 1:帖子详情
      }
    }
  },
  watch: {
    itemData: {
      handler(n, o) {
        this.newData = n
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取首页顶部视频分类导航列表
    findCategory({ $store }) {
      return $store.getters['findCategory']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    },
 isMemberAndNotShop() {
      return (!this.newData.base.price && !this.newData.base.isBought && !this.isMember && this.newData.base.isVip) || (this.userInfo.vipType == 11 && this.newData.base.isVip)
    }
  },
  filters: {
    nickNamef(v, tabList) {
      for (let index = 0; index < tabList.length; index++) {
        const element = tabList[index]
        if (v == element.id) {
          return element.name
        }
      }
    }
  },

  components: {
    VideoImage: () => import('@/components/videoImage.vue'),
    DmPopup: () => import('@/components/Popup/index.vue')
  },
  methods: {
    // 详情跳转
    toDetail(item) {
      this.$router.push(`/community/detail/${item.base.id}`)
    },
    fn_playVideo(item) {

        if (
          (!this.isMember && !this.newData.base.isBought && !this.newData.base.price && this.newData.base.isVip) ||
           (this.userInfo.vipType == 11 && this.newData.base.isVip && !this.newData.base.price)
        ) {
          this.$refs['popupMsg'].popupMsg = {
            show: true,
            title: '注意',
            content: `您当前还不是VIP，无法继续`,
            type: 'up_post',
            ok: '充值VIP',
            img: '',
            cancel: '做任务得VIP',
            position: 'center',
            showBalance: false,
            cb: async returnData => {
              this.$router.push('/mine/newRecharge?type=1')
            },
            close: async returnData => {
              this.$router.push('/mine/agent')
            }
          }
          return
        }
        this.$emit('fn_playVideo', item)

    },

    // 图片预览
    clickImg(imgUrl) {
      if (this.isDetail) {
        const imgArr = []
        let index = 0
        const domArr = this.$refs['refImg']
        console.log('domArr', domArr)
        domArr.forEach((itemBlob, indexBlob) => {
          if (imgUrl === itemBlob.imgURL) {
            index = indexBlob
          }
          imgArr.push(itemBlob.realUrl)
        })

        ImagePreview({
          images: imgArr, // 需要预览的图片 URL 数组
          showIndex: true, // 是否显示页码
          loop: true, // 是否开启循环播放
          startPosition: index, // 图片预览起始位置索引

          closeable: true
        })
      }
    }
  },
  mounted() {
    // 判断是否显示展开收起按钮
    // if (this.newData.node && this.newData.node.text && !this.isDetail) {
    //   this.ifOver = this.$refs.spanBox.offsetHeight > this.$refs.textBox.offsetHeight;
    // }
  }
}
</script>
<style lang="scss" scoped>
.play_text {
  position: absolute;
  top: 0.28rem;
  right: 0.4rem;
  display: flex;
  align-items: center;
  color: #f4f4f4;
  img {
    width: 0.24rem !important;
    height: 0.24rem !important;
    margin-right: 0.1rem;
  }
}

.img-video {
  position: relative;
  width: 6.78rem;
  height: 3.82rem;

  .play {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 0.6rem;
      height: 0.6rem;
    }

    // left: 50%;
    // top:50%;
    // width: ;
  }
}

.postDetails {
  margin-bottom: 0.1rem;

  .desc-txt {
    font-size: 0.26rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: normal;
    white-space: pre-wrap;

    .at {
      padding: 0.04rem 0.2rem;
      color: #fff;
      border-radius: 0.06rem;
      background: $btnBg;
      margin-right: 0.13rem;
      font-size: 0.24rem;
      white-space: nowrap;
    }

    .categoryType {
      color: $txtActive;
      margin-right: 0.05rem;
    }

    .title_box {
      @include flexbox($jc: flex-start, $ai: flex-start);
      @include textoverflow(2);
      color: rgba($color: #000000, $alpha: 0.7);
    }

    .title {
      font-size: 0.32rem;
    }

    .desc {
      margin-top: 0.24rem;
      color: #999;
      @include textoverflow(3);
    }

    .desc_btn {
      color: #ffcf86;
    }
  }

  .details-txt {
    font-size: 0.26rem;
    color: rgba($color: #999, $alpha: 1);
    white-space: pre-line;

    &.details-title {
      font-size: 0.36rem;
      margin-bottom: 0.16rem;
      padding-bottom: 0.28rem;
      border-bottom: 0.01rem solid #999;
      color: #333;
    }

    .categoryType {
      color: #1d93d6;
      margin-right: 0.05rem;
    }
  }

  .img-pack {
    margin: 0.2rem 0;
    .img-item {

      margin-top: 0.2rem;
      border-radius: 0.2rem;
:deep(){
  img{
    border-radius: 0.2rem;

  }
}
    }
  }

  .img-list {
    @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
    margin: 0.14rem 0 0.2rem 0;

    .img-item {
      // width: 2.94rem;
      width: fix-content;
      margin-top: 0.1rem;
      border-radius: 0.1rem;
      overflow: hidden;
      width: 1.94rem;
      height: 1.94rem;
      margin-right: 0.16rem;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    i {
      width: 2.26rem;
    }

    .img-item:nth-child(3n) {
      margin-right: 0;
    }
  }

  .video-cover {
    margin: 0 auto;

    .video-desc {
      font-size: 0.3rem;
      max-height: 1.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      white-space: normal;
      white-space: pre-wrap;
    }

    .video-pack {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.2rem 0;

      .video-l {
        min-width: 2.74rem;
        max-width: 2.74rem;
        height: 1.54rem;
        margin-right: 0.2rem;
        border-radius: 0.08rem;
        overflow: hidden;

        ::v-deep {
          .vide_detail {
            height: 0.3rem;
            font-size: 0.18rem;
            padding: 0 0.1rem;
          }

          .video_image {
            height: 100%;
          }
        }
      }

      .video-r {
        font-size: 0.2rem;
        color: #333;
      }
    }

    .sm-video {
      position: relative;

      ::v-deep {
        .warpNoPadding {
          img {
            object-fit: contain;
            max-height: 6.9rem;
          }
        }
      }

      .van-icon-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

:deep() .btn-wrap {
  > div:nth-child(2) {
    background: red;
  }
}

.node_text {
  @include textoverflow(4);
  white-space: pre-line;
  color: #999;
}
</style>
