<template>
  <div class="sign-in">
    <EBackTop color="#fff" :background="'transparent'">
      <slot slot="right" name="right">
        <div class="right-btn" @click="$router.push('/mine/checkIn/rule')">签到规则</div>
      </slot>
    </EBackTop>
    <div class="sign-in-top-wrap">
      <div class="score-wrap">
        <div class="count">
          积分<span> {{ allMsgObj?.countPoints || 0 }}</span>
        </div>
        <div class="day">
          已连续签到 <span>{{ allMsgObj?.checkedDays || 0 }}</span> 天
        </div>
        <div :class="allMsgObj?.todayChecked ? 'isBtn' : 'btn'" @click="fn_signIn">
          {{ allMsgObj?.todayChecked ? '已签到' : '签到' }}
        </div>
      </div>
    </div>
    <!-- 福利兑换 -->
    <div class="sign-in-common-list">
      <ETab
        class="sign-in-tab"
        :activeId="indexActive"
        :tabList="tabListObj.list"
        :animated="true"
        @change="changeId"
        :title-active-color="'#FF669D'"
        :title-inactive-color="'#333'"
      >
        <template v-slot:default="scope">
          <div class="line"></div>
          <ul v-if="scope.item.name == '萌新任务'">
            <li v-for="(item, index) in taskList" :key="index">
              <div class="left-wrap">
                <div class="t1">{{ item.title }}</div>
              </div>
              <div class="right-wrap" :class="{ finished: item.status }">
                <span v-if="!item.status">+{{ item.rewardPoint }}积分</span>
                <span v-else>已完成</span>
              </div>
            </li>
          </ul>
          <ul v-if="scope.item.name == '每日福利'">
            <li v-for="(item, index) in todaySetup" :key="index">
              <div class="left-wrap">
                <div class="t1">{{ item.title }}</div>
                <div class="t2">{{ item.remark }}</div>
              </div>
              <div class="right-wrap" :class="{ finished: item.status }">
                <span v-if="!item.status">+{{ item.rewardPoint }}积分</span>
                <span v-else>已完成</span>
              </div>
            </li>
          </ul>
          <ul v-if="scope.item.name == '福利兑换'" class="redeem">
            <li v-for="(item, index) in prizeList" :key="index" class="redeem-item">
              <EDecryptImg class="item_bg" :imgCDN="imgCDN" :imgURL="item.cover ? item.cover : ''"> </EDecryptImg>
              <div class="item_desc">
                <p>{{ item.pointsNum }}积分</p>
                <p @click="fn_redeem(item)">立即兑换</p>
              </div>
            </li>
            <div class="redeemBtn">
              <div @click="$router.push('/mine/checkIn/checkRedeem')">查看兑换记录</div>
            </div>
          </ul>
        </template>
      </ETab>
      <!-- 全部积分 -->
      <div class="footer" v-if="selItem.name == '萌新任务' && selItem.type === 1">
        <span
          >完成所有的萌新任务+ <span class="rewardPoint">{{ mxAllObj.rewardPoint }}积分</span>
        </span>
      </div>
      <!-- <div class="history" @click="$router.push('/mine/sign-in/redeemhistory')">查看兑换记录</div> -->
    </div>
  </div>
</template>

<script>
import { handleURlParams } from '@/utils/utils_tools'
import { checkin_page, checkin_click, prize_redeem } from '@/api/user'
export default {
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {
      tabListObj: {
        active: 0,
        list: [
          { name: '萌新任务', type: 1 },
          { name: '每日福利', type: 3 },
          { name: '福利兑换', type: 2 }
        ]
      },
      indexActive: 0,
      selItem: { name: '萌新任务', type: 1 },
      allMsgObj: {},
      prizeList: [], //奖励列表
      taskList: [], //任务列表
      todaySetup: [], //每日福利
      score: 0, //积分
      mxAllObj: {} //萌新
    }
  },
  mounted() {
    console.log(1111)
    if (this.$route.query.active) {
      const active = +this.$route.query.active
      this.indexActive = active
      this.changeId(this.tabListObj.list[active].type)
    }
    this.checkinPage()
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  methods: {
    //领取
    async fn_redeem(item) {
      const res = await prize_redeem({
        prizeId: item.id
      })
      if (res.code === 200) {
        this.$toast('领取成功')
        this.checkinPage()
      } else {
        return this.$toast(res.tip)
      }
    },
    // 签到
    async fn_signIn() {
      //添加点击动画
      if (this.allMsgObj.todayChecked) return this.$toast('今日已签到')
      const res = await checkin_click({})
      if (res.code === 200) {
        this.$toast('签到成功')
        this.checkinPage()
      } else {
        return this.$toast(res.tip)
      }
    },
    // 获取任务中心列表
    async checkinPage() {
      const res = await checkin_page({})
      if (res.code === 200) {
        this.allMsgObj = res.data
        let { prizeList, taskList } = res.data
        this.prizeList = prizeList || [] //奖励列表
        this.taskList = []
        this.todaySetup = []
        taskList.forEach(element => {
          if (element.taskType == 1 && element.title != '完成所有的萌新任务') {
            this.taskList.push(element)
          } else if (element.title == '完成所有的萌新任务') {
            this.mxAllObj = element
          } else if (element.taskType == 2) {
            this.todaySetup.push(element)
          }
        })
      } else {
        return this.$toast(res.tip)
      }
    },
    // 任务详情跳转
    itemDetail(item) {
      if (item.type == 8) return this.$router.push(`/mine/work/invite-friends`)
      // 跳转完成页面
      if (item.status === 0) {
        let code = handleURlParams(item.goTo, this.$router)
      } else if (item.status === 1) {
        this.missionReward(item)
        this.$toast('领取中...')
      } else {
        return
      }
    },
    // 领取任务
    async missionReward(item) {
      const res = await this.$missionReward({
        id: item.type
      })
      if (res.code === 200) {
        this.missionInfo()
        return this.$toast('领取成功')
      } else {
        return this.$toast(res.tip)
      }
    },
    // banner跳转
    clickImg(item) {
      let code = handleURlParams(item.href, this.$router)
    },
    // 标题改变
    changeId(index) {
      console.log(index, '22')
      this.tabListObj.list.map(item => {
        if (item.type == index) {
          this.selItem = item
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.r_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in {
  padding-bottom: 0.4rem;
  min-height: 100vh;

  .right-btn {
    font-size: 0.24rem;
    border-radius: 0.32rem;
    color: #333;
    padding: 0.08rem 0.16rem;
    background: rgba(255, 255, 255, 0.6);
  }

  .sign-in-top-wrap {
    width: 100%;
    height: 4.6rem;
    background: url('../../../assets/imgs/mine/sign_in_bg.png') no-repeat;
    background-size: 100% 100%;
    @include flexbox($jc: center, $fd: column);
    position: absolute;
    top: 0;

    .score-wrap {
      text-align: center;
      color: #fff;
      .count {
        font-size: 0.42rem;

        span {
          font-size: 0.48rem;
        }
      }

      .day {
        font-size: 0.24rem;
        margin: 0.1rem 0 0.29rem 0;
      }

      span {
        color: $txtActive;
      }

      .btn {
        border-radius: 0.32rem;
        background: $btnBg;
        padding: 0.08rem 0.4rem;
        color: #fff;
      }

      .isBtn {
        border-radius: 0.32rem;
        padding: 0.08rem 0.4rem;
        background: $btnBg;
        color: #fff;
      }
    }
  }

  .sign-in-common-list {
    margin-top: 3rem;
    border-radius: 0.32rem 0.32rem 0rem 0rem;
    padding: 0 0.32rem;
    background: #fff;
    min-height: calc(100vh - 4.6rem);
    z-index: 2;
    position: relative;
    .line {
      border: 0.01rem solid #c7c7c7;
    }

    .title {
      padding: 0.3rem 0;
      font-size: 0.36rem;
      border-bottom: 1px solid#eee;
    }

    ul {
      li {
        padding: 0.3rem 0;
        padding-right: 0.05rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.28rem;

        //border-bottom: 1px solid#eee;
        &:last-child {
          border-bottom: 0;
          box-shadow: none;
        }

        .left-wrap {
          .t1 {
          }

          .t2 {
            font-size: 0.22rem;
            opacity: 0.6;
          }
        }
        .right-wrap-btn {
          border-radius: 0.12rem;
          background: $btnBg;
          padding: 0.08rem 0.16rem;
          color: #fff;
        }
        .right-wrap {
          @include flex-center;
          width: 1.39rem;
          height: 0.55rem;
          border-radius: 0.12rem;
          background: $btnBg;
          color: #fff;

          &.finished {
            color: #fff;
            background: $btnBg;
          }
        }
      }
    }
    // 积分兑换
    .redeem {
      @include flexbox($fw: wrap);
      margin-top: 0.32rem;
      .redeem-item {
        margin-bottom: 0.14rem;
        display: block;
        margin-right: 0;
        padding: 0;
        .item_bg {
          width: 3.36rem;
          height: 1.52rem;
          border-radius: 0.16rem 0.16rem 0rem 0rem;
          :deep() {
            img {
              border-radius: 0.16rem 0.16rem 0rem 0rem;
            }
          }
          .title_txt {
            position: absolute;
            top: 0.3rem;
            left: 0.24rem;
            color: #ccc;
            font-size: 0.24rem;

            .sub_title {
              font-size: 0.32rem;
              color: #fff;
              font-weight: 600;
            }
          }
        }
        .item_desc {
          @include flexbox();
          border-radius: 0rem 0rem 0.16rem 0.16rem;
          background: rgba(255, 255, 255, 0.06);
          padding: 0.18rem 0.24rem;
          p {
            &:last-child {
              background: $txtActive;
              color: #333;
              padding: 0.06rem 0.16rem;
              border-radius: 0.21rem;
              font-size: 0.2rem;
            }
          }
        }
      }

      .redeemBtn {
        width: 100%;
        @extend .r_center;
        div {
          @extend .r_center;
          width: 7rem;
          background-color: #ff669d;
          color: #fff;
          height: 0.8rem;
          border-radius: 0.12rem;
        }
      }
    }
  }

  .footer {
    text-align: center;
    padding: 0.3rem 0;

    .rewardPoint {
      color: $txtActive;
    }
  }

  .history {
    margin: 0.54rem auto 0;
    @include flex-center;
    border-radius: 0.12rem;
    background: #00f6f8;
    width: 6.22rem;
    height: 0.72rem;
    color: #333;
  }
}
</style>
