<template>
  <!-- 更多专题更多列表,一行3个 -->
  <div class="home-comic-author">
    <EBackTop :title="$route.query.name" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂时没有更多数据！"
        @load="onLoad"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
      >
        <div class="list-wrap">
          <ul>
            <DmComicCard v-for="(item, index) in comicsList" :item="item" :key="index"></DmComicCard>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { comicsAuthor } from 'api/comics'
export default {
  components: {
    DmComicCard: () => import('@/components/HomeComic/oneCard')
  },
  data() {
    return {
      //加载刷新
      pageNum: 1,
      pageSize: 20,
      refreshing: false,
      loading: false,
      finished: false,
      comicsList: []
    }
  },
  methods: {
    async comicsAuthor(type) {
      const res = await comicsAuthor({
        author: this.$route.query.name,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200 && res.data) {
        this.refreshing = false
        this.loading = false
        this.comicsData = res.data.list
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true
        }
        if (type == 'pull') this.comicsList = []
        if (res.data.list) this.comicsList = [...this.comicsList, ...res.data.list]
      } else {
        this.finished = true
        this.refreshing = false
        this.loading = false
        return this.$toast(res.tip)
      }
    },
    //上拉加载更多
    async onLoad() {
      this.pageNum += 1
      this.comicsAuthor()
    },
    // 刷新
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.comicsAuthor('pull')
    }
  },
  mounted() {
    this.onRefresh()
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.home-comic-author {
  min-height: 100vh;
  padding-bottom: 0.1rem;
  .list-wrap {
    padding: 0 0.37rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
</style>
