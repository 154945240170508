<template>
  <div class="recharge">
    <!-- 账户余额 -->
    <div class="user-gold">
      <p>当前余额</p>
      <p>{{ userInfo.balance | changeMoneyYuan }}</p>
    </div>

    <!-- 主题内容 -->
    <div class="recharge-main">
      <!-- 提现按钮 -->
      <!-- <div class="withdraw-btn" @click="$router.push('/mine/withdraw')">提现</div> -->
      <!-- 选择金币类型 -->
      <div class="gold-list">
        <div class="title">选择金币充值额度</div>
        <ul class="check-type">
          <li
            v-for="(item, index) in priceList"
            :class="{ active: active === index }"
            @click="changeActive(index)"
            :key="item.id"
          >
            <div class="top-text">
              {{ item.goldBase }}
              <img style="margin-left: 0.2rem" src="@/assets/imgs/index/gold.png" alt="" />
            </div>
            <p style="white-space: nowrap" class="name">{{ item.name }}</p>
            <!-- <img src="@/assets/imgs/mine/Subtract.png" alt=""> -->
            <img class="tipsicon" src="@/assets/imgs/mine/Subtract.png" alt="" />
          </li>
        </ul>
        <div class="active-desc">{{ priceList[active]?.desc }}</div>
      </div>

      <!-- 底部支付按钮 -->
      <!-- <div class="footer-btn" @click="showPopFn">立即支付</div> -->
      <div class="submit-pop">
        <div class="content">
          <ul class="pay-list">
            <li :key="index" v-for="(item, index) in activeData.rchgType" @click="fn_sel(item.type)" class="item">
              <div class="left" v-if="item.type == 'alipay'">
                <img src="@/assets/imgs/mine/zfb.png" alt="" />{{ item.typeName }}
              </div>
              <div class="left" v-if="item.type == 'wechat'">
                <img src="@/assets/imgs/mine/weChat.png" alt="" />{{ item.typeName }}
              </div>
              <div class="left" v-if="item.type == 'overage'">
                <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
              </div>
              <div class="left" v-if="item.type == 'union'">
                <img src="@/assets/imgs/mine/bank.png" alt="" />{{ item.typeName }}
              </div>
              <div class="right">
                <div class="active" v-if="item.type == payMode"></div>
              </div>
            </li>
          </ul>
          <div class="submit" @click="showPopFn">
            <div>{{ activeData.payAmount | changeMoneyYuan }}/立即支付</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部支付弹窗 -->
    <van-popup v-model="showPop" position="bottom" :style="{ height: '50vh' }" class="submit-pop">
      <div class="content">
        <div class="title">请确认订单</div>
        <div class="pice">
          已选择：<span style="color: #ff669d">¥{{ activeData.payAmount | changeMoneyYuan }}</span>
        </div>
        <ul class="pay-list" v-if="activeData.rchgType && activeData.rchgType.length">
          <li :key="index" v-for="(item, index) in activeData.rchgType" @click="fn_sel(item.type)" class="item">
            <div class="left" v-if="item.type === 'alipay'">
              <img v-if="payMode === item.type" src="@/assets/imgs/mine/zfb2.png" alt="" />
              <img v-else src="@/assets/imgs/mine/zfb1.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'wechat'">
              <img v-if="payMode === item.type" src="@/assets/imgs/mine/weChat.png" alt="" />
              <img v-else src="@/assets/imgs/mine/WeChat3.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'overage'">
              <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'union'">
              <img src="@/assets/imgs/mine/bank.png" alt="" />{{ item.typeName }}
            </div>
            <div class="left" v-if="item.type === 'daichong'">
              <img src="@/assets/imgs/mine/gold.png" alt="" />{{ item.typeName }}
            </div>
            <div class="right">
              <div class="active" v-if="item.type == payMode"></div>
            </div>
          </li>
        </ul>
        <div class="tip-wrap">
          付款提示：<br />
          1、支付前请先绑定手机号，避免重新安装时用户权益遗失！<br />
          2、支付前先选择支付方式，再点“确认支付”跳转后请及时付款，超时支付无法到账，需重新发起支付。<br />
          3、若支付时出现任何风险提示请不要担心，重新支付一次即可，并不会重复付款。<br />
          4、付款如遇到其他问题，可咨询在线客服处理
        </div>
        <div class="submit" @click="fn_submit">
          <div>确认支付</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { paytypeinfo, recharge_sumbit } from 'api/home'
export default {
  components: {},
  data() {
    return {
      priceList: [],
      active: 0,
      activeData: {},
      showPop: false,
      payMode: ''
    }
  },
  async mounted() {
    await this.getPaytypeinfo()
    if (this.priceList && this.priceList.length > 0) {
      this.activeData = this.priceList[0]
      if (this.activeData.rchgType && this.activeData.rchgType.length !== 0) {
        this.payMode = this.activeData.rchgType[0].type
      }
    }
    window.addEventListener('scroll', this.scrollHandle) // 绑定页面滚动事件s
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  methods: {
    // 消费记录
    onClickRight() {
      this.$router.push(`/mine/recharge/record`)
    },

    // 获取购买金币列表
    async getPaytypeinfo() {
      const res = await paytypeinfo({})
      if (res.code === 200) {
        this.priceList = res.data.payModes
        if (this.priceList.length) {
          this.changeActive(0)
        }
      } else {
        this.$toast(res.tip)
      }
    },
    // 打开底部弹窗
    showPopFn() {
      this.showPop = true
    },
    // 切换卡片类型
    changeActive(index) {
      this.active = index
      this.activeData = this.priceList[index]
      if (this.activeData.rchgType && this.activeData.rchgType.length !== 0) {
        this.payMode = this.activeData.rchgType?.[0]?.type || ''
      }
    },
    // 选择支付方式
    fn_sel(type) {
      this.payMode = type
    },
    // 支付金币
    async fn_submit() {
      if (!this.payMode) return this.$toast('请选择支付通道！！！')
      this.$toast.loading({
        duration: 0,
        message: '正在生成订单'
      })
      const res = await recharge_sumbit({
        payAmount: this.activeData.payAmount,
        payMode: this.payMode,
        productId: this.activeData.id,
        rchgUse: this.activeData.rchgUse
      })
      if (res.code === 200) {
        this.showPop = false
        if (!res.data) return this.$toast('购买成功！！！')
        if (res.data && res.data.isOpenNewBrowser) {
          this.downloadFileByTagA(res.data.payUrl)
        }
        this.$toast.clear()
      } else {
        this.$toast.clear()
        this.$toast(res.tip)
      }
    },
    /* 利用a标签跳转safari浏览器机制 */
    downloadFileByTagA(fileUrl) {
      const TagA = document.createElement('a')
      TagA.href = fileUrl
      // TagA.target = '__blank';
      TagA.className = 'oInput'
      TagA.style.display = 'none'
      // 兼容ios safari浏览器
      const e = document.createEvent('MouseEvent')
      e.initEvent('click', false, false)
      TagA.dispatchEvent(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.pice {
  font-size: 0.28rem;
  color: #040404;
  text-align: center;
}
.txbtn {
  width: 1rem;
  height: 0.48rem;
  border-radius: 15px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rtMoney {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.24rem;
}
.recharge {
  min-height: 100vh;
  padding-top: 0;
  color: #fff;
  :deep() {
    .LayoutsHeader {
      .van-nav-bar {
        background: transparent;
        border: none;
        .van-icon-arrow-left,
        .van-nav-bar__title {
          color: #fff !important;
        }
      }
    }
  }
  .right-btn {
    color: #333;
    border-radius: 0.37rem;
    font-size: 0.24rem;
    height: 0.48rem;
    width: 1.4rem;
    text-align: center;
    line-height: 0.48rem;
  }
  .user-gold {
    font-size: 0.42rem;
    font-weight: 600;
    width: 6.86rem;
    height: 2.82rem;
    padding-top: 1rem;
    margin: 0.2rem auto;
    background: url('../../../assets/imgs/mine/user-balance-bg.png') no-repeat;
    background-size: 100% 100%;
    p {
      font-size: 0.32rem;
      font-weight: 400;
      margin-left: 0.5rem;
      color: #333;
      &:last-child {
        font-size: 0.6rem;
        color: #ff77ce;
      }
    }
  }
  &-main {
    background: #fff;
    padding-top: 0.4rem;
    color: #848494;
    font-size: 0.35rem;
    min-height: calc(100vh - 5.16rem);
    .withdraw-btn {
      background: $btnBg;
      box-shadow: $shadow;
      width: 3.2rem;
      height: 0.74rem;
      text-align: center;
      line-height: 0.74rem;
      margin: 0 auto;
      color: #040404;
      font-size: 0.36rem;
      border-radius: 0.05rem;
    }
    .gold-list {
      // box-shadow: $shadow;s
      margin: 0rem 0.25rem 0.3rem 0.25rem;
      padding: 0.3rem;
      background-color: #fff;
      border-radius: 15px 15px 0 0;
      .title {
        font-size: 0.32rem;
        color: #040404;
        font-weight: 600;
        text-align: center;
        padding: 0.2rem;
      }
      ul {
        @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
        li {
          width: 2rem;
          flex-shrink: 0;
          height: 1.2rem;
          border: 0.01rem solid #707070;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 0.2rem;
          margin-top: 0.15rem;
          border-radius: 0.05rem;
          padding: 0.16rem 0;
          position: relative;
          p {
            margin: 0;
          }

          .top-text {
            @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: wrap);
            img {
              margin-right: 0.15rem;
              width: 0.37rem;
              height: 0.37rem;
            }
          }
          .name {
            transform: scale(0.7);
          }
        }
        li:nth-child(3n) {
          margin-right: 0;
        }
        .active {
          border: 1px solid #ff669d;
          color: #ff669d;
          .tipsicon {
            display: block;
          }
        }
        .tipsicon {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 0.52rem;
          height: 0.47rem;
          display: none;
        }
      }
      .active-desc {
        font-size: 0.3rem;
        padding: 0.2rem 0;
      }
    }
  }

  // 会员卡类型

  // 底部按钮
  .footer-btn {
    width: 6.3rem;
    margin: 0 auto;
    height: 0.9rem;
    color: #040404;
    background: $btnBg;
    text-align: center;
    font-weight: 600;
    line-height: 0.9rem;
    border-radius: 0.05rem;
  }

  // 弹窗
  .submit-pop {
    // background: url('/images/mine/pop-bg.png') no-repeat;
    // background-size: 100% 100%;
    border-top-left-radius: 0.15rem;
    border-top-right-radius: 0.15rem;
    padding: 0rem 0.3rem;
    ul {
      padding: 0 0.3rem;
      li {
        height: 0.8rem;
        display: flex;
        align-items: center;
        padding: 0 0.3rem;
        background: linear-gradient(90deg, rgba(251, 224, 124, 0.1) 2.5%, rgba(255, 187, 16, 0.1) 97.4%);
      }
      li:nth-child(2n + 1) {
        background: #ff669d;
      }
    }
    .title {
      font-size: 0.38rem;
      color: #040404;
      width: 100%;
      margin-bottom: 0.48rem;
      margin-top: 0.4rem;

      text-align: center;
      div {
        color: #666;
        font-size: 0.26rem;
        margin-left: 0.1rem;
      }
      span {
        color: #ed9200;
        font-weight: 600;
      }
    }

    // 支付方式
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .left {
      display: flex;
      align-items: center;
      font-size: 0.28rem;
      color: #040404;
      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.33rem;
      }
    }
    // 选中状态
    .right {
      width: 0.28rem;
      height: 0.28rem;
      border-radius: 50%;
      border: 0.02rem solid #ed9200;

      position: relative;
      .active {
        width: 0.2rem;
        height: 0.2rem;
        background: #ed9200;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .tip-wrap {
      font-size: 0.24rem;
      color: #040404;
      margin-top: 0.5rem;
      margin-right: 0.7rem;
    }
    // 支付按钮
    .submit {
      width: 6.4rem;
      height: 0.9rem;
      text-align: center;
      line-height: 0.9rem;
      background: #ff669d;
      color: #fff;
      font-size: 0.32rem;
      margin: 0 auto;
      margin-top: 0.6rem;
    }
  }
}
</style>
