<template>
  <div class="release">
    <HeaderBar :title="'举报'"></HeaderBar>
    <div class="release-main">
      <!-- 描述信息 -->
      <van-field v-model="text" autosize required type="textarea" :placeholder="textList" />
      <div class="uploadImg">
        <div class="feedbackImg">
          <DecryptImg
            class="bg-box"
            :imgURL="item"
            v-for="(item, index) in upLoadImgs"
            :key="index"
            ref="refImg"
            @click.native="imagePreview(index)"
          >
          </DecryptImg>
        </div>
        <van-uploader multiple :max-count="3" :after-read="onReadImg" :before-delete="deleteImage" />
      </div>

      <!-- <ul class="img-show">
        <DmImagePreview
          ref="refImgParent"
          v-for="(item, index) in upLoadImgs"
          :key="index"
          :upLoadImgs="upLoadImgs"
          :rowNum="3"
          :item="item"
          :showClose="true"
        ></DmImagePreview>
      </ul>
      <div class="btn-wrap">
        <UploadImgs :maxLength="9" :upLoadImgs="upLoadImgs" @onReadImg="onReadImg">
          <div class="btn1">
            <img src="/images/dating/upload.png" alt="" />
            插入图片
          </div>
        </UploadImgs>
        <div class="btn2" @click="submit">发布</div>
      </div> -->
    </div>
    <div class="submitBtn">
      <van-button type="primary" color="#FF669D" :disabled="!text" round block @click="submit">提交反馈</van-button>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { imgsCompress } from '@/utils/utils_tools'
import { dating_complaint } from 'api/dating'
import { uploadImgs } from 'api/home'
export default {
  components: {
    HeaderBar: () => import('@/components/HeaderBar.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {
      upLoadImgs: [],
      text: '',
      textList:
        '1.每一条楼凤只能举报一次不能重复举报\n, 2.楼凤举报有截图证明的合适以后可享受楼凤退款\n,3.购买楼凤后超过7天再进行举报则视为常规举报，不享受退款处理'
    }
  },
  methods: {
    // 图片预览
    imagePreview(indexImg) {
      let imgArr = []
      let domArr = this.$refs['refImg']
      domArr.forEach(item => {
        imgArr.push(item.realUrl)
      })
      ImagePreview({
        images: imgArr, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: true, //是否开启循环播放
        startPosition: indexImg //图片预览起始位置索引
      })
    },
    //读取img
    async onReadImg(file) {
      if (this.upLoadImgs.length >= 4) return this.$toast('最多传4张')
      //组合
      let formData = new FormData()
      let newBlobList = []
      if (!file.length) {
        newBlobList = await imgsCompress([file])
      } else {
        newBlobList = await imgsCompress(file)
      }
      for (let index = 0; index < newBlobList.length; index++) {
        formData.append('upload[]', newBlobList[index])
      }
      //上传
      this.$toast.loading({ duration: 0, message: '图片上传中请稍后' })
      const res = await uploadImgs(formData)
      if (res.code === 200) {
        this.$toast.clear()
        this.$toast('上传成功')
        this.upLoadImgs.push(...res.data.paths)
        // this.$emit('result', this.upLoadImgs)
      } else {
        this.$toast(res.tip || res.msg)
      }
    },
    deleteImage(file) {
      console.log(file, 'index')
    },
    async submit() {
      if (!this.text || this.text.length < 8) {
        return this.$toast('描述信息字数不可少于8个字')
      }
      // if (this.upLoadImgs.length < 4) {
      //   return this.$toast('图片不能少于4张')
      // }
      const res = await dating_complaint({
        images: this.upLoadImgs,
        text: this.text,
        type: +this.$route.query.type, //1:楼凤 2:外围
        objectId: +this.$route.params.id
      })
      if (res.code === 200) {
        this.$router.go('-1')
        return this.$toast('举报成功，请等待审核')
      } else if (res.code === 6059) {
        return this.$toast('重复上传')
      } else {
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.release {
  min-height: 100vh;
  padding-top: 1rem;
  &-main {
    margin: 0.24rem;
    padding: 0.24rem;
    // margin-top: 1.24rem;
    background: #eee;
    border-radius: 0.16rem;
    .van-cell {
      border-radius: 0.24rem;
      height: 3.87rem;
      margin-top: 0.2rem;
      background: #eee;
      color: #000;
      :deep() {
        .field-placeholder-text-color {
          color: #9493b1;
        }
        .van-field__control {
          color: #333;
          font-size: 0.24rem;
        }
      }
    }
    .van-cell::after {
      display: none;
    }
  }
  // .img-show {
  //   display: flex;
  //   flex-wrap: wrap;
  //   margin-top: 0.2rem;
  //   :deep() {
  //     li {
  //       &.rowNum3 {
  //         height: 2.12rem;
  //       }
  //     }
  //   }
  // }
  // .btn-wrap {
  //   display: flex;
  //   padding-top: 0.4rem;
  //   .btn1 {
  //     @include flex-center;
  //     border-radius: 0.24rem;
  //     width: 2.6rem;
  //     height: 0.78rem;
  //     color: #ff7777;
  //     font-size: 0.32rem;
  //     background: #fff;
  //     img {
  //       margin-right: 0.1rem;
  //       width: 0.33rem;
  //       height: 0.28rem;
  //     }
  //   }
  //   .btn2 {
  //     font-size: 0.32rem;
  //     background: #fff;
  //     @include flex-center;
  //     border-radius: 0.24rem;
  //     width: 4.12rem;
  //     margin-left: 0.4rem;
  //     color: #ff8b00;
  //   }
  // }

  .uploadImg {
    display: flex;
    width: 5.04rem;
    // overflow: hidden;

    .feedbackImg {
      display: flex;

      .bg-box {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    ::v-deep {
      .van-uploader {
        .van-uploader__upload {
          width: 1.68rem;
          height: 1.68rem;
        }
      }
    }
  }
}

.submitBtn {
  background: #fff;
  padding: 0.16rem 0.24rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: $pcMaxWidth;
}
</style>
