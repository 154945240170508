<template>
  <div class="boss-main">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="cityItem && cityItem.name + '暂无更多数据'"
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="list-wrap">
          <bossCardNew :bossList="bossList"></bossCardNew>
          <SelfCard
            :showType="item.datingType"
            :itemData="item"
            v-for="(item, index) in recommentGirl"
            :key="index"
          ></SelfCard>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { dating_category } from '@/api/dating'
export default {
  props: {
    cityItem: {
      type: Object,
      default() {
        return null
      }
    }
  },
  components: {
    bossCardNew: () => import('@/components/Dating/boss/bossCardNew.vue'),
    SelfCard: () => import('@/components/Dating/self-employed/selfCard.vue')
  },
  watch: {
    cityItem: {
      handler(n, o) {
        this.onRefresh()
      }
    }
  },
  data() {
    return {
      bossList: [],
      pageNum: 1,
      pageSize: 10,
      refreshing: false,
      finished: false,
      loading: false,
      recommentGirl: []
    }
  },
  computed: {},
  mounted() {
    this.onRefresh()
  },
  methods: {
    onLoad() {
      this.pageNum++
      this.datingBossRecoment()
    },
    //上拉刷新
    onRefresh() {
      this.bossList = []
      this.finished = false
      this.loading = true
      this.pageNum = 1
      this.datingBossRecoment()
    },
    // 获取经纪人列表
    async datingBossRecoment() {
      const res = await dating_category({
        cityId: this.cityItem ? this.cityItem.id : 0,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: 3 // 1楼凤 2外围 3商家
      })
      if (res.code === 200) {
        this.loading = false
        this.refreshing = false
        if (!res.data || !res.data.bossList || res.data.bossList.length < this.pageSize) {
          this.finished = true
        }
        for (let index = 0; index < res.data.bossList.length; index++) {
          const element = res.data.bossList[index]
          if (element.recomment) {
            this.bossList.push(element)
          }
        }
        if (this.bossList.length > 0) {
          let recommentGirl = []
          this.bossList.forEach(e => {
            recommentGirl = recommentGirl.concat(e.recommentGirl)
          })
          this.recommentGirl = [...recommentGirl]
        }
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip)
      }
    }
  }
}
</script>

<style lang="scss" scopde>
.boss-main {
  //   padding: 0 0.24rem;
  .list-wrap {
    .bossInfo-wrap {
      padding: 0 0.24rem;
    }

    .item-card {
      padding: 0.25rem 0.24rem !important;
      border-bottom: 1px solid #bea2c5;
      margin-bottom: 0;
    }
  }
}
</style>
