<template>
  <div class="findhoTop" @click="$router.push('/subpage/search')">
    <div class="iptbox">请输入你想要的文字</div>
    <div class="textbox">搜索</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.findhoTop {
  display: flex;
  width: 7.5rem;
  height: 0.64rem;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem 0.36rem;
  z-index: 20;
  left: auto;
  background: #fff;

  .iptbox {
    width: 5.7rem;
    border-radius: 12px 0px 0px 12px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 0.22rem;
    padding: 0 0.28rem;
    display: flex;
    align-items: center;
    height: 0.64rem;
  }
  .textbox {
    flex-grow: 1;
    border-radius: 0px 12px 12px 0px;
    background: $btnBg;
    font-size: 0.28rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.64rem;
  }
}
</style>
