<template>
  <div class="record-container">
    <EBackTop title="流水记录"></EBackTop>
    <van-pull-refresh v-if="transList && transList.length>0" v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad">
        <ul class="record-listWrap">
          <li class="item" v-for="(item, index) in transList" :key="index">
            <div class="left">
              <div>订单号：{{ item.orderNo }}</div>
              <div>{{ item.createdAt | formatDate}}</div>
              <div>{{ item.desc }}</div>
            </div>
            <div class="right">
              <div v-clipboard:copy="item.orderNo" v-clipboard:success="onCopy" v-clipboard:error="onError">
                <span>复制订单号</span>
              </div>
              <div>
                {{ item.coinAmount | changeMoneyYuan }}
                <img src="@/assets/imgs/index/gold.png" alt="" />
              </div>
            </div>
          </li>
        </ul>
      </van-list>
    </van-pull-refresh>
     <Nodata :text="'您还没有记录哦～'" v-else/>
  </div>
</template>
<script>
import { transList } from 'api/user'
export default {
  data() {
    return {
      // 上拉加载（loading:false代表可继续上拉加载）
      loading: false,
      // 上拉加载完成（完成只需要出现1次，finished: true，转圈消失，加载完成字体出现）
      finished: false,
      // 下拉刷新
      refreshing: false,
      // 关注列表
      transList: [],
      // 请求类型
      getType: '',
      // 当前页
      pageNum: 1,
      // 当前个数
      pageSize: 10
    }
  },

  components: {
     Nodata: () => import('@/components/JavNodata.vue'),
  },
  methods: {
    // 获取流水记录
    async get_transList(type) {
      try {
        const res = await transList({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.refreshing = false
          this.loading = false
          this.transList = [...this.transList, ...res.data.list]
          if (res.data && res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          // 下拉消失（自动开启下拉）
          this.refreshing = false
          this.loading = false
          this.finished = true
          this.$toast(res.tip)
        }
      } catch (error) {
        this.refreshing = false
        this.loading = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 下拉加载
    onLoad() {
      this.pageNum++
      this.get_transList()
    },
    // 上拉刷新
    onRefresh() {
      this.finished = false
      this.loading = true
      this.pageNum = 1
      this.transList = []
      this.get_transList()
    },
    onCopy() {
      this.$toast('复制成功！！！')
    },
    onError() {
      this.$toast('复制失败！！！')
    }
  },
  mounted() {
    this.onRefresh()
  },
  created() {},
  computed: {}
}
</script>
<style lang="scss" scoped>
.record-container {
  padding-top: 1rem;
  min-height: 100vh;

  .record-listWrap {
    margin: 0.3rem 0;
    .item {
      display: flex;
      justify-content: space-between;
      font-size: 0.24rem;
      align-items: center;
      padding: 0.16rem 0.25rem;
      background: #ffffff;
      margin: 0.15rem 0.3rem;
      box-shadow: $shadow;
      .left {
        display: flex;
        flex-direction: column;
        div {
          &:nth-child(2) {
            padding: 0.15rem 0;
            color: #93939e;
          }
          &:nth-child(3) {
           @include textoverflow();
          }
        }
      }
      .right {
        div {
          &:first-child {
            span {
              display: inline-block;
              width: 1.74rem;
              height: 0.5rem;
              border-radius: 0.43rem;
              background: $btnBg;
              margin-bottom: 0.53rem;
              color: #fff;
              text-align: center;
              line-height: 0.5rem;
            }
          }
          &:last-child {
            font-size: 0.32rem;
            text-align: right;
            img {
              width: 0.3rem;
              height: 0.3rem;
              margin-left: 0.1rem;
            }
          }
        }
      }
    }
  }
}
</style>
