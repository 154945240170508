<template>
  <div class="recharge">
    <EBackTop :title="'意见反馈详情页'" />
    <div class="feedbackText">
      <textarea v-model="Textlength"
        :placeholder="`一，描述问题的详细情况，有助于我们快速帮您解决问题（必填）\n二，也可以进入我的页面底部【加入群聊】里面的问题处理专员群，可找专员直接反馈，处理更迅速快捷~！`"></textarea>
      <div class="uploadImg">
        <div class="feedbackImg">
          <DecryptImg class="bg-box" :imgURL="item" v-for="(item, index) in upLoadImgs" :key="index" ref="refImg"
            @click.native="imagePreview(index)">
          </DecryptImg>
        </div>
        <van-uploader multiple :max-count="3" :after-read="onReadImg" :before-delete="deleteImage" />
      </div>
    </div>
    <div class="submitBtn">
      <van-button type="primary" color="#FF669D" :disabled="!Textlength" round block
        @click="customerFeedback">提交反馈</van-button>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import { imgsCompress } from '@/utils/utils_tools'
import { feedback_add } from 'api/user'
import { uploadImgs } from 'api/home'
export default {
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {
      fileList: [],
      upLoadImgs: [],
      Textlength: ''
    }
  },
  async mounted() { },
  computed: {},
  methods: {
    // 图片预览
    imagePreview(indexImg) {
      let imgArr = []
      let domArr = this.$refs['refImg']
      domArr.forEach(item => {
        imgArr.push(item.realUrl)
      })
      ImagePreview({
        images: imgArr, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: true, //是否开启循环播放
        startPosition: indexImg //图片预览起始位置索引
      })
    },
    async onReadImg(file) {
      if (this.upLoadImgs.length >= 3) return this.$toast('最多传3张')
      //组合
      let formData = new FormData()
      let newBlobList = []
      if (!file.length) {
        newBlobList = await imgsCompress([file])
      } else {
        newBlobList = await imgsCompress(file)
      }
      for (let index = 0; index < newBlobList.length; index++) {
        formData.append('upload[]', newBlobList[index])
      }
      //上传
      this.$toast.loading({ duration: 0, message: '图片上传中请稍后' })
      const res = await uploadImgs(formData)
      if (res.code === 200) {
        this.$toast.clear()
        this.$toast('上传成功')
        this.upLoadImgs.push(...res.data.paths)
        // this.$emit('result', this.upLoadImgs)
      } else {
        this.$toast(res.tip || res.msg)
      }
    },
    deleteImage(file) {
      console.log(file, 'index')
    },
    async customerFeedback() {
      const res = await feedback_add({
        content: this.Textlength,
        pics: this.upLoadImgs,
        type: 1
      })
      //   if (this.$route.query.type) {
      //     let data = {
      //       objectId: this.$route.query.id,
      //       text: this.Textlength,
      //       images: [...this.upLoadImgs],
      //       type: this.$route.query.type ? +this.$route.query.type : 1,
      //     }
      //     res = await this.$datingComplaint(data) // 约炮举报 type 1:楼凤 2:外围
      //   } else {
      //     let data = {
      //       content: this.Textlength,
      //       pics: [...this.upLoadImgs],
      //       type: this.$route.query.type ? +this.$route.query.type : 1,
      //     }
      //     res = await this.$customerFeedback(data)
      //   }
      if (res.code == 200) {
        this.Textlength = ''
        this.upLoadImgs = []
        setTimeout(() => {
          this.$router.back()
        }, 500)
        this.$toast('提交成功')
      } else {
        this.$toast(res.tip)
      }
    }
  },
  //图片筛选
  filters: {
    filterPayImg(item) {
      switch (item.type) {
        case 'alipay':
          return '/images/mine/zfb.png'
          break
        case 'wechat':
          return '/images/mine/weChat.png'
          break
        case 'overage':
          return '/images/home/gold.png'
          break
        case 'union':
          return '/images/mine/bank.png'
          break
        case 'daichong':
          return '/images/mine/bank.png'
          break
        default:
          return '/images/mine/bank.png'
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recharge {
  min-height: 100vh;
  background: #fff;
  overflow: hidden;

  .feedbackText {
    margin: 0.24rem;
    padding: 0.24rem;
    // margin-top: 1.24rem;
    background: #eee;
    border-radius: 0.16rem;

    textarea {
      width: 100%;
      height: 3rem;
      background: transparent;
      border: 0;
    }

    .uploadImg {
      display: flex;
      width: 5.04rem;
      overflow: hidden;

      .feedbackImg {
        display: flex;

        .bg-box {
          width: 1.68rem;
          height: 1.68rem;
        }
      }

      ::v-deep {
        .van-uploader {
          .van-uploader__upload {
            width: 1.68rem;
            height: 1.68rem;
          }
        }
      }
    }
  }

  .submitBtn {
    background: #fff;
    padding: 0.16rem 0.24rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: $pcMaxWidth;
  }
}
</style>
