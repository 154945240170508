<template>
  <!-- 一行两个 -->
  <div class="twoBox-wrap">
    <MoreHeader :itemTopic="itemTopic"></MoreHeader>
    <div class="list-wrap">
      <ul>
        <HomeVideoCard v-for="(item, index) in novelList " :imgRadius="imgRadius" :item="item" :key="index"
          @clickImg="clickImg"></HomeVideoCard>
      </ul>
    </div>
    <!-- <div class="btn">
      <div @click="fn_more">查看更多</div>
      <div @click="comicsTopicChange">换一换</div>
    </div> -->
  </div>
</template>
<script>
import { videosRouterTo } from '@/utils/utils_tools'
export default {
  components: {
    HomeVideoCard: () => import('@/components/HomeNovel/oneCard'),
    MoreHeader: () => import('@/components/HomeNovel/topic/moreHeader.vue'),
  },

  data() {
    return {
      novelList: [],

    }
  },
  props: {
    itemTopic: {
      type: Object,
      default() {
        return {}
      },
    },

    imgRadius: {
      type: String,
      default() {
        return ''
      },
    },
    showType: {
      type: String,
      default() {
        return ''
      },
    },
  },
  computed: {},
  methods: {
    clickImg(item) {
      let newIndex = 0
      this.itemTopic.mediaList.forEach((element, index) => {
        if (element.id == item.id) {
          newIndex = index
        }
      })
      videosRouterTo(
        this.$store,
        this.$router,
        this.itemTopic.mediaList,
        item,
        newIndex
      )
    },
    //查看更多
    fn_more() {
      console.log(this.itemTopic);
      this.$router.push({
        path: `/home-novel/more/list`, query: {
          id: this.itemTopic.id,
          name: this.itemTopic.name
        }
      });
    },
    async comicsTopicChange() {
      const res = await this.$novelChange({
        id: this.itemTopic.id,
      });
      if (res.code === 200 && res.data) {
        this.novelList = res.data.list;
      } else {
        return this.$toast(res.tip)
      }
    }
  },
  mounted() {
    this.novelList = this.itemTopic.novelList
  },
}
</script>

<style lang="scss" scoped>
//<!-- 一行两个 -->
.twoBox-wrap {
  // box-shadow: 0 0.06rem 0.1rem rgb(0 0 0 / 10%);
  // padding-bottom: 0.1rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 1.05rem;
    padding: 0.3rem 0.37rem;

    div {
      &:first-child {
        font-size: 0.32rem;
        color: #6a6a6a;
      }

      &:last-child {
        background: #fff;
        @include flex-center;
        font-size: 0.24rem;
        width: 1.02rem;
        height: 0.46rem;
        color: #FF669D;
        border-radius: 0.12rem;
        box-shadow: 0.06rem 0.06rem 0.1rem rgb(0 0 0 / 10%);
      }
    }
  }

  .list-wrap {
    padding: 0 0.37rem;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li {
      width: 2.1rem;
    }
  }
}

.btn {
  display: flex;
  width: 5rem;
  margin: 0.2rem auto;

  div {
    width: 1.72rem;
    height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    border-radius: 0.24rem;
    box-shadow: 0.06rem 0.06rem 0.1rem rgb(0 0 0 / 10%);
    font-size: 0.28rem;
    margin: 0 auto;
    background: #fff;
    color: rgb(255, 139, 0);
    // &:first-child{
    //   color:#ff7777;
    // }
  }

}
</style>
