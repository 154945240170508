<template>
  <div class="photo-item" @click="$router.push(`/photoDetail/${photoData.id}`)">

    <DecryptImg :needPadding="false" class="cover-img" :imgURL="photoData.cover">
      <PayType :item="photoData" />
      <div class="likes">
        <van-icon name="like-o" :color="photoData.isLike ? '#ed608b' : '#fff'" />
        {{ photoData.likes | numberFilter }}
      </div>
    </DecryptImg>
    <p class="title">{{ photoData.title }}</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    photoData() {
      let data = JSON.parse(JSON.stringify(this.data));
      if (data.isVip) {
        data.payType = 1;
      } else if (data.price) {
        data.payType = 2;
      }
      return data;
    },
  },

  mounted() { },
  components: {
    PayType: () => import('@/components/PayType'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
  },
};
</script>

<style lang="scss" scoped>
.photo-item {
  width: 2.24rem;
  margin-bottom: 0.3rem;
  position: relative;
}

.cover-img {
  height: 2.88rem !important;
  position: relative;
  border-radius: 0.1rem;
  overflow: hidden;

  .photo-type {
    position: absolute;
    top: 0;
    right: 0;

    .item-type {
      width: 0.68rem;
      height: 0.3rem;
    }

    .item-type-gold {
      font-size: 0.2rem;
      color: #fff;
      background: #ed608b;
      height: 0.3rem;
      border-bottom-left-radius: 0.12rem;
      line-height: 0.3rem;
      padding: 0 0.1rem;
      box-sizing: border-box;

      img {
        width: 0.2rem;
        height: 0.2rem;
      }
    }
  }

  .likes {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0.02rem 0.2rem;
    font-size: 0.22rem;
    background: rgba($color: #000000, $alpha: 0.3);
    color: #fff;

    :deep() {
      .van-icon-like-o {
        margin-right: 0.15rem;
      }
    }
  }
}

.title {
  margin: 0.15rem 0 0 0;
  font-size: 0.24rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; //多行在这里修改数字即可
  overflow: hidden;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
</style>
