<template>
  <!-- 单个漫画 -->
  <div class="payType-wrap">
    <div
      v-if="item.payType == 0 || item.comicsPayType == 0 || item.hGamePayType == 0 || item.novelPayType == 0"
      class="free"
    >
      <span>免费</span>
    </div>
    <div
      v-if="item.payType == 1 || item.comicsPayType == 1 || item.hGamePayType == 1 || item.novelPayType == 1"
      class="free vip"
    >
      <span>VIP</span>
    </div>
    <div
      v-if="item.payType == 2 || item.comicsPayType == 2 || item.hGamePayType == 2 || item.novelPayType == 2"
      class="free price"
    >
      <div class="coin">
        <img src="@/assets/imgs/index/gold.png" alt="" />
        {{ item.price | changeGold }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    imgRadius: {
      type: String,
      default() {
        return '0.15rem'
      }
    }
  },
  computed: {},
  methods: {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
.payType-wrap {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
  .free {
    z-index: 1;
    padding: 0.01rem 0.06rem;
    background: #4e982e;
    color: #fff;
    font-size: 0.22rem;
    text-align: center;
    // border-top-left-radius: 0.15rem;
    // border-bottom-right-radius: 0.15rem;
    span {
      display: inline-block;
      transform: scale(0.9);
    }
    .coin {
      display: flex;
      align-items: center;
      transform: scale(0.9);
    }
  }
  .vip {
    background: #ff669d;
  }
  .price {
    padding: 0.01rem 0.06rem;
    background: #3e287d;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 0.24rem;
      height: 0.24rem;
      margin-right: 0.05rem;
    }
  }
}
</style>
