import { render, staticRenderFns } from "./PullUp.vue?vue&type=template&id=6596c766&scoped=true&"
import script from "./PullUp.vue?vue&type=script&lang=js&"
export * from "./PullUp.vue?vue&type=script&lang=js&"
import style0 from "./PullUp.vue?vue&type=style&index=0&id=6596c766&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6596c766",
  null
  
)

export default component.exports