<template>
  <div class="tab-list">
    <van-tabs
      v-model="activeDefault"
      swipe-threshold="2"
      :swipeable="swipeable"
      lazy-render
      :animated="animated"
      :line-height="lineHeight"
      :title-inactive-color="titleInactiveColor"
      :title-active-color="titleActiveColor"
      :sticky="sticky"
      :background="background"
      :before-change="beforeChange"
      @change="change"
      :offset-top="offsetTop"
      :class="addClass"
    >
      <van-tab v-for="(item, index) in titles" :key="item.id" :title="item.name" :name="item.id">
        <template v-slot:title>
          <div class="tab-title acc">
            <span>{{ item.name }}</span>
            <!-- 当前选中的标签显示图标 -->
            <!-- <div class="icon" v-if="isShowIcon">
          <van-icon   :name="require('../static/newAppMh/small.svg')" />
        </div> -->
          </div>
        </template>
        <slot :data="item.showType" :item="item" :index="index" />
        <!-- <slot :data="item.showType" :item="item" :index="index" /> -->
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  props: {
    isShowIcon: {
      type: Boolean,
      default() {
        return false
      }
    },
    addClass: {
      type: String,
      default() {
        return ''
      }
    },
    //切换是否滚回顶部
    ifScrollTop: {
      type: Boolean,
      default() {
        return true
      }
    },
    animated: {
      type: Boolean,
      default() {
        return true
      }
    },
    swipeable: {
      type: Boolean,
      default() {
        return true
      }
    },
    titles: {
      type: Array,
      default() {
        return []
      }
    },
    background: {
      type: String,
      default() {
        return '#fff'
      }
    },
    beforeChange: {
      type: Function,
      default() {
        return () => true
      }
    },
    offsetTop: {
      type: String,
      default() {
        return ''
      }
    },
    sticky: {
      type: Boolean,
      default() {
        return false
      }
    },
    active: {
      type: Number,
      default() {
        return 0
      }
    },
    lineHeight: {
      type: Number,
      default() {
        return 3
      }
    },
    hasRoute: {
      type: Boolean,
      default() {
        return true
      }
    },
    titleActiveColor: {
      type: String,
      default() {
        return '#ff8b00'
      }
    },
    titleInactiveColor: {
      type: String,
      default() {
        return '#723c1f'
      }
    },
    path: {
      type: String,
      default() {
        return '/'
      }
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    change(id) {
      let nowItem = {}
      this.titles.forEach(item => {
        if (item && item.id == id) {
          nowItem = item
        }
      })
      if (this.ifScrollTop) document.body.scrollTop = document.documentElement.scrollTop = 0
      this.$emit('change', id, nowItem)
    }
  },
  computed: {
    activeDefault: {
      get() {
        return this.active
      },
      set() {}
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  font-size: 0.28rem;
  position: absolute;
  bottom: -0.34rem;
  text-align: center;
  width: 100%;
}
.tab-list {
  :deep() {
    .ysmh_top {
      img {
        width: 1.27rem;
      }
    }
    .van-tab--active {
      .ysmh_top {
        img {
          width: 1.4rem;
        }
      }
      .acc {
        .icon {
          display: block;
        }
      }
    }
    .acc {
      position: relative;
      .icon {
        display: none;
      }
    }

    .van-sticky {
      position: fixed;
      left: 50%;
      width: 100%;
      max-width: $pcMaxWidth;
      transform: translate(-50%, 0);
      z-index: 10;
      overflow: hidden;
    }
    .van-tabs__wrap {
      height: 0.88rem;
    }
    .van-tabs__wrap,
    .van-tab__text--ellipsis {
      overflow: visible;
    }
    .van-tabs__nav--line {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
    }
    .van-tab {
      padding: 0 0.2rem;
      opacity: 0.7;
      margin: 0.2rem 0;
      flex: none;
      font-size: 0.32rem;
    }
    .van-tab--active {
      font-weight: normal;
      opacity: 1;
      box-shadow: 0 0.02rem 0.3rem 0 rgb(0 0 0 / 10%);
    }
    //首页主导航
    .home-tab {
      .van-tabs__wrap {
        overflow-x: auto;
        overflow-y: visible;
        @include scrollbar-hide;
      }
      .van-sticky {
        align-items: center;
        display: flex;
        height: 1.3rem;
        background: #fff;
        top: 0.8rem;

        z-index: 12;
      }
      .van-tab {
        padding: 0 0.16rem;
      }
      .van-tab.van-tab--active {
        // background: #fff;
        // border-radius: 0.12rem;
        font-size: 0.4rem;
      }
      .van-tabs__line {
        display: none;
      }
      .van-tabs__nav--line {
      }
    }
    // 有声漫画tabs
    .ysmh_tab {
      .van-tabs__wrap {
        overflow-x: auto;
        overflow-y: visible;
        @include scrollbar-hide;
      }
      .van-sticky {
        align-items: center;
        display: flex;
        height: 0.9rem;
        background: #fff;
        top: 0rem;
      }
      .van-tab {
        padding: 0 0.16rem;
      }
      .van-tab.van-tab--active {
        // background: #fff;
        // border-radius: 0.12rem;
        font-size: 0.4rem;
      }
      .van-tabs__line {
        display: none;
      }
      .van-tabs__nav--line {
      }
    }
    //首页次级导航
    .home-tab2 {
      .van-tabs__wrap {
        height: auto;
        padding-left: 0.37rem;
        padding-right: 0.37rem;
        background: #fff;
      }
      .van-tab {
        font-size: 0.32rem;
        padding: 0.16rem 0.35rem !important;
        margin: 0.1rem 0;
        // box-shadow: 0 1px 2px 0 #ebeaee inset;
      }
      .van-tab.van-tab--active {
        background: #fff;
        border-radius: 0.24rem;
        box-shadow: 0 0.5px 0.2rem rgb(0 0 0 / 10%);
      }
      .van-tabs__line {
        display: none;
      }
    }
    //搜索导航
    .search-tab {
      .van-sticky {
        top: 2.06rem;
        z-index: 1;
      }
      .van-tabs__wrap {
        padding-left: 0.37rem;
        padding-right: 0.37rem;
        background: #fff;
        height: calc(0.7rem + 0.4rem);
      }
      .van-tab {
        font-size: 0.32rem;
        padding: 0 0.54rem;
      }
      .van-tab.van-tab--active {
        background: #fff;
        border-radius: 0.24rem;
      }
      .van-tabs__line {
        display: none;
      }
    }
    //新
    .new-tab {
      .van-sticky {
        width: 5rem;
        top: 0.1rem;
        z-index: 9999;
      }
      .van-tabs__wrap {
        background: #fff;
        height: calc(0.7rem + 0.4rem);
      }
      .van-tabs__nav {
        justify-content: center;
      }
      .van-tab {
        font-size: 0.36rem;
        padding: 0 0.2rem;
        margin-right: 0.3rem;
        box-shadow: unset;
      }
      .van-tab.van-tab--active {
        background: #fff;
        //border-radius: 0.24rem;
      }
      .van-tabs__line {
        display: none;
      }
    }
    //个人中心浏览历史导航,
    .mineHistory-tab {
      .van-sticky {
        z-index: 1;
      }
      .van-tabs__wrap {
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fff;
      }
      .van-tab {
        height: 0.55rem;
        font-size: 0.32rem;
        padding: 0;
        flex: auto;
        box-shadow: unset;
      }
      .van-tab.van-tab--active {
        //background: #fff;
      }
      .van-tabs__line {
        display: none;
      }
      .van-tabs__nav--line {
        padding-bottom: 0;
      }
    }
    //个人中心浏览历史导航,
    .bookshelf-tab {
      .van-sticky {
        z-index: 1;
        box-sizing: border-box;
      }
      .van-tabs__wrap {
        padding-left: 0.37rem;
        width: 100%;
      }
      .van-tab {
        height: 0.55rem;
        font-size: 0.32rem;
        padding: 0;
        margin-right: 0.3rem;
        // flex: auto;
        box-shadow: unset;
      }
      .van-tab.van-tab--active {
        //background: #fff;
      }
      .van-tabs__line {
        bottom: 4px;
        width: 0.24rem;
        height: 0.08rem;
        background: #ff669d;
        border-radius: 0.07rem;
      }
      .van-tabs__nav--line {
        padding-bottom: 0;
      }
    }
    //新茶列表
    .girls-tab {
      .van-sticky {
        width: 3.12rem;
        top: 0.1rem;
        z-index: 9999;
      }
      .van-tabs__wrap {
        background: #fff;
      }
      .van-tab {
        height: 0.55rem;
        font-size: 0.32rem;
        padding: 0;
        flex: auto;
        box-shadow: 0 1px 2px 0 #ebeaee inset;
      }
      .van-tab.van-tab--active {
        background: #fff;
        border-radius: 0.12rem;
      }
      .van-tabs__line {
        display: none;
      }
      .van-tabs__nav--line {
        padding-bottom: 0;
      }
    }
    //广场导航
    .square-tab {
      .van-tabs__wrap {
        padding-left: 0.37rem;
        background: #fff;
        height: 1rem;
      }
      .van-tab {
        font-size: 0.28rem;
      }
      .van-tab.van-tab--active {
        background: #fff;
        border-radius: 0.1rem;
      }
      .van-tabs__line {
        display: none;
      }
    }
    //up主首页导航,首页更多视频导航
    .uploader-tab {
      .van-tabs__wrap {
        height: 1rem;
        padding-left: 0.37rem;
        padding-right: 0.37rem;
        background: #fff;
      }
      .van-tab {
        flex: none;
        font-size: 0.28rem;
        padding: 0 0.2rem;
        margin-right: 0.2rem;
        .van-tab__text {
          display: flex;
        }
      }
      .van-tab.van-tab--active {
        background: #fff;
        border-radius: 0.2rem;
      }
      .van-tabs__nav--line {
      }
      .van-tabs__line {
        display: none;
      }
    }
    //卡片中心导航
    .cards-center-tab {
      .van-tabs__wrap {
        height: 0.6rem;
        padding-left: 0.37rem;
        padding-right: 0.37rem;
        background: #fff;
      }
      .van-tab {
        padding: 0 0.2rem;
        flex: none;
        font-size: 0.28rem;
        margin: 0 0.1rem;
        box-shadow: 0 0.5px 0.1rem rgb(0 0 0 / 10%) inset;
        border-radius: 0.18rem;
        &:nth-child(1) {
          margin-left: 0;
        }
        .van-tab__text {
          display: flex;
        }
      }
      .van-tab.van-tab--active {
        background: #fff;
      }
      .van-tabs__nav--line {
      }
      .van-tabs__line {
        display: none;
      }
    }
    //首页长列表页
    .home-list-tab2 {
      margin-top: -0.2rem;
      .van-tabs__wrap {
        height: 0.8rem;
        // box-shadow: 0 0.06rem 0.1rem rgb(0 0 0 / 10%);
      }
      .van-tabs__nav--line {
        display: flex;
        justify-content: center;
      }
      .van-tab {
        font-size: 0.28rem;
        padding: 0 0.4rem;
        // border-right: 1px solid #ccc;
        &:nth-child(3) {
          border-right: 0;
        }
        &.van-tab--active {
          border-radius: 0;
          background: transparent;
          box-shadow: none;
          font-weight: 600;
        }
        .van-tab__text {
          color: #333;
        }
      }
      .van-tabs__line {
        background-color: #ff669d;
        height: 0.06rem;
        width: 0.36rem;
        bottom: 0;
      }
    }
    //女优作品导航
    .actor-tab {
      .van-tabs__nav--line {
        border-radius: 0.1rem;
        .van-tab {
          font-size: 0.3rem;
          flex: 1;
        }
        .van-tab--active {
          opacity: 1;
          box-shadow: none;
        }
        .van-tabs__line {
          display: none;
        }
      }
    }
    //签到页面
    .sign-in-tab {
      padding-top: 0.3rem;
      .van-tabs__wrap {
        height: auto;
        background: #fff;
      }
      .van-tabs__nav {
        justify-content: center;
      }
      .van-tab {
        font-size: 0.36rem;
        padding: 0 0.2rem;
        margin-right: 0.3rem;
        box-shadow: unset;
      }
      .van-tab.van-tab--active {
        background: #fff;
      }
      .van-tabs__line {
        display: none;
      }
    }
  }
}
</style>
