<template>
  
  <div class="application">
    <EBackTop :title="'应用中心'" />
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
      :skeleton="skeleton"
    >
      <div class="app-item" v-for="(item,index) in appsList" :key="index">
        <div class="info-box">
          <EDecryptImg :imgCDN="imgCDN" class="avatar" :imgURL="item.avatar" />
        <div class="info">
          <div class="title">
            {{ item.name }}
            <span>推荐</span>
          </div>
          <div class="desc">
            {{ item.desc }}
          </div>
        </div>
        </div>
        <!-- btn -->
        <div class="btn" @click="down(item.download_url)">立即下载</div>
      </div>
    </PullUp>
  </div>
</template>

<script>
import { apps_list } from 'api/home'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'

export default {
  name: 'Application',
  components: {
    PullUp: () => import('@/components/PullUp.vue'),
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      skeleton: false,
      appsList: []
    }
  },
  created() {
    this.refreshData()
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
  },
  methods: {
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.loading = true
      this.finished = false
      this.pageNum = 1
      this.appsList = []
      this.skeleton = true
      this.getApps()
    },
    moreData() {
      this.pageNum += 1
      this.getApps()
    },
    // 获取应用列表
    async getApps() {
      try {
        const res = await apps_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          console.log(res,'res==')
          this.loading = false
          this.refreshing = false
          this.appsList = [...this.appsList, ...res.data.list]
          if (res.data.list.length < this.pageSize || !res.data.list) {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
      }
    },
    // 活动跳转
    down(item) {
      window.open(item)
    },
  }
}
</script>

<style lang="scss" scoped>
.application {
  min-height: 100vh;
  padding: 0 0 0  0rem;
}
.app-item {
  width: 7rem;
margin: auto;
  // box-shadow: $shadow;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  // border-radius: 0.05rem;
  @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
  // margin-top: .3rem;
  padding: 0.2rem 0.25rem;
  .info-box{
      @include flexbox($jc: flex-start, $ai: center, $fd: row, $fw: nowrap);
  }
  .avatar {
    width: 1.1rem;
    border-radius: 0.06rem;
    flex-shrink: 0;
    :deep() {
      .warp {
        border-radius: 0.06rem;
        img {
          border-radius: 0.06rem;
        }
      }
    }
  }
  .info{
    font-size: 0.24rem;
    // width: 3.6rem;
    flex-grow: 1;
    margin:0 0.1rem 0 0.2rem;
    .title{
      font-size: 0.26rem;
      font-weight: 600;
      padding: 0.1rem;
     @include flexbox($jc: flex-start, $ai: flex-end, $fd: row, $fw: nowrap);
      // border-bottom: 0.01rem solid #848494;
      span{
        padding: 0.02rem 0.12rem;
        border: 0.02rem solid #FF669D;
        border-radius: 0.08rem;
        color: #FF669D;
        font-size: 0.22rem;
        margin-left: 0.17rem;
        white-space: nowrap;
      }
    }
    .desc{
      color: #878796;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      white-space: nowrap; //文本不会换行
      max-width: 3.4rem;
      // margin-top: 0.12rem;
    }
  }
  .btn{
    width: 1.6rem;
    height: 0.6rem;
    border-radius: 0.37rem;
    background: $btnBg;
    text-align: center;
    line-height: 0.6rem;
    flex-shrink: 0;
    font-size: 0.26rem;
    color: #fff;
  }
}
</style>
