<template>
    <div class="popup-list">
        <van-popup @click-overlay="fn_close" class="popup-chapter" v-model="popupMsg.show" get-container="body"
            :position="popupMsg.position" round>
            <div class="chapterTop" v-if="popupMsg.dataList.chapterList.length">
                <div class="chapterTopL">
                    <DecryptImg :imgURL="popupMsg.dataList.novelData.coverImg" class="default">
                    </DecryptImg>
                    <div class="defaultTitle">
                        <h2>{{ popupMsg.dataList.novelData.title }}</h2>
                        <p>作者：{{ popupMsg.dataList.novelData.author.length > 0 ? '多作者' :
                            popupMsg.dataList.novelData.author[0]
                            }}</p>
                    </div>
                </div>
                <div class="chapterTopR" @click="goDetail">
                    <van-icon name="arrow" />
                </div>
            </div>
            <div class="continue" v-if="popupMsg.dataList.chapterList.length">
                <div class="continueL">
                    {{ popupMsg.dataList.novelData.isSerial ? '连载中' : '已完结' }}
                    第{{ popupMsg.dataList.novelData.chapterCount }}章
                    {{ popupMsg.dataList.chapterList[(popupMsg.atLastIndex - 1)].title }}
                </div>
                <div class="continueR" @click="fn_sortDer">
                    <img src="@/assets/imgs/novel/toSort_icon.png" alt="">
                    {{ sortDer ? '正' : '倒' }}序
                </div>
            </div>
            <ul>
                <li v-for="(item, index) in popupMsg.muLuList" :key="index"
                    :class="{ 'active': item.id === popupMsg.currentId }" @click="fn_ok(item.chapterNum)">
                    <div class="liL">
                        第{{ item.chapterNum }}章: {{ item.title }}
                    </div>
                    <div class="liR">{{ item.chapterNum > (popupMsg.currentIndex - 1) ? '未' : '已' }}读</div>
                </li>
            </ul>
        </van-popup>
    </div>
</template>

<script>

export default {
    props: {

    },
    components: {
        DecryptImg: () => import('@/components/DecryptImg/index.vue'),
        DmComicCardPayType: () => import('@/components/PayType.vue'),
    },
    data() {
        return {
            popupMsg: {
                show: false,
                type: 'text',
                position: 'left',
                dataList: {
                    chapterList: []
                },
                atLastIndex: 0, //最新章节index
                currentId: undefined, //当前章节ID
                currentIndex: 0, //当前章节Index
                cb: null,
                muLuList: [], // 目录渲染列表
            },


            sortDer: false
        }
    },
    methods: {
        goDetail() {
            this.$router.push({
                path: `/home-novel/decial/` + this.popupMsg.dataList.novelData.id,
            });
        },
        //排序
        fn_sortDer() {
            this.sortDer = !this.sortDer;
            this.popupMsg.muLuList = this.popupMsg.muLuList.reverse();
        },
        //取消、关闭
        fn_close() {
            this.popupMsg.show = false;
            setTimeout(() => {
                this.$emit('closePopup');
            }, 500);
        },
        //确定
        fn_ok(status) {
            this.popupMsg.cb(status);
            this.fn_close()
        },
    },
    computed: {

    },

}
</script>

<style lang="scss" scoped>
.popup-chapter {
    height: 100%;
    width: 6.4rem;
    background: #F6E9C7;
    padding: 0 .3rem;

    :deep() {
        border-radius: 0;
    }

    .chapterTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .5rem;

        .chapterTopL {
            display: flex;
            align-items: center;

            .default {
                margin-right: .28rem;
                min-width: .88rem;
                max-width: .88rem;
                border-radius: .08rem;
                overflow: hidden;
            }

            .defaultTitle {
                h2 {
                    font-size: .36rem;
                    color: #473600;
                    margin: 0;
                }

                p {
                    margin: 0;
                    color: #766A43;
                    font-size: .24rem;
                }
            }
        }

        .chapterTopR {
            font-size: .34rem;
        }
    }

    .continue {
        color: #766A43;
        font-size: .24rem;
        line-height: .28rem;
        margin-top: .2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .continueR {
            color: #473600;
            display: flex;
            align-items: center;

            img {
                height: .28rem;
                width: .28rem;
                margin-right: .08rem;
            }
        }
    }

    ul {
        margin-top: .3rem;

        li {
            font-size: .28rem;
            line-height: .36rem;
            margin-bottom: .18rem;
            color: #473600;
            opacity: 0.35;
            display: flex;
            align-items: center;
            justify-content: space-between;
            white-space: nowrap;

            &.active {
                opacity: 1;
            }

            .liL {
                width: 4.5rem;
                overflow: hidden;
                text-overflow: ellipsis;
                whit-space: nowrap;
            }

        }
    }
}
</style>
