<template>
  <!-- 单个漫画 -->
  <li @click="clickImg(item)" :class="{ comicsVideoLi: showType == 1 || nowTabItem.showType == 8 }">
    <!-- <template v-if="showBg">
      <div class="md-img-bg1"></div>
      <div class="md-img-bg2"></div>
    </template> -->
    <DecryptImg :needPadding="false" class="md-img" :imgURL="item.coverImg">
      <DmComicCardPayType :item="item"></DmComicCardPayType>

      <img v-if="item.isAudio" src="@/assets/imgs/novel/is_audio.png" alt="" class="novel-type-img" />
      <img v-else src="@/assets/imgs/novel/not_audio.png" alt="" class="novel-type-img" />
    </DecryptImg>
    <!--可以选中		-->
    <template v-if="canChecked">
      <div class="md-img-bg3" @click.stop="toChoose">
        <div class="dot">
          <div :class="{ checked: checked }"></div>
        </div>
      </div>
    </template>
    <div class="decial-wrap">
      <div class="decial" :class="{ showDesc }">
        <div class="title" :style="{ color: onColor }">
          <img v-if="item.isAudio" src="@/assets/imgs/novel/not_book.png" alt="" class="novel-text-img" />
          <img v-else src="@/assets/imgs/novel/is_book.png" alt="" class="novel-text-img" />
          {{ item.title }}
        </div>
        <div v-if="showDesc" class="desc">{{ item.desc }}</div>
      </div>
      <slot name="status" :watchNum="item.watchNum" :newChapter="item.newChapter">
        <div class="serialStatus">
          <div v-if="!item.isSerial" class="finished">
            已完结·共{{ item.chapterCount }}话
          </div>
          <div v-else class="loading">连载中·更新{{ item.newChapter }}话</div>
        </div>
      </slot>
      <div class="likes"></div>
      <div v-if="showContinued" class="continued">未观看</div>
    </div>
    <slot></slot>
  </li>
</template>
<script>
export default {
  components: {
    DmComicCardPayType: () => import('@/components/PayType.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
  },

  data() {
    return {}
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    canChecked: {
      type: Boolean,
      default: false,
    },
    showContinued: {
      type: Boolean,
      default: false,
    },
    showDesc: {
      type: Boolean,
      default: true,
    },
    showBg: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    nowTabItem: {
      type: Object,
      default() {
        return {}
      },
    },
    showType: {
      type: Number,
      default() {
        return 0 // 1:有声漫画
      },
    },
    onColor: {
      type: String,
      default() {
        return '#6a6a6a'
      },
    },
  },
  computed: {},
  methods: {
    clickImg(item) {
      this.$router.push(`/home-novel/decial/${item.id}`)
    },
    toChoose() {
      this.$emit('update:checked', !this.checked)
    },
  },
  mounted() { },
}
</script>

<style lang="scss" scoped>
//<!-- 一行3个 -->
li {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 2.1rem;
  margin-bottom: 0.4rem;
  z-index: 2;

  .md-img-bg1 {
    display: inline-block;
    background: red;
    opacity: 0.15;
    width: 90%;
    height: 0.4rem;
    top: -0.1rem;
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .md-img-bg2 {
    content: '';
    display: inline-block;
    background: red;
    opacity: 0.1;
    width: 80%;
    height: 0.4rem;
    top: -0.2rem;
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .md-img-bg3 {
    z-index: 99;
    content: '';
    display: inline-block;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 2.86rem;
    top: 0;
    border-radius: 0.1rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .dot {
      position: absolute;
      top: 0.24rem;
      left: 0.24rem;
      background: #ffffff;
      border: 0.01rem solid #FF669D;
      border-radius: 0.32rem;
      width: 0.32rem;
      height: 0.32rem;

      .checked {
        position: absolute;
        top: -0.03rem;
        //left: 0rem;
        width: 0.41rem;
        height: 0.29rem;
        background: url('../../../assets/imgs/homec/checked.png') center center/100% 100% no-repeat;
      }
    }
  }

  .comicsVideo {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    height: 0.42rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.18rem;
    font-size: 0.22rem;
    color: #fff;

    img {
      height: 0.25rem;
    }

    div {
      display: flex;
      align-content: center;

      img {
        margin-right: 0.08rem;
      }
    }
  }

  .md-img {
    position: relative;
    height: 2.86rem;

    :deep() {
      img {
        border-radius: 0.05rem;
      }
    }

    .novel-type-img {
      width: 0.64rem;
      height: 0.64rem;
      position: absolute;
      right: 0.08rem;
      bottom: 0.08rem;
    }

    :deep() {
      img {
        border-radius: 0.05rem;
      }
    }
  }

  .decial-wrap {
    width: 100%;
    border-radius: 0.12rem;

    .decial {
      display: flex;
      flex-direction: column;
      height: 0.5rem;



      &.showDesc {
        height: 0.8rem;
      }

      .title {
        position: relative;
        padding-top: 0.12rem;
        font-size: 0.28rem;
        color: #6a6a6a;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: normal;

        .novel-text-img {
          position: relative;
          top: -0.05rem;
          width: 0.4rem;
          height: 0.28rem;
        }
      }

      .desc {
        font-size: 0.18rem;
        color: #a0a0a0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: normal;
      }
    }

    .serialStatus {
      display: flex;
      margin-top: 0.1rem;

      div {
        border-radius: 0.04rem;
        font-size: 0.2rem;
        padding: 0.02rem 0.1rem;
      }

      .loading {
        background: rgba(255, 139, 0, 0.1);
        color: #FF669D;
      }

      .finished {
        background: rgba(255, 119, 119, 0.1);
        color: #ff7777;
      }
    }

    .continued {
      margin-top: 0.24rem;
      padding: 0.04rem 0.08rem;
      background: #FF669D;
      border-radius: 6px;
      color: #ffffff;
      display: inline-block;
    }
  }
}

.comicsVideoLi {
  margin-top: 0;
  margin-bottom: 0.2rem;

  .md-img-bg1,
  .md-img-bg2 {
    display: none;
  }

  .decial-wrap {
    .decial {
      height: 0.5rem;

      div {
        &:last-child {
          display: none;
        }
      }
    }
  }

  .serialStatus {
    display: none !important;
  }
}
</style>
