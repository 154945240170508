<template>
  <ul class="bot_list">
    <li v-for="item in highQualityImg" :key="item.id" @click="clickImg(item)">
      <div class="row_center">
        <div class="imgbox">
          <DecryptImg :imgCDN="cdn" :imgURL="item.avatar">
          </DecryptImg>
        </div>
        <div class="descbox">
          <div >{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
      <div class="btn">立即下载</div>
    </li>
  </ul>
</template>
<script>
import { appPage, appsClick } from 'api/home'
export default {
  props: {
    highQualityImg: {
      type: Array,
      default: [],
    },
    type: {
      type: Number,
      default: 0
    }
  },
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  data() {
    return {

    }
  },
  computed: {
    // 图片cdn
    cdn() {
      return this.$store.getters['config/cdn']
    }
  },

  mounted() {

  },
  methods: {
    async clickImg(item) {
      appsClick({
        id: item.id
      }).then((res) => {
        if (res.code === 200) {
          if (!item.download_url) return
        } else {
          return this.$toast('统计出错！')
        }
      })
      window.open(item.download_url, '_self')
    },
  }
}
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.row_center {
  display: flex;
  align-items: center;
}
.bot_list {
  // padding: 0.25rem 0.3rem 2.25rem 0.3rem;//
  @include flexbox($fw: wrap);
  margin-top: .1rem;
  padding: 0 .1rem;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: .3rem;
    font-size: .22rem;
    .btn {
      background-color: #ff95bb;
      color: #fff;
      width: 1.5rem;
      height: .5rem;
      text-align: center;
      line-height: .5rem;
      border-radius: .2rem;
    }
  }
}
.imgbox {width: .9rem;height: .9rem;border-radius: .1rem;overflow: hidden;}
.descbox {margin-left: .1rem;}
.desc {
  color: #a1a1a1;margin-top: .05rem;width: 4rem;height: .3rem;
  overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
  margin-top: .1rem;
}
</style>
