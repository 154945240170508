<template>
  <div class="my-generate">
    <EBackTop :background="'transparent'" class="mine-head" color="#fff" title="我的AI生成">
      <template #right>
        <div class="choose-box">
          <div @click="isShowStatus = !isShowStatus">
            <span> {{ selectItem.text }} </span>
            <img src="@/assets/imgs/trade/select_icon.png" alt="" />
          </div>
          <ul v-if="isShowStatus">
            <li v-for="item in selectList" :key="item.id" @click="handleClick(item)">
              {{ item.text }}
            </li>
          </ul>
        </div>
      </template>
    </EBackTop>
    <div class="label-pack">
      <van-tabs v-model="type" @change="topTabChange">
        <van-tab v-for="item in category" :key="item.id">
          <template #title>
            <span>{{ item.name1 }}</span>
            <span class="spe-color" :class="{ 'spe-yellow': item.id == 2 }">{{ item.name2 }}</span>
          </template>
          <template>
            <ERefreshLoad
              @refreshData="refreshData"
              @moreData="moreData"
              :finished="finished"
              :loading="loading"
              :refreshing="refreshing"
            >
              <GenerateItem @fn_play_video="fn_play_video" v-for="item in mediaList" :key="item.id" :item="item" />
            </ERefreshLoad>
          </template>
        </van-tab>
      </van-tabs>
    </div>
    <ComPlayVideo ref="ComPlayVideo" />
    <AiPlayVideo ref="AiPlayVideo" />
  </div>
</template>

<script>
import { aiorder_facechange_list, aiorder_strip_list } from 'api/ai' //api列表

export default {
  name: 'myGenerate',
  components: {
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    JavFourCard: () => import('@/components/stencil/JavFourCard.vue'), //长视频
    GenerateItem: () => import('./components/generateItem.vue'),
    ComPlayVideo: () => import('@/components/Community/video.vue'),
    AiPlayVideo: () => import('@/components/aiLab/video.vue')
  },
  data() {
    return {
      type: 0,
      category: [
        { name1: 'AI脱衣', id: 0 },
        {
          name1: 'AI换脸',
          name2: '(图片)',
          id: 1
        },
        {
          name1: 'AI换脸',
          name2: '(视频)',
          id: 2
        }
      ],
      finished: true,
      loading: false,
      refreshing: false,
      mediaList: [],
      selectList: [
        {
          text: '全部',
          id: 0
        },
        {
          text: '排队中',
          id: 1
        },
        {
          text: '生成中',
          id: 2
        },
        {
          text: '已完成',
          id: 3
        },
        {
          text: '生成失败',
          id: 4
        }
      ],
      isShowStatus: false,
      selectItem: {
        text: '全部',
        id: 0
      },
      pageNum: 1,
      pageSize: 10,
      pageParams: {
        aoContentType: 0,
        status: 0
      }
    }
  },
  methods: {
    fn_play_video(item) {
      if (item.type) {
        this.$refs.AiPlayVideo.fn_playVideo(item.videoUrl, item.videoCover)
      } else {
        this.$refs.ComPlayVideo.fn_playVideo(item.videoUrl, item.videoCover)
      }
    },
    async getList(pull) {
      const res = this.pageParams.aoContentType
        ? await aiorder_facechange_list({ ...this.pageParams, pageNum: this.pageNum, pageSize: this.pageSize })
        : await aiorder_strip_list({ ...this.pageParams, pageNum: this.pageNum, pageSize: this.pageSize })
      if (res.code == 200) {
        this.loading = false
        this.refreshing = false
        if (res.data.list && res.data.list.length) {
          this.mediaList = [...this.mediaList, ...res.data.list]
          if (res.data.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      } else {
        this.loading = false
        this.refreshing = false
        this.finished = true
        return this.$toast(res.tip)
      }
    },
    topTabChange(id) {
      this.pageParams.aoContentType = id
      this.pageNum = 1
      this.mediaList = []
      // this.selectItem = {
      //   text: '全部',
      //   id: 0
      // }
      // this.pageParams.status = 0
      this.getList()
    },
    handleClick(item) {
      this.selectItem = item
      this.pageParams.status = item.id
      this.isShowStatus = false
      this.mediaList = []
      this.pageNum = 1
      this.getList()
    },
    change(type) {
      this.type = type
      this.refreshData()
    },
    refreshData(refreshing) {
      this.refreshing = refreshing
      this.finished = false
      this.loading = false
      this.mediaList = []
      this.pageNum = 1
      this.getList()
    },
    moreData(loading) {
      this.loading = loading
      this.pageNum += 1
    },
    compComponent(showType) {
      return 'JavFourCard'
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.my-generate {
  min-height: 100vh;
  background-color: #333;
  :deep() .generate-edit {
    padding: 0.04rem 0.16rem;
    border-radius: 0.24rem;
    border: 1px solid #00f6f8;
    background: rgba(34, 74, 134, 0.2);
  }
  .E-tab {
  }
  :deep() {
    .van-tabs__content {
      padding-top: 0.5rem;
      width: calc(100vw - 0.72rem);
    }
    .van-tabs__nav {
      background: none;
    }
    .van-tabs__nav--line {
      padding-right: 0.15rem;
    }
    .van-tabs__line {
      background: $btnBg;
    }
    .van-tab {
      // justify-content: flex-start;
      flex: auto;
      span {
        font-size: 0.28rem !important;
        color: #fff;
        font-family: PingFang SC;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .spe-color {
          color: $txtActive;
        }
        .spe-yellow {
          color: #f9c006;
        }
      }
    }
  }
  .label-pack {
    padding: 0 calc(0.36rem + 1.18rem) 0 0.36rem;
    margin-bottom: 0.65rem;
    position: relative;
    > div {
      &:nth-child(2) {
        position: absolute;
        right: 0.3rem;
        top: 0.2rem;
        border-radius: 0.06rem;
        border: 1px solid #535353;
        background: #292929;
        height: 0.52rem;
        margin-left: 0.1rem;
        img {
          width: 0.2rem;
          margin-left: 0.06rem;
        }
      }
    }
  }
  :deep() .choose-box {
    color: #fff;
    font-family: PingFang HK;
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    > div {
      height: 100%;
      padding: 0.08rem 0.12rem;
      min-width: 1.46rem;
      position: relative;
      border-radius: 0.06rem;
      border: 1px solid #535353;
      background: #292929;
      @include flex-align-center;
      justify-content: space-between;
      > img {
        position: absolute;
        right: 0.12rem;
        top: 50%;
        transform: translate(0, -50%);
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.06rem;
      }
    }
    > ul {
      width: 100%;
      margin-top: 0.06rem;
      background: #292929;
      border-radius: 0.08rem;
      border: 1px solid #535353;
      position: absolute;
      left: 0;
      bottom: -4.1rem;
      li {
        @include flex-center;
        padding: 0.23rem 0;
        border-bottom: 1px dashed #535353;
      }
    }
  }
}
</style>
