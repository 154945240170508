<template>
  <div class="long-six-item">
    <EDecryptImg :imgCDN="imgCDN" :imgURL="item.coverImg" class="item-bg">
      <!-- 视频分类标签 -->
      <div class="video-type">
        <div class="video-type-vip" v-if="item.payType === 1">VIP</div>
        <div class="video-type-gold" v-if="item.payType === 2">
          <img src="@/assets/imgs/mine/gold.png" alt="" />
          {{ item.price | changeGold }}
        </div>
        <div class="video-type-free" v-if="item.payType === 0">免费</div>
      </div>
      <!-- 观看数 -->
      <div class="num">
        <div class="left-num"> <van-icon name="eye" size="0.3rem" color="#fff" />
          {{ item.watchTimes | numberFilter }}
        </div>
        <div> {{ item.playTime | secondToDate }}
        </div>
      </div>
      <slot/>
    </EDecryptImg>
    <!-- 影片描述 -->
    <div class="item-desc">
      <div class="title">{{ item.title }}</div>
      <div class="tagsPack">
        <div class="tags" v-if="item?.tagInfo?.[0]">#{{ item.tagInfo[0].name }}</div>
        <div class="tags" v-else-if="item?.tags?.[0]">#{{ item.tags[0] }}</div>
        <div v-else></div>
        <img src="@/assets/imgs/More_icon.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShortVideo',
  props: ['item'],
  components: {},
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.long-six-item {
  width: 100%;
  margin-bottom: 0.1rem;
  box-shadow: 0 0.01rem 0.04rem 0.03rem rgb(28 32 32 / 8%);
  border-radius: 0.08rem;

  .item-bg {
    position: relative;
    border-radius: 0.08rem;
    overflow: hidden;

    :deep() {
      .warpNoPadding {
        border-radius: 0.12rem;
      }
    }

    .video-type {
      position: absolute;
      top: 0.15rem;
      right: 0.18rem;
      height: 0.35rem;
      line-height: 0.35rem;
      color: #fff;
      font-size: 0.26rem;
      font-weight: 600;

      div {
        height: 100%;
        height: 0.35rem;
        line-height: 0.35rem;
        text-align: center;
        border-radius: 0.04rem;
      }

      &-vip {
        width: 0.64rem;
        background: #ff669d;
      }

      &-gold {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0.68rem;
        background: #3E287D;

        img {
          width: 0.22rem;
          height: 0.22rem;
          margin-right: 0.04rem;
        }
      }

      &-free {
        width: 0.64rem;
        background: #2FE495;
      }
    }
    .num{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    @include flexbox();
    padding: 0 0.1rem;
    color: #fff;
    font-size: 0.2rem;
    .left-num{
      @include flexbox($jc:center);
      :deep(){
        .van-icon{
          margin-right: 0.1rem;
        }
      }
    }
    }
  }


  .item-desc {
    font-size: 0.24rem;
    padding: 0.14rem;

    .title {
      @include textoverflow();
    }

    .tagsPack {
      height: 0.32rem;
      @include flexbox();
      margin-top: 0.1rem;

      .tags {
        background: #f4f4f4;
        color: #999;
        font-size: 0.2rem;
        border-radius: 0.08rem;
        height: 0.32rem;
        line-height: 0.32rem;
        padding: 0 0.1rem;
      }

      img {
        height: .22rem;
      }
    }
  }
}
</style>
