<template>
    <div class="rem-daily">
      <div class="bgImg">
        <img :src="bgImg"/>
      </div>
      <div class="wrap">
        <div class="main">
          <div class="swiper-container"
            :class="'swiperCard'+itemTopic.id"
            @touchmove.stop>
            <div class="swiper-wrapper">
              <div
                ref="swiper-slide-scale"
                class="swiper-slide"
                v-for="item in swiperList"
                :key="item.id"
              >
                <div class="item"  @click="clickImg(item)">
                  <DmComicCardPayType :item="item"></DmComicCardPayType>
                  <div class="decial-wrap">
                    <div>
                      <img src="/newAppMh/home-comic/fire.png" alt="">
                      {{item.watchTimes | numberFilter}}
                    </div>
                    <div>{{item.title}}</div>
                    <div>{{item.desc}}</div>
                    <div>作者：{{item.author?item.author:'暂无'}}</div>
                  </div>
                  <DecryptImg class="md-img" :imgRadius="'0.12rem'" :lazy="false"  :imgURL="item.coverImg" />
                  <!-- <img
                    class="bg-img"
                    :src="item.coverImg"
                  />             -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { handleVerImg} from '@/utils/utils_tools'
  export default {
    head() {
      return {
      };
    },
    components: {
      DecryptImg: () => import('@/components/DecryptImg/index.vue'),
      DmComicCardPayType: () => import('@/components/PayType.vue'),
    },
    data() {
      return {
        bgImg:"",
        swiperList: []
      }
    },
    props: {
      itemTopic: {
        type: Object,
        default() {
          return {}
        },
      },
      nowTabItem: {
        type: Object,
        default() {
          return {}
        },
      },
    },
    computed: {},
    methods: {
      //初始化轮播
      initSwiper() {
        let that = this
        new Swiper({
          //防止一个页面多个轮播
          el: '.swiperCard'+this.itemTopic.id,
          effect: 'coverflow',
          slidesPerView: 1.5,
          centeredSlides: true,
          cardsEffect: {
            slideShadows: true,
          },
          // delay: 5000,
          // autoplay:true,
          on: {
            slideChangeTransitionStart: function () {
              setTimeout(() => {
                that.$refs["swiper-slide-scale"].forEach((item)=>{
                  if(item.className=="swiper-slide swiper-slide-visible swiper-slide-active"){
                    that.bgImg=item.children[0].children[2].children[0].src;
                  }
                });
              }, 300);
            },
          },
        })
      },

      //批量获取图片
      async getCardList() {
        if(!this.itemTopic.comicsList) return;
        this.swiperList=JSON.parse(JSON.stringify(this.itemTopic.comicsList));
        this.initSwiper();

        const res = await handleVerImg(this.imgCDN + this.swiperList[0].coverImg)
        const cover = window.URL
          ? window.URL.createObjectURL(new Blob([res]))
          : window.webkitURL.createObjectURL(new Blob([res]));
        this.bgImg= cover;
      },
      clickImg(item) {
        if(this.nowTabItem.showType == 8){
          this.$router.push(`/play/comicsVideo/${item.id}`)
        }else{
          this.$router.push(`/home-comic/decial/${item.id}`)
        }
      },
    },
    mounted() {
      this.getCardList();
    },
    activated() {
      this.initSwiper();
    },
    computed: {
      imgCDN({ $store }) {
        return $store.getters['config/cdn'].imgCdn
      },
    },
  }
  </script>

  <style lang="scss" scoped>
  //推荐专区，每日推荐
  .rem-daily {
    position: relative;
    height: 9.52rem;
    // margin-top: 0.44rem;
    &::before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: rgba($color: #000000, $alpha: 0.7);
      -webkit-backdrop-filter: blur(0.1rem);
      backdrop-filter: blur(0.1rem);
    }
    .bgImg{
      width: 100%;
      height: 100%;
      font-size: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .wrap{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      .main{
        padding-top: 0.77rem;
        .swiper-container {
          display: flex;
          align-items: center;
          justify-content: center;
          .swiper-slide {
            border-radius: 0.24rem;
            height: 7.97rem;
            .item {
              position: relative;
              font-size: 0.24rem;
              color: #fff;
              height: 100%;
              .decial-wrap {
                width: 100%;
                position: absolute;
                border-radius: 0.44rem;
                background: rgba($color: #000000, $alpha:0.3);
                bottom: 0;
                padding: 0.2rem 0.37rem;
                div{
                  &:nth-child(1){
                    @include flex-align-center;
                    img{
                      margin-right: 0.05rem;
                      width: 0.28rem;
                    }
                  }
                  &:nth-child(2){
                    font-size: 0.28rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    white-space: normal;
                  }
                  &:nth-child(3){
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    white-space: normal;
                    color: #ccc;
                  }
                  &:nth-child(4){
                    text-align: right;
                  }
                }
                // white-space: nowrap;
                // position: absolute;
                // bottom: 0.32rem;
                // left: 50%;
                // transform: translateX(-50%);
                // z-index: 9;
                // font-size:0.26rem ;
              }
              .bg-img {
                width: 100%;
                height: 100%;
                border-radius: 0.24rem;
                object-fit: cover;
              }
            }
          }
        }
      }

    }

  }
  </style>
