<template>
  <!-- 更多专题更多列表,一行3个 -->
  <div class="home-comic-decial">
    <EBackTop
      class="back-top"
      :title="' '"
      :fixed="true"
      :isDark="true"
      :background="'transparent'"
      :openFallback="true"
      :color="'#fff'"
    >
      <slot slot="right">
        <div class="top_collect">
          <div @click="sharePopup"><img src="@/assets/imgs/homec/shareIcon.png" alt="" />分享</div>
          <div style="opacity: 0.7" v-if="comicsData.isCollect" @click="setFavour">已加入</div>
          <div v-else @click="setFavour">加入书架</div>
        </div>
      </slot>
    </EBackTop>
    <template v-if="comicsData.id">
      <div class="home-comic-top">
        <DecryptImg class="md-img" :imgRadius="'0.12rem'" :imgURL="comicsData.coverImg" />

        <div class="right-wrap">
          <div class="wrap">
            <div class="wrap-pack">
              <div class="btn-wrap">
                <!-- vip且我不是vip -->
                <!-- @click="fn_buy" -->
                <div class="vip vip_watch" v-if="shoudBuy === 1">
                  <!-- <div>VIP免费观看</div> -->
                </div>
                <!-- 有价钱未购买 -->
                <div class="buy" v-else-if="shoudBuy === 2" @click="fn_buy">
                  <div class="coin">
                    <img src="@/assets/imgs/index/gold.png" alt="" />
                    <span> {{ comicsData.price | changeGold }} 购买观看 </span>
                  </div>
                </div>
                <!-- 其他情况 -->
                <div v-else @click="fn_buy">
                  <div class="vip vip_watch" v-if="shoudBuy === 7"></div>
                  <div class="vip free" v-else-if="shoudBuy === 8">已购观看</div>
                  <div class="vip free" v-else-if="shoudBuy === 9">免费观看</div>
                </div>
              </div>
              <div class="title">{{ comicsData.title }}</div>
              <!-- <div class="timesWatched">
                <img src="/newAppMh/home-comic/fire.png" alt="">
                观看次数：{{comicsData.watchTimes | numberFilter}}
              </div> -->
              <div class="author">
                <div class="t">作者：</div>
                <li
                  v-for="(item, index) in comicsData.author"
                  @click="
                    $router.push({
                      path: `/home-comic/author`,
                      query: {
                        name: item
                      }
                    })
                  "
                  :key="index"
                >
                  {{ item }}
                </li>
              </div>
              <div class="author tag">
                <div class="t">标签：</div>
                <li
                  @click="
                    $router.push({
                      path: `/home-comic/tag-type-one`,
                      query: {
                        name: item.name,
                        id: item.id
                      }
                    })
                  "
                  v-for="(item, index) in comicsData.tags"
                  :key="index"
                >
                  {{ item.name }}
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main class="home-comic-bottom">
        <ul class="nav-wrap">
          <!-- <li :class="{'active':tab=='漫画详情'}" @click="fn_tab('漫画详情')">漫画详情</li>
          <li class="comments" :class="{'active':tab=='评论'}"  @click="fn_tab('评论')">评论({{comicsData.comments}})</li> -->
          <li class="loading-l" v-if="comicsData.isSerial">连载中·更新{{ comicsData.chapterNum }}话</li>
          <li class="loading" v-else>已完结·共{{ comicsData.chapterNum }}话</li>
          <li class="watchTimes">
            <img src="@/assets/imgs/homec/fire8.png" alt="" />
            观看次数：{{ comicsData.watchTimes | numberFilter }}
          </li>
        </ul>
        <div class="desc" v-if="comicsData.desc">
          {{ comicsData.desc }}
        </div>
        <div class="anthologyBotm">
          <div class="anthologyL" @click="$refs['ComicCatalogue'].initDatas()">
            <img src="@/assets/imgs/homec/anthology_icon.png" alt="" />
            目录
            <!-- <i></i> -->
          </div>
          <ul class="anthologyC" ref="anthologyC">
            <CatalogueItem
              v-for="(item, index) in chapterList"
              :item="item"
              :price="comicsData.price"
              :bgImages="comicsData.coverImg"
              :title="comicsData.title"
              :key="index"
            ></CatalogueItem>
          </ul>
          <div class="anthologyR" @click="startReading">
            {{ comicsHisIndex == 0 ? '开始阅读' : `续看${comicsHisIndex}集` }}
          </div>
        </div>
        <div class="tab-nav">
          <ul>
            <li class="left-wrap">
              <div class="top" :class="{ active: tabActive === 1 }" @click="tabActive = 1">
                <p>推荐</p>
                <img src="@/assets/imgs/homec/active_icon2.png" alt="" v-if="tabActive === 1" />
              </div>
              <div class="top" :class="{ active: tabActive === 2 }" @click="tabActive = 2">
                <p>互动点评</p>
                <img src="@/assets/imgs/homec/active_icon2.png" alt="" v-if="tabActive === 2" />
              </div>
            </li>
            <div class="score">
              <em>{{ comicsData.voteInfo.average | scoreConversion }}</em>
              <van-rate v-model="value" allow-half readonly :size="11" color="#FFBA57" void-color="#999" />
              <div
                class="score_tc"
                @click="
                  $router.push({
                    path: `/home-comic/evaluation/${$route.params.id}`,
                    query: {
                      coverImg: comicsData.coverImg,
                      title: comicsData.title
                    }
                  })
                "
              >
                去打分
                <van-icon name="arrow" />
              </div>
            </div>
          </ul>
        </div>
        <template>
          <van-pull-refresh v-show="tabActive == 1" v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="暂时没有更多数据！"
              @load="onLoad"
              :immediate-check="false"
              error-text="请求失败，点击重新加载"
            >
              <div class="recommend">
                <DmComicCard v-for="(item, index) in comicsList" :item="item" :key="index"></DmComicCard>
                <i></i>
              </div>
            </van-list>
          </van-pull-refresh>
        </template>
        <template>
          <Comment
            v-show="tabActive == 2"
            :isComics="true"
            class="comment-box"
            :publisher="comicsData"
            :objectId="+$route.params.id"
            :objectype="3"
            :disabledPull="true"
            @addComments="addComments"
          />
        </template>
      </main>
      <ComicCatalogue
        ref="ComicCatalogue"
        :price="comicsData.price"
        :shoudBuy="shoudBuy"
        :catalogueObj="catalogueObj"
        :bgImages="comicsData.coverImg"
        :title="comicsData.title"
      ></ComicCatalogue>
      <DmPopup ref="popupMsg"></DmPopup>
    </template>
    <SharePopup :setMyCode="setMyCode" :sharePath="$router.currentRoute.path" @closeGuidance="closeGuidance" />
  </div>
</template>
<script>
import { picViewRightTo } from '@/utils/utils_tools'
import { comicsDetail, collectAdd } from 'api/comics'
export default {
  name: 'comicsDecial',
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue'),
    DmComicCard: () => import('@/components/HomeComic/oneCard'),
    Comment: () => import('@/components/Comment/index.vue'),
    ComicCatalogue: () => import('@/components/HomeComic/comic/catalogue'),
    CatalogueItem: () => import('@/components/HomeComic/comic/catalogue/item'),
    SharePopup: () => import('@/components/Popup/sharePopup/index.vue')
  },
  watch: {
    $route: {
      async handler(n, o) {
        if (!!o) {
          if (n.path != o.path) {
            this.comicsDetail()
          }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      tabActive: 1,
      catalogueObj: {
        list: [],
        show: false
      },
      tab: '漫画详情',
      //漫画详情
      comicsData: {},
      chapterList: [],
      //收藏
      isLike: false,
      comicsList: [],
      //加载刷新
      pageNum: 1,
      pageSize: 9,
      refreshing: false,
      loading: false,
      finished: false,
      value: 2.5,
      setMyCode: false,
      comicsHisIndex: 0
    }
  },
  methods: {
    closeGuidance() {
      // 关闭弹窗
      this.setMyCode = false
    },
    sharePopup() {
      //分享弹窗
      this.setMyCode = true
    },
    //开始阅读
    startReading() {
      const current = this.comicsHisIndex > 0 ? this.comicsHisIndex - 1 : 0
      this.$refs.anthologyC.children[current].children[0].click()
    },
    //传递评论
    addComments() {
      this.comicsData.comments++
    },
    // 设置历史
    setHistory() {
      let comicsData = JSON.parse(JSON.stringify(this.comicsData))
      this.$store.commit('setComicsHisList', { type: 'add', item: comicsData })
    },
    //看漫画章节
    toWatch() {
      if (!this.chapterList.length) {
        return this.$toast('当前漫画无章节！！！')
      }
      this.$router.push({
        path: `/home-comic/pic-view/` + this.chapterList[0].id,
        query: {
          parentId: this.$route.params.id,
          price: this.comicsData.price,
          bgImages: this.comicsData.coverImg,
          title: this.comicsData.title
        }
      })
    },
    //本地收藏
    async setFavour() {
      const res = await collectAdd({
        collectType: 8,
        flag: !this.comicsData.isCollect,
        object_id: this.comicsData.id,
        type: 1
      })
      if (res.code === 200) {
        if (!this.comicsData.isCollect) {
          this.comicsData.isCollect = true
          return this.$toast('收藏成功')
        } else {
          this.comicsData.isCollect = false
          return this.$toast('取消收藏')
        }
      } else {
        return this.$toast('操作失败')
      }
    },
    //获取漫画详情
    async comicsDetail(type) {
      const res = await comicsDetail({
        id: +this.$route.params.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
      if (res.code === 200 && res.data) {
        this.chapterList = res.data.chapterList
        this.catalogueObj.list = res.data.chapterList
        this.comicsData = res.data.comicsData
        this.value = this.comicsData.voteInfo.average / 200 //获取评分
        this.chapterList.forEach((item, index) => {
          item.indexName = index + 1
        })
        this.fn_index(this.$route.params.id)
        this.refreshing = false
        this.loading = false
        if (
          !res.data ||
          !res.data.comicsData ||
          !res.data.comicsData.lookComics ||
          res.data.comicsData.lookComics.length < this.pageSize
        ) {
          this.finished = true
        }
        if (type == 'pull') this.comicsList = []
        if (res.data.comicsData && res.data.comicsData.lookComics)
          this.comicsList = [...this.comicsList, ...res.data.comicsData.lookComics]
      } else {
        this.finished = true
        this.refreshing = false
        this.loading = false
        return this.$toast(res.tip)
      }
    },
    fn_index(parentId) {
      const datasObj = this.$store.state.history.comicsHisMap[parentId]
      if (datasObj) {
        this.chapterList.forEach(e => {
          if (datasObj[e.id]) {
            this.comicsHisIndex = e.indexName
          }
        })
      } else {
        this.comicsHisIndex = 0
      }
    },
    // findIndex(parentId) {
    //   let status = false
    //   let newBookshelfList = JSON.parse(JSON.stringify(this.bookshelfList))
    //   let datasObj = JSON.parse(JSON.stringify(this.$store.state.history.comicChapterObj))
    //   newBookshelfList.forEach(element1 => {
    //     element1.list.forEach(element2 => {
    //       if (element2.id == parentId) {
    //         status = true
    //         //大于才更新
    //         // if (!element2.hasViewNum) {
    //         //   element2.hasViewNum = datasObj.Num
    //         // } else {
    //         //   if (datasObj.Num > element2.hasViewNum) element2.hasViewNum = Num
    //         // }
    //         // element2.hasViewCount = Count
    //         // this.$store.commit('setBookshelf', newBookshelfList)
    //       }
    //     })
    //   })
    // },
    //切换
    fn_tab(name) {
      this.tab = name
    },
    //购买
    async fn_buy() {
      if (!this.chapterList.length) {
        return this.$toast('当前漫画无章节！！！')
      }
      let picsId = this.chapterList.find(e => !e.isFree)
        ? this.chapterList.find(e => !e.isFree)['id']
        : this.chapterList[0].id
      picViewRightTo(
        this,
        picsId,
        this.comicsData.price,
        null,
        null,
        null,
        null,
        null,
        this.comicsData.coverImg,
        this.comicsData.title
      )
    },
    //上拉加载更多
    async onLoad() {
      this.pageNum += 1
      this.comicsDetail()
    },
    // 刷新
    onRefresh() {
      this.pageNum = 1
      this.finished = false
      this.loading = true
      this.comicsDetail('pull')
    }
  },
  mounted() {
    this.comicsDetail()
  },
  computed: {
    isVipMember({ $store }) {
      return $store.getters['user/isVipMember']
    },
    bookshelfList({ $store }) {
      return $store.state.history.bookshelfList
    },
    //判断是否能看
    shoudBuy() {
      if (this.comicsData.isVip && !this.isVipMember) {
        return 1
      } else if (this.comicsData.price && !this.comicsData.isBuy) {
        return 2
      } else {
        if (this.comicsData.isVip) {
          return 7 //VIP观看
        } else if (this.comicsData.isBuy) {
          return 8 //已购观看
        } else {
          return 9 //免费
        }
      }
    }
  },
  beforeDestroy() {
    this.setHistory()
  }
}
</script>

<style lang="scss" scoped>
:deep() {
  .submit-box {
    bottom: 0.9rem !important;
  }
  .back-top {
    position: fixed;
    z-index: 999;
  }
}
.home-comic-decial {
  min-height: 100vh;
  padding-bottom: 0.1rem;
  .home-comic-top {
    display: flex;
    height: 7rem;
    background: linear-gradient(180.24deg, rgba(0, 0, 0, 0.6) 6.94%, rgba(25, 24, 26, 0) 22.79%);

    .md-img {
      position: absolute;
      top: 0;
      z-index: -1;
      height: auto;
      max-width: 7.5rem;
    }
    .right-wrap {
      width: 100%;
      font-size: 0.24rem;
      color: #fff;
      padding: 0 0.36rem;
      background: linear-gradient(2.24deg, rgba(0, 0, 0, 0.6) 8.94%, rgba(25, 24, 26, 0) 40.79%);
      .wrap {
        width: 100%;
        position: relative;
        min-height: 7rem;
        .wrap-pack {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          margin-bottom: 0.5rem;

          .title {
            font-size: 0.32rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
          }
          .timesWatched {
            display: flex;
            align-items: center;
            margin-top: 0.16rem;
            font-size: 0.26rem;
            img {
              height: 0.36rem;
              margin-right: 0.16rem;
            }
          }
          .author {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0.15rem 0;
            .t {
              font-size: 0.28rem;
              color: #fff;
            }
            li {
              margin-right: 0.16rem;
              @include flex-center;
              @include box-shadow-all;
              border-radius: 0.1rem;
              font-size: 0.24rem;
              color: #ff669d;
            }
            &.tag {
              padding: 0;
              padding-bottom: 0.15rem;
              // max-height: 0.42rem;
              // overflow: auto;
              // @include scrollbar-hide;
              li {
                padding: 0.02rem 0.1rem;
                color: #4fd1c5;
                background: rgba(79, 209, 197, 0.1);
                border: 1px solid #4fd1c5;
                border-radius: 0.08rem;
                box-sizing: border-box;
                margin-bottom: 0.03rem;
              }
            }
          }
          .btn-wrap {
            display: flex;
            margin-bottom: 0.08rem;
            .vip {
              // background: #FF669D;
              height: 0.46rem;
              width: 2rem;
              // padding: 0.1rem 0.34rem;
              font-size: 0.26rem;
              border-radius: 0.14rem;
              color: #fff;
              @include flex-center;
              // @include box-shadow-all;
              white-space: nowrap;
            }
            .buy {
              .coin {
                white-space: nowrap;
                color: #fff;
                padding: 0.01rem 0.2rem;
                background: #3e287d;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0 0.08rem 0 0.08rem;
                font-size: 0.24rem;
                img {
                  width: 0.24rem;
                  margin-right: 0.05rem;
                }
                span {
                }
              }
            }
            .vip_watch {
              background-image: url('../../../assets/imgs/homec/vip_toatch.png');
              background-size: cover;
              background-position: 0;
              background-repeat: round;
            }
            .Buy {
            }
            .free {
              background: #4e982e;
            }
          }
        }
      }
    }
  }
  .home-comic-bottom {
    min-height: calc(100vh - 6.8rem);
    position: relative;
    background: #fff;
    padding-bottom: 1rem;
    @include box-shadow-all;
    .nav-wrap {
      text-align: center;
      padding: 0.24rem 0.3rem 0.36rem 0.3rem;
      font-size: 0.32rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba($color: #6a6a6a, $alpha: 0.7);
      // border-bottom: 0.02rem solid #eee;
      li.active {
        color: #6a6a6a;
      }
      .loading {
        background: #ff729473;
        border-radius: 0.04rem;
        color: #ffffff;
        font-size: 0.22rem;
        padding: 0.03rem 0.1rem;
      }
      .loading-l {
        background: #fff;
        border-radius: 0.04rem;
        color: #ff7294;
        font-size: 0.22rem;
        padding: 0.03rem 0.1rem;
      }
      .watchTimes {
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        img {
          width: 0.24rem;
          margin-right: 0.05rem;
        }
      }
    }
    .desc {
      padding: 0 0.3rem 0.2rem 0.3rem;
    }
    .anthologyBotm {
      height: 1rem;
      position: fixed;
      bottom: -3px;
      left: auto;
      // right: 0;
      max-width: 7.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      z-index: 99;
      .anthologyL {
        width: 1.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #999;
        font-size: 0.22rem;
        box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.1);
        padding: 0.1rem 0;
        img {
          width: 0.42rem;
        }
        i {
          width: 0.36rem;
          height: 0.04rem;
          border-radius: 1rem;
          background: #f9c006;
          margin-top: 0.05rem;
        }
      }
      .anthologyC {
        width: 4.5rem;
        height: 1rem;
        display: -webkit-box;
        overflow-x: auto;
        padding-left: 0.24rem;
        :deep() {
          li {
            width: 1.18rem;
            height: 0.72rem;
            font-size: 0.26rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f7f6fb;
            border: 1px solid #f7f6fb;
            border-radius: 0.08rem;
            margin: 0.14rem 0.24rem 0.14rem 0;
          }
        }
      }
      .anthologyC {
        @include scrollbar-hide;
      }
      .anthologyR {
        width: 1.8rem;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        font-size: 0.24rem;
        color: #fff;
        background: #ff7294;
        background-image: url('../../../assets/imgs/homec/anthology_bg_icon.png');
        background-size: auto 100%;
        background-position: 0;
        background-repeat: round;
      }
    }
    .tab-nav {
      @include flex-align-center;
      justify-content: space-between;
      padding: 0rem 0.4rem;
      width: 100%;
      border-top: 0.02rem solid rgba(0, 0, 0, 0.2);
      max-width: $pcMaxWidth;
      height: 1rem;
      ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-wrap {
          display: flex;
          align-items: center;
          .top {
            @include flex-center;
            font-size: 0.28rem;
            font-weight: 500;
            min-width: 1.2rem;
            white-space: nowrap;
            color: #333;
            opacity: 0.7;
            position: relative;
            img {
              width: 0.32rem;
              position: absolute;
              bottom: -0.15rem;
            }
            span {
              margin-left: 0.08rem;
            }
            &.active {
              position: relative;
              opacity: 1;
            }
          }
        }
        .score {
          display: flex;
          align-items: center;
          color: #333;
          em {
            font-size: 0.32rem;
            font-weight: 500;
            margin-right: 0.1rem;
            font-style: normal;
            font-family: 'Number-DINPro-Medium';
          }
          .score_tc {
            margin-left: 0.1rem;
          }
        }
        .right-wrap {
          div {
            width: 1.2rem;
            height: 0.46rem;
            border-radius: 0.12rem;
            color: #ff669d;
            font-size: 0.24rem;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0.5px 0.2rem rgb(0 0 0 / 10%);
          }
        }
      }
    }
    .recommend {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0.3rem;
      justify-content: space-between;
      i {
        width: 2.1rem;
      }
    }
    //评论框
    .comment-box {
      min-height: calc(100vh - 6.9rem);
      background: #fff;
      border-radius: 0;
      &::before {
        display: none;
      }
      :deep() {
        .header {
          display: none;
        }
        .content-main-wrap {
          height: auto;
          .content-main {
            &:first-child {
              // padding-top: 0;
            }
          }
        }
      }
    }
  }
}
.top_collect {
  position: absolute;
  // top: -0.2rem;
  right: 0.37rem;
  display: flex;
  z-index: 999;
  div {
    padding: 0 0.16rem;
    background: #ff669d;
    height: 0.46rem;
    line-height: 0.46rem;
    border-radius: 0.8rem;
    color: #fff;
    font-size: 0.24rem;
    @include flex-center;
    @include box-shadow-all;
    white-space: nowrap;
    margin-left: 0.2rem;
    img {
      height: 0.28rem;
      margin-right: 0.1rem;
    }
  }
  .vip {
    background: #ff669d;
    padding: 0.1rem 0.34rem;
    font-size: 0.26rem;
    border-radius: 0.8rem;
    color: #fff;
    @include flex-center;
    @include box-shadow-all;
    white-space: nowrap;
  }
  .buy {
    .coin {
      white-space: nowrap;
      color: #fff;
      padding: 0.1rem 0.34rem;
      background: #3e287d;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.14rem;
      font-size: 0.24rem;
      img {
        width: 0.24rem;
        margin-right: 0.05rem;
      }
      span {
      }
    }
  }
  .free {
    background: #4e982e;
  }
}
</style>
