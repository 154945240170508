<template>
  <div class="publish">
    <EBackTop :title="'我的发布'" />
    <JavTab
      @change="change"
      :sticky="true"
      :titles="cartoonCategory"
      :active="indexActive"
      :animated="false"
      :addClass="'mine-tab'"
    >
      <PullUp
        @refreshData="refreshData"
        @moreData="moreData"
        :finished="finished"
        :loading="loading"
        :refreshing="refreshing"
        :skeleton="skeleton"
        class="myPublissh-pullup"
            v-if="articleListData && articleListData.length>0"
      >
        <CommunityDefault v-for="(item, index) in articleListData" :key="index" :itemData="item" />

      </PullUp>
       <Nodata :text="'您还没有发布任何消息哦～'" v-else/>
    </JavTab>
  </div>
</template>
<script>
import { publish_list, publish_del } from 'api/user'
export default {
  data() {
    return {
      //上拉加载
      loading: false,
      //上拉加载完成
      finished: false,
      //下拉刷新
      refreshing: false,
      //文章列表
      articleListData: [],
      indexActive: 3,
      skeleton: false,
      //当前页
      pageNum: 1,
      // 当前个数
      pageSize: 5,
      cartoonCategory: [
        { id: 1, name: '帖子' },
        { id: 2, name: '上门' },
        // { id: 3, name: '发现帖子' },
      ]
    }
  },
  components: {
    CommunityDefault: () => import('@/components/Community/default.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
     Nodata: () => import('@/components/JavNodata.vue'),
    JavTab: () => import('@/components/JavTab.vue')
  },
  created() {
    //切换页面初始化
    this.refreshData()
  },
  methods: {
    //切换
    change(id) {
      this.indexActive = +id
      this.refreshData()
    },
    //下拉加载
    moreData(loading) {
      this.loading = loading
      this.pageNum++
      this.getList()
    },
    //上拉刷新
    refreshData(refreshing) {
      this.refreshing = refreshing
      //表示处于可以下拉状态
      this.finished = false
      this.loading = true
      this.pageNum = 1
      this.skeleton = true
      this.articleListData = []
      this.getList()
    },
    //获取列表
    async getList() {
      try {
        const res =await publish_list({

          type: this.indexActive,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userId:this.userInfo.id
        })
        if (res.code === 200) {
          this.refreshing = false
          this.loading = false
          if (this.indexActive === 3) {

            this.articleListData = [...this.articleListData, ...res.data.postList]


          this.articleListData.map(item => {
            let graphic = {
                word: [],
                picture: []
            }
            for (let index = 0; index < item.nodes.length; index++) {
                const itemX = item.nodes[index];
                if(itemX.type === 1) {
                    graphic.word.push(itemX)
                } else {
                    graphic.picture.push(itemX)
                }
            }
            if ( graphic.picture && graphic.picture.length && graphic.picture.length > 6) {
              graphic.picture = graphic.picture.splice(1, 6);
            }
            item.graphic = graphic
          });

            if (!res.data.postList || res.data.postList && res.data.postList.length < this.pageSize) {
              this.finished = true
            }
          } else {
            this.articleListData = [...this.articleListData, ...res.data.modelList]
            if (res.data.modelList && res.data.modelList.length < this.pageSize) {
              this.finished = true
            }
          }
        } else {
          this.$toast(res.tip)
          this.refreshing = false
          this.loading = false
          this.finished = true
        }
      } catch (error) {
        this.refreshing = false
        this.loading = false
        this.finished = true
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    }
  },

  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  }
}
</script>
<style lang="scss" scoped>
.publish {
  color: #333;
  min-height: 100vh;
  background: #fff;
  :deep(){
  .van-pull-refresh{
    padding: 0 0.25rem;
  }
}
}
</style>
