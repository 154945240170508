<template>
  <div class="squareFront-circle">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="暂时没有更多数据！" @load="onLoad"
        :immediate-check="false">
        <div v-if="adsType === 2 && iconHomeAds.length" class="top_banner">
          <AdAvatarList :list="iconHomeAds" :wrap="true" key-name="avatar" />
        </div>
        <div class="top_banner" v-else-if="adsType !== 3 && avAD.length">
          <EADSwiper :EADconfig="EADconfig" :imgCDN="imgCDN" :list="avAD" keyName="cover" @clickImg="activityBtn" />
        </div>
        <!-- 通知栏跑马灯 -->
        <van-notice-bar left-icon="volume" color="#000" background="rgb(238, 238, 238, 0.6)"
          @click="toInfo(runningLight)" :text="runningLight?.content" v-if="runningLight" />
        <div class="swiper-ad" v-if="activityAds.length > 1">
          <!-- 334*180 -->
          <EADSwiper class="ad-list2" :centeredSlides="activityAds.length > 1" :list="activityAds" keyName="img"
            :EADconfig="EADconfig" :imgCDN="imgCDN" :activeIndex="2" :slidesPerView="2" :spaceBetween="0"
            @clickImgBetter="activityBtn2">
          </EADSwiper>
          <div class="mask_ad" v-show="maskFlag"></div>
        </div>
        <div class="photo-box-circle">
          <PhotoItem v-for="(item, index) in photoList" :data="item" :key="index" />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { handleURlParams, handleParamsRouteJump } from '@/utils/utils_tools'
import { getPhotoList } from 'api/photo'
export default {
  name: 'photoPage',
  data() {
    return {
      address: '',
      //色图列表
      photoList: [],
      //关注圈子数据
      FollowTheCircle: {},
      refreshing: false,
      finished: false,
      loading: false,
      pageNum: 1,
      pageSize: 10,
      EADconfig: {
        speed: 300,
        autoplay: true,
        loop: true,
        delay: 2000
      },
      maskFlag: true
    }
  },
  props: ['type', 'showType', 'navItem', 'showRemShortVideoType', 'avAD', 'short_videoAD'],
  components: {
    PhotoItem: () => import('@/components/Photo/PhotoItem.vue'),
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
    // SearchJumpTo: () => import("@/components/Search/searchJumpTo.vue"),
  },
  computed: {
    adsType({ $store }) {
      return $store.getters['PhotoAdsType']
    },
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    activityAds({ $store }) {
      let data = $store.getters['ActivityAds']
      if (data.length) {
        setTimeout(() => {
          this.maskFlag = false
        }, 1000)
      }
      return data
    },
    iconHomeAds({ $store }) {
      return $store.getters['iconHomeAds']
    },
    // 获取首页推荐位广告
    runningLight({ $store }) {
      return $store.getters['runningLight']
    }
  },
  methods: {
    // 活动广告跳转
    async activityBtn(href) {
      const code = handleURlParams(href)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      const res = await advertiseClick({
        id: href.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
    },
    // 轮播跳转
    async activityBtn2(item) {
      if (item.url) {
        let code = handleURlParams(item.url)
        // handleParamsRouteJump(this, code);
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      } else {
        if (item.id) {
          advertiseClick({
            id: item.id
          })
          let url = item.href ? item.href : item.url
          let code = handleURlParams(url)
          handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
        }
      }
    },
    async toInfo(ifno) {
      if (ifno.jumpUrl) {
        const code = handleURlParams(ifno.jumpUrl)
        handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
        const res = await advertiseClick({
          id: ifno.id
        })
        if (res !== 200) {
          return this.$toast(res.tip)
        }
      }
    },
    //获取贴吧列表
    async get_circleList() {
      const res = await getPhotoList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code === 200) {
        this.refreshing = false
        this.loading = false
        this.photoList = [...this.photoList, ...res.data.list];
        if (!res.data.list || (res.data && res.data.list.length < this.pageSize)) {
          this.finished = true;
        }
      } else {
        this.finished = true
        this.loading = false
        this.refreshing = false
        return this.$toast(res.tip)
      }
      // const res = await this.$postBarFollowPage({
      //   pageNum: this.pageNum,
      //   pageSize: this.pageSize,
      // })
      // if (res.code === 200) {
      //   this.refreshing = false
      //   this.loading = false
      //   if (this.pageNum == 1) {
      //     this.FollowTheCircle = res.data
      //   }
      //   if (!res.data || !res.data.plazaCenterFocus || res.data.plazaCenterFocus.length < this.pageSize)
      //     this.finished = true
      //   if (res.data.plazaCenterFocus) this.articleListData = [...this.articleListData, ...res.data.plazaCenterFocus]
      // } else {
      //   this.finished = true
      //   this.loading = false
      //   this.refreshing = false
      //   return this.$toast(res.tip)
      // }
    },

    //上拉刷新
    onRefresh() {
      this.photoList = []
      this.finished = false
      this.pageNum = 1
      this.get_circleList()
    },
    onLoad() {
      // this.loading = false
      this.pageNum++
      this.get_circleList()
    },
  },
  mounted() {
    this.get_circleList()
    console.log(this.$store.getters['iconHomeAds'], 'asdfasdfas')
    console.log(this.navItem)
  },
  activated() { },
}
</script>

<style lang="scss" scoped>
.squareFront-circle {
  padding-top: 1.8rem;

  :deep() {
    .van-notice-bar {
      height: 0.6rem;
      margin: 0 0.24rem;
      // border-radius: 1rem;
      margin-top: 0.1rem;
      box-shadow: 0px 4px 4px 0px #00000040;
      opacity: 0.6;

      .van-icon {
        color: #000;
      }

      .van-notice-bar__wrap {
        font-size: 0.26rem;
      }
    }
  }


  .photo-box-circle {
    // padding: 0.15rem;
    padding: 0.15rem 0.25rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    >div {
      margin-right: 0.09rem;
    }

    >div:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.search-input {
  @include textoverflow();
  background: #F8F8F8;
  width: 3.74rem;
  height: 0.56rem;
  line-height: 0.62rem;
  border-radius: 0.34rem;
  color: #939496;
  font-size: 0.24rem;
  padding-left: 0.21rem;
  margin-left: 0.2rem;

  .van-icon-search {
    margin-right: 0.1rem;
  }
}

.search-icon {
  margin: 0 0.164rem 0 0.19rem;

}

.jumpTo {
  margin: .15rem .35rem .15rem 0;
}

.top_banner {

  // height: 4rem;
  .JGbtnList {
    padding: 0 0.3rem;
  }
}

// 运营活动广告
.swiper-ad {
  width: 100%;
  padding: 0 .23rem;
  // height: 1.8rem;
  background: #fff;
  margin: 0.24rem auto;
  position: relative;

  .ad-list1 {
    border-radius: 0.08rem;
    margin: 0 auto;
    overflow: hidden;
  }

  .ad-list2 {
    width: 100%;
    height: 1.5rem;

    :deep {
      .default {
        border-radius: 0.08rem;
        overflow: hidden;
        width: 3.2rem;
        height: 1.5rem;
      }
    }
  }

  .mask_ad {
    width: 7.3rem;
    height: 2rem;
    position: absolute;
    left: -0.1rem;
    top: -0.1rem;
    background-color: #fff;
    z-index: 200;
  }
}
</style>
