<template>
  <div class="message-container">
    <EBackTop title="消息中心"></EBackTop>
    <ul class="nav-list">
      <li v-for="item in countList" :key="item.id" @click="$router.push(item.url)">
        <div class="icon-box"><img :src="item.icon" alt="" /></div>
        <p>{{ item.title }}</p>
      </li>
    </ul>
    <div class="line"></div>
    <div class="msg-tit">私信列表</div>
    <PullUp
      @refreshData="refreshData"
      @moreData="moreData"
      :finished="finished"
      :loading="loading"
      :refreshing="refreshing"
      v-if="messageList && messageList.length > 0"
    >
      <ul class="message-list">
        <li
          v-for="item in messageList"
          :key="item.id"
          class="msg-item"
          @click.stop="$router.push(`/chatIM/${+item.peerId}?nickName=${item.nickName}`)"
        >
          <DecryptImg class="msg-acavtar" :imgCDN="imgCDN" :imgURL="item.avatar" />
          <div class="msg-box">
            <div class="box-top">
              <div class="title">
                <span>{{ item.nickName }}</span>
                <span class="last">{{ item.lastText }}</span>
              </div>
              <div class="rights">
                <div class="tip">
                  <div ></div>
                  <div class="tipNum" v-if="item.count">{{ item.count }}</div>
                </div>
                <span class="time">{{ item.updatedAt | timeDiff }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </PullUp>
  </div>
</template>
<script>
import { message_list } from 'api/user'
export default {
  data() {
    return {
      countList: [
      {
          id: 1,
          url: `/mine/msg/msgDetail`,
          icon: require('@/assets/imgs/icon_message_symbol_04.png'),
          title: '评论&回复'
        },
        {
          id: 2,
          url: `/mine/msg/ads`,
          icon: require('@/assets/imgs/icon_message_symbol_03.png'),
          title: '系统通知'
        }
      ],
      messageList: [],
      finished: false,
      refreshing: false,
      loading: false,
      pageNum: 1,
      pageSize: 10,
      type: 3
    }
  },

  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    PullUp: () => import('@/components/PullUp.vue'),
    NoData: () => import('@/components/noData.vue')
  },
  methods: {
    // 获取私信列表
    async get_sessionList() {
      try {
        const res = await message_list({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type: this.type
        })

        if (res.code === 200) {
          this.loading = false
          this.refreshing = false
          if (res.data.dialoglist && res.data.dialoglist.length > 0) {
            this.messageList = [...this.messageList, ...res.data.dialoglist]
          } else {
            this.finished = true
          }
        } else {
          this.loading = false
          this.refreshing = false
          this.finished = true
          return this.$toast(res.tip)
        }
      } catch (error) {
        this.loading = false
        this.refreshing = false
        this.finished = true
        console.log(error)
      }
    },
    refreshData(loading) {
      this.loading = loading
      this.pageNum = 1
      this.messageList = []
      this.get_sessionList()
    },
    moreData(refreshing) {
      this.refreshing = refreshing
      this.pageNum += 1
      this.get_sessionList()
    }
  },
  mounted() {
    // this.get_tipList()
    // this.get_messageList()
    this.refreshData()
  },

  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-list {
  @include flexbox($jc: space-between);
  padding: 0.24rem;
  li {
    @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap, $flex: 1);
    .icon-box {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      border: 0.01rem solid #eeeeee;
      background: #f2f9f9;
      text-align: center;
      @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap, $flex: initial);
      margin-bottom: 0.16rem;
    }
    img {
      width: 50%;
    }
  }
}
.message-container {
  min-height: 100vh;

  .line {
    width: 100%;
    background: #e7e7e7;
    height: 0.1rem;
  }
  .msg-tit {
    padding: 0.3rem;
    font-size: 0.3rem;
    font-weight: 500;
    padding-bottom: 0;
  }

  // 消息列表
  .message-list {
    padding: 0.24rem;

    .msg-item {
      @include flexbox($jc: flex-start, $flex: initial, $ai: center);
      margin-bottom: 0.32rem;
      background-color: #f1f1f1;
      padding: 0.24rem;
      .msg-acavtar {
        width: 0.96rem;
        height: 0.96rem;
        border-radius: 50%;
        margin-right: 0.16rem;
        ::v-deep {
          .warp {
            border-radius: 50%;
          }
        }
      }
      .msg-box {
        // padding-bottom: 0.44rem;
        // border-bottom: 0.01rem solid #E5E5E5;
        width: calc(100% - 1.12rem);
        .box-top {
          @include flexbox($flex: initial);
          width: 100%;
          // margin-bottom: 0.16rem;
          .tag {
            width: 0.56rem;
            height: 0.28rem;
            color: #16d2d6;
            background: #f2f9f9;
            padding: 0.04rem 0.08rem;
            text-align: center;
            list-style: 0.28rem;
            border-radius: 0.38rem;
            margin-left: 0.08rem;
            font-size: 0.2rem;
          }
          .title {
            font-size: 0.28rem;
            display: flex;
            flex-direction: column;
            width: 75%;
            span {height: .4rem;line-height: .4rem;}
            .last {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .rights {
            .tip {
              display: flex;justify-content: space-between;align-items: center;height: .4rem;
              .tipNum{width: .3rem;height: .3rem;background-color: #f10300;border-radius: 100%;color: #fff;text-align: center;line-height: .3rem;font-size: .2rem;}
            }
            .time {
              height: .4rem;
              line-height: .4rem;
              color: #999999;
            }
          }

        }
        .content {
          color: #666666;
          @include textoverflow();
          max-width: 70%;
        }
      }
    }
  }
}
</style>
