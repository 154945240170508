<template>
  <div id="app">
    <!-- 第一次启动滑块验证 -->
    <!-- <div :class="isFirstLogin ? 'verifybg' : ''" v-if="isFirstLogin">
      <div class="pd_box">
        <slide-verify
          :l="42"
          :r="10"
          :w="310"
          :h="200"
          class="slideVerify"
          slider-text=""
          @success="onSuccess"
          @fail="onFail"
          :imgs="imgs"
          sliderText="拖动滑块完成拼图"
        ></slide-verify>
      </div>
    </div> -->
    <router-view ref="childRouter" v-if="isRouterAlive" />
  </div>
</template>
<script>
import { config } from 'api/home'
import { ykInfo } from 'api/user'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      showActivityImg: true,
      isRouterAlive: true,
      imgs: [require('@/assets/imgs/v1.png'), require('@/assets/imgs/v2.png')]
    }
  },

  async mounted() {
    this.$store.commit('SET_ACCOUNT_IMG', true)
    this.$store.commit('SET_ANNOUNCEMENT_POP', true)
    this.$store.commit('SET_SPLASH_STATUS', true)
    this.$store.commit('SET_ACTIVITY_POP', true)
  await this.onSuccess()
  },
  computed: {
    // 是否第一次登陆
    isFirstLogin() {
      return this.$store.getters['getFirstLogin']
    },
    // 获取token
    token({ $store }) {
      return $store.getters['getToken']
    },
    // 获取渠道参数
    channel({ $store }) {
      return $store.getters['getChannel']
    },
    // 获取uuid
    uid({ $store }) {
      return $store.getters['getUid']
    },
    isLight({ $store }) {
      return $store.state.user.isLight
    },
    query(){
      const search = location.href.replace(/^[^?]*/, '')
      const searchParams = new URLSearchParams(search);
      const query = Object.fromEntries([...searchParams]);
      return query
    }
  },

  methods: {
    setChildRouterDialog() {
      if (this.$refs.childRouter) {
        this.$refs.childRouter.setAllDialog()
      } else {
        this.$nextTick(() => {
          if (this.$refs.childRouter) {
            this.$refs.childRouter.setAllDialog()
          }
        })
      }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 获取渠道参数
    async getChannel() {
      const query = this.query
      const hasDC = query && query.dc
      const hasPC = query && query.pc
      const $device = require('current-device').default
      let SysType = ''
      if (hasDC) {
        this.$store.dispatch('setParams', {
          key: 'dc',
          data: hasDC
        })
      }
      if (hasPC) {
        this.$store.dispatch('setParams', {
          key: 'pc',
          data: hasPC
        })
      }
      if (this.isLight === null || this.isLight === false) {
        const isLight = query['amp;isLight'] === 'true' || query['isLight'] === 'true'
        this.$store.commit('SET_ISLIGHT', isLight)
        this.state = false
      }
      if (this.isLight) {
        SysType = 'light'
      } else {
        if ($device.desktop()) {
          SysType = 'h5_pc'
        }
        if ($device.android()) {
          SysType = 'h5_android'
        }
        if ($device.ios()) {
          SysType = 'h5_ios'
        }
      }

      this.$store.dispatch('setParams', {
        key: 'SysType',
        data: SysType
      })
    },
    // 获取游客信息
    async getYkInfo() {
      const query = this.query
      // 是否有uid
      if (query['amp;UID']) {
        await this.$store.dispatch('setUid', query['amp;UID'])
      } else if (query.UID) {
        await this.$store.dispatch('setUid', query.UID)
      } else if (!this.uid) {
        const hasUID = Number(Math.random().toString().substr(2, 8) + Date.now()).toString(36)
        await this.$store.dispatch('setUid', hasUID)
      }
      // 是否有token如果存在uid并且没有token则传入渠道号参数
      if (!this.token) {
        try {
          const res = await ykInfo({
            affCode: this.channel ? JSON.stringify(this.channel) : '{}',
            devID: this.uid
          })
          if (res && res.code === 200) {
            await this.$store.dispatch('setUserInfo', res.data)
            await this.$store.dispatch('setToken', res.data.token)
          } else {
            this.$toast(res.tip)
          }
        } catch (error) {
          return this.$toast('请求出错，请稍后再试！')
        }
      } else {
        await this.$store.dispatch('setToken', this.token)
      }
    },
    //获取配置后再操作
    async getConfig() {
      const res = await config()
      if (res.code === 200 && res.data) {
        await this.$store.dispatch('setConfig', res.data)
      }
    },
    // 验证成功
    async onSuccess() {
      // 获取渠道参数
      await this.getChannel()
      // 用户信息
      await this.getYkInfo()
      await this.getConfig()
      this.$store.commit('SET_FIRSTLOGIN', false)
      this.setChildRouterDialog()
    },
    // 验证失败
    onFail() {
      return this.$toast('验证失败，请重新验证！')
    }
  }
}
</script>
<style lang="scss">
.verifybg {
  height: 100vh;
  width: 100vw;
  background: rgba($color: #000000, $alpha: 0.2);
  position: fixed !important;
  top: 50%;
  z-index: 100009;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .pd_box {
    font-size: 0.28rem !important;
    color: #999;
    padding: 0.24rem 0.43rem 0.49rem 0.43rem;

    background: #292929;
    border-radius: 0.12rem;
    box-sizing: content-box;
  }
}

</style>
