<template>
  <van-popup class="catalogue-popup" v-if="catalogueObj.show" v-model="catalogueObj.show" round position="bottom">
    <main class="catalogue-list">
      <div class="title">
        <div class="titleL">
          全部章节({{ list.length }})
          <div>连载中·更新到{{ list.length }}话</div>
        </div>
        <div class="titleR">
          <div v-if="listShowType" @click="listShowType = !listShowType">
            <img src="@/assets/imgs/homec/list_icon.png" alt="" />
            列表模式
          </div>
          <div v-else @click="listShowType = !listShowType">
            <img src="@/assets/imgs/homec/list_icon2.png" alt="" />
            缩略模式
          </div>
        </div>
        <!-- <div class="sort" @click="fn_sortDer">
            <img src="/newAppMh/home-comic/sort.png" alt="">
            <div v-if="sortDer">正序</div>
            <div v-else>反序</div>
          </div> -->
      </div>

      <ul class="catalogue">
        <CatalogueItem
          v-for="(item, index) in list"
          :item="item"
          :key="index"
          :listType="listShowType ? 0 : 1"
          :bgImages="bgImages"
          :shoudBuy="shoudBuy"
          :title="title"
          :price="price"
        ></CatalogueItem>
      </ul>
    </main>
  </van-popup>
</template>
<script>
export default {
  components: {
    CatalogueItem: () => import('@/components/HomeComic/comic/catalogue/item')
  },
  data() {
    return {
      sortDer: true,
      list: [],
      listShowType: false
    }
  },
  props: {
    catalogueObj: {
      type: Object,
      default() {
        return {}
      }
    },
    bgImages: {
      type: String,
      default() {
        return ''
      }
    },
    shoudBuy: {
      type: Number,
      default() {
        return 0
      }
    },
    price: {
      type: Number,
      default() {
        return 0
      }
    },
    title: {
      type: String,
      default() {
        return '' //封面名称
      }
    }
  },
  computed: {},
  methods: {
    //排序
    fn_sortDer() {
      this.sortDer = !this.sortDer
      this.list = this.list.reverse()
    },
    //初始化数据
    initDatas() {
      this.list = JSON.parse(JSON.stringify(this.catalogueObj.list))
      //动画
      setTimeout(() => {
        this.catalogueObj.show = true
      }, 100)
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.catalogue-popup {
  left: auto;
  max-width: $pcMaxWidth;
}
.catalogue-list {
  padding: 0.3rem 0.3rem 0 0.3rem;
  position: relative;
  background: #fff;
  border-top-left-radius: 0.44rem;
  border-top-right-radius: 0.44rem;
  .title {
    position: relative;
    text-align: center;
    font-size: 0.36rem;
    color: #6a6a6a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleL {
      font-size: 0.32rem;
      color: #6a6a6a;
      display: flex;
      align-items: center;
      div {
        background: rgba(255, 139, 0, 0.07);
        border-radius: 0.04rem;
        color: #ff669d;
        font-size: 0.22rem;
        padding: 0 0.1rem;
        margin-left: 0.2rem;
      }
    }
    .titleR {
      font-size: 0.24rem;
      color: #6a6a6a;
      div {
        display: flex;
        align-items: center;
        img {
          width: 0.42rem;
          height: 0.42rem;
          margin-right: 0.18rem;
        }
      }
    }
    .sort {
      font-size: 0.24rem;
      position: absolute;
      top: 0.1rem;
      right: 0;
      display: flex;
      align-items: center;
      img {
        width: 0.26rem;
        height: 0.21rem;
        margin-right: 0.1rem;
      }
    }
  }
  .catalogue {
    @include scrollbar-hide;
    max-height: 8.24rem;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.2rem;
    padding-bottom: 0.3rem;
    padding-top: 0.25rem;
    :deep() {
      li {
        background-color: #fff;
        border: 1px solid #aaaaaa;
      }
    }
  }
}
</style>
