<template>
  <div class="post_transaction_jiaoYiPost">
    <EBackTop :title="'发布交易'" background="#000" color="#fff"> </EBackTop>

    <div class="post_form">
      <van-field
        class="serve_content"
        show-word-limit
        rows="5"
        maxlength="500"
        v-model="form.text"
        type="textarea"
        placeholder="请输入您的需求或可以提供的服务..."
        :rules="[{ required: true, message: '请输入您的需求或可以提供的服务...' }]"
      />
      <div class="video_box" v-if="$route.query.postType === 'video'">
        <div class="video_title">
          <p class="label">添加视频</p>
          <p class="default_txt">只能添加一个视频,如果有视频则视频显示为封面</p>
        </div>
        <UploadVideo @result="resultVideo" />
      </div>
      <div class="photo_box" v-if="$route.query.postType === 'img'">
        <div class="video_title">
          <p class="label">添加图片</p>
          <p class="default_txt">最多只能添加9张图片,封面显示为第一张</p>
        </div>
        <UploadImg :maxLength="9" @result="resultImgs" />
      </div>
      <!-- 主体 -->
      <div class="form_main">
        <van-form @submit="onSubmit" :show-error-message="false">
          <div class="servive_type">
            <div class="tiaoJiao_main">
              <div>
                <span class="label">话题</span>
                <p class="active_tip">*必选</p>
                <div class="hasLianList">
                  <span class="default" @click="changeMyUser(false)" :class="!form.isSeller ? 'active' : ''"
                    >我是买家</span
                  >
                  <span class="default" @click="changeMyUser(true)" :class="form.isSeller ? 'active' : ''"
                    >我是卖家</span
                  >
                </div>
              </div>
              <div>
                <div class="default_tip">*单选</div>
                <div class="hasTypeList">
                  <div class="hasTypeList_item" v-for="item in postCategory" :key="item.id">
                    <span @click="checkTypeItem(item.id)" :class="typeItemActive === item.id ? 'active' : 'default'">{{
                      item.name
                    }}</span>
                    <img
                      v-if="typeItemActive === item.id"
                      @click="typeItemActive = 0"
                      src="@/assets/imgs/trade/close.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div>
                <span class="label">填写联系方式</span>
                <van-field
                  class="serve_content"
                  show-word-limit
                  rows="5"
                  maxlength="500"
                  v-model="form.contact"
                  type="textarea"
                  placeholder="地址:xx省xx市xx区xx街道xx号收货/发货人:张三联系微信/电话:*** *** ****"
                  :rules="[{ required: true, message: '请输入您的需求或可以提供的服务...' }]"
                />
              </div>
              <!-- 保证金 -->
              <div>
                <span class="label">交易保证金</span>
                <div class="earnestMoney_box">
                  <div class="stepeer_time_right">
                    <img src="@/assets/imgs/mine/gold.png" alt="" />
                    <van-stepper v-model.number="form.price" integer input-width="40px" step="1" />
                    <span>余额:{{ info.balance | changeGold }}</span>
                  </div>
                  <span class="goWallet" @click.stop="$router.push('/mine/newRecharge?type=0')">去充值>></span>
                </div>
              </div>
              <!-- 选择交保证金 -->
              <van-cell class="radio_cell" title="请根据实际业务双方需缴纳保证金" clickable @click="radio = '1'">
                <template #right-icon>
                  <van-radio v-model="radio" name="1">
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                    </template>
                  </van-radio>
                </template>
              </van-cell>
            </div>
            <div class="submit_btn">
              <van-button round block type="info" native-type="submit">支付保证金{{ form.price }}提交审核</van-button>
            </div>
          </div>
        </van-form>
      </div>
    </div>

    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>

<script>
import { community_publish } from '@/api/dating'
export default {
  components: {
    EBackTop: () => import('@/components/web_base/component_base/EBackTop/index.vue'),
    UploadVideo: () => import('@/components/UploadVideo/index.vue'),
    UploadImg: () => import('@/components/UploadImg/index.vue'),
    DmPopup: () => import('@/components/Popup/index.vue')
  },
  inject: ['reload'],
  data() {
    return {
      detailData: {},
      pageNum: 1,
      pageSize: 10,
      reportList: [],
      showBuy: false,
      refreshing: false, // 下拉刷新开关
      loading: false, // 上拉加载
      finished: false, // 上拉加载开关
      form: {
        contact: undefined,
        text: undefined,
        price: 0,
        isSeller: false
      },
      video: undefined,
      typeItemActive: 0,
      radio: 1,
      activeIcon: require('@/assets/imgs/trade/radio_active.png'),
      inactiveIcon: require('@/assets/imgs/trade/radio_active.png'),
      imgs: [],
      taskID: undefined
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 获取话题分类
    postCategory({ $store }) {
      return $store.getters['postCategory']
    },
    info({ $store }) {
      return $store.getters['getUserInfo']
    }
  },

  mounted() {
    // indexType 0 为暗黑游  1为帝王调教
    // console.log(this.$route.query.indexType, this.$route.query.postType);
  },

  methods: {
    async onSubmit() {
      if (this.info.balance < this.form.price * 100) {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '注意',
          content: '当前余额不足',
          type: 'up_post',
          ok: '购买VIP',
          img: '',
          cancel: '充值金币',
          position: 'center',
          showBalance: false,
          cb: async returnData => {
            this.$router.push('/mine/newRecharge?type=1')
          },
          close: async returnData => {
            this.$router.push('/mine/newRecharge?type=0')
          }
        }
      } else {
        // this.$refs['popupMsg'].popupMsg = {
        // show: true,
        // title: '支付保证金',
        // type: 'up_post',
        // ok: '缴纳交易保证金',
        // img: '',
        // cancel: '',
        // position: 'center',
        // showBalance: true,
        // tip: '买家/卖家已要求缴纳等额交易保证金',
        // cb: async (returnData) => {
        //     this.$refs['popupMsg'].popupMsg.show = false
        //     // await 先调用支付保证金接口

        // },
        // }
        await this.submitFn()
      }
    },

    async submitFn() {
      const res = await community_publish({
        categoryId: this.typeItemActive,
        contact: this.form.contact,
        cover: this.imgs[0],
        imgs: this.imgs,
        isSeller: this.form.isSeller,
        price: this.form.price * 100,
        text: this.form.text,
        video: this.video,
        taskID: this.taskID,
        type:1
      })
      if (res.code === 200) {
        this.$toast('上传成功')
        this.reload()
      } else {
        this.$toast(`上传失败,${res.tip}`)
      }
    },
    // 视频上传回调
    resultVideo(video) {
      this.taskID = video.id
      this.video = video.videoUri
    },
    // 图片上传回调
    resultImgs(imgs) {
      this.imgs = imgs
    },
    checkTypeItem(id) {
      this.typeItemActive = id
    },
    changeMyUser(isSeller) {
      this.form.isSeller = isSeller
    }
  }
}
</script>

<style lang="scss" scoped>
.post_transaction_jiaoYiPost {
  background: #212223;
  min-height: 100vh;

  .post_form {
    padding: 0.4rem 0.36rem 2rem 0.36rem;
  }

  :deep() {
    .van-cell {
      border-radius: 0.08rem;
      padding: 0;
      border: 1px solid #535353;
      background: #292929;
      margin-top: 0.4rem;

      .van-field__label {
        width: auto;
        color: #00f6f8;
        padding: 0.04rem 0.08rem;
        text-align: center;
        margin-right: 0;
      }

      .van-field__value {
        color: #fff;
        padding: 0.04rem 0.08rem;
      }

      .van-field__control {
        color: #fff;
      }
    }

    .van-cell::after {
      display: none;
    }
  }

  .serve_content {
    margin-top: 0;
    margin-bottom: 0.4rem;
  }
}

.label {
  border-radius: 0.08rem;
  padding: 0.04rem 0.08rem;
  color: #00f6f8;
  margin-right: 0.24rem;
  font-size: 0.26rem;
}

.default_txt {
  color: #787979;
  font-size: 0.24rem;
  font-weight: 400;
}

.video_title {
  @include flexbox($jc: flex-start);
  margin-bottom: 0.24rem;
}

.video_box {
  margin-bottom: 0.4rem;
}

.form_main {
  margin-top: 0.36rem;

  .title {
    color: #f9c006;
    font-family: PingFang HK;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .servive_type {
    margin-top: 0.44rem;

    .label {
      margin-bottom: 0.26rem;
      display: inline-block;
    }
  }
}

.chest_flex {
  @include flexbox($jc: flex-start);

  margin-top: 0.4rem;
  font-size: 0.26rem;

  .chest_list {
    position: relative;

    .chest_title {
      border-radius: 0rem 0.08rem 0.08rem 0rem;
      border: 1px solid #535353;
      background: #292929;
      padding: 0.04rem 0.18rem 0.04rem 0;
      @include flexbox($jc: flex-start);

      .label {
        margin-right: 0;
        width: 40%;
      }

      .text {
        width: 1.64rem;
        @include flexbox($jc: flex-start);

        span {
          width: 80%;
        }
      }

      img {
        width: 0.26rem;
        height: 0.22rem;
      }
    }

    .sub_list {
      position: absolute;
      width: 2rem;

      max-height: 2rem;
      overflow: auto;
      top: 100%;
      right: 0;
      z-index: 10;

      li {
        padding: 0.04rem 0.18rem;
        background: #292929;

        border-bottom: dotted 0.01rem #535353;
      }
    }
  }
}

.submit_btn {
  position: fixed;
  width: 100%;
  left: 50%;
  max-width: $pcMaxWidth;
  bottom: 0;
  transform: translate(-50%, 0);
  padding: 0.34rem 0;
  background: #212223;
  border-top: solid 0.01rem #535353;

  :deep() {
    .van-button {
      width: 6.5rem;
      height: 0.9rem;
      margin: 0 auto;
    }

    .van-button--info {
      border-radius: 0.46rem;
      background: #00f6f8;
      border: none;
    }
  }
}

.tiaoJiao_main {
  margin-top: 0.26rem;

  .active_tip {
    color: #f9c006;
    font-size: 0.26rem;
    margin-bottom: 0.26rem;
  }

  .default_tip {
    margin-bottom: 0.24rem;
  }

  .hasLianList {
    @include flexbox($jc: flex-start, $fw: wrap);

    span {
      margin-right: 0.16rem;
      margin-bottom: 0.36rem;
    }
  }

  .hasTypeList {
    @include flexbox($jc: flex-start, $fw: wrap);

    .hasTypeList_item {
      position: relative;
    }
    img {
      position: absolute;
      top: -0.1rem;
      right: 0.1rem;
      width: 0.24rem;
      height: 0.24rem;
    }
    .active {
      margin-right: 0.16rem;
      margin-bottom: 0.36rem;
      border-radius: 0.12rem;
      display: inline-block;
    }
  }
}

.active {
  border-radius: 0.12rem;
  border: 1px solid #00f6f8;
  padding: 0.16rem 0.18rem;
  color: #00f6f8;
}

.default {
  margin-right: 0.16rem;
  margin-bottom: 0.36rem;
  border-radius: 0.12rem;
  border: 2px solid #787979;
  padding: 0.16rem 0.08rem 0.16rem 0.1rem;
  display: inline-block;
}

.goWallet {
  color: #f9c006;
  font-size: 0.24rem;
  display: inline-block;
  margin-left: 0.31rem;
}

.earnestMoney_box {
  @include flexbox();

  .stepeer_time_right {
    @include flexbox($jc: flex-start);

    img {
      width: 0.42rem;
      height: 0.42rem;
    }

    :deep() {
      .van-stepper {
        margin: 0 0.4rem 0 0.16rem;
      }

      .van-stepper__minus,
      .van-stepper__plus {
        background-color: transparent;
        color: #fff;
      }

      .van-stepper__input {
        border-radius: 0.08rem;
        border: 1px solid #535353;
        background: #292929;
        color: #fff;
      }
    }
  }
}

.radio_cell {
  color: #787979;
  border: none !important;
  background-color: transparent !important;

  :deep() {
    .van-radio__icon {
      @include flexbox($jc: center);

      img {
        width: 0.32rem;
        height: 0.32rem;
      }
    }
  }
}
</style>
