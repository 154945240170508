<template>
  <div class="model-wrap">
    <HeaderBar :background="'transparent'" :color="'#fff'" :title="' '"></HeaderBar>
    <!-- 妹子图片轮播图 -->
    <DmSwiper
      ref="blSwiper"
      @clickImg="clickImg"
      class="ww-swiper"
      :imgs="modelDetail.images"
      :height="'6.77rem'"
      style="min-height: 6.77rem"
    />
    <ul class="model-decial">
      <li class="decial">
        <div class="name">
          <div class="title">{{ modelDetail.title }}</div>
          <div class="btn">
            <div class="collect_btn" v-if="!modelDetail.isCollect" @click="checkLike">
              <img src="@/assets/imgs/icon_add.png" alt="" />收藏
            </div>
            <div class="collect_btn checked" v-else @click="checkLike" style="opacity: 0.7">已收藏</div>
            <div class="report_btn" @click="checkRight">
              <img src="@/assets/imgs/icon_hookup_warn.png" alt="" />
              举报
            </div>
          </div>
        </div>
        <div class="area" v-if="modelDetail.serviceCity">
          <img src="@/assets/imgs/mine/position_icon.png" alt="" />{{ modelDetail.serviceCity[0] || '' }}
        </div>
        <!-- <div class="local">
          <img src="/images/dating/decial-local.png" alt="">
          <label>{{modelDetail.serviceCity&&modelDetail.serviceCity.length&&modelDetail.serviceCity[0]}}</label>
          <span>
            联系方式：{{modelDetail.isBuy||!modelDetail.reservationPrice?modelDetail.contactInfo:'******'}}
          </span>
        </div> -->
        <div class="tip">
          凡是有要求路费/定金/保证进/照片验证/视频验证等任何提前付费的行为千万不要上当,同时也请注意仙人跳，在寻欢前不要露富和带过于贵重随身物品，本APP为分享信息并不对寻欢经历负责，碰到有问题的信息，请及时举报给我们删除信息
        </div>
        <div class="time">
          {{ modelDetail.createdAt | timeDiff }}
        </div>
      </li>
      <li class="age">
        <div>销售量：{{ modelDetail.sells }}</div>
        <div>年龄：{{ modelDetail.girlAge }}</div>
        <div>
          价格一览： <em>{{ modelDetail.price }}</em>
        </div>
        <!-- <div>价格：{{modelDetail.price}}</div> -->
      </li>
      <li class="project">
        <div>
          服务项目：{{ modelDetail.serviceItems ? modelDetail.serviceItems.toString() : modelDetail.serviceItems }}
        </div>
      </li>
      <li class="introduce">介绍：{{ modelDetail.desc ? modelDetail.desc : '暂无详细介绍' }}</li>
      <!-- <li class="project">
        <div>
          <strong>地点：</strong
          >{{ modelDetail.serviceCity ? modelDetail.serviceCity.toString() : modelDetail.serviceCity }}
        </div>
      </li> -->

      <li class="introduce no-line">
        预约流程：<br />
        1.支付预约金之后，获得经纪人的联系方式<br />
        2.联系经纪人确定约妹时间、地点，在线选妹妹<br />
        3.与妹妹见面满意，则付尾款(全款减去预约金)给妹妹<br />
        4.如果经纪人服务过程中出现欺诈或者其他服务问题，可直接<br />
        联系平台『在线客服』举报<br />
      </li>

      <div class="bossInfo-wrap" :class="{ shadow: +$route.query.type == 2 }">
        <div class="bossInfo-item" v-if="bossInfo.recommentGirl && bossInfo.recommentGirl.length">
          <div class="top-avatar" @click="toBoss">
            <DecryptImg class="avatar" :imgURL="bossInfo.avatar" />
            <div class="avatar-right">
              <div class="avatar-right-top">
                <p class="name">{{ bossInfo.name }}</p>
                <p class="recommend" v-if="bossInfo.recommend">推荐</p>
              </div>
              <!-- 商家信息 -->
              <div class="boss-info">
                <div>认证妹子：{{ bossInfo.girls }}</div>
                <div>成交：{{ bossInfo.orders }}</div>
                <div>粉丝：{{ bossInfo.fans }}</div>
              </div>
            </div>
          </div>
          <!-- 妹子图片列表 -->
          <div class="girls-img">
            <div
              class="girls-item"
              @click="fn_imagePreview(index)"
              v-for="(girl, index) in bossInfo.recommentGirl"
              :key="index"
            >
              <DecryptImg
                ref="refImg"
                :needPadding="false"
                :lazy="false"
                :imgRadius="'0.1rem'"
                :imgURL="girl.images && girl.images[0]"
              />
            </div>
          </div>
        </div>
      </div>
    </ul>
    <div class="btn-pack">
      <!-- 微博需求：最新资讯里面的联系方式直接展示出来了，不需要解锁。 -->
      <div v-if="!modelDetail.isBuy && modelDetail.infoPrice" class="buy-btn" @click="puyInfo">
        <img src="@/assets/imgs/index/gold.png" alt="" />
        <em>{{ modelDetail.infoPrice | changeGold }}</em
        >金币解锁
      </div>
      <div class="releaseBtn_pack" v-if="modelDetail.isBuy || !modelDetail.infoPrice">
        <div v-clipboard:copy="modelDetail.contactInfo" v-clipboard:success="onCopy" class="contactDetails">
          {{ modelDetail.contactInfo }}
        </div>
        <!-- <div class="releaseBtn" @click="toPublish">发布</div> -->
      </div>
    </div>
    <DmPopup ref="popupMsg"></DmPopup>
  </div>
</template>

<script>
import { collect } from 'api/home'
import { loufeng_detail, dating_pay, model_detail } from '@/api/dating'
import { canBuy } from '@/utils/utils_tools'
import { ImagePreview } from 'vant'
// import ComPopupModel from '@/components/PopupModel/index.js'
export default {
  components: {
    HeaderBar: () => import('@/components/HeaderBar.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    DmSwiper: () => import('@/components/ZHSwiper.vue'),
    DmPopup: () => import('@/components/Popup/index.vue')
  },
  data() {
    return {
      bossInfo: {},
      modelDetail: {
        images: []
      }
    }
  },
  computed: {
    cdn({ $store }) {
      return $store.getters['config/cdn'].imgCdn
    },
    userInfo({ $store }) {
      return $store.getters['getUserInfo']
    }
  },
  async mounted() {
    this.datingModelDetail()
  },
  methods: {
    //发布
    toPublish() {
      if (this.$store.state.user.info.vipType == 11) {
        this.$router.push(`/dating/publish`)
      } else {
        return this.$toast('亲，只有商家才能上门发布哦！！！')
      }
    },
    //商家详情
    toBoss() {
      this.$router.push({
        path: `/dating/bosses-detail/${this.bossInfo.id}`,
        query: {
          name: this.bossInfo.name
        }
      })
    },
    // 图片预览
    fn_imagePreview(index) {
      let imgArr = []
      let domArr = this.$refs['refImg']
      domArr.forEach((item, index) => {
        imgArr.push(item.realUrl)
      })
      ImagePreview({
        images: imgArr, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: true, //是否开启循环播放
        startPosition: index //图片预览起始位置索引
      })
    },
    // 复制
    onCopy() {
      this.$toast('复制成功')
    },
    // 获取楼凤详情
    async datingModelDetail() {
      const res = await loufeng_detail({
        datingType: this.$route.query.name == '楼凤' ? 1 : 2,
        id: +this.$route.params.id
      })
      if (res.code === 200) {
        this.bossInfo = res.data.bossInfo
        this.modelDetail = res.data.details
      } else {
        return this.$toast(res.tip)
      }
    },
    // 图片预览
    clickImg(imgUrl) {
      let imgArr = []
      let index = 0
      let domArr = this.$refs['blSwiper'].$refs['refImg']
      domArr.forEach((itemBlob, indexBlob) => {
        if (imgUrl == itemBlob.imgURL) {
          index = indexBlob
        }
        imgArr.push(itemBlob.realUrl)
      })
      ImagePreview({
        images: imgArr, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: true, //是否开启循环播放
        startPosition: index //图片预览起始位置索引
      })
    },
    // 收藏楼凤信息
    async checkLike() {
      // DatingTypeLoufeng  DatingType = 1 //楼凤 collect_type=6
      // DatingTypeModel    DatingType = 2 //上门 collect_type=4
      let res = await collect({
        flag: !this.modelDetail.isCollect,
        object_id: this.modelDetail.id,
        collectType: this.modelDetail.datingType == 2 ? 4 : 6,
        isCollet: true,
        type: 1
      })
      if (res.code === 200) {
        if (this.modelDetail.isCollect) {
          this.modelDetail.isCollect = !this.modelDetail.isCollect
          this.$toast('已取消收藏')
        } else {
          this.modelDetail.isCollect = !this.modelDetail.isCollect
          this.$toast('收藏成功')
        }
      } else {
        this.$toast(res.tip)
      }
    },
    // 购买楼凤信息
    async puyInfo() {
      if (canBuy(this.$store, this.modelDetail.infoPrice)) {
        // let setting = {
        //   title: '立即支付',
        //   msg: '本次购买需花费' + this.modelDetail.infoPrice / 100 + '金币',
        //   confirmButtonText: '确认支付',
        //   cancelButtonText: '狠心离开',
        //   sellingPrice: this.modelDetail.infoPrice
        // }
        // ComPopupModel('postRecharge', setting, async datas => {
        //   if (datas.confirm) {
        //     const res = await this.$datingPay({
        //       objectId: this.modelDetail.id,
        //       type: 2 // 1 固定值，楼凤 2外围
        //     })
        //     if (res.code === 200) {
        //       this.modelDetail.isBuy = true
        //       this.$refs['popupMsg'].popupMsg.show = false
        //       this.$store.dispatch('user/get_userInfo')
        //       this.modelDetail.isBuy = true
        //       return this.$toast('购买成功')
        //     } else {
        //       return this.$toast(res.tip)
        //     }
        //   }
        // })
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '立即支付',
          showBalance: true,
          type: 'community-post-enough',
          ok: '确认支付',
          price: this.modelDetail.infoPrice,
          cancel: '狠心离开',
          position: 'center',
          isShowTips: true,
          balance: this.userInfo.balance,

          // show: true,
          // title: '立即支付',
          // price: this.modelDetail.infoPrice,
          // type: 'comic_pay_post',
          // ok: '确认支付',
          // position: 'center',
          cb: async returnData => {
            const res = await dating_pay({
              objectId: this.modelDetail.id,
              type: 2 // 1 固定值，楼凤 2外围
            })
            if (res.code === 200) {
              this.modelDetail.isBuy = true
              this.$refs['popupMsg'].popupMsg.show = false
              this.$store.dispatch('get_userInfo')
              this.modelDetail.isBuy = true
            } else {
              this.$router.push('/mine/newRecharge?type=0')
              return this.$toast(res.data.msg)
            }
          }
        }
      } else {
        this.$refs['popupMsg'].popupMsg = {
          show: true,
          title: '您的余额不足',
          content: '您的余额已不足，请充值金币购买',
          type: 'up_post',
          ok: '前往充值',
          img: '',
          cancel: '狠心离开',
          position: 'center',
          showBalance: false,
          cb: async returnData => {
            this.$router.push('/mine/newRecharge?type=0')
          }
        }
      }
    },
    // 举报跳转
    async checkRight() {
      if (this.modelDetail.isBuy || !this.modelDetail.infoPrice) {
        // 跳转举报编辑页
        this.$router.push({
          path: `/dating/report/` + this.modelDetail.id,
          query: {
            type: this.modelDetail.datingType
          }
        })
      } else {
        this.$toast('购买后才能提交举报')
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('setYpHisList', { type: 'add', item: this.modelDetail })
  }
}
</script>

<style lang="scss" scoped>
.model-wrap {
  background: #fff;
  min-height: 100vh;
  color: #999;
  padding-bottom: 1.2rem;

  .ww-swiper {
    :deep() {
      .van-swipe__indicators {
        bottom: 0.8rem;
      }
    }
  }

  .model-decial {
    padding-top: 0.35rem;
    font-size: 0.24rem;
    position: relative;
    background: #fff;
    z-index: 99;
    margin-top: -0.6rem;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;

    .age {
      em {
        color: #ff669d;
      }
    }

    .decial {
      .name {
        padding-bottom: 0.15rem;
        display: flex;
        justify-content: space-between;

        .title {
          font-size: 0.36rem;
          color: #333;
        }

        .btn {
          display: flex;
          align-items: center;

          //   div {
          //     &:nth-child(1),
          //     &:nth-child(2) {
          //       width: 1.02rem;
          //       height: 0.46rem;
          //       color: #ff8b00;
          //       @include flex-center;
          //       border-radius: 0.1rem;
          //     }
          //     &:nth-child(2) {
          //       color: #e02020;
          //       margin-left: 0.2rem;
          //       img {
          //         width: 0.26rem;
          //         margin-right: 0.05rem;
          //       }
          //     }
          //   }
          .collect_btn {
            height: 0.42rem;
            line-height: 0.42rem;
            padding: 0 0.2rem;
            border: 0.02rem solid #ff669d;
            border-radius: 0.32rem;
            color: #ff669d;
            display: flex;
            align-items: center;
            white-space: nowrap;

            &.checked {
              color: #c2c4c4;
              border: 0.02rem solid #c2c4c4;
            }

            img {
              height: 0.24rem;
              margin-right: 0.06rem;
            }
          }

          .report_btn {
            height: 0.42rem;
            line-height: 0.42rem;
            padding: 0 0.2rem;
            border: 0.02rem solid #fe7803;
            background: #fe7803;
            border-radius: 0.32rem;
            color: #fff;
            margin-left: 0.12rem;
            display: flex;
            align-items: center;
            white-space: nowrap;

            img {
              height: 0.24rem;
              margin-right: 0.06rem;
            }
          }
        }
      }

      .area {
        display: flex;
        align-items: center;
        color: #333;
        font-size: 0.24rem;
        margin-bottom: 0.06rem;

        img {
          height: 0.22rem;
          margin-right: 0.1rem;
        }
      }

      .local {
        display: flex;
        align-items: center;
        padding: 0.1rem 0;

        label {
          white-space: nowrap;
        }

        img {
          width: 0.18rem;
          height: 0.26rem;
          margin-right: 0.1rem;
        }

        span {
          width: 5.6rem;
          display: inline-block;
          padding-left: 0.3rem;
          color: #ff7777;
        }
      }

      .tip {
        color: #ff669d;
      }

      .time {
        padding: 0.1rem 0;
      }
    }

    .introduce {
      // white-space: pre-line;
    }

    .hasbuy {
      font-size: 0.32rem;
      display: flex;
      padding: 0.66rem;

      .copy {
        padding: 0.15rem 0;
        flex: 1;
        border-radius: 0.24rem;
        @include flex-center;
        // color: #ff7777;
        color: #0d9c09;

        span {
          display: inline-block;
          overflow: hidden;
          max-width: 4rem;
          white-space: nowrap;
          color: #0d9c09;
          padding-left: 0.1rem;
        }
      }

      .release {
        width: 2.12rem;
        height: 0.78rem;
        border-radius: 0.24rem;
        @include flex-center;
        color: #ff8b00;
        margin-left: 0.2rem;
      }
    }

    .no-line {
      &::after {
        display: none;
      }
    }

    li {
      padding: 0.1rem 0.44rem;
      position: relative;

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background: #eee;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    //商家推荐
    .bossInfo-wrap {
      background: #fff;
      padding-bottom: 0.3rem;
      overflow: hidden;
      margin: 0 0.3rem;
      margin-top: 0.1rem;

      &.shadow {
        // box-shadow: 0px -0.02px 0.08rem 0 rgba(0, 0, 0, 0.1);
      }

      .bossInfo-item {
        width: 100%;
        // padding: 0.22rem 0.22rem;
        background: #f2f9f9;
        border-radius: 0.2rem;
        overflow: hidden;

        .top-avatar {
          display: flex;
          align-items: center;
          font-size: 0.28rem;
          padding: 0.22rem;
          padding-bottom: 0.22rem;
          border-bottom: 1px solid #fff;

          .avatar {
            width: 1.16rem;
            height: 1.16rem;
            border-radius: 50%;
            margin-right: 0.2rem;
            overflow: hidden;

            :deep() {
              .warp {
                border-radius: 50%;
              }
            }
          }

          .avatar-right {
            flex: 1;
          }

          .avatar-right-top {
            display: flex;
            align-items: center;
            margin-bottom: 0.18rem;

            p {
              margin: 0;
            }

            .name {
              font-weight: 700;
              margin-right: 0.2rem;
            }
          }

          .boss-info {
            color: rgba($color: #6a6a6a, $alpha: 0.7);
            display: flex;
            justify-content: space-between;
          }

          .recommend {
            background-image: linear-gradient(to bottom, #fd9c3a, #fc342d);
            color: #fff;
            width: 0.7rem;
            height: 0.32rem;
            line-height: 0.32rem;
            font-size: 0.22rem;
            border-radius: 0.1rem;
            text-align: center;
          }
        }

        // 妹子图片
        .girls-img {
          margin: 0.3rem 0.22rem;
          display: flex;
          align-items: center;
          overflow-y: auto;
          @include scrollbar-hide;

          .girls-item {
            width: 2.02rem;
            height: 2.28rem;
            border-radius: 0.2rem;
            margin-right: 0.1rem;
            flex-shrink: 0;
            overflow: hidden;

            ::v-deep {
              .default {
                height: 100%;
              }
            }
          }
        }

        // 经纪人信息
        .boss-info {
          font-size: 0.24rem;
          display: flex;
          align-items: center;

          .line {
            width: 0.04rem;
            height: 0.17rem;
            background: #666666;
            margin: 0 0.3rem;
          }
        }
      }
    }
  }

  .btn-pack {
    position: fixed;
    left: 0;
    right: 0;
    bottom: -0.01rem;
    background: #fff;
    z-index: 999;

    .buy-btn {
      width: 7.02rem;
      height: 0.8rem;
      @include flex-center;
      border-radius: 0.08rem;
      margin: 0 auto;
      color: #ff669d;
      font-size: 0.24rem;
      background: #333;
      z-index: 999;
      margin-bottom: 0.38rem;

      img {
        width: 0.3rem;
        margin-right: 0.1rem;
      }

      em {
        color: #fff;
        font-size: 0.32rem;
        margin-right: 0.08rem;
      }
    }

    .releaseBtn_pack {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .contactDetails {
        width: 7.02rem;
        height: 0.8rem;
        @include flex-center;
        border-radius: 0.08rem;
        margin: 0 auto;
        color: #ff669d;
        font-size: 0.24rem;
        background: #333;
        z-index: 999;
        margin-bottom: 0.38rem;
      }

      .releaseBtn {
        width: 2.3rem;
        height: 0.8rem;
        @include flex-center;
        border-radius: 0.08rem;
        margin: 0 auto;
        color: #fff;
        font-size: 0.36rem;
        background: linear-gradient(109.99deg, #00dbd4 -0.6%, #31e5b0 46.87%, #31e5b0 100.66%);
        z-index: 999;
        margin-bottom: 0.38rem;
      }
    }
  }
}
</style>
