<template>
  <!-- 更多专题更多列表,一行3个 -->
  <div class="home-comic-more">
    <EBackTop :title="$route.query.name" />
    <ETab
      class="more-tab"
      @change="change"
      :sticky="true"
      :tabList="category"
      :v-model="sort ? sort : category && category[0] ? category[0].id : 0"
      :animated="false"
      :lineSwitch="true"
      :lineBg="'#ff669d'"
      :lineHeight="'0.08rem'"
      :line-width="'0.4rem'"
      :title-active-color="'#ff669d'"
      :title-inactive-color="'#333'"
      :sizeTab="'0.3rem'"
      :sizeActive="'.3rem'"
    >
      <template>
        <div class="more-scroll">
          <ERefreshLoad
            @refreshData="refreshData"
            @moreData="moreData"
            :finished="finished"
            :loading="loading"
            :refreshing="refreshing"
          >
            <div class="list-wrap">
              <ul>
                <DmComicCard
                  v-for="(item, index) in comicsList"
                  :item="item"
                  :key="index"
                  :nowTabItem="{ showType: $route.query.type }"
                ></DmComicCard>
              </ul>
            </div>
          </ERefreshLoad>
        </div>
      </template>
    </ETab>

    <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂时没有更多数据！"
        @load="onLoad"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
      >
        <div class="list-wrap">
          <ul>
            <DmComicCard
              v-for="(item, index) in comicsList"
              :item="item"
              :key="index"
              :nowTabItem="{ showType: $route.query.type }"
            ></DmComicCard>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh> -->
  </div>
</template>
<script>
import { comicsTopicList } from 'api/comics'
export default {
  components: {
    DmComicCard: () => import('@/components/HomeComic/oneCard'),
    ETab: () => import('@/components/web_base/component_base/ETab/index.vue')
    // HeaderBar: () => import('@/components/HeaderBar.vue'),
  },
  data() {
    return {
      category: [
        // { name: '最热', id: 0 },
        // { name: '最新', id: 1 }
        { name: '最新', id: 0 },
        { name: '最热', id: 1 }
      ],
      sort: 0,
      //加载刷新
      pageNum: 1,
      pageSize: 12,
      refreshing: false,
      loading: false,
      finished: false,
      comicsList: [],
      type: 0
    }
  },
  methods: {
    async comicsAuthor(type) {
      const res = await comicsTopicList({
        id: +this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sort: this.sort
      })
      if (res.code === 200 && res.data) {
        this.refreshing = false
        this.loading = false
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true
        }
        if (res.data.list) {
          if(this.pageNum == 1) this.comicsList = []
          this.comicsList = [...this.comicsList, ...res.data.list]
        }
      } else {
        this.finished = true
        this.refreshing = false
        this.loading = false
        return this.$toast(res.tip)
      }
    },
    change(type) {
      this.sort = type
      this.refreshData()
    },
    //上拉加载更多
    async moreData() {
      this.loading = true
      this.pageNum += 1
      this.comicsAuthor()
    },
    // 刷新
    refreshData() {
      this.refreshing = true
      this.finished = false
      this.pageNum = 1
      this.loading = true
      this.comicsAuthor()
    }
  },
  mounted() {
    this.refreshData()
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.home-comic-more {
  // min-height: 100vh;
  // padding-bottom: 0.1rem;
  :deep() {
    .van-sticky {
      z-index: 99;
      padding: 0 0.16rem;
      .van-tab {
        flex: inherit;
      }
    }
    .van-tabs__nav {
      background: transparent !important;
    }
  }
  .list-wrap {
    // padding: 0 0.37rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  // .more-scroll {
  //   height: calc(100vh - 2.1rem);
  //   padding: 0 0.26rem;
  //   overflow-x: auto;
  //   margin-top: 0.2rem;
  // }
  .more-tab {
    position: fixed;
    left: 0;
    top: calc(env(safe-area-inset-top) + 1rem);
    top: calc(constant(safe-area-inset-top) + 1rem);
    height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 1rem);
    height: calc(100vh - constant(safe-area-inset-bottom) - constant(safe-area-inset-top) - 1rem);
    overflow: hidden;
    z-index: 2;
    .more-scroll {
      // height: calc(100vh - 2.1rem);
      height: calc(100vh - 2.1rem - env(safe-area-inset-bottom) - env(safe-area-inset-top));
      height: calc(100vh - 2.1rem - constant(safe-area-inset-bottom) - constant(safe-area-inset-top));
      padding: 0 0.26rem;
      overflow-x: auto;
      margin-top: 0.2rem;
      padding-bottom: 1.2rem;

    }
  }
}
</style>
