<template>
  <div class="home-pic-view" :style="styleVar" ref="home-pic-view">
    <div class="click-show" v-show="clickShow">
      <!-- <HeaderBar :navText="datasObj.title" :bgColor="'transparent'" :isDark="true" :textColor="'#fff'"></HeaderBar> -->
      <EBackTop class="back-top" :title="datasObj.title" :fixed="true" :isDark="true" :background="'transparent'"
        :color="'#fff'" />
      <div class="share_bot" @click="setMyCode = true"><img src="@/assets/imgs/homec/shareIcon.png" alt="" /> 分享</div>
      <div class="progress-wrap" @click="clickShow = !clickShow">
        <!-- 护眼模式 -->
        <div class="hu_yan">
          <div class="hu_yan_title">护眼模式</div>
          <van-switch @click.stop.prevent v-model="eyeProtectionMode" @change="onInput" size="10px"
            active-color="#F9C006" inactive-color="#4a4647" />
          <div style="margin: 0 0.2rem 0 1.6rem">自动播放</div>
          <van-switch @click.stop.prevent v-model="autoPlay" @change="beginAuto" size="20px" active-color="#F9C006"
            inactive-color="#4a4647" />
        </div>
        <!-- 亮度 -->
        <div class="liang_du">
          <img src="@/assets/imgs/homec/tai_yang.png" alt="" />
          <van-slider v-model="liangDuValue" bar-height="0.1rem" bar-width="2rem" :min="0" :max="9"
            active-color="#F9C006" @change="onBrightness">
            <template #button>
              <div class="custom-button"></div>
            </template>
          </van-slider>
        </div>

        <!-- edit -->
        <div class="edit_menu">
          <img @click.stop="fn_upPage" src="@/assets/imgs/homec/pre.png" alt="" />
          <div v-if="!comicsData.isCollect" @click.stop="setFavour" class="addStore">
            <img src="@/assets/imgs/homec/shu_jia.png" alt="" />
            <p>加入书架</p>
          </div>
          <div v-else @click.stop="setFavour" class="isAddStore">
            <img src="@/assets/imgs/homec/yi_jia_ru.png" alt="" />
            <p>已加入</p>
          </div>
          <div @click.stop="open_ml" class="ml">
            <img src="@/assets/imgs/homec/mu_lu.png" alt="" />
            <p>目录</p>
          </div>
          <div @click.stop="goCommont" class="commont">
            <img src="@/assets/imgs/homec/ping_lun.png" alt="" />
            <p>评论</p>
          </div>
          <img @click.stop="fn_downPage" src="@/assets/imgs/homec/next.png" alt="" />
        </div>
      </div>
      <!-- 设置弹窗结束 -->
    </div>
    <div class="view-chapter" @click.stop="clickShow = !clickShow" v-show="!clickShow">
      第{{ chapterName }}话 <span>{{ pageValue }} / {{ pageMax }}</span>
    </div>
    <van-index-bar ref="view-index-bar" class="view-index-bar" @change="change" :index-list="indexList">
      <div v-for="(item, index) in datasObj.chapter" :key="index" @click="clickShow = !clickShow"
        :style="{ height: item.reset_high ? item.reset_high + 'px' : 'auto' }">
        <van-index-anchor :index="index + 1" />
        <DecryptImg :needPadding="false" ref="refImg" class="dm-img" :imgURL="item.comicsPic" />
      </div>
    </van-index-bar>
    <ComicCatalogue ref="ComicCatalogue" :price="price" :bgImages="bgImages" :catalogueObj="catalogueObj">
    </ComicCatalogue>
    <DmPopup ref="popupMsg"></DmPopup>
    <SharePopup v-if="setMyCode" :setMyCode="setMyCode" :sharePath="'/home-comic/decial/' + $route.query.parentId"
      @closeGuidance="closeGuidance" />
    <div class="BrightnessMask"></div>
  </div>
</template>
<script>
import { picViewRightTo } from '@/utils/utils_tools'
import { ImagePreview } from 'vant'
import { handleVerAutoImg } from '@/utils/utils_tools'
import { comicsDetail, collectAdd } from 'api/comics'

export default {
  name: 'comicsPic',
  components: {
    DmPopup: () => import('@/components/Popup/index.vue'),
    ComicCatalogue: () => import('@/components/HomeComic/comic/catalogue'),
    // HeaderBar: () => import('@/components/HeaderBar.vue'),
    DecryptImg: () => import('@/components/DecryptImg/index.vue'),
    SharePopup: () => import('@/components/Popup/sharePopup/index.vue')
  },
  data() {
    return {
      //引导条
      indexList: [],
      catalogueObj: {
        list: [],
        show: false
      },
      isLike: true,
      //点击
      clickShow: false,
      //章节控制
      pageMax: 50,
      pageValue: 1,
      chapterName: '',
      //加载刷新
      datasObj: {
        author: [],
        chapter: [],
        chapterInfos: []
      },
      pageItem: {},
      //窗口宽度
      clientWidth: 0,
      comicsData: {},
      setMyCode: false,
      // 设置
      liangDuValue: 8,
      styleVar: {
        '--brightness_css': '0.05',
        '--fontSize': '.3rem',
        '--lineHeight': '.44rem',
        '--backgroundActive': '#F6E9C7'
      },
      eyeProtectionMode: false, //护眼模式
      autoPlay: false, //自动播放,
      interval: undefined,
      price: 0,
      bgImages: '',
      title: ''
    }
  },
  watch: {
    $route: {
      async handler(n, o) {
        if (!!o) {
          if (n.path != o.path) {
            this.init()
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.init()
    this.price = Number(this.$route.query.price)
    this.bgImages = this.$route.query.bgImages
    this.title = this.$route.query.title
    //监控到底加载
    window.addEventListener('scroll', this.scrollHandle)
    // window.addEventListener('touchstart', this.touchFun)
    this.$refs['view-index-bar'].$el.addEventListener('touchstart', this.touchFun)
  },
  methods: {
    init() {
      //获取数据
      let datasObj = JSON.parse(JSON.stringify(this.$store.state.history.comicChapterObj))
      console.log(datasObj)
      //初始化
      this.comicsChapterPics(datasObj)
      //查找是否已收藏 更新书架当前已看到的位置
      this.findIndex()
      //获取漫画详细信息
      this.getComicsDetail()
      //页数跳转
      if (this.$store.state.history.comicsHisMap[this.$route.query.parentId]) {
        if (this.$store.state.history.comicsHisMap[this.$route.query.parentId][this.$route.params.id]) {
          const index = this.$store.state.history.comicsHisMap[this.$route.query.parentId][this.$route.params.id]
          setTimeout(() => {
            this.pageValue = index
            this.onChange(index)
          }, 200)
        }
      }
    },
    //TODO：开关护眼模式
    onInput(checked) {
      if (checked === false) {
        this.onBrightness()
      } else {
        this.onBrightness(2)
      }
    },
    //TODO：开关自动播放
    beginAuto(checked) {
      if (checked === false) {
        clearInterval(this.interval)
        this.$toast('自动阅读已关闭')
      } else {
        this.autoscroll()
        this.$toast('自动阅读已开启')
      }
    },
    //TODO：手势监听事件，暂停自动播放
    touchFun() {
      if (!this.clickShow) {
        if (this.autoPlay === true) {
          this.$toast('自动阅读已关闭')
          clearInterval(this.interval)
          this.autoPlay = false
        }
      }
    },
    autoscroll() {
      let n = this.$refs['view-index-bar'].scroller.scrollY
      this.interval = setInterval(() => {
        if (this.$refs['view-index-bar'] && this.$refs['view-index-bar'].scroller) {
          n = n + 1
          this.$refs['view-index-bar'].scroller.scrollTo(0, n)
        }
      }, 50)
    },
    closeGuidance() {
      this.setMyCode = false
    },
    onBrightness(value) {
      //亮度调节
      let a = 9
      if (value) {
        a = 9 - value
      } else {
        a = 9 - this.liangDuValue
      }
      this.styleVar['--brightness_css'] = a / 20
    },
    // 打开评论列表
    goCommont() {
      this.$router.push({
        path: `/home-comic/evaluation/${+this.$route.query.parentId}`,
        query: {
          coverImg: this.datasObj.chapter[0].comicsPic,
          title: this.datasObj.title
        }
      })
    },
    //滚动事件
    scrollHandle() {
      if (this.datasObj.chapter.length < 3) return
      if (this.pageValue >= this.datasObj.chapter.length - 3) {
        let marginBot = 0
        if (document.documentElement.scrollTop) {
          marginBot =
            document.documentElement.scrollHeight -
            (document.documentElement.scrollTop + document.body.scrollTop) -
            document.documentElement.clientHeight
        } else {
          marginBot = document.body.scrollHeight - document.body.scrollTop - document.body.clientHeight
        }
        if (marginBot <= 0) {
          this.fn_downPage()
        }
      }
    },
    // 图片预览
    fn_imagePreview(index) {
      const domain = this.$store.getters['config/cdn'].imgCdn
      let baseUri = ''
      let imgArr = []
      let domArr = this.$refs['refImg']
      domArr.forEach(async (item, index) => {
        if (item.realUrl) {
          imgArr.push(item.realUrl)
        }
      })
      ImagePreview({
        images: imgArr, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: false, //是否开启循环播放
        startPosition: index, //图片预览起始位置索引
        closeable: true,
        onChange: async function (nowIndex) {
          // 懒加载完成后继续加载下一页
          if (nowIndex === imgArr.length - 1) {
            baseUri = await handleVerAutoImg(`${domain}${domArr[nowIndex + 1].imgURL}`)
            imgArr.push(baseUri)
            this.startPosition = nowIndex
          }
        }
      })
    },
    //初始化图片高度
    get_viewPicHeight(item) {
      //没有数据默认auto
      if (!item.high || !item.width) return
      if (!this.clientWidth) {
        this.clientWidth = document.querySelector('.home-pic-view').clientWidth
      }
      item.reset_width = document.querySelector('.home-pic-view').clientWidth
      item.reset_high = (this.clientWidth * item.high) / item.width
      if (!Number.isInteger(item.reset_high)) {
        item.reset_high = +item.reset_high.toFixed(3)
      }
    },
    //上一章
    async fn_upPage() {
      if (this.catalogueObj.list.length <= 1) return this.$toast('当前只有一章')
      if (this.chapterName - 1 === 0) return this.$toast('当前第一章')
      this.catalogueObj.list.forEach(async (item, index) => {
        if (+this.$route.params.id == item.id) {
          this.pageItem = this.catalogueObj.list[index - 1]
          this.$toast('正在加载第' + (this.chapterName - 1) + '话')
          let code = await picViewRightTo(
            this,
            +this.pageItem.id,
            this.price,
            null,
            null,
            null,
            null,
            null,
            this.bgImages,
            this.title
          )
        }
      })
    },
    //下一章
    async fn_downPage() {
      if (this.chapterName - 1 === this.catalogueObj.list.length) return this.$toast('已经是最后一话了')
      this.catalogueObj.list.forEach(async (item, index) => {
        if (+this.$route.params.id == item.id) {
          if (this.catalogueObj.list.length - 1 != index) {
            this.pageItem = this.catalogueObj.list[index + 1]
            this.$toast('正在加载第' + (this.chapterName + 1) + '话')
            let code = await picViewRightTo(
              this,
              +this.pageItem.id,
              this.price,
              null,
              null,
              null,
              null,
              null,
              this.bgImages,
              this.title
            )
          } else {
            return this.$toast('已经是最后一话了')
          }
        }
      })
    },
    //打开目录
    open_ml() {
      this.clickShow = false
      this.$refs['ComicCatalogue'].initDatas()
    },
    //slider滑动跳页，跳页不能开启-van字符查找改变
    onChange(value) {
      this.$refs['view-index-bar'].scrollTo(value.toString())
    },
    //van字符查找改变,正在滑动不调用
    change(index) {
      this.pageValue = index
      this.$store.commit('setComicsHisMap', {
        parentId: this.$route.query.parentId,
        id: this.$route.params.id,
        pageValue: this.pageValue
      })
    },
    //获取章节信息
    comicsChapterPics(datasObj) {
      this.datasObj = datasObj
      this.pageMax = datasObj.chapter.length
      datasObj.chapter.forEach((item, index) => {
        //引导条
        this.indexList.push(index + 1)
        //计算初始化高度
        this.get_viewPicHeight(item)
      })

      datasObj.chapterInfos.forEach((item, index) => {
        item.indexName = index + 1
        if (+this.$route.params.id == item.id) {
          this.chapterName = index + 1
        }
      })
      this.catalogueObj.list = datasObj.chapterInfos
    },
    //收藏位置
    findIndex() {
      let status = false
      let newBookshelfList = JSON.parse(JSON.stringify(this.bookshelfList))
      newBookshelfList.forEach(element1 => {
        element1.list.forEach(element2 => {
          if (element2.id == +this.$route.query.parentId) {
            status = true
            //大于才更新
            if (!element2.hasViewNum) {
              element2.hasViewNum = this.datasObj.Num
            } else {
              if (this.datasObj.Num > element2.hasViewNum) element2.hasViewNum = this.datasObj.Num
            }
            element2.hasViewCount = this.datasObj.Count
            this.$store.commit('setBookshelf', newBookshelfList)
          }
        })
      })
      if (!status) {
        this.isLike = false
      } else {
        this.isLike = true
      }
    },
    //本地收藏
    async setFavour() {
      const res1 = await collectAdd({
        collectType: 8,
        flag: !this.comicsData.isCollect,
        object_id: this.comicsData.id,
        type: 1
      })
      if (res1.code === 200) {
        if (!this.comicsData.isCollect) {
          this.comicsData.isCollect = true
          return this.$toast('收藏成功')
        } else {
          this.comicsData.isCollect = false
          return this.$toast('取消收藏')
        }
      } else {
        return this.$toast('操作失败')
      }
    },
    async getComicsDetail() {
      const res = await comicsDetail({
        id: +this.$route.query.parentId
      })
      if (res.code === 200) {
        this.comicsData = res.data.comicsData
      }
    }
  },

  computed: {
    bookshelfList({ $store }) {
      return $store.state.history.bookshelfList
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandle)
    clearInterval(this.interval)
    this.$refs['view-index-bar'].$el.removeEventListener('touchstart', this.touchFun)
  }
}
</script>

<style lang="scss" scoped>
.home-pic-view {
  margin-bottom: 3rem;
  width: 100%;

  .click-show {
    :deep() {
      .nav-wrap {
        background: linear-gradient(180.24deg, rgba(0, 0, 0, 0.6) 20.94%, rgba(25, 24, 26, 0) 80.79%);
      }
    }

    .share_bot {
      position: fixed;
      top: 0.25rem;
      right: 0.44rem;
      display: flex;
      align-items: center;
      border-radius: 1rem;
      background: #ff669d;
      color: #fff;
      font-size: 0.28rem;
      height: 0.5rem;
      padding: 0 0.12rem;
      z-index: 10001;

      img {
        height: 0.28rem;
        margin-right: 0.1rem;
      }
    }

    .add-store {
      position: fixed;
      text-align: center;
      right: 50%;
      margin-right: -calc($pcMaxWidth/2);
      top: 20vh;
      width: 1.69rem;
      height: 0.53rem;
      line-height: 0.53rem;
      border-top-left-radius: 0.27rem;
      border-bottom-left-radius: 0.27rem;
      background: #ffffff;
      font-size: 0.28rem;
      color: #ff7777;
      z-index: 99;
    }

    .progress-wrap {
      max-width: $pcMaxWidth;
      position: fixed;
      bottom: 0;
      width: calc(100% - 0.72rem);
      z-index: 99;
      border-radius: 0.16rem;
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid rgba(255, 255, 255, 0.5);
      @include box-shadow-all;
      padding: 0.48rem 0.64rem;
      // margin: .4rem .36rem;
      color: #fff;
      left: 50%;
      transform: translate(-50%, 0);

      .hu_yan {
        @include flex-align-center;

        .hu_yan_title {
          width: 1.28rem;
          height: 0.54rem;
          border-radius: 0.08rem;
          border-radius: 0.08rem;
          background: #ff669d;
          text-align: center;
          line-height: 0.54rem;

          margin-right: 0.22rem;
        }

        .ke_du {
          width: 3.6rem;
          height: 0.24rem;
          background-color: #fff;
        }
      }

      .liang_du {
        @include flex-align-center;
        margin: 0.48rem 0;

        img {
          width: 0.42rem;
          height: 0.42rem;
          margin-right: 0.36rem;
        }

        .custom-button {
          width: 0.12rem;
          height: 0.24rem;
          flex-shrink: 0;
          border-radius: 0.12rem;
          background: #f9c006;
        }
      }

      .edit_menu {
        @include flex-align-center;
        justify-content: space-around;
        font-size: 0.2rem;

        &>img {
          width: 0.42rem;
        }

        .addStore,
        .ml,
        .commont,
        .isAddStore {
          @include flexbox($fd: column, $jc: center, $flex: 0.2);
        }

        .addStore,
        .isAddStore {
          img {
            width: 0.33rem;
          }
        }

        .ml {
          img {
            width: 0.44rem;
          }
        }

        .commont {
          img {
            width: 0.43rem;
          }
        }

        .isAddStore {
          color: rgba(255, 255, 255, 0.4);
          font-family: PingFang HK;
          font-size: 0.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .view-chapter {
    z-index: 999;
    position: fixed;
    bottom: 0.8rem;
    right: 50%;
    margin-right: -calc($pcMaxWidth/2) + 0.2rem;
    background: rgba(#000, 0.6);
    border-radius: 0.2rem;
    padding: 0.05rem 0.2rem;
    text-align: center;
    color: #fff;
  }

  .view-wrap {
    li {
      font-size: 0;
      width: 100%;
      min-height: 4rem;

      .dm-img {
        width: 100%;
      }
    }
  }

  //定位器
  :deep() {
    .view-index-bar {

      .van-index-bar__sidebar,
      .van-index-anchor {
        display: none;
      }
    }
  }
}

.BrightnessMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  opacity: var(--brightness_css) !important;
  pointer-events: none;
  z-index: 9999;
}
</style>
