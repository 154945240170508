<template>
  <div class="sign-rule">
    <EBackTop :title="'积分兑换记录'" :placeholder="false"></EBackTop>
    <div class="rule-wrap">
      <ul v-if="list && list.length" v-for="item in list" :key="item.id">
        <li>
          <div class="price">{{ item.prizeName || '' }}</div>
          <div class="date">{{ item.updatedAt }}</div>
        </li>
        <li class="point">-{{ item.usePoint }}</li>
      </ul>
      <Nodata v-else />
    </div>
  </div>
</template>

<script>
import { prize_redeemhistory } from '@/api/user'

export default {
  components: {

    Nodata: () => import('@/components/JavNodata.vue')
  },
  data() {
    return {
      list: [],
      param: {
        "pageNum": 1,
        "pageSize": 100
      }
    }
  },
  methods: {
    format(time, format) {
      var t = new Date(time);
      var tf = function (i) { return (i < 10 ? '0' : '') + i };
      return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        switch (a) {
          case 'yyyy':
            return tf(t.getFullYear());
            break;
          case 'MM':
            return tf(t.getMonth() + 1);
            break;
          case 'mm':
            return tf(t.getMinutes());
            break;
          case 'dd':
            return tf(t.getDate());
            break;
          case 'HH':
            return tf(t.getHours());
            break;
          case 'ss':
            return tf(t.getSeconds());
            break;
        }
      })
    },
    async getList() {
      let res = await prize_redeemhistory(this.param)
      if (res.code === 200) {
        res.data.list.length && res.data.list.forEach(item => {
          item.updatedAt = this.format(item.updatedAt, 'yyyy/MM/dd HH:mm')
        })
        this.list = res.data.list
        console.log('ressss', this.list)
      } else {
        this.$toast(res.tip)
      }
    },

  },
  mounted() {
    this.getList()
    // let d = '2024-08-12T07:42:45.964Z'
    // let m = this.format(d, 'yyyy-MM-dd HH-mm').replace(/-/g, '/')
    // console.log('mmmm____________', m)
  }
}
</script>

<style lang="scss" scoped>
.sign-rule {
  padding-top: .5rem;
  //margin-top: 0.2rem;
  font-size: 0.28rem;
  color: #6a6a6a;

  .rule-wrap {
    padding: 0.61rem 0.64rem 0;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .3rem 0;

      .price {
        color: #000;
      }

      .date {
        color: #aaaaaa;
        font-size: .22rem;
      }

      .point {
        border-radius: .12rem;
        background-color: #ffeff5;
        color: #ff78aa;
        border: 1px solid #ff78aa;
        padding: .05rem .2rem;
      }
    }
  }
}
</style>
