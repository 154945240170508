<template>
    <div class="header-nav" :class="{ showline: showline, isLongVideo: isLongVideo }"
        :style="{ color: color, background: background }">
        <div class="left-text" @click="toBack">
            <van-icon :size="22" name="arrow-left" />
            <!-- <span>{{ leftText }}</span> -->
        </div>
        <p class="title">{{ title }}</p>
        <div slot="right" @click="checkRight" class="right-text2">
            <div v-if="rightText">{{ rightText }}</div>
            <div v-if="rightImages" class="rightImg"><img :src="rightImages" alt="" /></div>
        </div>
        <!-- <div @click="checkRight" class="right-text2"> <div v-if="rightText=='编辑'||rightText=='确认'">{{ rightText }}</div></div> -->
    </div>
</template>

<script>
import { fallback } from '@/utils/utils_tools'
export default {
    props: {
        openFallback: {
            type: Boolean,
            default() {
                return false;
            },
        },
        showline: {
            type: Boolean,
            default() {
                return false
            },
        },
        leftText: {
            type: String,
            default() {
                return '返回'
            },
        },
        toBackType: {
            type: Boolean,
            default() {
                return false
            },
        },
        title: {
            type: String,
            default() {
                return '标题'
            },
        },
        rightText: {
            type: String,
            default() {
                return ''
            },
        },
        rightImages: {
            type: String,
            default() {
                return ''
            },
        },
        color: {
            type: String,
            default() {
                return '#000'
            },
        },
        background: {
            type: String,
            default() {
                return '#fff'
            },
        },
        isLongVideo: {
            type: Boolean,
            default() {
                return false
            },
        }
    },
    data() {
        return {
            // color:'#fff'
        }
    },
    methods: {
        // // 监听滚动高度
        // scrollHandle(e) {
        //   let top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
        //   if (top >= 60) {
        //     this.color = '#171719'
        //   }else{
        //     this.color = 'transparent'
        //   }
        // },
        toBack() {
            if(this.showTo){
                this.$router.go('/circle');
            }else if (!this.toBackType) {
            
                if (this.openFallback) {

                    fallback(this)
                } else {
                    this.$router.go(-1);
                }
            } else {
                this.$emit('toBack')
            }
        },
        checkRight() {
            this.$emit('checkRight')
        },
    },
    mounted() {
        // window.addEventListener('scroll', this.scrollHandle) //绑定页面滚动事件
    },
}
</script>

<style lang="scss" scoped>
.header-nav {
    font-size: 0.32rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: $pcMaxWidth;
    height: 1rem;
    line-height: 1rem;
    padding: 0 0.34rem 0 0.2rem;
    justify-content: center;
    box-sizing: border-box;
    z-index: 12;

    &.showline {
        // border-bottom: 1px solid #e5e5e5;
    }

    p {
        margin: 0;
    }

    .left-text {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0.2rem;
        margin-left: -0.1rem;

        ::v-deep {
            .van-icon {
                margin-right: 0.15rem;
            }
        }
    }

    .title {
        max-width: 4.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .right-text {
        min-width: 0.5rem;

        div {
            font-weight: 400;
            font-size: 0.22rem !important;
            height: 0.33rem;
            line-height: 0.33rem;
            padding: 0 0.13rem;
            border-radius: 0.2rem;
        }
    }

    .right-text2 {
        position: absolute;
        right: 0.2rem;

        div {
            font-weight: 400;
            font-size: 0.28rem !important;
            border: 0;
            height: 0.33rem;
            line-height: 0.33rem;
            padding: 0 0.13rem;
            border-radius: 0.2rem;
        }

        .rightImg {
            height: 0.48rem;
            padding: 0;

            img {
                width: 0.48rem;
            }
        }
    }
}

.isLongVideo {
    width: 50%;
    max-width: 3rem;
}
</style>
