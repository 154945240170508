<template>
  <div class="item-card" @click="toDeatail(itemData)">
    <DecryptImg :needPadding="false" class="img-card" :imgURL="itemData.images && itemData.images[0]"> </DecryptImg>
    <!-- 楼风最新 -->
    <div class="itemData-msg">
      <div class="title van-ellipsis">{{ itemData.title }}</div>
      <div>销售量：{{ itemData.sells }}</div>
      <div>年龄：{{ itemData.girlAge }}岁</div>
      <div>地区：{{ itemData.cityName || itemData.serviceCity[0] }}</div>
      <div>
        <em>价格一览：{{ itemData.price }}</em>
      </div>
      <div>描述：{{ itemData.desc }}</div>
      <div>服务项目：{{ itemData.serviceItems ? itemData.serviceItems.toString() : itemData.serviceItems }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showType: {
      type: Number,
      default() {
        return 1
      }
    },
    itemData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  components: {
    DecryptImg: () => import('@/components/DecryptImg/index.vue')
  },
  computed: {},
  mounted() {},
  methods: {
    toDeatail() {
      this.$router.push({
        path: `/dating/model-decial/${this.itemData.id}`,
        query: {
          type: this.showType,
          name: this.showType == 1 ? '楼凤' : '外围'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item-card {
  width: 100%;
  position: relative;
  display: flex;
  //   flex-direction: column;
  //   background-color: #f7f6fb;
  color: #333333;
  margin-bottom: 0.3rem;
  //卡片
  .img-card {
    border-radius: 0.08rem;
    overflow: hidden;
    min-width: 2.08rem;
    max-width: 2.08rem;
    height: 3.04rem;
    position: relative;
    margin-right: 0.28rem;
    :deep() {
      .warp {
        border-top-left-radius: 0.24rem;
        border-top-right-radius: 0.24rem;
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  //详细信息
  .itemData-msg {
    padding: 0.11rem 0.2rem;
    padding-left: 0;
    border-radius: 0.24rem;
    background: #fff;
    position: relative;
    z-index: 2;
    margin-top: -0.15rem;
    display: flex;
    flex-direction: column;
    color: #999;
    em {
      color: #ff669d;
    }
    .title {
      font-size: 0.26rem;
      color: #333333;
      font-size: 0.3rem;
      margin-bottom: 0.1rem;
    }
    div {
      width: 4.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
