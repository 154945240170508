<template>
  <div class="advertise-item">
    <van-swipe class="my-swipe" :autoplay="2000" indicator-color="white" style="width: 100%;height: 100%;">
      <van-swipe-item v-for="item in list">
        <EDecryptImg :imgCDN="imgCDN" :imgURL="item.cover" @click.native="toPlay(item)" />
        <div class="advertise-desc">{{ item.title }}</div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { advertiseClick } from 'api/home'
import { handleParamsRouteJump, handleURlParams } from '@/utils/utils_tools'
export default {
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
  },
  methods: {
    async toPlay(item) {
      if (!item.href) return
      const code = handleURlParams(item.href)
      handleParamsRouteJump(this.$router, this.$refs['playVideo'], code, this.$store)
      const res = await advertiseClick({
        id: item.id
      })
      if (res !== 200) {
        return this.$toast(res.tip)
      }
      return

    },
  }
}
</script>

<style lang="scss" scoped>
.advertise-item {
  position: relative;
  border-radius: 0.08rem;
  overflow: hidden;

  :deep() img {
    height: 1.9rem;
  }

  &::before {
    content: '广告';
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    font-family: PingFang SC;
    font-size: 0.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.02rem 0.12rem;
    border-radius: 0.12rem 0rem;
    background: linear-gradient(111deg, #ff3981 9.07%, #cd0025 96.67%);
    z-index: 1;
  }
}

.advertise-desc {
  font-size: 0.24rem;
  padding: 0.14rem;
}
</style>
